import {
  ACTION_TYPE,
  processBeatsByStrategyConst,
} from 'constant/PatternMatchingConst';

import { beatProcessingStrategies } from './strategies';

export * from './strategies';
export * from './processors';
export * from './validators';
export * from './types';
export * from './patternRangeUtils';

/**
 * 패턴 매칭모드에서 비트 업데이트시(유사패턴, 찾은 rpeak 관련) 비트 처리 전략 선택 및 실행
 */
const { WITH_FINDING_RPEAKS, WITHOUT_FINDING_RPEAKS, DELETE_RPEAK } =
  processBeatsByStrategyConst;

/**
 * 선택된 전략으로 비트 처리 실행
 *  * import한 모듈
 *    1. beat marker in 30s strip
 *    2. beat label button marker in 10s strip
 */
export const processBeatsByStrategy = (currentBeats, options) => {
  const strategy = selectStrategy(options);
  return beatProcessingStrategies[strategy](currentBeats, options);
};

/**
 * 비트 처리 전략 선택
 * @param {Object} options - 전략 선택을 위한 옵션
 * @returns {string} 선택된 전략 키
 */
const selectStrategy = (options) => {
  // Delete_Rpeak 액션인 경우
  if (options.actionType === ACTION_TYPE.DELETE_RPEAK) {
    return DELETE_RPEAK;
  }

  // Select_BeatType 액션이면서 rpeak가 있는 경우
  const isSelectBeatTypeWithRpeaks =
    options.actionType === ACTION_TYPE.SELECT_BEAT_TYPE &&
    options.rpeakList.length > 0;

  return isSelectBeatTypeWithRpeaks
    ? WITH_FINDING_RPEAKS
    : WITHOUT_FINDING_RPEAKS;
};
