import { useEffect, useState } from 'react';
import { useDispatch, batch } from 'react-redux';

import {
  ShapeReviewRawAndEventCalledCase,
  ShapeReviewSectionArea,
} from 'constant/ShapeReviewConst';

import { getEventInfo } from 'util/EventConstUtil';

import useShallowEqualSelector from 'component/hook/useShallowEqualSelector';
import useChartList from 'component/hook/useChartList';

import FormsPanelListFragmentContainer from 'redux/container/fragment/test-result/shape-review/formsPanel/FormsPanelListFragmentContainer';
import FormsPanelNavigationFragmentContainer from 'redux/container/fragment/test-result/shape-review/formsPanel/FormsPanelNavigationFragmentContainer';
import { CheckBoxStatus } from 'redux/container/fragment/test-result/shape-review/ShapeReviewFragmentContainer';

import { ShapeReviewEventType } from '@type/ecgEventType/baseEventType';
import {
  ClickedInfo,
  FormPanelSelectedInfo,
  SelectedInfo,
  SetLastSelectedSectionInfo,
  SetSelectedItemList,
  ChartListItem,
} from 'redux/duck/shapeReview/shapeReviewDuckType';
import {
  setLastSelectedSectionInfo as setLastSelectedSectionInfoHandle,
  setSelectedItemList as setSelectedItemListHandle,
  //
  selectFormDataOfSelectedEvent,
  selectSelectedInfoOfFormsPanel,
  selectClickedInfo,
  selectSelectedInfo,
} from 'redux/duck/shapeReviewDuck';

import FormsPanelFooterFragmentContainer from './FormsPanelFooterFragmentContainer';

function FormsPanelFragmentContainer() {
  const dispatch = useDispatch();

  // redux selectors
  const clickedInfo = useShallowEqualSelector(selectClickedInfo) as ClickedInfo;
  const panelList = useShallowEqualSelector(
    selectFormDataOfSelectedEvent
  ) as ChartListItem[];
  const selectedInfoState = useShallowEqualSelector(
    selectSelectedInfo
  ) as SelectedInfo;
  const selectedInfoOfFormsPanel = useShallowEqualSelector(
    selectSelectedInfoOfFormsPanel
  ) as FormPanelSelectedInfo;

  const [checkBoxStatus, setCheckBoxStatus] = useState<CheckBoxStatus>({
    isIndeterminate: false,
    isChecked: false,
  });

  // redux dispatches
  const handleSelectedItemList = ({
    panelType,
    selectedItemList,
  }: SetSelectedItemList) => {
    return dispatch(setSelectedItemListHandle({ panelType, selectedItemList }));
  };
  const handleLastSelectedSectionInfo = ({
    triggerType,
    panelType,
    lastSelectedSectionInfo,
  }: SetLastSelectedSectionInfo) => {
    return dispatch(
      setLastSelectedSectionInfoHandle({
        triggerType,
        panelType,
        lastSelectedSectionInfo,
      })
    );
  };

  const {
    gridLayoutRef,
    chartItemRectangle,
    //
    chartList,
    //
    selectedItemList,
    lastSelectedSectionInfo,
    setSelectedItemList,
    setLastSelectedSectionInfo,
    //
    onClickGridItem,
  } = useChartList({
    panelType: ShapeReviewSectionArea.FORMS,
    panelList,
    selectedInfo: selectedInfoOfFormsPanel,
    setCheckBoxStatus,
  });

  // useEffect
  // form panel focus, select 유지 기능
  useEffect(() => {
    const selectedEventInfo = getEventInfo({
      beatType: clickedInfo.beatType,
      ectopicType: clickedInfo.ectopicType,
    })?.[0] as unknown as { type: ShapeReviewEventType };

    if (!selectedEventInfo || !lastSelectedSectionInfo[0]) return;

    const selectedFormId =
      panelList?.[lastSelectedSectionInfo[0].index].formInfo.id;
    const eventPanelSelectState =
      selectedInfoState[ShapeReviewSectionArea.EVENTS][selectedEventInfo?.type][
        selectedFormId
      ];

    if (
      !eventPanelSelectState?.lastSelectedSectionInfo ||
      !eventPanelSelectState?.selectedItemList
    ) {
      return;
    }

    batch(() => {
      handleLastSelectedSectionInfo({
        triggerType: ShapeReviewRawAndEventCalledCase.CLICK_EVENT,
        panelType: ShapeReviewSectionArea.EVENTS,
        lastSelectedSectionInfo: eventPanelSelectState?.lastSelectedSectionInfo,
      });
      handleSelectedItemList({
        panelType: ShapeReviewSectionArea.EVENTS,
        selectedItemList: eventPanelSelectState?.selectedItemList,
      });
    });
  }, [lastSelectedSectionInfo]);

  return (
    <>
      <FormsPanelNavigationFragmentContainer
        checkBoxStatus={checkBoxStatus}
        setCheckBoxStatus={setCheckBoxStatus}
      />
      <FormsPanelListFragmentContainer
        // local state
        checkBoxStatus={checkBoxStatus}
        // useChartList Hook state
        gridLayoutRef={gridLayoutRef}
        chartList={chartList}
        chartItemRectangle={chartItemRectangle}
        selectedItemList={selectedItemList}
        setSelectedItemList={setSelectedItemList}
        lastSelectedSectionInfo={lastSelectedSectionInfo}
        setLastSelectedSectionInfo={setLastSelectedSectionInfo}
        //
        onClickGridItem={onClickGridItem}
      />
      <FormsPanelFooterFragmentContainer />
    </>
  );
}

export default FormsPanelFragmentContainer;
