import { CHART_EDIT_CONST } from 'constant/ChartEditConst';
import {
  CLASS_HUINNO_EVENT_MARKER_PRIORITY_SELECTED,
  CLASS_NAME_HUINNO_CONTEXT_MENU_AREA,
} from 'constant/EventConst';
import {
  FIND_PATTERN_LEVEL_TYPE,
  PATTERN_MATCHING,
} from 'constant/PatternMatchingConst';

import { isRange1InRange2 } from 'util/checkRange';

import AppColors, { getThemeColor } from 'theme/AppColors';
import { EventConstTypes } from '@type/ecgEventType/baseEventType';

const CONST_CLASS_NAME_HUINNO_CONTEXT_MENU_AREA =
  CLASS_NAME_HUINNO_CONTEXT_MENU_AREA;
const CONST_CLASS_HUINNO_EVENT_MARKER_PRIORITY_SELECTED =
  CLASS_HUINNO_EVENT_MARKER_PRIORITY_SELECTED;

const eventMarkerRenderer = {
  /************************/
  /*  render event marker */
  /************************/
  /**
   * @typedef EventInfoType
   * @prop {number} onsetLocalWaveformIndex 시작점, 차트로 시각화된 데이터 인덱스 기준
   * @prop {Boolean} hasOnsetMarker 시작 마커 사용 여부
   * @prop {number} terminationLocalWaveformIndex 종료점, 차트로 시각화된 데이터 인덱스 기준
   * @prop {Boolean} hasTerminationMarker 종료 마커 사용 여부
   * @prop {AppColor} selectedAreaColor 선택된 이벤트 마커의 에어리어 색상
   * @prop {AppColor} unselectedAreaColor 선택되지 않은 이벤트 마커의 에어리어 색상
   * @prop {AppColor | undefined} fillOpacity ONLY Noise 를 위한 속성
   * @prop {AppColor} color 이벤트 마커 색상
   * @prop {number | undefined} offset 이벤트 마커의 Under Line 간격, 생략시 render 안함
   * @prop {String} className 이벤트 마커 종류
   * @prop {number} zIndex 이벤트 마커 UI render 순위
   * @prop {number | undefined} priorityZIndex Click 이벤트를 수용하는 Event Marker 와 동일 크기의 Element 의 UI render 순위
   *
   * @param {*} chart
   * @param {EventInfoType} EventInfo
   * @returns {SVGElement & {setSelectedState: function, priorityElement: SVGElement}} 이벤트 마커 요소를 그룹핑한 <g> 요소, 선택 상태 변경을 위한 기능 있음
   */
  renderEventMarker(
    chart,
    {
      wfiPair, // todo: jyoon - 수정 필요한 부분
      tenSecStripStartGlobalWfi,
      similarPatternCurrLvlTargetRange,
      onsetLocalWaveformIndex,
      hasOnsetMarker,
      terminationLocalWaveformIndex,
      hasTerminationMarker,
      color,
      offset,
      className,
      zIndex,
      priorityZIndex,
      type,
      currLvl,
    }
  ) {
    // ❗️❗️❗️ 이벤트 마커의 모든 요소의 위치는 transform 속성의 translate 기능을 통해 이루어집니다. ❗️❗️❗️
    const {
      x: plotX,
      y: plotY,
      width: plotWidth,
      height: plotHeight,
    } = chart.plotBox;
    const strokeWidth = 1;
    const underLineStrokeWidth = 2;
    const triangleSvg = 'M0 0H1V3L4 0Z';
    const TRIANGLE_CORRECTION_VALUE = 1; // onset or term line을 포함하지 않고 삼각형을 그리기 때문에, line width + 1 좌표에 삼각형을 그리기 위한 보정값
    const widthPerPoint = plotWidth / chart.xAxis[0].max;
    const onsetXPixel = plotX + widthPerPoint * onsetLocalWaveformIndex;
    const terminationXPixel =
      plotX + widthPerPoint * terminationLocalWaveformIndex;
    const eventLengthPixel = terminationXPixel - onsetXPixel;

    // pattern matching logic start
    const spCurrLvlEventMarkerGroup =
      CHART_EDIT_CONST.PATTERN_MATCHING_EVENT_MARKER_GROUP + '-' + currLvl;
    const {
      onsetWaveformIndex: spCurrLvlTargetOnsetWfi,
      terminationWaveformIndex: spCurrLvlTargetTerminationWfi,
    } = similarPatternCurrLvlTargetRange || {};
    const [spOnsetWfi = undefined, spTermWfi = undefined] = wfiPair ?? [];
    // spCurrLvlTargetRangeInSpRange true 대표 패턴 양 옆에 rect를 그리는 조건
    const spCurrLvlTargetRangeInSpRange = isRange1InRange2({
      range1: {
        start: spCurrLvlTargetOnsetWfi,
        end: spCurrLvlTargetTerminationWfi,
      },
      range2: {
        start: spOnsetWfi,
        end: spTermWfi,
      },
    });

    // 대표 패턴일 경우 onsetWfi를 클래스로 추가
    const markerClass = spCurrLvlTargetRangeInSpRange
      ? `huinno-event-marker ${className} origin-pattern-${spOnsetWfi}`
      : `huinno-event-marker ${className}`;
    const addEventMarkerGroup =
      type === PATTERN_MATCHING
        ? chart[spCurrLvlEventMarkerGroup]
        : chart[CHART_EDIT_CONST.EVENT_MARKER_GROUP];

    const marker = chart.renderer
      .g()
      .attr({
        class: markerClass,
        fill: color,
        zIndex,
      })
      .add(addEventMarkerGroup);

    const isPatternMatching = type === PATTERN_MATCHING;

    if (isPatternMatching) {
      if (currLvl === 'level2' && !spCurrLvlTargetRangeInSpRange) {
        const parentElementMarkerGroup = addEventMarkerGroup.element.parentNode;
        const eventMarkerGroup =
          CHART_EDIT_CONST.PATTERN_MATCHING_EVENT_MARKER_GROUP;
        const level1EventMarkerGroup = `${eventMarkerGroup}-${FIND_PATTERN_LEVEL_TYPE.LEVEL1_LOWER_CASE}`;
        const level1MarkerGroup = parentElementMarkerGroup
          .querySelector(`.${level1EventMarkerGroup}`)
          .querySelector(`.origin-pattern-${spOnsetWfi}`);
        const level1rectElements =
          level1MarkerGroup &&
          level1MarkerGroup.querySelectorAll('.origin-pattern');
        if (level1rectElements) {
          level1rectElements.forEach((marker) => marker.remove());
        }
      }

      // 처음 찾은 대표 패턴이 두번째 제외할 패턴에 포함이 되면 양 옆 보더 삭제
      if (spCurrLvlTargetRangeInSpRange) {
        // 대표 패턴 왼쪽, 오른쪽 rect colors
        let originFindPatternBorderColor;
        if (currLvl === FIND_PATTERN_LEVEL_TYPE.LEVEL1_LOWER_CASE) {
          originFindPatternBorderColor = getThemeColor('RED_70');
        } else if (currLvl === FIND_PATTERN_LEVEL_TYPE.LEVEL2_LOWER_CASE) {
          originFindPatternBorderColor = getThemeColor('PAUSE_70');
        }

        chart.renderer
          .rect(0, 0, 2, plotHeight, 0, 0)
          .attr({
            class: `origin-pattern huinno-event-marker-onset-line ${spOnsetWfi}`,
            transform: `translate(${onsetXPixel}, ${plotY})`,
            fill: originFindPatternBorderColor,
            zIndex: currLvl?.zIndex,
          })
          .add(marker);
        chart.renderer
          .rect(0, 0, 2, plotHeight, 0, 0)
          .attr({
            class: `origin-pattern huinno-event-marker-termination-line ${spTermWfi}`,
            transform: `translate(${
              terminationXPixel - strokeWidth
            }, ${plotY})`,
            fill: originFindPatternBorderColor,
            zIndex: currLvl?.zIndex,
          })
          .add(marker);
      }
    }

    const { priorityElement, eventMarkerArea, setSelectedState } =
      this.renderHighlighter(chart, {
        onsetLocalWaveformIndex,
        terminationLocalWaveformIndex,
        className: `${CONST_CLASS_NAME_HUINNO_CONTEXT_MENU_AREA} ${className}`,
        priorityZIndex,
        type,
        color,
      });

    // 선택된 이벤트 구간 강조를 위한 영역 생성
    eventMarkerArea.add(marker);
    /**
     * 선택된 이벤트 구간 강조 여부 처리 용
     * @param {*} isSelected true 면 강조된 색으로 변경
     */
    marker.setSelectedState = setSelectedState;

    // 별도의 Click Event Handler 를 적용하기 위한 요소
    if (!isNaN(priorityZIndex)) {
      marker.priorityElement = priorityElement.add(
        chart[CHART_EDIT_CONST.EVENT_MARKER_GROUP]
      );
    }

    // 이벤트 구간 시작 지점이 있는 경우 생성
    if (hasOnsetMarker) {
      chart.renderer
        .path()
        .attr({
          class: 'huinno-event-marker-onset-top',
          d: triangleSvg,
          transform: `translate(${onsetXPixel}, ${plotY})`,
          fill: color,
          opacity: 0.55,
          zIndex: 2,
        })
        .add(marker);
      chart.renderer
        .path()
        .attr({
          class: 'huinno-event-marker-onset-bottom',
          d: triangleSvg,
          // ⭐️ rotate: deg 단위를 쓰지 않는다.
          // ⭐️ translate: px 단위를 쓰지 않는다.
          transform: `translate(${onsetXPixel + TRIANGLE_CORRECTION_VALUE}, ${
            plotY + plotHeight + TRIANGLE_CORRECTION_VALUE
          }) rotate(270)`,
          fill: color,
          opacity: 0.55,
          zIndex: 2,
        })
        .add(marker);
      chart.renderer
        .rect(0, 0, strokeWidth, plotHeight, 0, 0)
        .attr({
          class: 'huinno-event-marker-onset-line',
          transform: `translate(${onsetXPixel}, ${plotY})`,
          fill: color,
          opacity: 0.55,
          zIndex: 1,
        })
        .add(marker);
    }
    // 이벤트 구간 종료 지점이 있는 경우 생성
    if (hasTerminationMarker) {
      chart.renderer
        .path()
        .attr({
          class: 'huinno-event-marker-term-top',
          d: triangleSvg,
          transform: `translate(${
            terminationXPixel - TRIANGLE_CORRECTION_VALUE
          }, ${plotY - TRIANGLE_CORRECTION_VALUE}) rotate(90)`,
          fill: color,
          opacity: 0.55,
          zIndex: 2,
        })
        .add(marker);
      chart.renderer
        .path()
        .attr({
          class: 'huinno-event-marker-term-bottom',
          d: triangleSvg,
          transform: `translate(${terminationXPixel}, ${
            plotY + plotHeight
          }) rotate(180)`,
          fill: color,
          opacity: 0.55,
          zIndex: 2,
        })
        .add(marker);
      chart.renderer
        .rect(0, 0, strokeWidth, plotHeight, 0, 0)
        .attr({
          class: 'huinno-event-marker-term-line',
          transform: `translate(${terminationXPixel - strokeWidth}, ${plotY})`,
          fill: color,
          opacity: 0.55,
          zIndex: 1,
        })
        .add(marker);
    }
    // AF, Others 구간에 언더라인 생성
    if (!isNaN(offset)) {
      chart.renderer
        .rect(0, 0, eventLengthPixel, underLineStrokeWidth, 0, 0)
        .attr({
          class: 'huinno-event-marker-under-line',
          transform: `translate(${onsetXPixel}, ${
            plotY + plotHeight + offset
          })`,
          fill: color,
          zIndex: 0,
        })
        .add(marker);
    }

    return marker;
  },
  // render event marker에서 사용되는 highlight 부분
  renderHighlighter: (
    chart,
    {
      //
      onsetLocalWaveformIndex,
      terminationLocalWaveformIndex,
      className = '',
      priorityZIndex = -1,
      type,
      color,
    }
  ) => {
    const selectedAreaColor = AppColors.BLUE_30;
    const unselectedAreaColor = className.includes(EventConstTypes.LEAD_OFF)
      ? AppColors.LEAD_OFF
      : AppColors.LIGHT_DIM;

    const {
      x: plotX,
      y: plotY,
      width: plotWidth,
      height: plotHeight,
    } = chart.plotBox;
    const widthPerPoint = plotWidth / chart.xAxis[0].max;
    const onsetXPixel = plotX + widthPerPoint * onsetLocalWaveformIndex;
    const terminationXPixel =
      plotX + widthPerPoint * terminationLocalWaveformIndex;
    const eventLengthPixel = terminationXPixel - onsetXPixel;

    const priorityElement = chart.renderer
      .rect(0, 0, eventLengthPixel, plotHeight, 0, 0)
      .attr({
        class: `huinno-event-marker-priority  ${className}`,
        transform: `translate(${onsetXPixel}, ${plotY})`,
        zIndex: priorityZIndex,
      })
      .css({ fill: unselectedAreaColor, fillOpacity: 0 });

    const isPatternMatching = type === PATTERN_MATCHING;
    let param: { fillOpacity?: number; fill: string } = {
      fillOpacity: 1,
      fill: unselectedAreaColor,
    };
    if (isPatternMatching) {
      param = {
        fill: color,
      };
    }
    const eventMarkerArea = chart.renderer
      .rect(0, 0, eventLengthPixel, plotHeight, 0, 0)
      .attr({
        class: `huinno-event-marker-area  ${className}`,
        transform: `translate(${onsetXPixel}, ${plotY})`,
        zIndex: 0,
      })
      .css(param);
    // .css({ fillOpacity: 1, fill: unselectedAreaColor });

    /**
     * 선택된 이벤트 구간 강조 여부 처리 용
     * @param {boolean} isSelected true 면 강조된 색으로 변경
     */
    function setSelectedState(isSelected) {
      if (isSelected) {
        priorityElement.attr({
          class: `huinno-event-marker-priority ${CONST_CLASS_HUINNO_EVENT_MARKER_PRIORITY_SELECTED} ${className}`,
          transform: `translate(${onsetXPixel}, ${plotY})`,
          zIndex: 9,
        });

        eventMarkerArea.css({
          fillOpacity: 1,
          fill: selectedAreaColor,
        });
      } else {
        priorityElement.attr({
          class: `huinno-event-marker-priority  ${className}`,
          transform: `translate(${onsetXPixel}, ${plotY})`,
          zIndex: priorityZIndex,
        });

        eventMarkerArea.css({
          fillOpacity: 1,
          fill: unselectedAreaColor,
        });
      }
    }

    return {
      priorityElement,
      eventMarkerArea,
      setSelectedState,
    };
  },
};

export default eventMarkerRenderer;
