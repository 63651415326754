const errorMessage = 'range error';

// target 위치가 range start ~ range end 사이에 여부 판단
export function isTargetWithinRange({
  target: t,
  range: { start: rS, end: rE },
}: {
  target: number;
  range: { start: number; end: number };
}) {
  try {
    if (Number.isNaN(t) || Number.isNaN(rS) || Number.isNaN(rE)) {
      return false;
    }

    return rS <= t && t <= rE;
  } catch (error) {
    console.error(errorMessage, error);
    return false;
  }
}

// range1이 range2에 완전히 포함되는 여부 판단
export function isRange1InRange2({
  range1: { start: r1S, end: r1E },
  range2: { start: r2S, end: r2E },
}: {
  range1: { start: number; end: number };
  range2: { start: number; end: number };
}): boolean {
  try {
    if (
      Number.isNaN(r1S) ||
      Number.isNaN(r1E) ||
      Number.isNaN(r2S) ||
      Number.isNaN(r2E)
    ) {
      return false;
    }

    return r2S <= r1S && r1E <= r2E;
    // return r1S <= r2S && r2E <= r1E;
  } catch (error) {
    console.error(errorMessage, error);
    return false;
  }
}

// range1, range2이 서로 겹치는 여부 판단
export function isRangeOverlap({
  range1: { start: r1S, end: r1E },
  range2: { start: r2S, end: r2E },
}: {
  range1: { start: number; end: number };
  range2: { start: number; end: number };
}): boolean {
  /*
    1. 완전히 포함되는 경우
    start        end
      |-----------|
        |-----|   (pair)
 
    2. 일부가 겹치는 경우 (좌측 겹침)
         start        end
          |-----------|
    |-------|  (pair)
 
    3. 일부가 겹치는 경우 (우측 겹침)
    start        end
      |-----------|
              |------| (pair)
 
    4. 완전히 포함하는 경우
      start        end
        |-------|
    |-------------| (pair)
 
    5. 정확히 겹치는 경우
    start        end
      |-----------|
      |-----------| (pair)
  */
  try {
    if (
      Number.isNaN(r1S) ||
      Number.isNaN(r1E) ||
      Number.isNaN(r2S) ||
      Number.isNaN(r2E)
    ) {
      return false;
    }

    return r2S <= r1E && r2E >= r1S;
  } catch (error) {
    console.error(errorMessage, error);
    return false;
  }
}

export function isRangeListInRange(
  rangeList: [number, number][],
  range: { start: number; end: number }
) {
  try {
    if (Number.isNaN(range.start) || Number.isNaN(range.end)) {
      return false;
    }

    return rangeList.some((r) =>
      isRange1InRange2({
        range1: { start: range.start, end: range.end },
        range2: { start: r[0], end: r[1] },
      })
    );
  } catch (error) {
    console.error(errorMessage, error);
    return false;
  }
}
