import { ECG_CHART_UNIT } from 'constant/ChartEditConst';

import { WaveformIndex } from '@type/ecgEventType/eventUnit';
import { Beats } from '@type/ecgEventType/eventType';

import { getKeyWaveformIndex } from './BeatUtil';

const THIRTY_SEC_WAVEFORM_LENGTH = ECG_CHART_UNIT.THIRTY_SEC_WAVEFORM_IDX;

/**
 * onsetWaveformIndex와 terminationWaveformIndex를 포함하는 정규 30초 구간을 반환
 *
 * 정규 30초: 0, 30, 60, 90, ...
 *
 * @param {*} onsetWaveformIndex
 * @param {*} terminationWaveformIndex
 * @returns
 */
export function getRegular30sWaveformIndexRange(
  onsetWaveformIndex: WaveformIndex,
  terminationWaveformIndex: WaveformIndex
) {
  let result = {
    onsetWaveformIndex: getKeyWaveformIndex(onsetWaveformIndex),
    terminationWaveformIndex: getKeyWaveformIndex(terminationWaveformIndex),
  };

  if (result.terminationWaveformIndex < terminationWaveformIndex) {
    result.terminationWaveformIndex += THIRTY_SEC_WAVEFORM_LENGTH;
  }

  return result;
}

/**
 * standardOnsetWaveformIndex 부터 standardTerminationWaveformIndex 까지의 Beats 반환
 *
 * @param {WaveformIndex} standardOnsetWaveformIndex
 * @param {WaveformIndex} standardTerminationWaveformIndex
 * @param {*} beatEventInfoMap
 * @returns
 */
export function getLongTermChartStripBeatsData({
  standardOnsetWaveformIndex,
  standardTerminationWaveformIndex,
  beatEventInfoMap,
}) {
  const longTermChartStripBeatsData: Beats = {
    beatType: [],
    hr: [],
    waveformIndex: [],
  };

  // 30초 스트립 두 구간에 걸친 경우는 for문을 2번 돈다
  for (
    let wfi = standardOnsetWaveformIndex;
    wfi < standardTerminationWaveformIndex;
    wfi += THIRTY_SEC_WAVEFORM_LENGTH
  ) {
    const currentBeats: Beats = beatEventInfoMap[wfi]?.beats;
    if (currentBeats) {
      longTermChartStripBeatsData.beatType.push(...currentBeats.beatType);
      longTermChartStripBeatsData.hr.push(...currentBeats.hr);
      longTermChartStripBeatsData.waveformIndex.push(
        ...currentBeats.waveformIndex
      );
    }
  }

  return longTermChartStripBeatsData;
}
