import { call, put, takeLatest, select } from 'redux-saga/effects';

import { TEST_STATUS_ARRAY as _TEST_STATUS_ARRAY } from 'constant/Const';

import {
  buildBaseParams,
  addPrescriptionDuration,
  addOrderBy,
  addSearchKeyword,
  addInternalConfirmTimestampMsRange,
  combineValuesByKeys,
  updateUrlWithParams,
  formatPatientName,
  formatBirthDate,
  addTestType,
  addHid,
  addEstimatedReportDeliveryTimestampMsRange,
  addEditManagedBy,
} from 'util/EcgTestListUtil';

import ApiManager from 'network/ApiManager';

import { DateType, EcgTest, MedicalAffairUser } from 'type/apiDataType';
import { Nullable } from 'type/commonType';

import { getEcgTestSucceed } from 'redux/duck/testResultDuck';

import { EcgTestListInitialState } from './ecgTestListDuckType';

const abortControllers = new Map();

const TEST_STATUS_ARRAY = _TEST_STATUS_ARRAY;

// Selector
// Actions
// InitialState
// Reducer
// Action Creators
// Saga functions
// Saga

// Selector
export const selectSidePanel = ({ ecgTestListReducer }) =>
  ecgTestListReducer.sidePanel;
export const selectPatchState = ({ ecgTestListReducer }) =>
  ecgTestListReducer.patch;
export const selectTableFilterOptions = ({ ecgTestListReducer }) =>
  ecgTestListReducer.fetch.tableFilterOptions;
export const selectFetchData = ({ ecgTestListReducer }) =>
  ecgTestListReducer.fetch;
// Actions
// Create
const CREATE_ECG_TEST_REQUESTED = 'ecg-test-list/CREATE_ECG_TEST_REQUESTED';
const CREATE_ECG_TEST_SUCCEED = 'ecg-test-list/CREATE_ECG_TEST_SUCCEED';
const CREATE_ECG_TEST_FAILED = 'ecg-test-list/CREATE_ECG_TEST_FAILED';
// Fetch ECG Test List
const FETCH_ECG_TEST_LIST_REQUESTED = 'ecg-tests/FETCH_ECG_TEST_LIST_REQUESTED';
const FETCH_ECG_TEST_LIST_SUCCEED = 'ecg-tests/FETCH_ECG_TEST_LIST_SUCCEED';
const FETCH_ECG_TEST_LIST_FAILED = 'ecg-tests/FETCH_ECG_TEST_LIST_FAILED';
// HId Report Metrics
const FETCH_HID_REPORT_METRICS_REQUESTED =
  'ecg-tests/FETCH_HID_REPORT_METRICS_REQUESTED';
const FETCH_HID_REPORT_METRICS_SUCCEED =
  'ecg-tests/FETCH_HID_REPORT_METRICS_SUCCEED';
const FETCH_HID_REPORT_METRICS_FAILED =
  'ecg-tests/FETCH_HID_REPORT_METRICS_FAILED';
// Fetch Hospital ID List
const FETCH_HID_LIST_REQUESTED = 'ecg-tests/FETCH_HID_LIST_REQUESTED';
const FETCH_HID_LIST_SUCCEED = 'ecg-tests/FETCH_HID_LIST_SUCCEED';
const FETCH_HID_LIST_FAILED = 'ecg-tests/FETCH_HID_LIST_FAILED';
// Get Medical Affair User List
const GET_MEDICAL_AFFAIR_USER_LIST_REQUESTED =
  'ecg-tests/GET_MEDICAL_AFFAIR_USER_LIST_REQUESTED';
const GET_MEDICAL_AFFAIR_USER_LIST_SUCCEED =
  'ecg-tests/GET_MEDICAL_AFFAIR_USER_LIST_SUCCEED';
const GET_MEDICAL_AFFAIR_USER_LIST_FAILED =
  'ecg-tests/GET_MEDICAL_AFFAIR_USER_LIST_FAILED';
// Patch
const PATCH_ECG_TEST_REQUESTED = 'ecg-test-list/PATCH_ECG_TEST_REQUESTED';
const PATCH_ECG_TEST_SUCCEED = 'ecg-test-list/PATCH_ECG_TEST_SUCCEED';
const PATCH_ECG_TEST_FAILED = 'ecg-test-list/PATCH_ECG_TEST_FAILED';
// Revert ECG Test Status CUSTOMER_CONFIRMED to CLOUD_CONFIRMED
const REVERT_STATUS_REQUESTED = 'ecg-test-list/REVERT_STATUS_REQUESTED';
const REVERT_STATUS_SUCCEED = 'ecg-test-list/REVERT_STATUS_SUCCEED';
const REVERT_STATUS_FAILED = 'ecg-test-list/REVERT_STATUS_FAILED';
// Confirm Review
const ECG_TEST_CONFIRM_REVIEW_REQUESTED =
  'ecg-test-list/ECG_TEST_CONFIRM_REVIEW_REQUESTED';
const ECG_TEST_CONFIRM_REVIEW_SUCCEED =
  'ecg-test-list/ECG_TEST_CONFIRM_REVIEW_SUCCEED';
const ECG_TEST_CONFIRM_REVIEW_FAILED =
  'ecg-test-list/ECG_TEST_CONFIRM_REVIEW_FAILED';
// Edit Done Review
const ECG_TEST_EDIT_DONE_REVIEW_REQUESTED =
  'ecgTest/ECG_TEST_EDIT_DONE_REVIEW_REQUESTED';
const ECG_TEST_EDIT_DONE_REVIEW_SUCCEED =
  'ecgTest/ECG_TEST_EDIT_DONE_REVIEW_SUCCEED';
const ECG_TEST_EDIT_DONE_REVIEW_FAILED =
  'ecgTest/ECG_TEST_EDIT_DONE_REVIEW_FAILED';
// get validReport latest value from ECG Test API
const GET_VALID_REPORT_REQUESTED = 'ecg-test-list/GET_VALID_REPORT_REQUESTED';
const GET_VALID_REPORT_SUCCEED = 'ecg-test-list/GET_VALID_REPORT_SUCCEED';
const GET_VALID_REPORT_FAILED = 'ecg-test-list/GET_VALID_REPORT_FAILED';
// reset table filter options
const RESET_TABLE_FILTER_OPTIONS = 'ecg-test-list/RESET_TABLE_FILTER_OPTIONS';
// show sidePanel
const SHOW_SIDE_PANEL = 'ecg-test-list/SHOW_SIDE_PANEL';
const HIDE_SIDE_PANEL = 'ecg-test-list/HIDE_SIDE_PANEL';
const SET_SIDE_PANEL_DATA = 'ecg-test-list/SET_SIDE_PANEL_DATA';
// 편집자, 결과지 담당자 할당
const POST_SET_EDIT_MANAGED_BY_REQUESTED =
  'ecg-test-list/POST_SET_EDIT_MANAGED_BY_REQUESTED';
const POST_SET_EDIT_MANAGED_BY_SUCCEED =
  'ecg-test-list/POST_SET_EDIT_MANAGED_BY_SUCCEED';
const POST_SET_EDIT_MANAGED_BY_FAILED =
  'ecg-test-list/POST_SET_EDIT_MANAGED_BY_FAILED';
const POST_UNSET_EDIT_MANAGED_BY_REQUESTED =
  'ecg-test-list/POST_UNSET_EDIT_MANAGED_BY_REQUESTED';
const POST_UNSET_EDIT_MANAGED_BY_SUCCEED =
  'ecg-test-list/POST_UNSET_EDIT_MANAGED_BY_SUCCEED';
const POST_UNSET_EDIT_MANAGED_BY_FAILED =
  'ecg-test-list/POST_UNSET_EDIT_MANAGED_BY_FAILED';
const POST_SET_CREATE_REPORT_MANAGED_BY_REQUESTED =
  'ecg-test-list/POST_SET_CREATE_REPORT_MANAGED_BY_REQUESTED';
const POST_SET_CREATE_REPORT_MANAGED_BY_SUCCEED =
  'ecg-test-list/POST_SET_CREATE_REPORT_MANAGED_BY_SUCCEED';
const POST_SET_CREATE_REPORT_MANAGED_BY_FAILED =
  'ecg-test-list/POST_SET_CREATE_REPORT_MANAGED_BY_FAILED';
const POST_UNSET_CREATE_REPORT_MANAGED_BY_REQUESTED =
  'ecg-test-list/POST_UNSET_CREATE_REPORT_MANAGED_BY_REQUESTED';
const POST_UNSET_CREATE_REPORT_MANAGED_BY_SUCCEED =
  'ecg-test-list/POST_UNSET_CREATE_REPORT_MANAGED_BY_SUCCEED';
const POST_UNSET_CREATE_REPORT_MANAGED_BY_FAILED =
  'ecg-test-list/POST_UNSET_CREATE_REPORT_MANAGED_BY_FAILED';

const POST_UPDATE_STATUS_AS_EDIT_REQUESTED_REQUESTED =
  'ecg-test-list/POST_UPDATE_STATUS_AS_EDIT_REQUESTED_REQUESTED';
const POST_UPDATE_STATUS_AS_EDIT_REQUESTED_SUCCEED =
  'ecg-test-list/POST_UPDATE_STATUS_AS_EDIT_REQUESTED_SUCCEED';
const POST_UPDATE_STATUS_AS_EDIT_REQUESTED_FAILED =
  'ecg-test-list/POST_UPDATE_STATUS_AS_EDIT_REQUESTED_FAILED';
const POST_UPDATE_STATUS_AS_DISCARDED_REQUESTED =
  'ecg-test-list/POST_UPDATE_STATUS_AS_DISCARDED_REQUESTED';
const POST_UPDATE_STATUS_AS_DISCARDED_SUCCEED =
  'ecg-test-list/POST_UPDATE_STATUS_AS_DISCARDED_SUCCEED';
const POST_UPDATE_STATUS_AS_DISCARDED_FAILED =
  'ecg-test-list/POST_UPDATE_STATUS_AS_DISCARDED_FAILED';
const POST_CHANGE_END_DATE_TIME_REQUESTED =
  'ecg-test-list/POST_CHANGE_END_DATE_TIME_REQUESTED';
const POST_CHANGE_END_DATE_TIME_SUCCEED =
  'ecg-test-list/POST_CHANGE_END_DATE_TIME_SUCCEED';
const POST_CHANGE_END_DATE_TIME_FAILED =
  'ecg-test-list/POST_CHANGE_END_DATE_TIME_FAILED';

// InitialState
const initialState: EcgTestListInitialState = {
  create: {
    pending: false,
    data: null,
    error: null,
  },
  hidList: {
    pending: false,
    data: [],
    error: null,
  },
  medicalAffairUserList: {
    pending: false,
    data: [],
    error: null,
  },
  fetch: {
    pending: false,
    data: [],
    error: null,
    // 검색, 필터링, 페이지 등의 옵션 값
    tableFilterOptions: {
      firstPage: 1,
      page: 1,
      lastPage: null,
      totalCount: null,
      searchKeyword: '',
      testType: [],
      hid: [],
      editManagedBy: [],
      cloudStatus: [],
      prescriptionDuration: [],
      internalConfirmTimestampMsRange: [],
      estimatedReportDeliveryTimestampMsRange: [],
      orderBy: '-eventCounts,-estimatedReportDeliveryDatetime,',
      isManuallyUpdated: false,
    },
  },
  patch: {
    pending: false,
    data: null,
    error: null,
  },
  revertStatus: {
    pending: false,
    error: null,
  },
  confirm: {
    pending: false,
    data: null,
    error: null,
  },
  editDone: {
    pending: false,
    data: null,
    error: null,
  },
  validReport: {
    pending: false,
    data: false,
    error: null,
  },
  sidePanel: {
    isOpen: false,
    ecgTest: null,
  },
  editManagedBy: {
    pending: false,
    error: null,
  },
  createReportManagedBy: {
    pending: false,
    error: null,
  },
  reportMetrics: {
    pending: false,
    data: null,
    error: null,
  },
  updateStatusAsEditRequested: {
    pending: false,
    error: null,
  },
  updateStatusAsDiscarded: {
    pending: false,
    error: null,
  },
  changeEndDatetime: {
    pending: false,
    error: null,
  },
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    // Create
    case CREATE_ECG_TEST_REQUESTED:
      return {
        ...state,
        create: {
          ...state.create,
          pending: true,
          error: null,
        },
      };
    case CREATE_ECG_TEST_SUCCEED:
      return {
        ...state,
        create: {
          ...state.create,
          pending: false,
          data: action.data,
        },
      };
    case CREATE_ECG_TEST_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          pending: false,
          error: action.error,
        },
      };
    // Fetch Ecg Test List
    case FETCH_ECG_TEST_LIST_REQUESTED:
      const { type, ...actionPayload } = action;

      const tableFilterOptions = state.fetch.tableFilterOptions;

      return {
        ...state,
        fetch: {
          ...state.fetch,
          pending: true,
          error: null,
          tableFilterOptions: {
            ...tableFilterOptions,
            ...actionPayload,
          },
        },
      };
    case FETCH_ECG_TEST_LIST_SUCCEED:
      return {
        ...state,
        fetch: {
          pending: false,
          data: action.data,
          error: null,
          tableFilterOptions: {
            ...state.fetch.tableFilterOptions,
            page: action.page,
            lastPage: action.lastPage,
            totalCount: action.totalCount,
          },
        },
      };
    case FETCH_ECG_TEST_LIST_FAILED:
      return {
        ...state,
        fetch: {
          ...state.fetch,
          pending: false,
          error: action.error,
        },
      };
    case FETCH_HID_REPORT_METRICS_REQUESTED:
      return {
        ...state,
        reportMetrics: {
          ...state.reportMetrics,
          pending: true,
          error: null,
        },
      };
    case FETCH_HID_REPORT_METRICS_SUCCEED:
      return {
        ...state,
        reportMetrics: {
          pending: false,
          data: action.data,
          error: null,
        },
      };
    case FETCH_HID_REPORT_METRICS_FAILED:
      return {
        ...state,
        reportMetrics: {
          pending: false,
          error: action.error,
        },
      };
    case FETCH_HID_LIST_REQUESTED:
      return {
        ...state,
        hidList: {
          ...state.hidList,
          pending: true,
          error: null,
        },
      };
    case FETCH_HID_LIST_SUCCEED:
      return {
        ...state,
        hidList: {
          pending: false,
          data: action.data,
          error: null,
        },
      };
    case FETCH_HID_LIST_FAILED:
      return {
        ...state,
        hidList: {
          ...state.hidList,
          pending: false,
          error: action.error,
        },
      };
    case GET_MEDICAL_AFFAIR_USER_LIST_REQUESTED:
      return {
        ...state,
        medicalAffairUserList: {
          ...state.medicalAffairUserList,
          pending: true,
          error: null,
        },
      };
    case GET_MEDICAL_AFFAIR_USER_LIST_SUCCEED:
      return {
        ...state,
        medicalAffairUserList: {
          pending: false,
          data: action.data,
          error: null,
        },
      };
    case GET_MEDICAL_AFFAIR_USER_LIST_FAILED:
      return {
        ...state,
        medicalAffairUserList: {
          ...state.medicalAffairUserList,
          pending: false,
          error: action.error,
        },
      };
    // Patch
    case PATCH_ECG_TEST_REQUESTED:
      return {
        ...state,
        patch: {
          ...state.patch,
          pending: true,
          error: null,
        },
      };
    case PATCH_ECG_TEST_SUCCEED:
      return {
        ...state,
        patch: {
          ...state.patch,
          pending: false,
          data: {
            ...state.patch.data,
            ...action.data,
          },
          error: null,
        },
      };
    case PATCH_ECG_TEST_FAILED:
      return {
        ...state,
        patch: {
          pending: false,
          error: action.error,
        },
      };
    // Revert ECG Test Status CUSTOMER_CONFIRMED to CLOUD_CONFIRMED
    case REVERT_STATUS_REQUESTED:
      return {
        ...state,
        revertStatus: {
          pending: true,
          error: null,
        },
      };
    case REVERT_STATUS_SUCCEED:
      return {
        ...state,
        patch: {
          ...state.patch,
          data: action.data,
        },
        fetch: {
          ...state.fetch,
          isUploadedToEmr: action.isUploadedToEmr,
        },
        revertStatus: {
          pending: false,
          error: null,
        },
      };
    case REVERT_STATUS_FAILED:
      return {
        ...state,
        revertStatus: {
          pending: false,
          error: action.error,
        },
      };
    // Confirm Review
    case ECG_TEST_CONFIRM_REVIEW_REQUESTED:
      return {
        ...state,
        confirm: {
          ...state.confirm,
          pending: true,
          error: null,
        },
      };
    case ECG_TEST_CONFIRM_REVIEW_SUCCEED:
      return {
        ...state,
        confirm: {
          ...state.confirm,
          pending: false,
          data: action.data,
        },
      };
    case ECG_TEST_CONFIRM_REVIEW_FAILED:
      return {
        ...state,
        confirm: {
          ...state.confirm,
          pending: false,
          error: action.error,
        },
      };
    // Edit Done Review
    case ECG_TEST_EDIT_DONE_REVIEW_REQUESTED:
      return {
        ...state,
        editDone: {
          pending: true,
          error: null,
        },
      };
    case ECG_TEST_EDIT_DONE_REVIEW_SUCCEED:
      return {
        ...state,
        editDone: {
          pending: false,
          data: action.data,
        },
      };
    case ECG_TEST_EDIT_DONE_REVIEW_FAILED:
      return {
        ...state,
        editDone: {
          pending: false,
          error: action.error,
        },
      };
    // get validReport latest value from ECG Test API
    case GET_VALID_REPORT_REQUESTED:
      return {
        ...state,
        validReport: {
          ...state.validReport,
          pending: true,
          data: false,
          error: null,
        },
      };
    case GET_VALID_REPORT_SUCCEED:
      return {
        ...state,
        validReport: {
          ...state.validReport,
          pending: false,
          data: action.data.latestReport.validReport,
        },
      };
    case GET_VALID_REPORT_FAILED:
      return {
        ...state,
        validReport: {
          ...state.validReport,
          pending: false,
          data: false,
          error: action.error,
        },
      };
    case RESET_TABLE_FILTER_OPTIONS:
      return {
        ...state,
        fetch: {
          pending: true,
          data: [],
          error: null,
          tableFilterOptions: initialState.fetch.tableFilterOptions,
        },
      };
    case SHOW_SIDE_PANEL:
      const { ecgTestId } = action;
      let ecgTest: Nullable<EcgTest> = null;
      if (ecgTestId) {
        // 찾지 못했을 경우 undefined가 반환되는데, ecgTest의 타입은 Nullable이므로 null을 넣어준다.
        const foundEcgTest = state.fetch.data?.find(
          (item) => item.tid === ecgTestId
        );
        ecgTest = foundEcgTest || null;
      }
      return {
        ...state,
        sidePanel: {
          isOpen: true,
          ecgTest: ecgTest,
        },
      };
    case HIDE_SIDE_PANEL:
      return {
        ...state,
        sidePanel: {
          isOpen: false,
          ecgTest: null,
        },
      };
    case SET_SIDE_PANEL_DATA:
      return {
        ...state,
        sidePanel: {
          ...state.sidePanel,
          ecgTest: action.ecgTest,
        },
      };
    case POST_SET_EDIT_MANAGED_BY_REQUESTED:
    case POST_UNSET_EDIT_MANAGED_BY_REQUESTED:
      return {
        ...state,
        editManagedBy: {
          pending: true,
          error: null,
        },
      };
    case POST_SET_EDIT_MANAGED_BY_SUCCEED:
    case POST_UNSET_EDIT_MANAGED_BY_SUCCEED:
      return {
        ...state,
        editManagedBy: {
          pending: false,
          error: null,
        },
      };
    case POST_SET_EDIT_MANAGED_BY_FAILED:
    case POST_UNSET_EDIT_MANAGED_BY_FAILED:
      return {
        ...state,
        editManagedBy: {
          pending: false,
          error: action.error,
        },
      };
    case POST_SET_CREATE_REPORT_MANAGED_BY_REQUESTED:
    case POST_UNSET_CREATE_REPORT_MANAGED_BY_REQUESTED:
      return {
        ...state,
        createReportManagedBy: {
          pending: true,
          error: null,
        },
      };
    case POST_SET_CREATE_REPORT_MANAGED_BY_SUCCEED:
    case POST_UNSET_CREATE_REPORT_MANAGED_BY_SUCCEED:
      return {
        ...state,
        createReportManagedBy: {
          pending: false,
          error: null,
        },
      };
    case POST_SET_CREATE_REPORT_MANAGED_BY_FAILED:
    case POST_UNSET_CREATE_REPORT_MANAGED_BY_FAILED:
      return {
        ...state,
        createReportManagedBy: {
          pending: false,
          error: action.error,
        },
      };

    case POST_UPDATE_STATUS_AS_EDIT_REQUESTED_REQUESTED:
      return {
        ...state,
        updateStatusAsEditRequested: {
          pending: true,
          error: null,
        },
      };
    case POST_UPDATE_STATUS_AS_EDIT_REQUESTED_SUCCEED:
      return {
        ...state,
        updateStatusAsEditRequested: {
          pending: false,
          error: null,
        },
      };
    case POST_UPDATE_STATUS_AS_EDIT_REQUESTED_FAILED:
      return {
        ...state,
        updateStatusAsEditRequested: {
          pending: false,
          error: action.error,
        },
      };
    case POST_UPDATE_STATUS_AS_DISCARDED_REQUESTED:
      return {
        ...state,
        updateStatusAsDiscarded: {
          pending: true,
          error: null,
        },
      };
    case POST_UPDATE_STATUS_AS_DISCARDED_SUCCEED:
      return {
        ...state,
        updateStatusAsDiscarded: {
          pending: false,
          error: null,
        },
      };
    case POST_UPDATE_STATUS_AS_DISCARDED_FAILED:
      return {
        ...state,
        updateStatusAsDiscarded: {
          pending: false,
          error: action.error,
        },
      };
    case POST_CHANGE_END_DATE_TIME_REQUESTED:
      return {
        ...state,
        changeEndDatetime: {
          pending: true,
          error: null,
        },
      };
    case POST_CHANGE_END_DATE_TIME_SUCCEED:
      return {
        ...state,
        changeEndDatetime: {
          pending: false,
          error: null,
        },
      };
    case POST_CHANGE_END_DATE_TIME_FAILED:
      return {
        ...state,
        changeEndDatetime: {
          pending: false,
          error: action.error,
        },
      };

    default:
      return state;
  }
}

// Action Creators
// Fetch Ecg Test List
export function fetchEcgTestsRequested({
  testType,
  hid,
  editManagedBy,
  cloudStatus,
  prescriptionDuration,
  page,
  orderBy,
  searchKeyword,
  internalConfirmTimestampMsRange,
  estimatedReportDeliveryTimestampMsRange,
}: {
  testType?: number[];
  hid?: number[];
  editManagedBy?: MedicalAffairUser[];
  cloudStatus?: number[];
  prescriptionDuration?: number[];
  page?: number;
  orderBy?: string;
  searchKeyword?: string;
  internalConfirmTimestampMsRange?: DateType[];
  estimatedReportDeliveryTimestampMsRange?: DateType[];
} = {}) {
  return {
    type: FETCH_ECG_TEST_LIST_REQUESTED,
    ..._removeUndefinedProperties({
      testType,
      hid,
      cloudStatus,
      editManagedBy,
      prescriptionDuration,
      page,
      orderBy,
      searchKeyword,
      internalConfirmTimestampMsRange,
      estimatedReportDeliveryTimestampMsRange,
    }),
  };
  // inner utility function
  function _removeUndefinedProperties(obj) {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      if (value === undefined) return acc;
      // For all other cases, just assign the value
      acc[key] = value;
      return acc;
    }, {});
  }
}
function fetchEcgTestsSucceed({ page, lastPage, totalCount, data }) {
  return {
    type: FETCH_ECG_TEST_LIST_SUCCEED,
    page,
    lastPage,
    totalCount,
    data,
  };
}
function fetchEcgTestsFailed(error) {
  return { type: FETCH_ECG_TEST_LIST_FAILED, error };
}
export function fetchHidReportMetricsRequested() {
  return { type: FETCH_HID_REPORT_METRICS_REQUESTED };
}
function fetchHidReportMetricsSucceed(data) {
  return { type: FETCH_HID_REPORT_METRICS_SUCCEED, data };
}
function fetchHidReportMetricsFailed(error) {
  return { type: FETCH_HID_REPORT_METRICS_FAILED, error };
}
export function fetchHidListRequested() {
  return { type: FETCH_HID_LIST_REQUESTED };
}
function fetchHidListSucceed(data) {
  return { type: FETCH_HID_LIST_SUCCEED, data };
}
function fetchHidListFailed(error) {
  return { type: FETCH_HID_LIST_FAILED, error };
}
export function getMedicalAffairUserListRequested() {
  return { type: GET_MEDICAL_AFFAIR_USER_LIST_REQUESTED };
}
function getMedicalAffairUserListSucceed(data) {
  return { type: GET_MEDICAL_AFFAIR_USER_LIST_SUCCEED, data };
}
function getMedicalAffairUserListFailed(error) {
  return { type: GET_MEDICAL_AFFAIR_USER_LIST_FAILED, error };
}
// Patch
export function patchEcgTestRequested({ ecgTestId, form, callback }) {
  return {
    type: PATCH_ECG_TEST_REQUESTED,
    ecgTestId,
    form,
    callback,
  };
}
function patchEcgTestSucceed(data) {
  return {
    type: PATCH_ECG_TEST_SUCCEED,
    data,
  };
}
function patchEcgTestFailed(error) {
  return {
    type: PATCH_ECG_TEST_FAILED,
    error,
  };
}
// Revert ECG Test Status CUSTOMER_CONFIRMED to CLOUD_CONFIRMED
export function revertStatusRequested({ ecgTestId, callback }) {
  return {
    type: REVERT_STATUS_REQUESTED,
    ecgTestId,
    callback,
  };
}
function revertStatusSucceed(data) {
  return {
    type: REVERT_STATUS_SUCCEED,
    data,
  };
}
function revertStatusFailed(error) {
  return {
    type: REVERT_STATUS_FAILED,
    error: error,
  };
}
// Confirm Review
export function confirmEcgTestReviewRequested({
  ecgTestId,
  reGenerateReport,
  callback,
}) {
  return {
    type: ECG_TEST_CONFIRM_REVIEW_REQUESTED,
    ecgTestId,
    reGenerateReport,
    callback,
  };
}
function confirmEcgTestReviewSucceed(data) {
  return {
    type: ECG_TEST_CONFIRM_REVIEW_SUCCEED,
    data,
  };
}
function confirmEcgTestReviewFailed(error) {
  return {
    type: ECG_TEST_CONFIRM_REVIEW_FAILED,
    error,
  };
}
// Edit Done Review
export function editDoneEcgTestReviewRequested({ ecgTestId }) {
  return { type: ECG_TEST_EDIT_DONE_REVIEW_REQUESTED, ecgTestId };
}
function editDoneEcgTestReviewSucceed(data) {
  return { type: ECG_TEST_EDIT_DONE_REVIEW_SUCCEED, data };
}
function editDoneEcgTestReviewFailed(error) {
  return { type: ECG_TEST_EDIT_DONE_REVIEW_FAILED, error };
}
// get validReport latest value from ECG Test API
export function getValidReportRequested({ ecgTestId }) {
  return {
    type: GET_VALID_REPORT_REQUESTED,
    ecgTestId,
  };
}
function getValidReportSucceed(data) {
  return {
    type: GET_VALID_REPORT_SUCCEED,
    data,
  };
}
function getValidReportFailed(error) {
  return { type: GET_VALID_REPORT_FAILED, error: error };
}

// reset table filter options
export function resetTableFilterOptions() {
  return {
    type: RESET_TABLE_FILTER_OPTIONS,
  };
}

// sidePanel actions
export function showSidePanel({ ecgTestId }) {
  return { type: SHOW_SIDE_PANEL, ecgTestId };
}
export function hideSidePanel() {
  return { type: HIDE_SIDE_PANEL };
}
function _setSidePanelData({ ecgTest }) {
  return { type: SET_SIDE_PANEL_DATA, ecgTest };
}

export function postSetEditManagedByRequested({ ecgTestId }) {
  return {
    type: POST_SET_EDIT_MANAGED_BY_REQUESTED,
    ecgTestId,
  };
}
function postSetEditManagedBySucceed() {
  return {
    type: POST_SET_EDIT_MANAGED_BY_SUCCEED,
  };
}
function postSetEditManagedByFailed(error) {
  return {
    type: POST_SET_EDIT_MANAGED_BY_FAILED,
    error,
  };
}
export function postUnsetEditManagedByRequested({ ecgTestId }) {
  return {
    type: POST_UNSET_EDIT_MANAGED_BY_REQUESTED,
    ecgTestId,
  };
}
function postUnsetEditManagedBySucceed() {
  return {
    type: POST_UNSET_EDIT_MANAGED_BY_SUCCEED,
  };
}
function postUnsetEditManagedByFailed(error) {
  return {
    type: POST_UNSET_EDIT_MANAGED_BY_FAILED,
    error,
  };
}
export function postSetCreateReportManagedByRequested({ ecgTestId }) {
  return {
    type: POST_SET_CREATE_REPORT_MANAGED_BY_REQUESTED,
    ecgTestId,
  };
}
function postSetCreateReportManagedBySucceed() {
  return {
    type: POST_SET_CREATE_REPORT_MANAGED_BY_SUCCEED,
  };
}
function postSetCreateReportManagedByFailed(error) {
  return {
    type: POST_SET_CREATE_REPORT_MANAGED_BY_FAILED,
    error,
  };
}
export function postUnsetCreateReportManagedByRequested({ ecgTestId }) {
  return {
    type: POST_UNSET_CREATE_REPORT_MANAGED_BY_REQUESTED,
    ecgTestId,
  };
}
function postUnsetCreateReportManagedBySucceed() {
  return {
    type: POST_UNSET_CREATE_REPORT_MANAGED_BY_SUCCEED,
  };
}
function postUnsetCreateReportManagedByFailed(error) {
  return {
    type: POST_UNSET_CREATE_REPORT_MANAGED_BY_FAILED,
    error,
  };
}

export function postUpdateStatusAsEditRequestedRequested({
  ecgTestId,
  statusUpdatedReason,
}) {
  return {
    type: POST_UPDATE_STATUS_AS_EDIT_REQUESTED_REQUESTED,
    ecgTestId,
    statusUpdatedReason,
  };
}
function postUpdateStatusAsEditRequestedSucceed() {
  return {
    type: POST_UPDATE_STATUS_AS_EDIT_REQUESTED_SUCCEED,
  };
}
function postUpdateStatusAsEditRequestedFailed(error) {
  return {
    type: POST_UPDATE_STATUS_AS_EDIT_REQUESTED_FAILED,
    error,
  };
}

export function postUpdateStatusAsDiscardedRequested({
  ecgTestId,
  statusUpdatedReason,
}) {
  return {
    type: POST_UPDATE_STATUS_AS_DISCARDED_REQUESTED,
    ecgTestId,
    statusUpdatedReason,
  };
}
function postUpdateStatusAsDiscardedSucceed() {
  return {
    type: POST_UPDATE_STATUS_AS_DISCARDED_SUCCEED,
  };
}
function postUpdateStatusAsDiscardedFailed(error) {
  return {
    type: POST_UPDATE_STATUS_AS_DISCARDED_FAILED,
    error,
  };
}
export function postChangeEndDateRequested({
  ecgTestId,
  changedEndDatetime,
  changedEndDatetimeNote,
}) {
  return {
    type: POST_CHANGE_END_DATE_TIME_REQUESTED,
    ecgTestId,
    changedEndDatetime,
    changedEndDatetimeNote,
  };
}
function postChangeEndDateSucceed() {
  return {
    type: POST_CHANGE_END_DATE_TIME_SUCCEED,
  };
}
function postChangeEndDateFailed(error) {
  return {
    type: POST_CHANGE_END_DATE_TIME_FAILED,
    error,
  };
}

// Saga functions
function* _fetchEcgTestList() {
  try {
    if (abortControllers.has('_fetchEcgTestList')) {
      console.warn(`🛑 Cancelling previous request for: _fetchEcgTestList`);
      abortControllers.get('_fetchEcgTestList').abort();
    }

    const controller = new AbortController();
    abortControllers.set('_fetchEcgTestList', controller);

    const {
      testType,
      hid,
      editManagedBy,
      cloudStatus,
      prescriptionDuration,
      page,
      orderBy,
      searchKeyword,
      internalConfirmTimestampMsRange,
      estimatedReportDeliveryTimestampMsRange,
    } = yield select(selectTableFilterOptions);

    let params = buildBaseParams({
      cloudStatus: combineValuesByKeys({
        statusArray: TEST_STATUS_ARRAY,
        targetStatusArray: cloudStatus,
      }),
      page,
    });
    params = addTestType({ params, testType });
    params = addHid({ params, hid });
    params = addEditManagedBy({ params, editManagedBy });
    params = addPrescriptionDuration({ params, prescriptionDuration });
    params = addOrderBy({ params, orderBy });
    params = addSearchKeyword({ params, searchKeyword });
    params = addInternalConfirmTimestampMsRange({
      params,
      internalConfirmTimestampMsRange,
    });
    params = addEstimatedReportDeliveryTimestampMsRange({
      params,
      estimatedReportDeliveryTimestampMsRange,
    });

    const response = yield call(ApiManager.readEcgTests as any, {
      params,
      signal: controller.signal,
    });

    if (!response) return;

    const { data } = response;
    const { count, results } = data;
    const pageSize = 100;
    const lastPage = Math.ceil(count / pageSize);
    yield put(
      fetchEcgTestsSucceed({
        page,
        lastPage,
        totalCount: count,
        data: results,
      })
    );

    // fetchEcgTests 성공시 url 업데이트
    updateUrlWithParams({
      searchKeyword,
      testType,
      hid,
      editManagedBy,
      cloudStatus: combineValuesByKeys({
        statusArray: TEST_STATUS_ARRAY,
        targetStatusArray: cloudStatus,
      }),
      prescriptionDuration,
      orderBy,
      page,
      internalConfirmTimestampMsRange,
      estimatedReportDeliveryTimestampMsRange,
    });
  } catch (error: any) {
    console.error(error);
    if (error?.name !== 'AbortError') {
      yield put(fetchEcgTestsFailed(error));
    }
  }
}
function* _fetchHidReportMetrics() {
  try {
    if (abortControllers.has('_fetchHidReportMetrics')) {
      console.warn(
        `🛑 Cancelling previous request for: _fetchHidReportMetrics`
      );
      abortControllers.get('_fetchHidReportMetrics').abort();
    }

    const controller = new AbortController();
    abortControllers.set('_fetchHidReportMetrics', controller);

    const hid = yield select(({ authReducer }) => authReducer.me.hospital.hid);
    const { data } = yield call(ApiManager.getHidReportMetrics as any, {
      hid,
      signal: controller.signal,
    });
    yield put(fetchHidReportMetricsSucceed(data.result));
  } catch (error) {
    console.error(error);
    yield put(fetchHidReportMetricsFailed(error));
  }
}

function* _fetchHospitalsHid() {
  try {
    const { data } = yield call(ApiManager.getHospitalsHid);
    yield put(fetchHidListSucceed(data.results));
  } catch (error) {
    yield put(fetchHidListFailed(error));
  }
}

function* _getMedicalAffairUserList() {
  try {
    const { data } = yield call(ApiManager.getMedicalStaffsMedicalAffairUsers);
    yield put(getMedicalAffairUserListSucceed(data.results));
  } catch (error) {
    yield put(getMedicalAffairUserListFailed(error));
  }
}

function* _patchEcgTest(action) {
  try {
    const { isOpen } = yield select(selectSidePanel);

    const { ecgTestId, form, callback } = action;

    const formattedForm = {
      ...form,
      patientName: formatPatientName(form.patientName),
      patientBirth: formatBirthDate(form.patientBirth),
    };

    const { data } = yield call(ApiManager.patchEcgTest as any, {
      ecgTestId,
      body: formattedForm,
      callback,
    });

    if (isOpen) yield put(_setSidePanelData({ ecgTest: data.result }));
    yield put(patchEcgTestSucceed(data.result));
    yield put(
      getEcgTestSucceed({
        ...data.result,
        latestReport: data.result.latestReport,
      })
    );
  } catch (error) {
    console.error(error);
    yield put(patchEcgTestFailed(error));
  }
}

function* _revertStatus(action) {
  try {
    const { ecgTestId, callback } = action;
    const { data } = yield call(ApiManager.patchRevertStatus as any, {
      ecgTestId,
      callback,
    });
    yield put(getEcgTestSucceed({ ...data.result, isUploadedToEmr: false }));
    yield put(revertStatusSucceed({ ...data.result, isUploadedToEmr: false }));
  } catch (error) {
    console.error(error);
    yield put(revertStatusFailed(error));
  }
}

function* _ecgTestConfirmReview(action) {
  try {
    const { ecgTestId } = action;
    const { data } = yield call(ApiManager.ecgTestConfirmReview as any, {
      ecgTestId,
    });

    yield put(confirmEcgTestReviewSucceed(data));
  } catch (error) {
    console.error(error);
    yield put(confirmEcgTestReviewFailed(error));
  }
}

function* _ecgTestEditDoneReview(action) {
  try {
    const { ecgTestId } = action;
    const { data: editDoneData } = yield call(
      ApiManager.ecgTestEditDoneReview as any,
      { ecgTestId }
    );
    const { result } = editDoneData;

    yield put(editDoneEcgTestReviewSucceed(result));
  } catch (error) {
    console.error(error);
    yield put(editDoneEcgTestReviewFailed(error));
  }
}

function* _postSetEditManagedBy(action) {
  try {
    const { ecgTestId } = action;
    yield call(ApiManager.postSetEditManagedBy as any, {
      ecgTestId,
    });
    yield put(postSetEditManagedBySucceed());
  } catch (error) {
    console.error(error);
    yield put(postSetEditManagedByFailed(error));
  }
}
function* _postUnsetEditManagedBy(action) {
  try {
    const { ecgTestId } = action;
    yield call(ApiManager.postUnsetEditManagedBy as any, {
      ecgTestId,
    });
    yield put(postUnsetEditManagedBySucceed());
  } catch (error) {
    console.error(error);
    yield put(postUnsetEditManagedByFailed(error));
  }
}
function* _postSetCreateReportManagedBy(action) {
  try {
    const { ecgTestId } = action;
    yield call(ApiManager.postSetCreateReportManagedBy as any, {
      ecgTestId,
    });
    yield put(postSetCreateReportManagedBySucceed());
  } catch (error) {
    console.error(error);
    yield put(postSetCreateReportManagedByFailed(error));
  }
}
function* _postUnsetCreateReportManagedBy(action) {
  try {
    const { ecgTestId } = action;
    yield call(ApiManager.postUnsetCreateReportManagedBy as any, {
      ecgTestId,
    });
    yield put(postUnsetCreateReportManagedBySucceed());
  } catch (error) {
    console.error(error);
    yield put(postUnsetCreateReportManagedByFailed(error));
  }
}

function* _getValidReport(action) {
  try {
    const { ecgTestId } = action;
    const { data } = yield call(ApiManager.readEcgTest as any, {
      ecgTestId,
    });

    yield put(getValidReportSucceed(data.result));
  } catch (error) {
    console.error(error);
    yield put(getValidReportFailed(error));
  }
}

function* _postUpdateStatusAsEditRequested(action) {
  try {
    const { ecgTestId, statusUpdatedReason } = action;

    yield call(ApiManager.postUpdateStatusAsEditRequested as any, {
      ecgTestId,
      statusUpdatedReason,
    });

    yield put(postUpdateStatusAsEditRequestedSucceed());
  } catch (error) {
    console.error(error);
    yield put(postUpdateStatusAsEditRequestedFailed(error));
  }
}

function* _postUpdateStatusAsDiscarded(action) {
  try {
    const { ecgTestId, statusUpdatedReason } = action;

    yield call(ApiManager.postUpdateStatusAsDiscarded as any, {
      ecgTestId,
      statusUpdatedReason,
    });

    yield put(postUpdateStatusAsDiscardedSucceed());
  } catch (error) {
    console.error(error);
    yield put(postUpdateStatusAsDiscardedFailed(error));
  }
}

function* _postChangeEndDatetime(action) {
  try {
    const { ecgTestId, changedEndDatetime, changedEndDatetimeNote } = action;

    yield call(ApiManager.postChangeEndDatetime as any, {
      ecgTestId,
      changedEndDatetime,
      changedEndDatetimeNote,
    });

    yield put(postChangeEndDateSucceed());
  } catch (error) {
    console.error(error);
    yield put(postChangeEndDateFailed(error));
  }
}

// Saga
export function* saga() {
  // Ecg Test List Read
  yield takeLatest(FETCH_ECG_TEST_LIST_REQUESTED, _fetchEcgTestList);
  yield takeLatest(FETCH_ECG_TEST_LIST_REQUESTED, _fetchHidReportMetrics);
  // Get Hospitals ID
  yield takeLatest(FETCH_HID_LIST_REQUESTED, _fetchHospitalsHid);
  // Get Medical Affair User List
  yield takeLatest(
    GET_MEDICAL_AFFAIR_USER_LIST_REQUESTED,
    _getMedicalAffairUserList
  );
  // Ecg Test  Patch
  yield takeLatest(PATCH_ECG_TEST_REQUESTED, _patchEcgTest);
  // cloudStatus
  yield takeLatest(REVERT_STATUS_REQUESTED, _revertStatus);
  yield takeLatest(ECG_TEST_CONFIRM_REVIEW_REQUESTED, _ecgTestConfirmReview);
  yield takeLatest(ECG_TEST_EDIT_DONE_REVIEW_REQUESTED, _ecgTestEditDoneReview);
  // fetch tableList when set tableFilterOptions
  yield takeLatest(RESET_TABLE_FILTER_OPTIONS, _fetchEcgTestList);
  // 편집자, 결과지 담당자 할당/해제
  yield takeLatest(POST_SET_EDIT_MANAGED_BY_REQUESTED, _postSetEditManagedBy);
  yield takeLatest(
    POST_UNSET_EDIT_MANAGED_BY_REQUESTED,
    _postUnsetEditManagedBy
  );
  yield takeLatest(
    POST_SET_CREATE_REPORT_MANAGED_BY_REQUESTED,
    _postSetCreateReportManagedBy
  );
  yield takeLatest(
    POST_UNSET_CREATE_REPORT_MANAGED_BY_REQUESTED,
    _postUnsetCreateReportManagedBy
  );
  // etc
  yield takeLatest(GET_VALID_REPORT_REQUESTED, _getValidReport);
  yield takeLatest(
    POST_UPDATE_STATUS_AS_EDIT_REQUESTED_REQUESTED,
    _postUpdateStatusAsEditRequested
  );
  yield takeLatest(
    POST_UPDATE_STATUS_AS_DISCARDED_REQUESTED,
    _postUpdateStatusAsDiscarded
  );
  yield takeLatest(POST_CHANGE_END_DATE_TIME_REQUESTED, _postChangeEndDatetime);
}
