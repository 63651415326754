import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import DateUtil from 'util/DateUtil';

import useShallowEqualSelector from 'component/hook/useShallowEqualSelector';

import {
  setExamHospitalTimeZone,
  setTimeZoneOffsetDiffMinutes,
  setUserAffiliatedHospitalTimeZone,
} from 'redux/duck/authDuck';

interface TimeZoneState {
  examHospitalTimeZone: string;
  userAffiliatedHospitalTimeZone: string;
  timeZoneOffsetDiffMinutes: number;
}

const ONE_MINUTE_IN_MS = 1000 * 60;

const useTimeZone = (examTimeZone?: string) => {
  const dispatch = useDispatch();

  const {
    examHospitalTimeZone,
    userAffiliatedHospitalTimeZone,
    timeZoneOffsetDiffMinutes,
  } = useShallowEqualSelector(
    (state) => state.authReducer.timeZone
  ) as TimeZoneState;

  const handleSetExamHospitalTimeZone = (timeZone?: string) => {
    dispatch(setExamHospitalTimeZone(timeZone));
  };
  const handleSetUserAffiliatedHospitalTimeZone = (timeZone?: string) => {
    dispatch(setUserAffiliatedHospitalTimeZone(timeZone));
  };
  const handleSetTimeZoneOffsetDiffMinutes = (minutes: number) => {
    dispatch(setTimeZoneOffsetDiffMinutes(minutes));
  };

  /**
   * 타임존 설정 기준으로 날짜 객체 생성
   * @param date 날짜 객체
   * @returns 타임존 설정 기준으로 날짜 객체 생성
   */
  const newDate = (date?: Date | number | string) => {
    return DateUtil.getUserLocationTime({
      date,
      timeZone: examHospitalTimeZone || userAffiliatedHospitalTimeZone,
    });
  };

  /**
   * 스토어 내에서 현재 타임존들을 가져와서,
   * "같은 시점"을 두 타임존 기준으로 계산한 뒤 그 차이를 저장
   */
  const updateTimeZoneOffsetDiff = () => {
    // 두 타임존이 모두 있지 않으면 계산할 필요 없음
    if (!examHospitalTimeZone || !userAffiliatedHospitalTimeZone) {
      handleSetTimeZoneOffsetDiffMinutes(0);
      return;
    }

    // 1) 공통 기준이 될 '현재 시점' (UTC or 브라우저 로컬 상관없이 new Date()로 충분)
    const now = new Date();
    // 2) exam 타임존 시각
    const examDate = DateUtil.getUserLocationTime({
      date: now,
      timeZone: examHospitalTimeZone,
    });
    // 3) user 타임존 시각
    const userDate = DateUtil.getUserLocationTime({
      date: now,
      timeZone: userAffiliatedHospitalTimeZone,
    });
    // 4) 두 타임존의 시각 차이 계산
    const diffMs = userDate.getTime() - examDate.getTime();
    // 5) 분 단위로 변환
    const diffMinutes = diffMs / ONE_MINUTE_IN_MS;

    // 6) 스토어에 저장
    handleSetTimeZoneOffsetDiffMinutes(diffMinutes);
  };

  useEffect(() => {
    if (!examTimeZone) return;

    // 페이지 진입 시 examHospitalTimeZone 설정
    handleSetExamHospitalTimeZone(examTimeZone);
    updateTimeZoneOffsetDiff();

    return () => {
      handleSetExamHospitalTimeZone(undefined);
      updateTimeZoneOffsetDiff();
    };
  }, [examTimeZone]);

  return {
    newDate,
    updateTimeZoneOffsetDiff,
    timeZoneOffsetDiffMinutes,
    handleSetExamHospitalTimeZone,
    handleSetUserAffiliatedHospitalTimeZone,
    handleSetTimeZoneOffsetDiffMinutes,
  };
};

export default useTimeZone;
