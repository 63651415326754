import { sortTarget } from 'constant/TestTableConst';

import { TableFilterOptions } from 'redux/duck/ecgTestList/ecgTestListDuckType';

// Common Types
type Params = { [x: string]: any };

type BuildBaseParamsPram = {
  cloudStatus: TableFilterOptions['cloudStatus'];
  page: TableFilterOptions['page'];
};
export function buildBaseParams({
  cloudStatus,
  page,
}: BuildBaseParamsPram): Params {
  return {
    cloudStatus,
    page,
    pageSize: 100,
  };
}

type AddTestTypeParam = {
  params: Params;
  testType: TableFilterOptions['testType'];
};
export function addTestType({ params, testType }: AddTestTypeParam) {
  return testType ? { ...params, testType } : params;
}

type AddHidParam = {
  params: Params;
  hid: TableFilterOptions['hid'];
};
export function addHid({ params, hid }: AddHidParam) {
  return hid ? { ...params, hid } : params;
}

type AddEditManagedByParam = {
  params: Params;
  editManagedBy: TableFilterOptions['editManagedBy'];
};
export function addEditManagedBy({
  params,
  editManagedBy,
}: AddEditManagedByParam) {
  return editManagedBy ? { ...params, editManagedBy } : params;
}

type AddPrescriptionDurationParam = {
  params: Params;
  prescriptionDuration: TableFilterOptions['prescriptionDuration'];
};
export function addPrescriptionDuration({
  params,
  prescriptionDuration,
}: AddPrescriptionDurationParam): Params {
  return prescriptionDuration ? { ...params, prescriptionDuration } : params;
}

type AddOrderByParam = {
  params: Params;
  orderBy: TableFilterOptions['orderBy'];
};
export function addOrderBy({ params, orderBy }: AddOrderByParam) {
  const sortOrder = [
    sortTarget.estimatedReportDeliveryDatetime,
    sortTarget.eventCounts,
    sortTarget.uploadDatetime,
  ];
  const orderByArray = orderBy.split(',').filter(Boolean);

  const sortedOrderBy = sortOrder
    .filter((baseItem) =>
      orderByArray.some((orderItem) => orderItem.replace(/^-/, '') === baseItem)
    )
    .map((baseItem) =>
      orderByArray.find((orderItem) => orderItem.replace(/^-/, '') === baseItem)
    );

  return orderBy ? { ...params, orderBy: sortedOrderBy } : params;
}

type AddSearchKeywordParam = {
  params: Params;
  searchKeyword: TableFilterOptions['searchKeyword'];
};
export function addSearchKeyword({
  params,
  searchKeyword,
}: AddSearchKeywordParam): Params {
  return searchKeyword ? { ...params, q: searchKeyword } : params;
}

type AddInternalConfirmTimestampMsRangeParam = {
  params: Params;
  internalConfirmTimestampMsRange: TableFilterOptions['internalConfirmTimestampMsRange'];
};
export function addInternalConfirmTimestampMsRange({
  params,
  internalConfirmTimestampMsRange,
}: AddInternalConfirmTimestampMsRangeParam): Params {
  if (!internalConfirmTimestampMsRange) {
    return { ...params };
  }

  if (
    internalConfirmTimestampMsRange.length !== 2 ||
    internalConfirmTimestampMsRange[0] === null ||
    internalConfirmTimestampMsRange[1] === null
  ) {
    return { ...params };
  }

  const dateTimeValue = [
    Date.parse(internalConfirmTimestampMsRange[0]),
    Date.parse(internalConfirmTimestampMsRange[1]),
  ];

  return { ...params, internalConfirmTimestampMsRange: dateTimeValue };
}

type AddEstimatedReportDeliveryTimestampMsRange = {
  params: Params;
  estimatedReportDeliveryTimestampMsRange: TableFilterOptions['estimatedReportDeliveryTimestampMsRange'];
};
export function addEstimatedReportDeliveryTimestampMsRange({
  params,
  estimatedReportDeliveryTimestampMsRange,
}: AddEstimatedReportDeliveryTimestampMsRange): Params {
  if (!estimatedReportDeliveryTimestampMsRange) {
    return { ...params };
  }

  if (
    estimatedReportDeliveryTimestampMsRange.length !== 2 ||
    estimatedReportDeliveryTimestampMsRange[0] === null ||
    estimatedReportDeliveryTimestampMsRange[1] === null
  ) {
    return { ...params };
  }

  const dateTimeValue = [
    Date.parse(estimatedReportDeliveryTimestampMsRange[0]),
    Date.parse(estimatedReportDeliveryTimestampMsRange[1]),
  ];

  return { ...params, estimatedReportDeliveryTimestampMsRange: dateTimeValue };
}

export function combineValuesByKeys({ statusArray, targetStatusArray }) {
  return statusArray
    .filter((item) => targetStatusArray.includes(item.key))
    .map((item) => item.value)
    .flat();
}

export function updateUrlWithParams({
  searchKeyword,
  testType,
  hid,
  editManagedBy,
  cloudStatus,
  prescriptionDuration,
  orderBy,
  page,
  internalConfirmTimestampMsRange,
  estimatedReportDeliveryTimestampMsRange,
}) {
  const queryParams = convertToQueryParams({
    searchKeyword,
    testType,
    hid,
    editManagedBy,
    cloudStatus,
    prescriptionDuration,
    orderBy,
    page,
    internalConfirmTimestampMsRange,
    estimatedReportDeliveryTimestampMsRange,
  });

  window.history.pushState('', '', `?${queryParams}`);
}
function convertToQueryParams(obj) {
  const queryParams = new URLSearchParams();

  for (let key in obj) {
    if (!obj.hasOwnProperty(key)) continue;

    let value = obj[key];

    if (
      key === 'internalConfirmTimestampMsRange' ||
      key === 'estimatedReportDeliveryTimestampMsRange'
    ) {
      value = obj[key].map((dateTime) => Date.parse(dateTime));
    }

    // 값이 없는 경우 무시
    if (
      value === null ||
      value === undefined ||
      value === '' ||
      value.length === 0
    ) {
      continue;
    }

    // 값이 배열인 경우, 콤마로 구분된 문자열로 변환
    const stringValue = Array.isArray(value) ? value.join(',') : value;
    queryParams.set(key, stringValue);
  }

  return queryParams;
}

export function formatBirthDate(birthDate: string) {
  // 정규표현식을 사용하여 yyyy, yyyy-mm, yyyy-mm-dd 형태를 확인
  const yearOnlyRegex = /^\d{4}$/;
  const yearMonthRegex = /^\d{4}-\d{1,2}$/;

  if (yearOnlyRegex.test(birthDate)) return `${birthDate}-**-**`;
  if (yearMonthRegex.test(birthDate)) return `${birthDate}-**`;
  return birthDate;
}
export function formatPatientName(patientName: string) {
  if (patientName !== '') return patientName;
  return '***';
}
