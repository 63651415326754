import styled from 'styled-components';
import { batch } from 'react-redux';

import {
  EDIT_STATE_FIELD,
  PATTERN_MATCHING_CONST,
  PATTERN_MATCHING_REDUX_STATE_FIELD,
} from 'constant/PatternMatchingConst';

import usePatternMatchingAction from 'component/hook/usePatternMatching/usePatternMatchingAction';

import Button from 'component/ui/button/Button';
import {
  ExceptPattern,
  SearchPattern,
  SearchRPeak,
  SettingRPeak,
} from 'component/ui/icons';
import Tooltip from 'component/ui/tooltip/Tooltip';

import { TooltipTitleWrapper } from '../../hr-review/side-panel';

// pattern matching 편집시 사용하는 기능들을 모아놓은 컴포넌트
function PatternMatchingEdit({
  isFocusPatternSimilarity,
  isDisabledByCaliper,
  patternSimilarity,
  setPatternSimilarity,
  findPatternDisabledValidation,
  exclusionPatternDisabledValidation,
  findRpeakDisabledValidation,
  getRpeakDisabledValidation,
  handleSetEditState,
}) {
  const disabledCondition = {
    findPatternDisabled: findPatternDisabledValidation || isDisabledByCaliper,
    exclusionPatternDisabled:
      exclusionPatternDisabledValidation || isDisabledByCaliper,
    rpeakRangeDisabled: findRpeakDisabledValidation || isDisabledByCaliper,
    getRpeakDisabled: getRpeakDisabledValidation || isDisabledByCaliper,
  };

  const {
    editRange: {
      searchCondition: { editSectionIntervals, isTotalRangeSelected },
    },
    editState: { isPatternMatchingEnabled, isSetRpeak },
    findingPatternTargetRange: {
      onsetWaveformIndex: findingPatternTargetOnsetWfi,
      terminationWaveformIndex: findingPatternTargetTerminationWfi,
    },
    findingRpeakTargetList,
    recordingTime,
    //
    handleGetSimilarPatternRequest,
    handleSetExclusionPattern,
    handleSetToggleRpeak,
    handleGetRpeakRequest,
    handleSetInitPatternOfActionUpdate,
  } = usePatternMatchingAction();

  const onClickFindSimilarPattern = ({
    findingPatternTargetOnsetWfi,
    findingPatternTargetTerminationWfi,
  }) => {
    batch(() => {
      const editSectionOnsetWaveformIndex =
        editSectionIntervals[0]?.timestampToWaveformIndex ??
        (isTotalRangeSelected ? 0 : undefined);
      const editSectionTerminationWaveformIndex =
        editSectionIntervals[1]?.timestampToWaveformIndex ??
        (isTotalRangeSelected
          ? (recordingTime.recordingEndMs - recordingTime.recordingStartMs) / 4
          : undefined);

      if (
        editSectionOnsetWaveformIndex === undefined ||
        editSectionTerminationWaveformIndex === undefined
      ) {
        return;
      }

      handleGetSimilarPatternRequest({
        editSectionOnsetWaveformIndex,
        editSectionTerminationWaveformIndex,
        patternOnsetWaveformIndex: findingPatternTargetOnsetWfi,
        patternTerminationWaveformIndex: findingPatternTargetTerminationWfi,
        similarity: Number(patternSimilarity),
      });
      handleSetEditState({
        editStateField: { [EDIT_STATE_FIELD.IS_FIND_SIMILAR_PATTERN]: false },
        state: false,
      });
    });
  };

  const onClickExcludeSimilarPattern = ({
    findingPatternTargetOnsetWfi,
    findingPatternTargetTerminationWfi,
  }) => {
    handleSetExclusionPattern();
  };

  const onClickSetRpeak = (isSetRpeak) => {
    batch(() => {
      handleSetInitPatternOfActionUpdate([
        PATTERN_MATCHING_REDUX_STATE_FIELD.FINDING_RPEAK_TARGET_LIST,
      ]);
      handleSetEditState({
        editStateField: {
          [EDIT_STATE_FIELD.IS_SET_RPEAK]: !isSetRpeak,
        },
      });
    });
  };

  const onClickFindRpeak = () => {
    handleGetRpeakRequest(findingRpeakTargetList);
  };
  return (
    <>
      {isPatternMatchingEnabled && (
        <PatternMatchingEditWrapper>
          <Tooltip
            title={
              <TooltipTitleWrapper>{'유사 패턴 찾기'}</TooltipTitleWrapper>
            }
            placement={'bottom'}
            option={{ maxWidth: 'initial' }}>
            <Button
              disabled={
                disabledCondition.findPatternDisabled ||
                Number(patternSimilarity) === 0
              }
              style={{
                width: '28px',
                height: '28px',
                padding: '2px',
                gap: '8px',
                borderRadius: '3px',
                outline: 'none',
              }}
              color={'secondary'}
              centerIcon={<SearchPatternWrapper />}
              onClick={() => {
                batch(() => {
                  onClickFindSimilarPattern({
                    findingPatternTargetOnsetWfi,
                    findingPatternTargetTerminationWfi,
                  });
                  handleSetEditState({
                    editStateField: {
                      [EDIT_STATE_FIELD.IS_PATTERN_MATCHING_ENABLED]: true,
                      [EDIT_STATE_FIELD.IS_FIND_SIMILAR_PATTERN]: true,
                      [EDIT_STATE_FIELD.IS_EXCLUDE_SIMILAR_PATTERN]: false,
                      [EDIT_STATE_FIELD.IS_SET_RPEAK]: false,
                      [EDIT_STATE_FIELD.IS_FIND_RPEAK]: false,
                    },
                  });
                });
              }}
            />
          </Tooltip>
          <Tooltip
            title={
              <TooltipTitleWrapper>
                {'유사 패턴에서 제외하기'}
              </TooltipTitleWrapper>
            }
            placement={'bottom'}
            option={{ maxWidth: 'initial' }}>
            <Button
              disabled={disabledCondition.exclusionPatternDisabled}
              style={{
                width: '28px',
                height: '28px',
                padding: '2px',
                marginLeft: '2px',
                borderRadius: '3px',
                outline: 'none',
              }}
              color={'secondary'}
              centerIcon={<ExceptPatternWrapper />}
              onClick={() => {
                onClickExcludeSimilarPattern({
                  findingPatternTargetOnsetWfi,
                  findingPatternTargetTerminationWfi,
                });
              }}
            />
          </Tooltip>

          <SimilarityInputWrapper
            className={
              PATTERN_MATCHING_CONST.PATTERN_MATCHING_SIMILARITY_INPUT
            }>
            <StyledInputTitle disabled={disabledCondition.findPatternDisabled}>
              유사도
            </StyledInputTitle>
            <StyledInput
              disabled={disabledCondition.findPatternDisabled}
              type="text"
              value={patternSimilarity}
              onFocus={() => {
                isFocusPatternSimilarity.current = true;
              }}
              onBlur={() => {
                isFocusPatternSimilarity.current = false;
              }}
              onChange={(event) => {
                const { value } = event.target;
                if (value === '0.') {
                  setPatternSimilarity(value);
                  return;
                }
                const regex = /^0\.(5[0-9]?|[6-9][0-9]?)$/;
                if (regex.test(value)) {
                  setPatternSimilarity(value);
                }
              }}
            />
          </SimilarityInputWrapper>

          <Tooltip
            title={
              <TooltipTitleWrapper>{'Rpeak 설정하기'}</TooltipTitleWrapper>
            }
            placement={'bottom'}
            option={{ maxWidth: 'initial' }}>
            <Button
              disabled={disabledCondition.rpeakRangeDisabled}
              style={{
                width: '28px',
                height: '28px',
                padding: '2px',
                marginLeft: '8px',
                borderRadius: '3px',
                outline: 'none',
              }}
              color={'secondary'}
              centerIcon={<SettingRPeakWrapper />}
              onClick={() => {
                onClickSetRpeak(isSetRpeak);
              }}
            />
          </Tooltip>

          <Tooltip
            title={<TooltipTitleWrapper>{'Rpeak 찾기'}</TooltipTitleWrapper>}
            placement={'bottom'}
            option={{ maxWidth: 'initial' }}>
            <Button
              disabled={disabledCondition.getRpeakDisabled}
              style={{
                width: '28px',
                height: '28px',
                padding: '2px',
                marginLeft: '2px',
                borderRadius: '3px',
                outline: 'none',
              }}
              color={'secondary'}
              centerIcon={<SearchRPeakWrapper />}
              onClick={() => {
                onClickFindRpeak();
              }}
            />
          </Tooltip>

          <VerticalBar />
        </PatternMatchingEditWrapper>
      )}
    </>
  );
}

interface StyledInputTitleProps {
  disabled?: boolean;
}

const PatternMatchingEditWrapper = styled.div`
  display: flex;
  width: 244px;
  height: 28px;
  justify-content: end;
  align-items: center;
`;

const SearchPatternWrapper = styled(SearchPattern)`
  width: 24px;
  height: 24px;
  padding: 4px;
`;
const ExceptPatternWrapper = styled(ExceptPattern)`
  width: 17px !important;
  height: 17px !important;
  padding: 4px;
`;

const SearchRPeakWrapper = styled(SearchRPeak)`
  width: 21px !important;
  height: 21px !important;
  padding: 4px;
`;

const SettingRPeakWrapper = styled(SettingRPeak)`
  width: 22px !important;
  height: 22px !important;
  padding: 4px;
`;
const SimilarityInputWrapper = styled.div`
  margin-left: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  border: 1px solid ${(props) => props.theme.color.MEDIUM};
  border-radius: 6px;
  width: 82px;
  height: 22px;
`;
const StyledInputTitle = styled.span<StyledInputTitleProps>`
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  line-height: 15.6px;
  text-align: left;
  color: ${(props) =>
    props.disabled
      ? props.theme.color.COOL_GRAY_50
      : props.theme.color.COOL_GRAY_70};
  text-align: end;
  margin-top: 1px;
`;
const StyledInput = styled.input`
  font-family: 'Spoqa Han Sans Neo';
  width: 100%;
  padding: 0;
  font-size: 12px;
  line-height: 15.6px;
  font-weight: 400;
  color: ${(props) =>
    props.disabled
      ? props.theme.color.COOL_GRAY_50
      : props.theme.color.COOL_GRAY_90};
  background: transparent;
  border: none;
  outline: none;
  text-align: start;
  margin-top: 1px;

  /* Hide arrows from input number */
  /* Chrome, Safari, Edge, Opera */
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

const VerticalBar = styled.div`
  height: 20px;
  border-right: 1px solid ${(props) => props.theme.color.MEDIUM_LIGHT};
  margin: 0px 7px;
`;

export default PatternMatchingEdit;
