import { CHART_CONST } from 'constant/ChartConst';

import { transformMsToWaveformIndex } from 'util/EcgEvent/unitConversionUtils';

import { isTargetWithinRange } from '../../checkRange';

// 대표 유사 패턴 찾기 드래그 이벤트(mousedown, mouseup)유효성 검사
export function isValidMousedown({
  recordingStartMs,
  globalOnsetWfi,
  editRange,
}) {
  const {
    isTotalRangeSelected,
    editStartRange,
    editEndRange,
    startValid,
    endValid,
  } = getEditRangeProperty(editRange);
  const isEditRangeValid = startValid && endValid;
  const isOnsetWfiWithinEditRange = isOnsetWfiWithInEditRange({
    recordingStartMs,
    globalOnsetWfi,
    editStartRange,
    editEndRange,
  });

  const isValid =
    isTotalRangeSelected || (isEditRangeValid && isOnsetWfiWithinEditRange);
  return isValid;
}
export function isValidMouseup({
  recordingStartMs,
  editRange,
  globalOnsetWfi,
  globalTerminationWfi,
}) {
  const {
    isTotalRangeSelected,
    editStartRange,
    editEndRange,
    startValid,
    endValid,
  } = getEditRangeProperty(editRange);
  const isValidEditRange = startValid && endValid;
  const isWithinEditRangeRst = isOnsetTerminationWithinEditRange({
    recordingStartMs,
    //
    globalOnsetWfi,
    globalTerminationWfi,
    //
    editStartRange,
    editEndRange,
  });
  const isValid =
    isTotalRangeSelected || (isValidEditRange && isWithinEditRangeRst);

  return isValid;
}

// 유사 패턴 redux state(editRange) 구조분해
function getEditRangeProperty(editRange) {
  const {
    searchCondition: {
      editSectionIntervals: [editStartRange, editEndRange],
      isTotalRangeSelected,
    },
    validationStatus: { startValid, endValid },
  } = editRange;

  return {
    isTotalRangeSelected,
    editStartRange,
    editEndRange,
    //
    startValid,
    endValid,
  };
}

// onset wfi가 edit range 내에 있는지 확인
function isOnsetWfiWithInEditRange({
  recordingStartMs,
  globalOnsetWfi,
  editStartRange,
  editEndRange,
}) {
  const isOnsetWithInRange = isTargetWithinEditRange({
    recordingStartMs,
    targetWfi: globalOnsetWfi,
    editStartRange,
    editEndRange,
  });

  return isOnsetWithInRange;
}
// onset wfi, termination wfi가 edit range 내에 있는지 확인
function isOnsetTerminationWithinEditRange({
  recordingStartMs,
  //
  globalOnsetWfi,
  globalTerminationWfi,
  //
  editStartRange,
  editEndRange,
}) {
  const isOnsetWithInRange = isTargetWithinEditRange({
    recordingStartMs,
    targetWfi: globalOnsetWfi,
    editStartRange,
    editEndRange,
  });
  const isTerminationWithInRange = isTargetWithinEditRange({
    recordingStartMs,
    targetWfi: globalTerminationWfi,
    editStartRange,
    editEndRange,
  });

  return isOnsetWithInRange && isTerminationWithInRange;
}
// target wfi가 edit range 내에 있는지 확인
function isTargetWithinEditRange({
  recordingStartMs,
  targetWfi,
  editStartRange,
  editEndRange,
}) {
  const { editRangeStartWfi, editRangeEndWfi } = transformEditRangeWfi(
    recordingStartMs,
    { editStartRange, editEndRange }
  );

  return isTargetWithinRange({
    target: targetWfi,
    range: {
      start: editRangeStartWfi,
      end: editRangeEndWfi,
    },
  });
}

// edit range의 ms data를 wfi 변경
function transformEditRangeWfi(
  recordingStartMs,
  { editStartRange, editEndRange }
) {
  const editRangeStartWfi = transformMsToWaveformIndex(
    recordingStartMs,
    editStartRange?.timestamp
  );
  const editRangeEndWfi = transformMsToWaveformIndex(
    recordingStartMs,
    editEndRange?.timestamp
  );

  return { editRangeStartWfi, editRangeEndWfi };
}

export function isDragRangeValid(globalOnsetWfi, globalTerminationWfi, event) {
  return (
    globalOnsetWfi !== globalTerminationWfi && !isInvalidTarget(event.target)
  );
}

function isInvalidTarget(target) {
  const invalidTargetClassList = [
    CHART_CONST.HIGHCHARTS_BACKGROUND,
    CHART_CONST.HIGHCHARTS_BUTTON_BOX,
  ];
  if (!target.classList || target.classList.length === 0) return true;
  return invalidTargetClassList.some((cls) => target.classList.contains(cls));
}
