import { useDispatch } from 'react-redux';

import { type InverseEcg } from 'redux/duck/ops/opsDuckType'; // todo: ke [#patternMatching #refactoring] ops에서 import 받는 부분 고민 해보기
import {
  setTogglePatternMatching,
  setFindingPatternTargetRange,
  getSimilarPatternRequest,
  SetExclusionPattern,
  setToggleRpeak,
  getRpeakRequest,
  setFindingRpeakTargetList,
  applyPatternMatchingRequest,
  setInitPatternOfActionUpdate,
  setRpeakUpdateOption,
  setPatternMatchUpdateIntervals,
  setPatternMatchValidationStatus,
  setPatternMatchUpdateScopeType,
  setEditState,
  setPatternOfActionUpdate,
} from 'redux/duck/patternMatchingDuck';
import { selectTenSecStrip } from 'redux/duck/testResultDuck';
import { setUnDo } from 'redux/duck/undoableDuck';
import {
  PatternMatchConditionState,
  PatternMatchingState,
  PatternMatchingInterval,
} from '@type/patternMatching/type';

import useShallowEqualSelector from '../useShallowEqualSelector';

function usePatternMatchingAction() {
  // redux state
  const patternMatchingData = useShallowEqualSelector(
    (state: { patternMatchingDuckReducer: PatternMatchingState }) => {
      return state.patternMatchingDuckReducer;
    }
  ) as PatternMatchingState;
  const tenSecStripDetail = useShallowEqualSelector(selectTenSecStrip) as any;
  const recordingTime = useShallowEqualSelector(
    (state) => state.testResultReducer.recordingTime
  ) as { recordingStartMs: number; recordingEndMs: number };

  // action creator
  const dispatch = useDispatch();

  // setting - 변경구간
  const handleSetPatternMatchUpdateIntervals = (
    editSectionIntervals: PatternMatchingInterval
  ) => {
    dispatch(setPatternMatchUpdateIntervals({ editSectionIntervals }));
  };
  const handleSetPatternMatchValidationStatus = (
    validationStatus: Partial<InverseEcg['validationStatus']>
  ) => {
    dispatch(setPatternMatchValidationStatus({ validationStatus }));
  };
  const handleSetPatternMatchUpdateScopeType = (
    searchCondition: Partial<PatternMatchConditionState['searchCondition']>
  ) => {
    dispatch(setPatternMatchUpdateScopeType({ searchCondition }));
  };

  // setting - patternMatching 편집 관련
  const handleSetTogglePatternMatching = (isPatternMatchingEnabled) => {
    dispatch(setTogglePatternMatching(isPatternMatchingEnabled));
  };
  const handleSetFindingPatternTargetRange = (payload) => {
    dispatch(setFindingPatternTargetRange(payload));
  };
  const handleSetExclusionPattern = () => {
    dispatch(SetExclusionPattern());
  };
  const handleSetToggleRpeak = (payload = undefined) => {
    dispatch(setToggleRpeak(payload));
  };
  const handleSetFindingRpeakTargetList = (payload) => {
    dispatch(setFindingRpeakTargetList(payload));
  };
  const handleSetRpeakUpdateOption = (option) => {
    dispatch(setRpeakUpdateOption(option));
  };
  const handleUndo = () => {
    dispatch(setUnDo());
  };
  const handleSetEditState = ({ editStateField }) => {
    dispatch(setEditState({ editStateField }));
  };
  const handleSetInitPatternOfActionUpdate = (
    initUpdateField: string[] = []
  ) => {
    dispatch(setInitPatternOfActionUpdate(initUpdateField));
  };
  const handleSetPatternOfActionUpdate = (targetPatternOfAction: string) => {
    dispatch(setPatternOfActionUpdate(targetPatternOfAction));
  };

  // api 관련
  const handleGetSimilarPatternRequest = (payload) => {
    dispatch(getSimilarPatternRequest(payload));
  };
  const handleGetRpeakRequest = (findingRpeakTargetList) => {
    dispatch(getRpeakRequest(findingRpeakTargetList));
  };
  const handleApplyPatternMatchingEdit = () => {
    dispatch(applyPatternMatchingRequest());
  };

  return {
    // redux state
    ...patternMatchingData,
    tenSecStrip: { ...tenSecStripDetail },
    recordingTime,
    // setting - 변경구간
    handleSetPatternMatchUpdateIntervals,
    handleSetPatternMatchValidationStatus,
    handleSetPatternMatchUpdateScopeType,
    // setting - patternMatching 편집 관련
    handleSetTogglePatternMatching,
    handleSetFindingPatternTargetRange,
    handleSetExclusionPattern,
    handleSetToggleRpeak,
    handleSetFindingRpeakTargetList,
    handleSetRpeakUpdateOption,
    handleUndo,
    handleSetEditState,
    handleSetInitPatternOfActionUpdate,
    handleSetPatternOfActionUpdate,
    // api 관련
    handleGetSimilarPatternRequest,
    handleGetRpeakRequest,
    handleApplyPatternMatchingEdit,
  };
}

export default usePatternMatchingAction;
