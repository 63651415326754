import { PatternAction } from '@type/patternMatching/type';

import {
  SELECTION_MARKER_TYPE,
  SELECTION_MARKER_OFFSET_CONST,
  ICON_SVG,
  CHART_EDIT_CONST,
} from './ChartEditConst';

export const PATTERN_MATCHING = 'PATTERN_MATCHING';
export const PATTERN_MATCHING_TEN_SEC_STRIP_CHART_TYPE = 'ten';
export const PATTERN_MATCHING_THIRTY_SEC_STRIP_CHART_TYPE = 'thirty';
export const PATTERN_MATCHING_REDUX_STATE_FIELD = {
  INITIAL_STATE: 'initialState',
  EDIT_RANGE: 'editRange', // 패턴 매칭 적용 대상 구간
  EDIT_STATE: 'editState', // 패턴 매칭 편집 상태
  FINDING_PATTERN_TARGET_RANGE: 'findingPatternTargetRange',
  SIMILAR_PATTERN_DATA: 'similarPatternData',
  FINDING_RPEAK_TARGET_LIST: 'findingRpeakTargetList',
  RPEAK_LIST: 'rpeakList',
  PATTERN_OF_ACTION: 'patternAction',
  EDITED_WAVEFORM_INDEXES: 'editedWaveformIndexes',
  APPLY_PATTERN_MATCHING_ACTION: 'applyPatternMatchingAction',
};
export const EDIT_STATE_FIELD = {
  IS_PATTERN_MATCHING_ENABLED: 'isPatternMatchingEnabled',
  IS_FIND_SIMILAR_PATTERN: 'isFindSimilarPattern',
  IS_EXCLUDE_SIMILAR_PATTERN: 'isExcludeSimilarPattern',
  IS_SET_RPEAK: 'isSetRpeak',
  IS_FIND_RPEAK: 'isFindRpeak',
};

export const FIND_PATTERN_LEVEL = {
  LEVEL1: 'Level1',
  LEVEL2: 'Level2',
};

export const FIND_PATTERN_LEVEL_TYPE = {
  // redux state에서 사용
  LEVEL: 'Level',
  LEVEL1: FIND_PATTERN_LEVEL.LEVEL1,
  LEVEL2: FIND_PATTERN_LEVEL.LEVEL2,
  // element class에서 사용
  LEVEL_LOWER_CASE: 'level',
  LEVEL1_LOWER_CASE: 'level1',
  LEVEL2_LOWER_CASE: 'level2',
};

export const ACTION_TYPE: Record<string, PatternAction> = {
  INIT: 'Init',
  FIND_LEVEL1: `Find_${FIND_PATTERN_LEVEL_TYPE.LEVEL1}`,
  FIND_LEVEL2: `Find_${FIND_PATTERN_LEVEL_TYPE.LEVEL2}`,
  EXCLUSION: 'Exclusion',
  SET_RPEAK: 'Set_Rpeak',
  GET_RPEAK: 'Get_Rpeak',
  SELECT_BEAT_TYPE: 'Select_BeatType',
  DELETE_RPEAK: 'Delete_Rpeak',
  APPLY_RPEAK: 'Apply_Rpeak',
  RESET_PATTERN_MATCHING: 'Reset_Pattern_Matching',
  CLOSE_10S_STRIP: 'Close_10s_Strip',
  UNDO_STATE: 'Undo_State',
};

export const PATTERN_MATCHING_CONST = {
  PATTERN_MATCHING_BEAT_MARKER_GROUP: {
    TYPE: CHART_EDIT_CONST.PATTERN_MATCHING_BEAT_MARKER_GROUP,
    SUB_TYPE: {
      PATTERN_MATCHING_FOUND_BEAT_MARKER:
        CHART_EDIT_CONST.PATTERN_MATCHING_FOUND_BEAT_MARKER,
      PATTERN_MATCHING_ORIGIN_BEAT_MARKER:
        CHART_EDIT_CONST.PATTERN_MATCHING_ORIGIN_BEAT_MARKER,
    },
  },
  TEN_SEC_STRIP_FINDING_RPEAK_TARGET: {
    TYPE: SELECTION_MARKER_TYPE.FINDING_RPEAK_TARGET,
  },
  TEN_SEC_STRIP_PATTERN_MATCHING_RPEAK: {
    CLASS_TITLE: {
      ICON_GROUP: CHART_EDIT_CONST.PATTERN_MATCHING_RPEAK_ICON_GROUP,
      RPEAK_GROUP: CHART_EDIT_CONST.PATTERN_MATCHING_RPEAK_GROUP,
    },
    OFF_SET: SELECTION_MARKER_OFFSET_CONST.TENSEC_STRIP,
    ICON_SVG: {
      STAR: ICON_SVG.STAR,
    },
    OFFSET: { TRANSLATE_X_OFFSET: -15, TRANSLATE_Y_OFFSET: -1.5 },
  },
  PATTERN_MATCHING_SIMILARITY_INPUT: 'pattern-matching-similarity-input',
  PATTERN_MATCHING_NAVIGATION_INPUT: 'pattern-matching-navigation-input',
  PATTERN_MATCHING_NAVIGATION_BUTTON: 'pattern-matching-navigation-button',
};

export const processBeatsByStrategyConst = {
  WITH_FINDING_RPEAKS: 'withFindingRpeaks',
  WITHOUT_FINDING_RPEAKS: 'withoutFindingRpeaks',
  DELETE_RPEAK: 'deleteRpeak',
};
