import { getEventInfo } from 'util/EventConstUtil';

import { TimeEvent } from '@type/ecgEventType/eventType';
import { SelectedEventList } from '@type/optimisticUpdate/process';
import {
  TimeEventForOptimisticUpdate,
  optimisticEventDataUpdateForTimeEventConstTypes,
  optimisticEventDataUpdateForTimeEventParam,
} from '@type/optimisticUpdate/type';
import { TimeEventType } from '@type/ecgEventType/baseEventType';

import {
  EventHandlingParams,
  EventReviewOptimisticEventDataUpdateForTimeEvent,
} from './optimisticEventDataUpdateForTimeEvent';

export class OptimisticEventDataUpdateForNormal extends EventReviewOptimisticEventDataUpdateForTimeEvent {
  private normalTypeEventList = [TimeEventType.AF, TimeEventType.OTHERS];
  validation() {}

  *optimisticEventData({
    updateReqOption,
  }: optimisticEventDataUpdateForTimeEventParam) {
    const isAddEvent = !updateReqOption.isRemove;
    const {
      leadOff: staleLeadOffList,
      data: staleTimeEventList,
    }: { leadOff: any; data: TimeEvent[] } = yield this.getTimeEventList();
    const {
      onset: onsetSelectionStrip,
      termination: terminationSelectionStrip,
    } = yield this.getSelectionStrip();

    if (terminationSelectionStrip.clickedTimestamp === undefined) {
      console.warn('terminationSelectionStrip is empty');
      return;
    }

    if (isAddEvent) {
      yield* this.handleAddEvent({
        updateReqOption,
        //
        staleLeadOffList,
        staleTimeEventList,
        //
        onsetSelectionStrip,
        terminationSelectionStrip,
      });
    } else {
      yield* this.handleRemoveEvent({
        updateReqOption,
        //
        staleLeadOffList,
        staleTimeEventList,
        //
        onsetSelectionStrip,
        terminationSelectionStrip,
      });
    }
  }
  private *handleAddEvent({
    updateReqOption,
    //
    staleLeadOffList,
    staleTimeEventList,
    //
    onsetSelectionStrip,
    terminationSelectionStrip,
  }: EventHandlingParams) {
    /*****************************************************************************************************************************************/
    /* STEP LIST                                                                                                                             */
    /*                                                                                                                                       */
    /* * STEP1. staleTimeEventList에서 다른 이벤트와 같은 이벤트 중 exclusiveRange인 이벤트를 제외한 것을 filter해서 exclusiveRange로 정의   */
    /*                                                                                                                                       */
    /* * STEP2. selectionStrip range와 기존이벤트를 비교해서 새로운 이벤트를 만들어 낸다.                                                    */
    /*    - selection marker range와                                                                                                         */
    /*      selection marker range에 포함되는 event의 onset, termination Ms 중에서 가장 작은 onset과 가장큰 termination이                     */
    /*      새로 만드는 new event marker state의 기준이 된다.                                                                                 */
    /*                                                                                                                                       */
    /* * STEP2.1. STEP2을 통해 새로 만든 이벤트에 leadoff구간이 있다면 leadoff 구간을 제외한 새로운 이벤트를 만들어 냅니다.                  */
    /*    - leadoff 구간이 없다면 STEP 과정은 패스                                                                                           */
    /*                                                                                                                                       */
    /* * STEP3. STEP1 + STEP2                                                                                                                */
    /*****************************************************************************************************************************************/

    // * STEP1. remove Fully Inclusive Range from selectionStrip Range & except other target timeEvent type
    const freshLeadOffList = [...staleLeadOffList];
    const eventType = getEventInfo({
      timeEventType: updateReqOption.timeEventType,
    }).findOne()?.type as optimisticEventDataUpdateForTimeEventConstTypes;
    // staleTimeEventList에서 다른 이벤트와 같은 이벤트 중 exclusiveRange인 이벤트를 제외한 것을 filter해서 exclusiveRange로 정의
    const exclusiveRange = staleTimeEventList.filter((staleTimeEvent) => {
      const isSameType = staleTimeEvent.type === eventType;
      const conditionOfExclusiveRangeOfSelectionStrip =
        this.isExclusiveRangeOfSelectionStrip({
          onsetSelectionStrip: onsetSelectionStrip.clickedTimestamp,
          terminationSelectionStrip: terminationSelectionStrip.clickedTimestamp,
          onsetRange: staleTimeEvent.onsetMs,
          terminationRange: staleTimeEvent.terminationMs,
        });

      return !isSameType || conditionOfExclusiveRangeOfSelectionStrip;
    });

    // * STEP2. selectionStrip range와 기존이벤트를 비교해서 새로운 이벤트를 만들어 낸다.
    const partiallyInclusiveRangeOptionFalse = staleTimeEventList.filter(
      (staleTimeEvent) => {
        const isSameType = staleTimeEvent.type === eventType;
        const conditionOfIsPartiallyInclusiveRangeOfSelectionStrip =
          this.isPartiallyInclusiveRangeOfSelectionStrip(
            {
              onsetSelectionStrip: onsetSelectionStrip.clickedTimestamp,
              terminationSelectionStrip:
                terminationSelectionStrip.clickedTimestamp,
              onsetRange: staleTimeEvent.onsetMs,
              terminationRange: staleTimeEvent.terminationMs,
            },
            { isIncludeBorder: false }
          );

        return (
          isSameType && conditionOfIsPartiallyInclusiveRangeOfSelectionStrip
        );
      }
    );

    let minOnsetMs = onsetSelectionStrip.clickedTimestamp;
    let maxTerminationMs = terminationSelectionStrip.clickedTimestamp;
    if (partiallyInclusiveRangeOptionFalse.length > 0) {
      minOnsetMs = Math.min(
        ...partiallyInclusiveRangeOptionFalse.map((event) => event.onsetMs),
        onsetSelectionStrip.clickedTimestamp
      );
      maxTerminationMs = Math.max(
        ...partiallyInclusiveRangeOptionFalse.map(
          (event) => event.terminationMs
        ),
        terminationSelectionStrip.clickedTimestamp
      );
    }

    let responseInfo: TimeEventForOptimisticUpdate[] = [
      {
        createAt: new Date().getTime(),
        durationMs: maxTerminationMs - minOnsetMs,
        onsetMs: minOnsetMs,
        terminationMs: maxTerminationMs,
        type: eventType,
        timeEventId: null,
        onsetRPeakIndex: null,
        position: null,
        isOptimisticEventDataUpdate: true, // useGetArrhythmiaEvents.jsx > useEffect(Time Event 구간정보 업데이트)에서 사용하는 isEqualTimeEventList(equalityFunctions.js>getObjectFromArray)에서 사용
      },
    ];

    // STEP2.1. STEP2을 통해 새로 만든 이벤트에 leadoff구간이 있다면 leadoff 구간을 제외한 새로운 이벤트를 만들어 냅니다.
    responseInfo = this.getAvailableEventRange({
      excludedEventList: freshLeadOffList,
      eventType,
      responseInfo,
    });

    // STEP3. STEP1 + STEP2
    let freshTimeEventList = [
      ...exclusiveRange,
      ...responseInfo,
    ] as TimeEvent[];
    freshTimeEventList = this.sortTimeEventList(freshTimeEventList);

    yield this.setTimeEventsList({
      freshLeadOffList,
      freshTimeEventList,
    });
  }

  private *handleRemoveEvent({
    updateReqOption,
    //
    staleLeadOffList,
    staleTimeEventList,
    //
    onsetSelectionStrip,
    terminationSelectionStrip,
  }: EventHandlingParams) {
    /*****************************************************/
    /* # 제거 되는 케이스 2가지                          */
    /*   CASE1. 이벤트 선택 제거 (isWholeUnMark = true)  */
    /*   CASE2. 구간 선택 제거 (isWholeUnMark = false)   */
    /*****************************************************/
    const freshLeadOffList = [...staleLeadOffList];
    const isWholeUnMark: unknown = yield this.getIsWholeUnMark();
    if (isWholeUnMark) {
      // CASE1. 이벤트 선택 제거 (isWholeUnMark = true)\
      const selectedEventList: SelectedEventList[] =
        yield this.getSelectedEventList();
      const freshTimeEventList = staleTimeEventList.filter(
        (staleTimeEvent) =>
          staleTimeEvent.timeEventId !== selectedEventList[0].timeEventId
      );

      yield this.setTimeEventsList({
        freshLeadOffList,
        freshTimeEventList,
      });
    } else {
      // CASE2. 구간 선택 제거 (isWholeUnMark = false)
      const eventType = getEventInfo({
        timeEventType: updateReqOption?.timeEventType,
      }).findOne()?.type as optimisticEventDataUpdateForTimeEventConstTypes;
      /*********************************************************************/
      /* STEP LIST                                                         */
      /* * STEP1. filter fully exclusive Range of selectionStrip Range     */
      /* * STEP2. create new event marker state by overlapping Event       */
      /* * STEP3. STEP1 + STEP2                                            */
      /*********************************************************************/
      // STEP1. filter fully exclusive Range of selectionStrip Range
      const exclusiveRange = staleTimeEventList.filter((staleTimeEvent) => {
        const isSameType = staleTimeEvent.type === eventType;
        const conditionOfExclusiveRangeOfSelectionStrip =
          this.isExclusiveRangeOfSelectionStrip({
            onsetSelectionStrip: onsetSelectionStrip.clickedTimestamp,
            terminationSelectionStrip:
              terminationSelectionStrip.clickedTimestamp,
            onsetRange: staleTimeEvent.onsetMs,
            terminationRange: staleTimeEvent.terminationMs,
          });

        return !isSameType || conditionOfExclusiveRangeOfSelectionStrip;
      });

      // STEP2. create new event marker state by overlapping Event
      const partiallyInclusiveRangeOptionFalse = staleTimeEventList.filter(
        (staleTimeEvent) => {
          const isSameType = staleTimeEvent.type === eventType;
          const conditionOfIsPartiallyInclusiveRangeOfSelectionStrip =
            this.isPartiallyInclusiveRangeOfSelectionStrip(
              {
                onsetSelectionStrip: onsetSelectionStrip.clickedTimestamp,
                terminationSelectionStrip:
                  terminationSelectionStrip.clickedTimestamp,
                onsetRange: staleTimeEvent.onsetMs,
                terminationRange: staleTimeEvent.terminationMs,
              },
              { isIncludeBorder: false }
            );

          return (
            isSameType && conditionOfIsPartiallyInclusiveRangeOfSelectionStrip
          );
        }
      );

      const onsetOverlappingEvent = this.findOverLappingEvent({
        baseSelectionStripTimeStamp: onsetSelectionStrip.clickedTimestamp,
        partiallyInclusiveRange: partiallyInclusiveRangeOptionFalse,
      });
      const terminationOverlappingEvent = this.findOverLappingEvent({
        baseSelectionStripTimeStamp: terminationSelectionStrip.clickedTimestamp,
        partiallyInclusiveRange: partiallyInclusiveRangeOptionFalse,
      });

      const responseInfo: TimeEventForOptimisticUpdate[] = [];
      if (onsetOverlappingEvent) {
        responseInfo.push({
          createAt: new Date().getTime(),
          durationMs:
            onsetSelectionStrip.clickedTimestamp -
            onsetOverlappingEvent.onsetMs,
          onsetMs: onsetOverlappingEvent.onsetMs,
          terminationMs: onsetSelectionStrip.clickedTimestamp,
          type: eventType,
          timeEventId: null,
          onsetRPeakIndex: null,
          position: null,
          isOptimisticEventDataUpdate: true, // useGetArrhythmiaEvents.jsx > useEffect(Time Event 구간정보 업데이트)에서 사용하는 isEqualTimeEventList(equalityFunctions.js>getObjectFromArray)에서 사용
        });
      }
      if (terminationOverlappingEvent) {
        responseInfo.push({
          createAt: new Date().getTime(),
          durationMs:
            terminationOverlappingEvent.terminationMs -
            terminationSelectionStrip.clickedTimestamp,
          onsetMs: terminationSelectionStrip.clickedTimestamp,
          terminationMs: terminationOverlappingEvent.terminationMs,
          type: eventType,
          timeEventId: null,
          onsetRPeakIndex: null,
          position: null,
          isOptimisticEventDataUpdate: true, // useGetArrhythmiaEvents.jsx > useEffect(Time Event 구간정보 업데이트)에서 사용하는 isEqualTimeEventList(equalityFunctions.js>getObjectFromArray)에서 사용
        });
      }

      // STEP3. STEP1 + STEP2
      let freshTimeEventList = [
        ...exclusiveRange,
        ...responseInfo,
      ] as TimeEvent[];
      freshTimeEventList = this.sortTimeEventList(freshTimeEventList);

      yield this.setTimeEventsList({
        freshLeadOffList,
        freshTimeEventList,
      });
    }
  }
}
