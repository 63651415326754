import { Store } from 'redux';

import { ACTION_TYPE } from 'constant/PatternMatchingConst';

import {
  getLongTermChartStripBeatsData,
  getRegular30sWaveformIndexRange,
} from 'util/rawUtil';
import { _getBeatLabelButtonDataList } from 'util/reduxDuck/TestResultDuckUtil';
import {
  filterBeatLists,
  filterPatternRangesInStrip,
  isWfiInPatternRanges,
} from 'util/patternMatching/processBeatLabelButton/filterPatternRanges';

import { selectSubBeatEventInfoMap } from 'component/hook/useGetArrhythmiaEvents/selectFunctions';

import { PatternAction, SimilarPatternData } from '@type/patternMatching/type';
import { WaveformIndex } from '@type/ecgEventType/eventUnit';
import { BeatType } from '@type/ecgEventType/baseEventType';

interface ProcessBeatLabelButtonParams {
  store: Store | null;
  isPatternMatchingEnabled: boolean;
  //
  actionType: PatternAction; // 패턴 매칭 편집 액션 타입
  beatType: BeatType;
  //
  similarPatternData: SimilarPatternData;
  eventReviewTenSecStripDetail: {
    onsetWaveformIdx: WaveformIndex;
    terminationWaveformIdx: WaveformIndex;
    [key: string]: any;
  };
}

interface FilteredResult {
  wfiList: number[];
  typeList: number[];
}

/**
 * 패턴 매칭시 10s strip에서 보여지는 beat label button 데이터 처리
 */
export function processBeatLabelButton({
  isPatternMatchingEnabled,
  store,
  beatType,
  actionType,
  similarPatternData,
  eventReviewTenSecStripDetail,
}: ProcessBeatLabelButtonParams) {
  const { onsetWaveformIdx, terminationWaveformIdx } =
    eventReviewTenSecStripDetail;

  if (
    store === null ||
    !isPatternMatchingEnabled ||
    (onsetWaveformIdx === undefined && terminationWaveformIdx === undefined)
  ) {
    return null;
  }
  /************************************************************************************/
  /*   STEP1: onsetWaveformIdx와 terminationWaveformIdx의 beatsNEctopicList 정보 조회 */
  /*   STEP2: 패턴 매칭 - 삭제 액션 처리                                              */
  /*   STEP2: 패턴 매칭 - 비트 편집 액션 처리                                         */
  /*   STEP3: 10s strip에서 보여지는 beat label button 데이터 처리                    */
  /************************************************************************************/

  // STEP1: onsetWaveformIdx와 terminationWaveformIdx의 beatsNEctopicList 정보 조회
  // onsetWaveformIdx와 terminationWaveformIdx 사이의 beatsNEctopicList 정보 조회
  const beatEventInfoMap = selectSubBeatEventInfoMap(
    store.getState(),
    onsetWaveformIdx,
    terminationWaveformIdx
  );
  // onsetWaveformIndex의 대표 30초 구간 조회
  const {
    onsetWaveformIndex: standardOnsetWaveformIndex,
    terminationWaveformIndex: standardTerminationWaveformIndex,
  } = getRegular30sWaveformIndexRange(onsetWaveformIdx, terminationWaveformIdx);

  // 대표 30초(또는 60초) 구간의 beats 정보 조회
  const longTermChartStripBeatsData = getLongTermChartStripBeatsData({
    standardOnsetWaveformIndex,
    standardTerminationWaveformIndex,
    beatEventInfoMap,
  });

  // 대표 30초 구간에서 구간(onsetWaveformIdx, terminationWaveformIdx) beatTypeList, waveformIndexLIist 정보 조회
  let { beatTypeList, beatWaveformIndexList } = filterBeatLists({
    beatTypeList: longTermChartStripBeatsData?.beatType.map(() => 0),
    beatWaveformIndexList: longTermChartStripBeatsData?.waveformIndex,
    onsetWaveformIdx,
    terminationWaveformIdx,
  });

  // 대표 30초 구간에서 구간(onsetWaveformIdx, terminationWaveformIdx) 유사 패턴 범위 필터링
  const filteredPatternRanges = filterPatternRangesInStrip({
    similarPatternData,
    onsetWaveformIdx,
    terminationWaveformIdx,
    //
    actionType,
  });

  // STEP2: 패턴 매칭 - 삭제 액션 처리
  if (actionType === ACTION_TYPE.DELETE_RPEAK) {
    const filtered: FilteredResult = { wfiList: [], typeList: [] };

    for (let i = 0; i < beatWaveformIndexList.length; i++) {
      const wfi = beatWaveformIndexList[i];
      if (!isWfiInPatternRanges(wfi, filteredPatternRanges)) {
        filtered.wfiList.push(wfi);
        filtered.typeList.push(beatTypeList[i]);
      }
    }

    beatWaveformIndexList = filtered.wfiList;
    beatTypeList = filtered.typeList;
  }

  // STEP2: 패턴 매칭 - 비트 편집 액션 처리
  if (actionType === ACTION_TYPE.SELECT_BEAT_TYPE) {
    beatTypeList = beatTypeList.map((type, idx) =>
      isWfiInPatternRanges(beatWaveformIndexList[idx], filteredPatternRanges)
        ? beatType
        : type
    );
  }

  // STEP3: 10s strip에서 보여지는 beat label button 데이터 처리
  const beatLabelButtonDataList = _getBeatLabelButtonDataList({
    beatTypeList,
    beatWaveformIndexList: beatWaveformIndexList.map(
      (wfi) => wfi - onsetWaveformIdx
    ),
  });

  return {
    ...eventReviewTenSecStripDetail,
    beatLabelButtonDataList,
  };
}
