import React from 'react';
import { useIntl } from 'react-intl';
import styled, { useTheme } from 'styled-components';

import { DialogWrapper, DialogButtonWrapper } from 'component/ui/dialog/Dialog';
import TextButton from 'component/ui/button/TextButton';

const TitleText = styled.div`
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 130%;
  font-weight: 700;
  color: ${(props) => props.theme.color.BLACK};
  white-space: pre-line;
`;

const MessageContainer = styled.div`
  margin-bottom: 16px;
  font-size: 12px;
  line-height: 130%;
`;

const AlertMessageText = styled.div`
  font-weight: bold;
  color: ${(props) => props.theme.color.RED};
`;

const MessageText = styled.div`
  color: ${(props) => props.theme.color.BLACK};
  white-space: pre-line;
`;

function ConfirmDialog(props) {
  const theme = useTheme();
  const intl = useIntl();

  const { open, params, callback, onClose } = props;
  const {
    title,
    titleStyle,
    alertMessage,
    message,
    messageStyle,
    cancelButtonText,
    confirmButtonText,
    cancelButtonColor,
    confirmButtonColor,
    onSubmit,
    onCancel,
    className,
  } = params;
  return (
    <DialogWrapper className={className} zIndex={11} open={open} maxWidth={380}>
      {title && <TitleText style={titleStyle}>{title}</TitleText>}

      <MessageContainer className={className}>
        {alertMessage && (
          <AlertMessageText className={className}>
            {alertMessage}
          </AlertMessageText>
        )}
        {message && (
          <MessageText className={className} style={messageStyle}>
            {message}
          </MessageText>
        )}
      </MessageContainer>

      <DialogButtonWrapper className={className}>
        <TextButton
          className={className}
          textColor={cancelButtonColor || theme.color.MEDIUM_DARK}
          title={
            cancelButtonText ||
            intl.formatMessage({
              id: '99-Dialog-Button-title-cancel',
              description: '팝업 Dialog의 취소 버튼',
              defaultMessage: '취소',
            })
          }
          onClick={() => {
            onClose();
            if (typeof onCancel === 'function') {
              onCancel();
            }
          }}
        />
        <TextButton
          className={className}
          textColor={confirmButtonColor || null}
          title={
            confirmButtonText ||
            intl.formatMessage({
              id: '99-Dialog-Button-title-confirm',
              description: '팝업 Dialog의 확인 버튼',
              defaultMessage: '확인',
            })
          }
          onClick={() => {
            onClose();
            if (typeof onSubmit === 'function') {
              onSubmit();
            }
          }}
        />
      </DialogButtonWrapper>
    </DialogWrapper>
  );
}

export default ConfirmDialog;
