import { useEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';

import { CONTEXT_MENU } from 'constant/EventConst';
import Const from 'constant/Const';
import { TEN_SEC_STRIP_DETAIL } from 'constant/ChartEditConst';

import { isInViewportInfo } from 'util/Utility';
import {
  EventHandlerTarget,
  addKeyupHandler,
  removeKeyupHandler,
} from 'util/EventManager';

import BeatEditContextmenu from 'component/fragment/test-result/share/beatEditContextmenu';

import useEditBeatEvent from '../tenSecStripDetail/useEditBeatEvent';

const { ESCAPE, KEY_Q, KEY_W, KEY_E, KEY_R, KEY_Z } = Const.KEY_MAP;

function BeatEditContextmenuContainer(props) {
  const {
    tenSecStripChartRef,
    clickFlagRef,
    dragFlag,
    chartOption: { tabType, editModeType },
    selectedBeatBtnInfoList, // 편집할 비트 대상
    isOpenBeatContextmenuSetting,
    beatContextmenuPositionSetting,
    initTenSecStripDetail,
  } = props;

  const { isOpenBeatContextmenu, handleBeatContextmenu } =
    isOpenBeatContextmenuSetting;
  const { beatContextmenuPosition } = beatContextmenuPositionSetting;

  const theme = useTheme();
  const wrapperRef = useRef(null);
  const draggedForEditBeat = dragFlag;

  const [rePositionInfo, setRePositionInfo] = useState({
    top: false,
    right: false,
    bottom: false,
    left: false,
  });

  const { addBeat, editBeat, deleteBeat } = useEditBeatEvent({
    tabType,
    initTenSecStripDetail,
  });

  // [reposition]
  useEffect(() => {
    if (!tenSecStripChartRef.current || !isOpenBeatContextmenu) return;

    let { top, right, bottom, left } = isInViewportInfo(wrapperRef.current);
    const isBeatReviewTab = tabType === TEN_SEC_STRIP_DETAIL.TAB.BEAT_REVIEW;

    if (!isBeatReviewTab && draggedForEditBeat) {
      bottom = true;
    }

    setRePositionInfo({
      ...rePositionInfo,
      top,
      right,
      bottom,
      left,
    });
  }, [tenSecStripChartRef, isOpenBeatContextmenu, beatContextmenuPosition]);

  useEffect(() => {
    const beatEditContextMenuEventHandler = (e) => {
      const { code, altKey, ctrlKey, metaKey, shiftKey } = e;

      const isAvailableDeleteCase = KEY_Z === code && draggedForEditBeat;

      const isPressedModifierKey = altKey || ctrlKey || metaKey || shiftKey;
      const isAvailableKey = [
        ESCAPE,
        KEY_Q,
        KEY_W,
        KEY_E,
        KEY_R,
        isAvailableDeleteCase ? KEY_Z : [],
      ].includes(code);

      if (!isOpenBeatContextmenu || isPressedModifierKey || !isAvailableKey) {
        return;
      }

      let selectedBeatValue;
      switch (code) {
        // Select classification
        case ESCAPE: // esc
          handleBeatContextmenu(false);
          break;
        case KEY_Q:
          selectedBeatValue = CONTEXT_MENU.BEAT_TYPE.NORMAL.value;
          break;
        case KEY_W:
          selectedBeatValue = CONTEXT_MENU.BEAT_TYPE.APC.value;
          break;
        case KEY_E:
          selectedBeatValue = CONTEXT_MENU.BEAT_TYPE.VPC.value;
          break;
        case KEY_R:
          selectedBeatValue = CONTEXT_MENU.BEAT_TYPE.QUESTIONABLE.value;
          break;
        case KEY_Z: // delete beat
          selectedBeatValue = CONTEXT_MENU.REMOVE.value;
          break;
        default:
          return;
      }

      editEvent(selectedBeatValue);
    };

    addKeyupHandler({
      eventHandler: beatEditContextMenuEventHandler,
      target: EventHandlerTarget.beatEditContextMenu,
    });
    return () => {
      removeKeyupHandler(beatEditContextMenuEventHandler);
    };
  }, [isOpenBeatContextmenu, selectedBeatBtnInfoList]);

  /* functions */
  const editEvent = (beatClassIndex) => {
    let beatTypeIndex;

    switch (beatClassIndex) {
      case CONTEXT_MENU.BEAT_TYPE.NORMAL.value:
      case CONTEXT_MENU.BEAT_TYPE.APC.value:
      case CONTEXT_MENU.BEAT_TYPE.VPC.value:
      case CONTEXT_MENU.BEAT_TYPE.QUESTIONABLE.value:
        beatTypeIndex = beatClassIndex;
        break;
      case CONTEXT_MENU.REMOVE.value:
        removeBeatType();
        break;
      default:
        return;
    }
    handleBeatContextmenu(false);
    if (beatTypeIndex === undefined) return;

    const isChangedBeatMode =
      editModeType === TEN_SEC_STRIP_DETAIL.EDIT_MODE.CHANGE_BEAT;
    const isAddBeatMode =
      editModeType === TEN_SEC_STRIP_DETAIL.EDIT_MODE.ADD_BEAT;

    isChangedBeatMode && editBeatType(beatTypeIndex);
    isAddBeatMode && addBeatType(beatTypeIndex);
  };
  const addBeatType = (beatTypeIndex) => {
    addBeat({ beatType: beatTypeIndex, selectedBeatBtnInfoList });
  };
  const editBeatType = (beatTypeIndex) => {
    editBeat({ beatType: beatTypeIndex, selectedBeatBtnInfoList });
  };
  const removeBeatType = () => {
    deleteBeat({ selectedBeatBtnInfoList });
  };

  return (
    <BeatEditContextmenu
      // props
      beatContextmenuPositionSetting={beatContextmenuPositionSetting}
      isOpenBeatContextmenuSetting={isOpenBeatContextmenuSetting}
      // state
      theme={theme}
      wrapperRef={wrapperRef}
      clickFlagRef={clickFlagRef}
      dragFlag={dragFlag}
      // useState
      repositionInfoState={{ rePositionInfo, setRePositionInfo }}
      // event handler
      onClick={editEvent}
    />
  );
}

export default BeatEditContextmenuContainer;
