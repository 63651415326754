import { batch } from 'react-redux';

import { CLASS_CONST } from 'constant/ClassConst';
import {
  EDIT_STATE_FIELD,
  PATTERN_MATCHING_REDUX_STATE_FIELD,
} from 'constant/PatternMatchingConst';

import { isDragRangeValid, isValidMousedown, isValidMouseup } from './valid';

import { getAdjustedWfi, transformXaxisToWfi } from '../../transformUnit';
import { isSimilarPatternListInRange } from '../checkPatternMatchingRange';

// 대표 유사 패턴 구간 설정 이벤트(Mousedown)
export function onMousedownFindingPatternTargetRange({
  event,
  chartInst,
  // 차트 관련
  recordingStartMs,
  tenSecStripStartGlobalWfi,
  // 패턴 매칭 관련
  editRange, // 패턴 매칭 편집 범위
  similarPatternListAcrossRange,
}) {
  const onsetWfi = transformXaxisToWfi({ chartInst, xAxis: event.layerX });
  const globalOnsetWfi = tenSecStripStartGlobalWfi + onsetWfi;
  const isValid = isValidMousedown({
    recordingStartMs,
    globalOnsetWfi,
    editRange,
  });
  event.isMousedownAvailable = isValid;

  return onsetWfi;
}

// 대표 유사 패턴 구간 설정 이벤트(Mouseup)
export function onMouseupFindingPatternTargetRange({
  event,
  recordingStartMs,
  // 차트 관련
  chartInst,
  chartEditUtil,
  tenSecStripStartGlobalWfi,
  onsetWfi, // mousedown wfi
  //
  isFirstPatternMatchingStep,
  currentLevel,
  // 차트 관련
  editRange, // 패턴 매칭 편집 범위
  similarPatternListAcrossRange,
  // redux action creator
  handleShowDialog,
  handleSetEditState,
  handleSetFindingPatternTargetRange,
  handleSetInitPatternOfActionUpdate,
}) {
  let terminationWfi = transformXaxisToWfi({ chartInst, xAxis: event.layerX });
  const [adjustedOnsetWfi, adjustedTerminationWfi] = getAdjustedWfi(
    onsetWfi,
    terminationWfi
  );
  const globalOnsetWfi = tenSecStripStartGlobalWfi + adjustedOnsetWfi;
  const globalTerminationWfi =
    tenSecStripStartGlobalWfi + adjustedTerminationWfi;

  const isDragRangeValidRst = isDragRangeValid(
    globalOnsetWfi,
    globalTerminationWfi,
    event
  );
  if (!isDragRangeValidRst) {
    handleSetFindingPatternTargetRange({});
    chartEditUtil.removeSelectionHighlightAll();
    chartEditUtil.removeSelectionMarkerAllInTenSecStrip();
    return;
  }

  const isValid = isValidMouseup({
    recordingStartMs,
    globalOnsetWfi,
    globalTerminationWfi,
    editRange,
  });
  event.isMouseupAvailable = isValid;

  if (!isValid) {
    for (let beatLabelButtonInst of chartInst.beatLabelButtonInstList) {
      beatLabelButtonInst.setState(0);
    }
  }

  if (onsetWfi === null || !isValid) {
    return;
  }

  const isDragRangeWithinSimilarPattern = isSimilarPatternListInRange(
    similarPatternListAcrossRange,
    {
      onsetWaveformIndex: globalOnsetWfi,
      terminationWaveformIndex: globalTerminationWfi,
    }
  );
  handlePatternMatchingStep({
    chartEditUtil,
    isFirstPatternMatchingStep,
    isDragRangeWithinSimilarPattern,
    //
    globalOnsetWfi,
    globalTerminationWfi,
    similarPatternListAcrossRange,
    //
    handleSetFindingPatternTargetRange,
    handleSetEditState,
    handleShowDialog,
    handleSetInitPatternOfActionUpdate,
  });
}

function handlePatternMatchingStep({
  chartEditUtil,
  isFirstPatternMatchingStep,
  isDragRangeWithinSimilarPattern,
  //
  globalOnsetWfi,
  globalTerminationWfi,
  similarPatternListAcrossRange,
  //
  handleSetFindingPatternTargetRange,
  handleSetEditState,
  handleShowDialog,
  handleSetInitPatternOfActionUpdate,
}) {
  if (isFirstPatternMatchingStep || isDragRangeWithinSimilarPattern) {
    batch(() => {
      handleSetFindingPatternTargetRange({
        onsetWaveformIndex: globalOnsetWfi,
        terminationWaveformIndex: globalTerminationWfi,
      });
      handleSetEditState({
        editStateField: {
          [EDIT_STATE_FIELD.IS_FIND_SIMILAR_PATTERN]: true,
        },
      });
    });
  } else {
    batch(() => {
      handleShowDialog('ConfirmDialog', {
        title: `새로운 패턴을 설정하시겠습니까?\n이전에 찾은 유사 패턴은 사라집니다.`,
        className: CLASS_CONST.DIALOG,
        cancelButtonText: '취소',
        confirmButtonText: '확인',
        onSubmit: () => {
          chartEditUtil.removePatternHighlightElement();
          batch(() => {
            handleSetInitPatternOfActionUpdate([
              PATTERN_MATCHING_REDUX_STATE_FIELD.SIMILAR_PATTERN_DATA,
              PATTERN_MATCHING_REDUX_STATE_FIELD.FINDING_RPEAK_TARGET_LIST,
              PATTERN_MATCHING_REDUX_STATE_FIELD.RPEAK_LIST,
              PATTERN_MATCHING_REDUX_STATE_FIELD.PATTERN_OF_ACTION,
              PATTERN_MATCHING_REDUX_STATE_FIELD.EDITED_WAVEFORM_INDEXES,
              PATTERN_MATCHING_REDUX_STATE_FIELD.APPLY_PATTERN_MATCHING_ACTION,
            ]);
            handleSetFindingPatternTargetRange({
              onsetWaveformIndex: globalOnsetWfi,
              terminationWaveformIndex: globalTerminationWfi,
            });
            handleSetEditState({
              editStateField: {
                [EDIT_STATE_FIELD.IS_FIND_SIMILAR_PATTERN]: true,
                [EDIT_STATE_FIELD.IS_PATTERN_MATCHING_ENABLED]: true,
              },
            });
          });
        },
        onCancel: () => {
          handleSetFindingPatternTargetRange({});
          chartEditUtil.removeSelectionHighlightAll();
          chartEditUtil.removeSelectionMarkerAllInTenSecStrip();
        },
      });
    });
  }
}
