/**
 * 주어진 waveformIndex가 패턴 범위 내에 있는지 확인
 * @param {number} wfi - 확인할 waveformIndex
 * @param {Array<[number, number]>} level1Patterns - Level 1 패턴 범위 목록
 * @param {Array<[number, number]>} level2Patterns - Level 2 패턴 범위 목록
 * @returns {{isInLevel1: boolean, isInLevel2: boolean}} 각 레벨 패턴 포함 여부
 */
export const isWaveformIndexInPatterns = (
  wfi,
  level1Patterns,
  level2Patterns
) => {
  const isInLevel1 = level1Patterns.some(
    ([start, end]) => start <= wfi && wfi <= end
  );
  const isInLevel2 = level2Patterns.some(
    ([start, end]) => start <= wfi && wfi <= end
  );
  return { isInLevel1, isInLevel2 };
};
