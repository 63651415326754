import { useCallback, useEffect, useState, useContext, useRef } from 'react';
import { useDispatch, batch } from 'react-redux';

import {
  ECTOPIC_TYPE,
  EVENT_GROUP_TYPE,
  SIDE_PANEL_EVENT_GROUP,
} from 'constant/EventConst';
import {
  ShapeReviewSectionArea,
  ShapeReviewRawAndEventCalledCase,
  ShapeReviewPositionMoveType,
} from 'constant/ShapeReviewConst';

import { getEventInfo, getSidePanelEventData } from 'util/EventConstUtil';

import useShallowEqualSelector from 'component/hook/useShallowEqualSelector';

import NavigationFragment from 'component/fragment/test-result/shape-review/Navigation';

import {
  // api
  getFormListRequested,
  patchBeatPostprocessRequested,
  //
  setPage,
  setSelectEvent,
  setLastSelectedSectionInfo as setLastSelectedSectionInfoHandle,
  setSelectedItemList as setSelectedItemListHandle,
  //
  selectClickedInfo,
  selectArrangeRequiredStatus,
  selectSelectedInfoOfFormsPanel,
  selectFormPoolingStateOfSelectedEvent,
  selectSelectedInfo,
  selectPaginationInfo,
  selectPatchBeatByFormIds,
  selectPatchBeatByWaveFormIndexes,
  selectEventPoolingStateOfSelectedEvent,
} from 'redux/duck/shapeReviewDuck';
import { showDialog } from 'redux/duck/dialogDuck';
import {
  ClickedInfo,
  SelectedInfo,
  SelectedInfoItem,
  FormPanelSizeInfo,
  ArrangeRequiredStatus,
  SetSelectedItemList,
  SetLastSelectedSectionInfo,
  PatchBeatByFormIds,
  PatchBeatByWaveFormIndexes,
  PoolingData,
} from 'redux/duck/shapeReview/shapeReviewDuckType';
import {
  BeatType,
  EctopicType,
  EventConstTypes,
  EventSection,
  ShapeReviewEventSection,
  ShapeReviewEventType,
} from '@type/ecgEventType/baseEventType';

import {
  StatisticData,
  EventTypeContext,
} from '../ShapeReviewFragmentContainer';
import { IShowDialogParams } from '../selectedItemPanel/SelectedItemPanelFragmentContainer';

type ShapeReviewEventGroup = {
  beatType: BeatType;
  ectopicType: Extract<EctopicType, 'ISOLATE' | 'COUPLET'>;
  eventSection: ShapeReviewEventSection;
  type: ShapeReviewEventType;
  label: string;
};

export interface ShapeEctopicStatisticsInfo extends ShapeReviewEventGroup {
  qty: number;
}

export interface ISelectedEvent {
  beatType: BeatType;
  ectopicType: EctopicType;
  eventType?: typeof EventConstTypes;
  selectedFormPanel?: Extract<ShapeReviewSectionArea, 'FORMS' | 'EVENTS'>;
}

function NavigationFragmentContainer() {
  const dispatch = useDispatch();
  const flag = useRef<boolean>(true);

  // selectors
  const shapeReviewClusteringStatisticsState = useShallowEqualSelector(
    (state: any) => state.shapeReviewReducer.clusteringStatistics.data
  ) as StatisticData;
  const { pending } = useShallowEqualSelector((state: any) =>
    selectFormPoolingStateOfSelectedEvent(state)
  ) as { pending: boolean };
  const clickedInfo = useShallowEqualSelector(selectClickedInfo) as ClickedInfo;
  const { isArrangeRequired } = useShallowEqualSelector(
    selectArrangeRequiredStatus
  ) as { isArrangeRequired: Pick<ArrangeRequiredStatus, 'isArrangeRequired'> };
  const { pending: patchBeatByFormIdsPending } = useShallowEqualSelector(
    selectPatchBeatByFormIds
  ) as { pending: Pick<PatchBeatByFormIds, 'pending'> };
  const { pending: patchBeatByWaveFormIndexesPending } =
    useShallowEqualSelector(selectPatchBeatByWaveFormIndexes) as {
      pending: Pick<PatchBeatByWaveFormIndexes, 'pending'>;
    };
  const { pending: pendingOfEventPoolingStateOfSelectedEvent } =
    useShallowEqualSelector(selectEventPoolingStateOfSelectedEvent) as {
      pending: Pick<PoolingData, 'pending'>;
    };

  // dispatches
  const handleSetSelectEvent = useCallback(
    ({ beatType, ectopicType, eventType, selectedFormPanel }: ISelectedEvent) =>
      dispatch(
        setSelectEvent({
          beatType,
          ectopicType,
          eventType,
          selectedFormPanel,
        })
      ),
    [dispatch]
  );
  const handleGetFormListRequested = useCallback(
    ({ beatType, ectopicType }: ISelectedEvent) =>
      dispatch(
        getFormListRequested({
          formBeatType: beatType,
          formEctopicType: ectopicType,
        })
      ),
    [dispatch]
  );
  const handlePatchBeatPostprocessRequested = useCallback(
    () => dispatch(patchBeatPostprocessRequested()),
    [dispatch]
  );
  const handleShowDialog = useCallback(
    ({ dialogKey, params, callback }: IShowDialogParams) =>
      dispatch(showDialog(dialogKey, params, callback)),
    [dispatch]
  );

  // useStates
  const { setEventType } = useContext(EventTypeContext);
  const [shapeEctopicStatisticsInfoList, setShapeEctopicStatisticsInfoList] =
    useState<ShapeEctopicStatisticsInfo[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<ISelectedEvent>({
    beatType: 2,
    ectopicType: EctopicType.ISOLATE,
  });

  const formPanelPaginationInfoState = useShallowEqualSelector(
    (state: any) => selectPaginationInfo(state)[ShapeReviewSectionArea.FORMS]
  ) as FormPanelSizeInfo;
  const selectedInfoOfFormsPanelState = useShallowEqualSelector(
    selectSelectedInfoOfFormsPanel
  ) as SelectedInfoItem;
  const selectedInfoState = useShallowEqualSelector(
    selectSelectedInfo
  ) as SelectedInfo;

  const handleSetPage = ({
    panelType,
    setPageType,
    value,
  }: {
    panelType: ShapeReviewSectionArea;
    setPageType: ShapeReviewPositionMoveType;
    value: number;
  }) => {
    return dispatch(setPage({ panelType, setPageType, value }));
  };
  const handleLastSelectedSectionInfo = ({
    triggerType,
    panelType,
    lastSelectedSectionInfo,
  }: SetLastSelectedSectionInfo) => {
    return dispatch(
      setLastSelectedSectionInfoHandle({
        triggerType,
        panelType,
        lastSelectedSectionInfo,
      })
    );
  };
  const handleSelectedItemList = ({
    panelType,
    selectedItemList,
  }: SetSelectedItemList) => {
    return dispatch(setSelectedItemListHandle({ panelType, selectedItemList }));
  };

  // useEffects
  useEffect(() => {
    flag.current = true;

    setSelectedEvent({
      beatType: clickedInfo.beatType ?? 2,
      ectopicType: clickedInfo.ectopicType ?? ECTOPIC_TYPE.ISOLATE,
    } as ISelectedEvent);

    const eventSection = getSidePanelEventData({
      groupType: EVENT_GROUP_TYPE.SHAPE,
      key: 'type',
      value: clickedInfo.eventType,
    }).eventSection;

    setEventType(eventSection ?? EventSection.ISO_VPC);
  }, []);

  // Navigation event type 설정, form panel page 설정, 차트 선택 유지 설정
  useEffect(() => {
    if (!selectedEvent.beatType && selectedEvent.ectopicType) return;

    const filterSelectedEventInfo = (
      shapeEctopicStatisticsInfo: ShapeEctopicStatisticsInfo
    ) =>
      shapeEctopicStatisticsInfo.beatType === selectedEvent.beatType &&
      shapeEctopicStatisticsInfo.ectopicType === selectedEvent.ectopicType;
    const selectedEventInfo = shapeEctopicStatisticsInfoList.filter(
      (shapeEctopicStatisticsInfo) =>
        filterSelectedEventInfo(shapeEctopicStatisticsInfo)
    );
    if (selectedEventInfo.length === 0) return;

    const {
      type: eventType,
      beatType,
      ectopicType,
    }: {
      type: ShapeReviewEventType;
      beatType: BeatType;
      ectopicType: EctopicType;
    } = selectedEventInfo[0];

    batch(() => {
      // # Navigation event type설정
      handleSetSelectEvent({
        beatType,
        ectopicType,
        eventType: eventType as unknown as typeof EventConstTypes | undefined,
      });
      handleGetFormListRequested({
        beatType: beatType,
        ectopicType: ectopicType,
      });

      // # form panel page 설정
      !!formPanelPaginationInfoState &&
        handleSetPage({
          panelType: ShapeReviewSectionArea.FORMS,
          setPageType: ShapeReviewPositionMoveType.JUMP,
          value: formPanelPaginationInfoState[eventType].currentPage,
        });

      // # 차트 선택 유지 설정: case 2가지
      //   * case1: change 검사 상세 Tab
      //     - flag.current === true
      //   * case2: change event Type
      //     - flag.current === false
      if (flag.current) {
        // case1: change 검사 상세 Tab
        handleLastSelectedSectionInfo({
          triggerType: ShapeReviewRawAndEventCalledCase.CLICK_EVENT,
          panelType: ShapeReviewSectionArea.FORMS,
          lastSelectedSectionInfo:
            selectedInfoOfFormsPanelState.lastSelectedSectionInfo,
        });
        handleSelectedItemList({
          panelType: ShapeReviewSectionArea.FORMS,
          selectedItemList: selectedInfoOfFormsPanelState.selectedItemList,
        });
        flag.current = false;
      } else {
        // case2: change event Type
        const selectedEventInfo = getEventInfo({
          beatType: selectedEvent.beatType,
          ectopicType: selectedEvent.ectopicType,
        })?.[0] as unknown as { type: ShapeReviewEventType };
        if (selectedEventInfo) {
          const selectedFormId =
            selectedInfoState[ShapeReviewSectionArea.FORMS][
              selectedEventInfo?.type
            ]?.lastSelectedSectionInfo?.[0]?.formInfo?.id;

          const formPanelSelectState =
            selectedInfoState[ShapeReviewSectionArea.FORMS][
              selectedEventInfo?.type
            ];
          const eventPanelSelectState =
            selectedInfoState[ShapeReviewSectionArea.EVENTS][
              selectedEventInfo?.type
            ][selectedFormId];

          formPanelSelectState?.lastSelectedSectionInfo &&
            handleLastSelectedSectionInfo({
              triggerType: ShapeReviewRawAndEventCalledCase.CLICK_EVENT,
              panelType: ShapeReviewSectionArea.FORMS,
              lastSelectedSectionInfo:
                formPanelSelectState?.lastSelectedSectionInfo,
            });
          formPanelSelectState?.lastSelectedSectionInfo &&
            handleSelectedItemList({
              panelType: ShapeReviewSectionArea.FORMS,
              selectedItemList: formPanelSelectState?.selectedItemList,
            });
          eventPanelSelectState?.lastSelectedSectionInfo &&
            handleLastSelectedSectionInfo({
              triggerType: ShapeReviewRawAndEventCalledCase.CLICK_EVENT,
              panelType: ShapeReviewSectionArea.EVENTS,
              lastSelectedSectionInfo:
                eventPanelSelectState?.lastSelectedSectionInfo,
            });
          eventPanelSelectState?.lastSelectedSectionInfo &&
            handleSelectedItemList({
              panelType: ShapeReviewSectionArea.EVENTS,
              selectedItemList: eventPanelSelectState?.selectedItemList,
            });
        }
      }
    });
  }, [selectedEvent]);

  useEffect(() => {
    if (!shapeReviewClusteringStatisticsState) return;

    const shapeEctopicList = SIDE_PANEL_EVENT_GROUP[
      EVENT_GROUP_TYPE.SHAPE
    ] as unknown as ShapeReviewEventGroup[];

    const ectopicStatistics = shapeEctopicList.map(
      (event: ShapeReviewEventGroup) => {
        const { eventSection } = event;

        Object.assign(event, {
          qty: shapeReviewClusteringStatisticsState[eventSection] ?? 0,
        });
        return event;
      }
    ) as ShapeEctopicStatisticsInfo[];

    setShapeEctopicStatisticsInfoList(ectopicStatistics);
  }, [shapeReviewClusteringStatisticsState]);

  const onClickShapeReviewEventStatisticInfo: (
    qty: number,
    beatType: BeatType,
    ectopicType: EctopicType,
    eventSection: EventSection
  ) => void = (qty, beatType, ectopicType, eventSection) => {
    if (pending || qty === 0) return;

    setSelectedEvent({ beatType, ectopicType });
    setEventType(eventSection);
  };

  return (
    <NavigationFragment
      // local State
      selectedEvent={selectedEvent}
      shapeEctopicStatisticsInfoList={shapeEctopicStatisticsInfoList}
      // redux state
      isArrangeRequired={isArrangeRequired}
      patchBeatByFormIdsPending={patchBeatByFormIdsPending}
      patchBeatByWaveFormIndexesPending={patchBeatByWaveFormIndexesPending}
      pendingOfEventPoolingStateOfSelectedEvent={
        pendingOfEventPoolingStateOfSelectedEvent
      }
      //
      handlePatchBeatPostprocessRequested={handlePatchBeatPostprocessRequested}
      handleShowDialog={handleShowDialog}
      //
      onClickShapeReviewEventStatisticInfo={
        onClickShapeReviewEventStatisticInfo
      }
    />
  );
}

export default NavigationFragmentContainer;
