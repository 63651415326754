import { useEffect } from 'react';

import {
  initializeEventMarkers,
  renderSimilarPatternEventMarker,
} from 'util/patternMatching/similarPattern';

import { PatternLevels } from '@type/patternMatching/type';
import { WaveformIndex } from '@type/ecgEventType/eventUnit';
import { CustomTheme } from '@type/types';

interface UsePatternMatchingSimilarPatternProps {
  // 차트 관련
  chartRef: any;
  chartInst: any;
  // 차트 관련 비트 데이터
  _beatEventData: any[];
  chartOnsetWfi: number;
  tenSecStripStartGlobalWfi: number;
  // 상태 플래그
  isTenSecStrip: boolean;
  isPatternMatchingEnabled: boolean;
  isFindRpeak: boolean;
  isSetRpeak: boolean;
  // 콜백
  renderBeatButtonList: () => void;
  // 패턴 매칭 데이터
  beatType: any;
  actionType: string | null;
  findingRpeakTargetList: number[];
  findingRpeakListInRange: number[];
  similarPatternDataByLevels: PatternLevels;
  similarPatternLevel2ListAcrossRange: [WaveformIndex, WaveformIndex][];
  similarPatternLevel1ListAcrossRange: [WaveformIndex, WaveformIndex][];
  findingPatternTargetRange: {
    onsetWaveformIndex: WaveformIndex | undefined;
    terminationWaveformIndex: WaveformIndex | undefined;
  };
  currentLevel: number;
  // 기타
  theme: CustomTheme;
}

function usePatternMatchingSimilarPattern({
  // 차트 관련
  chartRef,
  chartInst,
  // 차트 관련 비트 데이터
  _beatEventData,
  chartOnsetWfi,
  tenSecStripStartGlobalWfi,
  // 상태 플래그
  isTenSecStrip,
  isPatternMatchingEnabled,
  isFindRpeak,
  isSetRpeak,
  // 콜백
  renderBeatButtonList,
  // 패턴 매칭 데이터
  beatType, // 편집하는 데이터 타입
  actionType, // 편집 액션
  currentLevel, // 현재 패턴 레벨
  findingRpeakTargetList, // 찾을 Rpeak 목록
  findingRpeakListInRange, // 찾은 Rpeak 목록
  findingPatternTargetRange, // 패턴 찾기 대상 구간
  similarPatternDataByLevels, // 유사한 패턴 데이터
  similarPatternLevel2ListAcrossRange, // 유사한 패턴 목록 (Level2)
  similarPatternLevel1ListAcrossRange, // 유사한 패턴 목록 (Level1)
  // 기타
  theme,
}: UsePatternMatchingSimilarPatternProps) {
  // [render] similar pattern event marker
  useEffect(() => {
    if (!chartInst || !isPatternMatchingEnabled) {
      return;
    }

    if (currentLevel === 0) {
      initializeEventMarkers(chartInst);
      return;
    }

    renderSimilarPatternEventMarker({
      chartInst,
      currentLevel,
      similarPatternLevel1ListAcrossRange,
      similarPatternLevel2ListAcrossRange,
      similarPatternDataByLevels,
      chartOnsetWfi,
      tenSecStripStartGlobalWfi,
    });
  }, [
    chartInst,
    isPatternMatchingEnabled,
    chartOnsetWfi,
    similarPatternLevel1ListAcrossRange,
    similarPatternLevel2ListAcrossRange,
  ]);
}

export default usePatternMatchingSimilarPattern;
