import { CHART_CONST } from 'constant/ChartConst';
import { ComponentId } from 'constant/ComponentId';
import {
  BEAT_BUTTON_COLOR_MAP,
  CHART_EDIT_CONST,
  PLOT_LINE_COLOR_MAP,
  SELECTED_BEAT_BUTTON_FILL_MAP,
  SELECTED_BEAT_BUTTON_STROKE_MAP,
  TEN_SEC_STRIP_DETAIL,
} from 'constant/ChartEditConst';

import { isRangeOverlap, isTargetWithinRange } from 'util/checkRange';
import ChartUtil from 'util/ChartUtil';
import { _getBeatLabelButtonDataList } from 'util/reduxDuck/TestResultDuckUtil';

import { FIND_PATTERN_LEVEL_TYPE } from 'constant/PatternMatchingConst';

import { WaveformIndex } from '@type/ecgEventType/eventUnit';
import { BeatType } from '@type/ecgEventType/baseEventType';

// 10s strip beat button 렌더링 유틸리티
const beatButtonRenderer = {
  renderBeatButtonList(
    {
      chartInst,
      //
      beatLabelButtonDataList, // 비트 버튼 데이터
      selectedBeatBtnInfoList, // 선택된 비트 버튼 데이터
      //
      isPatternMatchingEnabled,
      isCaliperMode,
      // option
      tabType,
      chartOption,
      editModeType,
      // setState hook
      setChartOption,
      setSelectedBeatOption,
      setSelectedBeatBtnInfoList,
      // callback fn
      initTenSecStripDetail,
      handleSelectedItemList,
      handleBeatContextmenu,
      // etc
      theme,
    },
    patterMatchingOption
  ) {
    let newBeatLabelButtonDataList = beatLabelButtonDataList ?? [];

    if (!!patterMatchingOption) {
      const {
        findingRpeakListInRange,
        similarPatternDataByLevels,
        chartOnsetWfi,
        chartTerminationWfi,
        //
        actionType,
        beatType,
      } = patterMatchingOption;

      newBeatLabelButtonDataList = [];
      const similarPatternLevel1List =
        similarPatternDataByLevels[FIND_PATTERN_LEVEL_TYPE.LEVEL1]
          ?.similarPatternList ?? [];

      // Step1: filter 10s strip에 있는 유사 패턴
      const similarPatternLevel1ListAcrossRange =
        similarPatternLevel1List.filter((wfiPair) => {
          return isRangeOverlap({
            range1: {
              start: chartOnsetWfi,
              end: chartTerminationWfi,
            },
            range2: {
              start: wfiPair[0],
              end: wfiPair[1],
            },
          });
        }) ?? [];

      // Step2: filter 10s strip에 있는 유사 패턴 중 Rpeak를 포함하는 유사 패턴
      const filterSimilarPatternListAcrossRange: [
        WaveformIndex,
        WaveformIndex
      ][] = [];
      for (const [start, end] of similarPatternLevel1ListAcrossRange) {
        const isRpeakInSimilarPatternList = findingRpeakListInRange.some(
          (rpeak) =>
            isTargetWithinRange({ target: rpeak, range: { start, end } })
        );

        if (isRpeakInSimilarPatternList) {
          filterSimilarPatternListAcrossRange.push([start, end]);
        }
      }

      // Step3: filter '비트 버튼'이 '찾은 Rpeak를 포함하는 유사패턴 구간'에 포함되지 않는 경우
      /*********************************************************************************************************/
      /* | : 차트 구간                                                                                         */
      /* []: 유사패턴 구간                                                                                     */
      /* Btn: 비트 버튼                                                                                        */
      /* ' : 찾은 rpeak                                                                                        */
      /* |            '              '                       '           '         |                           */
      /* |  [ btn1 ]    [ btn2 ]   btn3   [ btn4  ]   [ btn5  ]   [ btn6  ]       |                            */
      /* |  ^           ^                 ^           ^            ^              |                            */
      /*    유사패턴1   유사패턴2         유사패턴3   유사패턴4    유사패턴5                                   */
      /* - 위 예시설명                                                                                         */
      /*   - 찾은 Rpeak를 포함하는 유사패턴 구간: 유사패턴4, 유사패턴5                                         */
      /*   - '비트 버튼'이 '찾은 Rpeak를 포함하는 유사패턴 구간'에 포함되지 않는 경우: btn1, btn2, btn3, btn4  */
      /*********************************************************************************************************/
      // const rst = _getBeatLabelButtonDataList({
      //   beatTypeList,
      //   beatWaveformIndexList,
      // });
      newBeatLabelButtonDataList = beatLabelButtonDataList.filter(
        (beatButtonData) => {
          const isBeatButtonInSimilarPatternList =
            filterSimilarPatternListAcrossRange.some((wfiPair) => {
              return isTargetWithinRange({
                target: beatButtonData.xAxisPoint,
                range: {
                  start: wfiPair[0] - chartOnsetWfi,
                  end: wfiPair[1] - chartOnsetWfi,
                },
              });
            });
          return !isBeatButtonInSimilarPatternList;
        }
      );

      // todo: jyoon - [#patternMatching] 유사 패턴에서 설정된 actionType을 rpeak 찾기 세팅시 초기화 필요
      if (actionType !== null) {
        const transformFromFindingRpeakToBeatButton =
          _getBeatLabelButtonDataList({
            beatTypeList: Array(findingRpeakListInRange.length).fill(beatType),
            beatWaveformIndexList: findingRpeakListInRange.map(
              (findingRpeak) => Number(findingRpeak) - chartOnsetWfi
            ),
          });
        newBeatLabelButtonDataList = [
          ...newBeatLabelButtonDataList,
          ...transformFromFindingRpeakToBeatButton,
        ];
      }
    }

    this.initBeatButtonList({
      chartInst,
      beatLabelButtonDataList: newBeatLabelButtonDataList,
    });

    for (const beatLabelButtonData of newBeatLabelButtonDataList) {
      const button = this.renderBeatButton(chartInst, {
        isPatternMatchingEnabled,
        xAxisPoint: beatLabelButtonData.xAxisPoint,
        isSelected: selectedBeatBtnInfoList.some(
          (v) => v.waveformIndex === beatLabelButtonData.xAxisPoint
        ),
        beatType: beatLabelButtonData.beatType,
        title: beatLabelButtonData.title,
        onClickBeatButton: this.onClickBeatButton({
          chartInst,
          isPatternMatchingEnabled,
          tabType,
          beatLabelButtonMetaData: beatLabelButtonData,
          beatLabelButtonData,
          selectedBeatBtnInfoList,
          theme,
          //
          handleSelectedItemList,
          handleBeatContextmenu,
          //
          setSelectedBeatOption,
          setSelectedBeatBtnInfoList,
          //
          initTenSecStripDetail,
        }),
        theme,
        tabType,
        isInteraction: !chartOption.isReportRepresentativeStripChangeMode,
      });

      button.position = beatLabelButtonData.position;
      button.beatLabelButtonMetaData = beatLabelButtonData;
      chartInst.beatLabelButtonInstList.push(button);

      // event button를 shift + drag로 선택 기능
      button.element.addEventListener(
        'mouseleave',
        this.onMouseLeaveBeatButton(
          beatLabelButtonData,
          editModeType,
          isCaliperMode,
          setSelectedBeatOption,
          button,
          setChartOption,
          setSelectedBeatBtnInfoList,
          initTenSecStripDetail
        )
      );
    }
  },
  renderBeatButton: (
    chart,
    {
      isPatternMatchingEnabled,
      xAxisPoint,
      isSelected,
      beatType,
      title,
      onClickBeatButton,
      theme,
      y,
      tabType,
      isInteraction,
    }
  ) => {
    let testEvent_BeatButton_xAxisPoint_toPixels,
      defaultAttrs,
      hoverAttrs,
      selectAttrs,
      patternMatchingHoverAttrs,
      patternMatchingSelectAttrs,
      disabledAttrs,
      beatButton,
      halfOfBeatButtonWidth;

    testEvent_BeatButton_xAxisPoint_toPixels =
      chart.xAxis[0].toPixels(xAxisPoint);

    //https://api.highcharts.com/class-reference/Highcharts.SVGRenderer#button
    defaultAttrs = {
      r: 2,
      width: 8,
      height: 8,
      zIndex: 5,
      fill: theme.color.WHITE,
      'stroke-width': 1,
      stroke: theme.color.COOL_GRAY_50,
      style: {
        fontFamily: 'Spoqa Han Sans Neo',
        color: theme.color[BEAT_BUTTON_COLOR_MAP[beatType]],
        fontSize: '13px',
        fontWeight: 500,
        cursor: 'pointer',
      },
    };
    hoverAttrs = {
      ...defaultAttrs,
      fill: theme.color.COOL_GRAY_40,
      zIndex: 7,
    };
    selectAttrs = {
      ...defaultAttrs,
      stroke: theme.color[SELECTED_BEAT_BUTTON_STROKE_MAP[beatType]],
      fill: theme.color[SELECTED_BEAT_BUTTON_FILL_MAP[beatType]],
      zIndex: 6,
      style: {
        ...defaultAttrs.style,
        color: theme.color[SELECTED_BEAT_BUTTON_STROKE_MAP[beatType]],
      },
    };
    patternMatchingHoverAttrs = {
      ...defaultAttrs,
      fill: theme.color.WHITE,
      zIndex: 7,
    };
    patternMatchingSelectAttrs = {
      ...defaultAttrs,
      stroke: theme.color.COOL_GRAY_50,
      fill: theme.color.WHITE,
      zIndex: 6,
    };
    disabledAttrs = {
      ...defaultAttrs,
    };

    // https://api.highcharts.com/class-reference/Highcharts.SVGRenderer#button
    if (isPatternMatchingEnabled) {
      beatButton = chart.renderer
        .button(
          title,
          0, // x
          0, // y
          onClickBeatButton,
          defaultAttrs, // theme
          patternMatchingHoverAttrs, // hoverState
          patternMatchingSelectAttrs, // selectState
          disabledAttrs // disabledState
        )
        .attr({
          class: `${CHART_CONST.BEAT_EVENT_BUTTON} ${CHART_CONST.BEAT_EVENT_BUTTON}-${xAxisPoint}`,
          'data-cid': `${ComponentId.BEAT_EVENT_BUTTON}`,
        })
        .css({
          // 리포트 담기시 버튼을 클릭 방지
          'pointer-events': `${isInteraction ? 'initial' : 'none'}`,
        })
        .add();
    } else {
      beatButton = chart.renderer
        .button(
          title,
          0, // x
          0, // y
          onClickBeatButton,
          defaultAttrs, // theme
          hoverAttrs, // hoverState
          selectAttrs, // selectState
          disabledAttrs // disabledState
        )
        .attr({
          class: `${CHART_CONST.BEAT_EVENT_BUTTON} ${CHART_CONST.BEAT_EVENT_BUTTON}-${xAxisPoint}`,
          'data-cid': `${ComponentId.BEAT_EVENT_BUTTON}`,
        })
        .css({
          // 리포트 담기시 버튼을 클릭 방지
          'pointer-events': `${isInteraction ? 'initial' : 'none'}`,
        })
        .add();
    }

    // Button Back Cover for Transparency Color
    chart.renderer
      .rect(
        0.5, // x
        0.5, // y
        24, // width
        24, // height
        2 // r
      )
      .attr({
        zIndex: -1,
        fill: theme.color.WHITE,
        'stroke-width': 1,
        stroke: theme.color.WHITE,
      })
      .add(beatButton);

    // 글자 특성에 따른 x축 이동해 button 가운데로 정렬
    if (title === 'S' || title === 'V') {
      const beatButtonX = beatButton.element.children[1].getAttribute('x');
      beatButton.element.children[1].setAttribute(
        'x',
        parseInt(beatButtonX) + 0.5
      );
    }
    if (title === 'N' || title === 'Q') {
      const beatButtonX = beatButton.element.children[1].getAttribute('x');
      beatButton.element.children[1].setAttribute(
        'x',
        parseInt(beatButtonX) - 0.5
      );
    }

    // 버튼 요소의 state 에 따라 0: 노말, 1: 호버, 2: 선택됨, 3: 비활성화 로 제어 가능
    // shape review 편집을 가능하게 하면서 2,0으로만 관리
    beatButton.setState(isSelected ? 2 : 0);

    halfOfBeatButtonWidth = beatButton.width / 2;

    // ⭐️ patientTrigger x축 위치는 아래 align의 x,y property에 의해서 결정됩니다.
    beatButton.align(
      {
        align: 'left',
        x: testEvent_BeatButton_xAxisPoint_toPixels - halfOfBeatButtonWidth,
        y: y, // ⭐️ 10 char와 beats button의 간격
      },
      false,
      null
    );

    return beatButton;
  },
  // 10s strip beat button click callback 함수
  onClickBeatButton({
    chartInst,
    isPatternMatchingEnabled,
    tabType,
    beatLabelButtonMetaData,
    beatLabelButtonData,
    selectedBeatBtnInfoList,
    theme,
    //
    handleSelectedItemList,
    handleBeatContextmenu,
    //
    setSelectedBeatOption,
    setSelectedBeatBtnInfoList,
    //
    initTenSecStripDetail,
  }) {
    return function (event) {
      if (isPatternMatchingEnabled) return;
      const { shiftKey } = event;
      const { beatType } = beatLabelButtonMetaData;
      setSelectedBeatOption(beatType);
      // todo: jyoon - [refactor]
      // update state; clicked beat label button
      // 0: unselected, 2: selected
      this.setState(this.state === 0 ? 2 : 0);

      const isShapeReview = tabType === TEN_SEC_STRIP_DETAIL.TAB.SHAPE_REVIEW;
      if (isShapeReview) {
        handleSelectedItemList({
          panelType: 'EVENTS',
          selectedItemList: new Map(),
        });
      }

      // available multiple button select with shiftKey
      if (shiftKey) {
        setSelectedBeatBtnInfoList((prev) => {
          const filteredSelectedBeatBtnInfoList = prev.filter(
            (v) => v.waveformIndex !== beatLabelButtonMetaData.xAxisPoint
          );
          let result: { waveformIndex: WaveformIndex; beatType: BeatType }[] =
            [];

          if (this.state === 0) {
            result = [...filteredSelectedBeatBtnInfoList];
          } else {
            result = [
              ...filteredSelectedBeatBtnInfoList,
              {
                waveformIndex: beatLabelButtonMetaData.xAxisPoint,
                beatType,
              },
            ];
          }
          if (result.length === 0) {
            initTenSecStripDetail();
          }
          return result;
        });
      }

      if (!shiftKey) {
        // init state; all beat label buttons
        for (let buttonInst of chartInst.beatLabelButtonInstList) {
          buttonInst.setState(0);
        }

        // process of selected beat label button
        const $backgroundButtonEl = this.element.children[0].cloneNode(true);
        $backgroundButtonEl.setAttribute('fill', 'white');
        this.element.prepend($backgroundButtonEl);

        // update waveform index clicked button
        setSelectedBeatBtnInfoList((prev) => {
          let result: { waveformIndex: WaveformIndex; beatType: BeatType }[] =
            [];
          if (
            (prev.length === 1 &&
              prev[0].waveformIndex !== beatLabelButtonMetaData.xAxisPoint) ||
            prev.length !== 1
          ) {
            result = [
              {
                waveformIndex: beatLabelButtonMetaData.xAxisPoint,
                beatType,
              },
            ];
          } else {
            initTenSecStripDetail();
          }
          return result;
        });

        const chartEditUtil = ChartUtil.chartEdit(chartInst, { theme });
        // init selection strip(selection marker + selection highlight)
        chartEditUtil.removeSelectionHighlightAll();
        chartEditUtil.removeSelectionMarkerAllInTenSecStrip();
        handleBeatContextmenu(false); // close context menu open status
      }

      // init plotLine(vertical line)
      chartInst.xAxis[0].removePlotLine(
        CHART_EDIT_CONST.VERTICAL_BEAT_PLOT_LINE
      );
      // render plotLine(vertical line)
      chartInst.xAxis[0].addPlotLine({
        id: CHART_EDIT_CONST.VERTICAL_BEAT_PLOT_LINE,
        class: CHART_EDIT_CONST.VERTICAL_BEAT_PLOT_LINE,
        value: beatLabelButtonMetaData.xAxisPoint,
        width: 2,
        color: theme.color[PLOT_LINE_COLOR_MAP[beatType]],
        zIndex: 4,
      });

      if (shiftKey && this.state === 0) {
        // init plotLine(vertical line)
        chartInst.xAxis[0].removePlotLine(
          CHART_EDIT_CONST.VERTICAL_BEAT_PLOT_LINE
        );

        const clickedIndexOfSelectedBeatBtnInfoList =
          selectedBeatBtnInfoList.findIndex(
            (selectedBeatWaveformIndex) =>
              selectedBeatWaveformIndex.waveformIndex ===
              beatLabelButtonMetaData.xAxisPoint
          );

        let renderVerticalInfo;
        if (
          clickedIndexOfSelectedBeatBtnInfoList ===
          selectedBeatBtnInfoList.length - 1
        ) {
          renderVerticalInfo = selectedBeatBtnInfoList.at(-2);
        } else {
          renderVerticalInfo = selectedBeatBtnInfoList.at(-1);
        }

        renderVerticalInfo?.waveformIndex &&
          chartInst.xAxis[0].addPlotLine({
            id: CHART_EDIT_CONST.VERTICAL_BEAT_PLOT_LINE,
            class: CHART_EDIT_CONST.VERTICAL_BEAT_PLOT_LINE,
            value: renderVerticalInfo.waveformIndex,
            width: 2,
            color:
              theme.color[PLOT_LINE_COLOR_MAP[renderVerticalInfo.beatType]],
            zIndex: 4,
          });
      }
    }; // end callback function
  },
  // 10s strip beat button mouseleave callback 함수
  onMouseLeaveBeatButton(
    beatLabelButtonData,
    editModeType,
    isCaliperMode,
    setSelectedBeatOption,
    button,
    setChartOption,
    setSelectedBeatBtnInfoList,
    initTenSecStripDetail
  ) {
    return function (event) {
      const { shiftKey, metaKey, ctrlKey } = event;
      const { beatType } = beatLabelButtonData;
      const isEnableDragSelect = !(
        (shiftKey && metaKey) ||
        (shiftKey && ctrlKey)
      ); //  shift + metaKey, shift + ctrlKey가 아니면 drag select 비활성화

      const isEditMode =
        editModeType === TEN_SEC_STRIP_DETAIL.EDIT_MODE.ADD_BEAT;
      if (isEnableDragSelect || isCaliperMode || isEditMode) return;

      setSelectedBeatOption(beatType);
      // update state; clicked beat label button
      // 0: unselected, 2: selected
      button.setState(button.state === 0 ? 2 : 0);
      // update chart edit mode
      setChartOption((prev) => {
        return {
          ...prev,
          editModeType: TEN_SEC_STRIP_DETAIL.EDIT_MODE.CHANGE_BEAT,
        };
      });

      // ### shift + click fn
      setSelectedBeatBtnInfoList((prev) => {
        const filteredSelectedBeatBtnInfoList = prev.filter(
          (v) => v.waveformIndex !== beatLabelButtonData.xAxisPoint
        );
        let result: { waveformIndex: WaveformIndex; beatType: BeatType }[] = [];

        if (button.state === 0) {
          result = [...filteredSelectedBeatBtnInfoList];
        } else {
          result = [
            ...filteredSelectedBeatBtnInfoList,
            { waveformIndex: beatLabelButtonData.xAxisPoint, beatType: 0 },
          ];
        }
        if (result.length === 0) {
          initTenSecStripDetail();
        }
        return result;
      });
    };
  },
  // 10s strip beat button 목록 초기화
  initBeatButtonList({ chartInst, beatLabelButtonDataList }) {
    while (
      Array.isArray(chartInst.beatLabelButtonInstList) &&
      chartInst.beatLabelButtonInstList.length !== 0
    ) {
      chartInst?.beatLabelButtonInstList.pop().destroy();
    }

    if (!Array.isArray(chartInst.beatLabelButtonInstList)) {
      chartInst.beatLabelButtonInstList = [];
    }

    if (!beatLabelButtonDataList || beatLabelButtonDataList?.length === 0) {
      return;
    }
  },
};

export default beatButtonRenderer;
