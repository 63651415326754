import { Action } from 'redux';
import { put, takeLatest } from 'redux-saga/effects';

import { ACTION_TYPE } from 'constant/PatternMatchingConst';

const UN_DO = 'CMD_PATTERN/UN_DO';
const SET_UN_DO = 'CMD_PATTERN/SET_UN_DO';

export function setUnDo() {
  return {
    type: SET_UN_DO,
  };
}

function unDo(state) {
  return {
    type: UN_DO,
    payload: state,
  };
}

const undoList: any = [];
function* _undo(action) {
  if (undoList.length === 0) return;
  const popItem = undoList.pop();
  yield put(popItem.undoItem());
}

export function undoable(reducer, originConfig) {
  return (state, action: Action & { payload?: any }, ...slices) => {
    handleUndoableAction(originConfig, action, state);

    switch (action.type) {
      case UN_DO:
        return {
          ...action.payload,
          patternAction: ACTION_TYPE.UNDO_STATE,
          similarPatternData: {
            ...action.payload.similarPatternData,
            pending: false,
          },
          rpeakList: {
            ...action.payload.rpeakList,
            pending: false,
          },
          editedWaveformIndexes: {
            ...action.payload.editedWaveformIndexes,
            pending: false,
          },
        };
      default:
        return reducer(state, action, ...slices);
    }
  };
}

export function includeAction(actionList: string[]) {
  return (actionType) => {
    return actionList.indexOf(actionType) >= 0;
  };
}

function handleUndoableAction(
  originConfig: any,
  action: Action<any> & { payload?: any },
  state: any
) {
  const config = {
    limit: undefined,
    filter: () => true,
    ...originConfig,
  };

  const isUndoAbleCase = config.filter(action.type);
  if (isUndoAbleCase) {
    const undoAction: UndoAction = {
      actionType: action.type,
      payload: action.payload,
      undoItem: null,
    };

    undoAction.undoItem = unDo.bind(this, state);
    if (config.limit !== undefined && undoList.length >= config.limit) {
      undoList.shift();
    }
    undoList.push(undoAction);
  }
}

export function* saga() {
  yield takeLatest(SET_UN_DO, _undo);
}

interface UndoAction {
  actionType: string;
  payload: any;
  undoItem: any;
}
