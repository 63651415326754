import React from 'react';
import styled, { CSSProperties } from 'styled-components';

const Wrapper = styled.label<{
  width?: number;
  height?: number;
  disabled?: boolean;
}>`
  position: relative;
  display: inline-block;
  width: ${({ width }) => width || 40}px;
  height: ${({ height }) => height || 20}px;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

const Input = styled.input.attrs({
  type: 'checkbox',
})`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + .slider {
    background-color: ${({ theme }) => theme.color.PRIMARY || '#4CAF50'};
  }

  &:checked + .slider .slider-icon {
    transform: translateX(100%);
  }
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 34px;
  transition: background-color 0.4s, transform 0.4s;

  .slider-icon {
    position: absolute;
    width: 43%;
    height: 75%;
    left: 8%;
    bottom: 12%;
    border-radius: 50%;
    background-color: white;
    transition: transform 0.4s;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

interface SwitchProps {
  onChange?: (e?: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  disabled?: boolean;
  width?: number;
  height?: number;
  style?: CSSProperties;
  icon?: React.ReactNode;
}

function Switch({
  onChange,
  checked,
  disabled,
  width,
  height,
  style,
  icon,
}: SwitchProps) {
  return (
    <Wrapper style={style} disabled={disabled} width={width} height={height}>
      <Input onChange={onChange} checked={checked} disabled={disabled} />
      <Slider className="slider">
        <div
          className="slider-icon"
          style={{ transform: checked ? 'translateX(100%)' : 'translateX(0)' }}>
          {icon}
        </div>
      </Slider>
    </Wrapper>
  );
}

export default Switch;
