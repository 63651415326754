import { useEffect, useMemo } from 'react';
import { useTheme } from 'styled-components';

import { CHART_EDIT_CONST } from 'constant/ChartEditConst';
import {
  FIND_PATTERN_LEVEL_TYPE,
  PATTERN_MATCHING_TEN_SEC_STRIP_CHART_TYPE,
} from 'constant/PatternMatchingConst';

import ChartUtil from 'util/ChartUtil';
import { isRangeOverlap, isTargetWithinRange } from 'util/checkRange';

import { CustomTheme } from '@type/types';
import { WaveformIndex } from '@type/ecgEventType/eventUnit';

import usePatternMatchingAction from './usePatternMatchingAction';
import usePatternMatchingValidation from './usePatternMatchingValidation';
import usePatternMatchingRpeak from './usePatternMatching_rpeak';
import usePatternMatchingSnackbar from './usePatternMatching_snackbar';
import usePatternMatchingMouseEvent from './usePatternMatching_mouseEvent';
import usePatternMatchingSimilarPattern from './usePatternMatching_similarPattern';

import { selectSubBeatEventInfoMap } from '../useGetArrhythmiaEvents/selectFunctions';
import useShallowEqualSelector from '../useShallowEqualSelector';
import { isEqualBeatEventInfoMap } from '../useGetArrhythmiaEvents/equalityFunctions';
import useSnackbarStack from '../useSnackbarStack';

function usePatternMatching({
  type,
  //
  chartRef,
  chartInst,
  chartContainerRef,
  //
  onsetWaveformIndex: chartOnsetWfi,
  terminationWaveformIndex: chartTerminationWfi,
  //
  initTenSecStripDetail,
  renderBeatButtonList,
  handleShowDialog,
}) {
  const theme = useTheme() as CustomTheme;
  const { enqueueMessage } = useSnackbarStack(undefined, 3 * 1000);
  const {
    editRange,
    tenSecStrip,
    patternAction,
    findingRpeakTargetList,
    findingPatternTargetRange,
    editState: { isPatternMatchingEnabled, isSetRpeak, isFindRpeak },
    applyPatternMatchingAction: { beatType, actionType },
    similarPatternData: {
      data: {
        currentLevel,
        levels: similarPatternDataByLevels,
        beatCountInPattern,
        beatCountInPatternLevel1,
        beatCountInPatternLevel2,
      },
    },
    editedWaveformIndexes: { pending: applyPending },
    rpeakList: { data: findingRpeakList },
    //
    handleSetFindingPatternTargetRange,
    handleSetFindingRpeakTargetList,
    handleSetInitPatternOfActionUpdate,
    //
  } = usePatternMatchingAction();
  const { handleSetEditState } = usePatternMatchingValidation();

  const beatEventData = useShallowEqualSelector(
    (state) =>
      selectSubBeatEventInfoMap(state, chartOnsetWfi, chartTerminationWfi),
    isEqualBeatEventInfoMap
  ) as any;
  const recordingStartMs = useShallowEqualSelector(
    (state) => state.testResultReducer.recordingTime.recordingStartMs
  ) as number;
  const isCaliperMode = useShallowEqualSelector(
    (state) => state.testResultReducer.caliper.isCaliperMode
  ) as boolean;

  const _beatEventData = beatEventData[chartOnsetWfi];
  const isTenSecStrip = type === PATTERN_MATCHING_TEN_SEC_STRIP_CHART_TYPE;

  const {
    centerWaveformIndex: centerWfi, // 30s strip내에서 10s strip의 중앙이 위치한 wfi
    representativeCenterWaveformIndex: representativeCenterWfi, // 10s strip의 중앙 wfi가 위치한 부분의 30s strip의 시작 wfi
    main: { onsetWaveformIndex: mainOnsetWfi = 0 },
    extra: { onsetWaveformIndex: extraOnsetWfi = 0 },
  } = tenSecStrip;

  // 30s strip의 시작 wfi(대표 wfi, representative wfi)
  const tenSecStripRepresentativeWfi =
    centerWfi < 1250 ? representativeCenterWfi - 7500 : representativeCenterWfi;
  // 30s strip안에서 10s strip의 시작 wfi(local wfi)
  const tenSecStripStartLocalWfi =
    centerWfi < 1250 ? extraOnsetWfi : mainOnsetWfi;
  // 10s strip의 시작 wfi(= global wfi)
  const tenSecStripStartGlobalWfi =
    tenSecStripRepresentativeWfi + tenSecStripStartLocalWfi;

  // 현재 레벨의 유사 패턴 목록중 chartOnsetWfi와 chartTerminationWfi 사이에 있는 유사 패턴 목록 조회
  const similarPatternListAcrossRange = useMemo(() => {
    const currLvl = FIND_PATTERN_LEVEL_TYPE.LEVEL + currentLevel;
    const currLvlSPData = similarPatternDataByLevels[currLvl];
    const rst =
      currLvlSPData &&
      currLvlSPData?.similarPatternList.filter((wfiPair) => {
        return isRangeOverlap({
          range1: {
            start: chartOnsetWfi,
            end: chartTerminationWfi,
          },
          range2: {
            start: wfiPair[0],
            end: wfiPair[1],
          },
        });
      });
    return rst ?? [];
  }, [similarPatternDataByLevels, chartOnsetWfi, chartTerminationWfi]);

  // 첫번째 유사 패턴 목록중 chartOnsetWfi와 chartTerminationWfi 사이에 있는 유사 패턴 목록 조회
  const similarPatternLevel1ListAcrossRange: any = useMemo(() => {
    const lvl1SPData =
      similarPatternDataByLevels[FIND_PATTERN_LEVEL_TYPE.LEVEL1];
    const rst =
      lvl1SPData &&
      lvl1SPData?.similarPatternList.filter((wfiPair) => {
        return isRangeOverlap({
          range1: {
            start: chartOnsetWfi,
            end: chartTerminationWfi,
          },
          range2: {
            start: wfiPair[0],
            end: wfiPair[1],
          },
        });
      });
    return rst ?? [];
  }, [similarPatternDataByLevels, chartOnsetWfi, chartTerminationWfi]);

  // 두번째 유사 패턴 목록중 chartOnsetWfi와 chartTerminationWfi 사이에 있는 유사 패턴 목록 조회
  const similarPatternLevel2ListAcrossRange: any = useMemo(() => {
    const lvl2SPData =
      similarPatternDataByLevels[FIND_PATTERN_LEVEL_TYPE.LEVEL2];
    const rst =
      lvl2SPData &&
      lvl2SPData?.similarPatternList.filter((wfiPair) => {
        return isRangeOverlap({
          range1: {
            start: chartOnsetWfi,
            end: chartTerminationWfi,
          },
          range2: {
            start: wfiPair[0],
            end: wfiPair[1],
          },
        });
      });
    return rst ?? [];
  }, [similarPatternDataByLevels, chartOnsetWfi, chartTerminationWfi]);

  // 찾은 rpeak 목록중 chartOnsetWfi와 chartTerminationWfi 사이에 있는 rpeak 목록 조회
  const findingRpeakListInRange: WaveformIndex[] = useMemo(() => {
    return findingRpeakList.filter((rpeak) => {
      return isTargetWithinRange({
        target: rpeak,
        range: { start: chartOnsetWfi, end: chartTerminationWfi },
      });
    });
  }, [findingRpeakList, chartOnsetWfi, chartTerminationWfi]);

  // [render] pattern matching off상태 eventMarker, beatMarker 초기화
  useEffect(() => {
    if (isPatternMatchingEnabled || !chartInst) {
      return;
    }

    ChartUtil.initSVGGroup(chartInst, {
      svgGroupProperty: CHART_EDIT_CONST.PATTERN_MATCHING_BEAT_MARKER_GROUP,
      zIndex: 4,
    });
    ChartUtil.initSVGGroup(chartInst, {
      svgGroupProperty: [
        CHART_EDIT_CONST.PATTERN_MATCHING_EVENT_MARKER_GROUP +
          '-' +
          FIND_PATTERN_LEVEL_TYPE.LEVEL1_LOWER_CASE,
        CHART_EDIT_CONST.PATTERN_MATCHING_EVENT_MARKER_GROUP +
          '-' +
          FIND_PATTERN_LEVEL_TYPE.LEVEL2_LOWER_CASE,
      ],
      zIndex: 5,
    });
  }, [chartInst, chartOnsetWfi, isPatternMatchingEnabled]);

  // [render] similar pattern event marker 로직을 별도 훅으로 분리
  usePatternMatchingSimilarPattern({
    // 차트 관련
    chartRef,
    chartInst,
    // 차트 관련 비트 데이터
    _beatEventData,
    chartOnsetWfi,
    tenSecStripStartGlobalWfi,
    // 상태 플래그
    isTenSecStrip,
    isPatternMatchingEnabled,
    isFindRpeak,
    isSetRpeak,
    // 콜백
    renderBeatButtonList,
    // 패턴 매칭 데이터
    beatType,
    actionType,
    findingRpeakTargetList,
    findingRpeakListInRange,
    similarPatternDataByLevels,
    similarPatternLevel2ListAcrossRange,
    similarPatternLevel1ListAcrossRange,
    findingPatternTargetRange,
    currentLevel,
    // 기타
    theme,
  });

  // R-peak 관련 로직을 별도 hook으로 분리
  usePatternMatchingRpeak({
    // 차트 관련
    chartRef,
    chartInst,
    chartOnsetWfi,
    chartTerminationWfi,
    tenSecStripStartGlobalWfi,
    // 비트 데이터
    _beatEventData,
    // 상태 플래그
    isTenSecStrip,
    isPatternMatchingEnabled,
    isFindRpeak,
    isSetRpeak,
    // 콜백
    renderBeatButtonList,
    // 패턴 매칭 데이터
    beatType,
    actionType,
    patternAction,
    findingRpeakTargetList,
    findingRpeakListInRange,
    similarPatternDataByLevels,
    similarPatternLevel2ListAcrossRange,
    similarPatternLevel1ListAcrossRange,
    // 기타
    initTenSecStripDetail,
    theme,
  });

  // MouseEvent 관련 로직을 별도 훅으로 분리
  usePatternMatchingMouseEvent({
    chartInst,
    chartContainerRef,
    chartRef,
    //
    chartOnsetWfi,
    tenSecStripStartGlobalWfi,
    //
    isTenSecStrip,
    isPatternMatchingEnabled,
    isSetRpeak,
    isCaliperMode,
    currentLevel,
    //
    editRange,
    similarPatternDataByLevels,
    similarPatternListAcrossRange,
    findingRpeakTargetList,
    handleSetFindingPatternTargetRange,
    handleSetInitPatternOfActionUpdate,
    handleSetFindingRpeakTargetList,
    handleSetEditState,
    handleShowDialog,
    recordingStartMs,
    theme,
  });

  return {};
}

export default usePatternMatching;
