import { CHART_EDIT_CONST } from 'constant/ChartEditConst';
import {
  FIND_PATTERN_LEVEL_TYPE,
  PATTERN_MATCHING,
} from 'constant/PatternMatchingConst';

import ChartUtil from 'util/ChartUtil';

import AppColors from 'theme/AppColors';

export function updateMarkers({
  chartInst,
  //
  patternList,
  similarPatternCurrLvl,
  similarPatternCurrLvlTargetRange,
  //
  tenSecStripStartGlobalWfi,
  onsetWaveformIndex,
  color,
}) {
  // if (patternMatchingMarkers.length > 0) {
  //   patternMatchingMarkers.forEach((marker) => marker.hide());
  // }
  if (patternList.length === 0) return;

  // render event marker
  renderEventMarkers({
    chartInst,
    //
    patternList,
    similarPatternCurrLvl,
    similarPatternCurrLvlTargetRange,
    //
    tenSecStripStartGlobalWfi,
    onsetWaveformIndex,
    color,
  });
}

function renderEventMarkers({
  chartInst,
  //
  patternList,
  similarPatternCurrLvl,
  similarPatternCurrLvlTargetRange,
  //
  tenSecStripStartGlobalWfi,
  onsetWaveformIndex,
  color,
}) {
  return patternList.map((wfiPair) => {
    const marker = ChartUtil.renderEventMarker(chartInst, {
      wfiPair,
      tenSecStripStartGlobalWfi,
      similarPatternCurrLvlTargetRange,
      onsetLocalWaveformIndex: wfiPair[0] - onsetWaveformIndex,
      terminationLocalWaveformIndex: wfiPair[1] - onsetWaveformIndex,
      color,
      zIndex: 4,
      // priorityZIndex: -1,
      priorityZIndex: undefined,
      type: PATTERN_MATCHING,
      currLvl: similarPatternCurrLvl,
    } as any) as any;

    return marker;
  });
}

export function initializeEventMarkers(chartInst) {
  const pmEventMarkerGroup =
    CHART_EDIT_CONST.PATTERN_MATCHING_EVENT_MARKER_GROUP;
  const level1EventMarkerGroup = `${pmEventMarkerGroup}-${FIND_PATTERN_LEVEL_TYPE.LEVEL1_LOWER_CASE}`;
  const level2EventMarkerGroup = `${pmEventMarkerGroup}-${FIND_PATTERN_LEVEL_TYPE.LEVEL2_LOWER_CASE}`;
  ChartUtil.initSVGGroup(chartInst, {
    svgGroupProperty: [level1EventMarkerGroup, level2EventMarkerGroup],
    zIndex: 5,
  });
}

export function renderSimilarPatternEventMarker({
  chartInst,
  currentLevel,
  similarPatternLevel1ListAcrossRange,
  similarPatternLevel2ListAcrossRange,
  similarPatternDataByLevels,
  chartOnsetWfi,
  tenSecStripStartGlobalWfi,
}) {
  const similarPatternConfig = getSimilarPatternConfigData(
    similarPatternLevel1ListAcrossRange,
    similarPatternLevel2ListAcrossRange
  );

  for (let i = 1; i <= currentLevel; i++) {
    const pmEventMarkerGroup =
      CHART_EDIT_CONST.PATTERN_MATCHING_EVENT_MARKER_GROUP;
    const level = FIND_PATTERN_LEVEL_TYPE.LEVEL_LOWER_CASE;
    const currLvl = level + i;
    const { color, patternList } = similarPatternConfig[currLvl];
    const similarPatternCurrLvlTargetRange =
      similarPatternDataByLevels[FIND_PATTERN_LEVEL_TYPE.LEVEL + i].targetRange;

    // if (i !== currentLevel) {
    if (i === 1) {
      ChartUtil.initSVGGroup(chartInst, {
        svgGroupProperty: pmEventMarkerGroup + '-' + level + (i + 1),
        zIndex: 5,
      });
    }
    ChartUtil.initSVGGroup(chartInst, {
      svgGroupProperty: pmEventMarkerGroup + '-' + level + i,
      zIndex: 5,
    });
    updateMarkers({
      chartInst,
      //
      patternList,
      similarPatternCurrLvl: level + i,
      similarPatternCurrLvlTargetRange,
      //
      tenSecStripStartGlobalWfi,
      onsetWaveformIndex: chartOnsetWfi,
      color,
    });
  }
}

function getSimilarPatternConfigData(level1List, level2List) {
  return {
    level1: {
      color: AppColors.RED_30,
      patternList: level1List,
      zIndex: 1,
    },
    level2: {
      color: AppColors.PAUSE_20,
      patternList: level2List,
      zIndex: 2,
    },
  };
}
