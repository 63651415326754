import { useState, useEffect, CSSProperties } from 'react';

import useSnackbarStack from 'component/hook/useSnackbarStack';

interface RequireContext {
  keys(): string[];
  (id: string): any;
  <T>(id: string): T;
}

declare global {
  interface NodeRequire {
    context(
      directory: string,
      useSubdirectories: boolean,
      regExp: RegExp
    ): RequireContext;
  }
}

interface Icon {
  name: string;
  path: string;
}

// 타입 정의
interface RequireContext {
  keys(): string[];
  (id: string): any;
}

interface Icon {
  name: string;
  path: string;
}

function IconViewer() {
  const { enqueueMessage } = useSnackbarStack();
  const [icons, setIcons] = useState<Icon[]>([]);
  const [filteredIcons, setFilteredIcons] = useState<Icon[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

  // 모든 SVG 파일 가져오기
  useEffect(() => {
    try {
      const importAll = (r: RequireContext) =>
        r.keys().map((key) => ({
          name: key.replace('static/icon/', ''),
          path: r(key).replace('static/icon/', ''),
        }));

      const svgs = importAll(
        require.context('../../static/icon', false, /\.svg$/)
      );

      const uniqueIcons = Array.from(
        new Map(svgs.map((item) => [item.path, item])).values()
      );
      setIcons(uniqueIcons);
      setFilteredIcons(uniqueIcons);
    } catch (error) {
      console.error('SVG 로딩 에러:', error);
    }
  }, []);

  // 검색 필터링
  useEffect(() => {
    const filtered = icons
      .filter((icon) =>
        icon.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .sort((a, b) =>
        sortOrder === 'asc'
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name)
      );
    setFilteredIcons(filtered);
  }, [searchTerm, sortOrder, icons]);

  return (
    <div style={styles.container}>
      <div style={styles.controls}>
        <input
          type="text"
          placeholder="🔍 Search icons..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={styles.input}
        />
        <button
          onClick={() =>
            setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'))
          }
          style={styles.button}>
          Sort: {sortOrder.toUpperCase()}
        </button>
      </div>

      <div style={styles.grid}>
        {filteredIcons.map((icon, index) => (
          <div
            key={index}
            style={styles.iconWrapper}
            onClick={() => {
              // 클릭 시 아이콘 이름을 복사하여 클립보드에 저장
              navigator.clipboard.writeText(icon.name);
              enqueueMessage(`아이콘 이름이 복사되었습니다.`);
            }}>
            <img src={icon.path} alt={icon.name} style={styles.icon} />
            <p style={styles.iconName}>{icon.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

const styles: { [key: string]: CSSProperties } = {
  container: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
  },
  controls: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
  input: {
    width: '60%',
    padding: '10px',
    fontSize: '16px',
    border: '1px solid #ccc',
    borderRadius: '5px',
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
    gap: '20px',
  },
  iconWrapper: {
    textAlign: 'center',
    padding: '10px',
    backgroundColor: '#f9f9f9',
    border: '1px solid #ddd',
    borderRadius: '10px',
    cursor: 'pointer',
  },
  icon: {
    width: '60px',
    height: '60px',
    marginBottom: '8px',
  },
  iconName: {
    fontSize: '14px',
    color: '#333',
  },
};

export default IconViewer;
