// xAxis를 wfi로 변환
export function transformXaxisToWfi({ chartInst, xAxis }): number {
  return Math.round(chartInst.xAxis[0].toValue(xAxis));
}

// onsetWfi, terminationWfi 숫자를 onsetWfi < terminationWfi 순으로 정렬
export function getAdjustedWfi(onsetWfi, terminationWfi) {
  return onsetWfi < terminationWfi
    ? [onsetWfi, terminationWfi]
    : [terminationWfi, onsetWfi];
}
