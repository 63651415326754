import { useEffect } from 'react';

import { CHART_EDIT_CONST } from 'constant/ChartEditConst';
import { FIND_PATTERN_LEVEL } from 'constant/PatternMatchingConst';

import ChartUtil from 'util/ChartUtil';
import { transformXaxisToWfi } from 'util/transformUnit';
import {
  onMousedownFindingPatternTargetRange,
  onMouseupFindingPatternTargetRange,
} from 'util/patternMatching/mouseEvent/dragEvent';
import { isTargetWithinRange } from 'util/checkRange';

import { Level, SimilarPatternLevels } from '@type/patternMatching/type';
import { WaveformIndex } from '@type/ecgEventType/eventUnit';

interface UsePatternMatchingMouseEventProps {
  // 차트 관련
  chartInst: any;
  chartContainerRef: any;
  chartRef: any;
  // 차트 범위 관련
  chartOnsetWfi: WaveformIndex;
  tenSecStripStartGlobalWfi: WaveformIndex;
  // 상태 플래그
  isTenSecStrip: boolean;
  isPatternMatchingEnabled: boolean;
  isSetRpeak: boolean;
  isCaliperMode: boolean;
  currentLevel: number;
  // 패턴 매칭 데이터
  editRange: any; // 패턴 매칭 편집 범위
  similarPatternDataByLevels: Record<Level, SimilarPatternLevels>;
  similarPatternListAcrossRange: [WaveformIndex, WaveformIndex][];
  findingRpeakTargetList: WaveformIndex[];
  // redux action creator
  handleSetFindingPatternTargetRange: Function;
  handleSetInitPatternOfActionUpdate: Function;
  handleSetFindingRpeakTargetList: Function;
  handleSetEditState: Function;
  handleShowDialog: Function;
  // 기타
  recordingStartMs: number;
  theme: any;
}

function usePatternMatchingMouseEvent({
  chartInst,
  chartContainerRef,
  chartRef,
  //
  chartOnsetWfi,
  tenSecStripStartGlobalWfi,
  //
  isTenSecStrip,
  isPatternMatchingEnabled,
  isSetRpeak,
  isCaliperMode,
  currentLevel,
  //
  editRange,
  similarPatternDataByLevels,
  similarPatternListAcrossRange,
  findingRpeakTargetList,
  //
  handleSetFindingPatternTargetRange,
  handleSetInitPatternOfActionUpdate,
  handleSetFindingRpeakTargetList,
  handleSetEditState,
  handleShowDialog,
  //
  recordingStartMs,
  theme,
}: UsePatternMatchingMouseEventProps) {
  const chartEditUtil = ChartUtil.chartEdit(chartInst, { theme });
  const isFirstPatternMatchingStep = currentLevel === 0;

  /**************************************************************************************************************/
  /*  10s strip - 유사 패턴 찾기 대표 구간 설정 drag 기능                                                       */
  /*   * 사용 두가지 케이스                                                                                     */
  /*     1. 유사 패턴 찾기 케이스                                                                               */
  /*     2. 유사 패턴에서 제외하기 케이스                                                                       */
  /*       - 유사 패턴을 찾은 상태에서 드래그를 한번 더 설정하면 패턴에서 제외하기 기능을 수행                  */
  /*       - 유사 패턴 찾은 케이스 안에서만 drag 기능을 수행                                                    */
  /**************************************************************************************************************/
  useEffect(() => {
    const isSimilarPattern2ndLevel = currentLevel === 2;
    if (
      !chartInst ||
      !isTenSecStrip ||
      !isPatternMatchingEnabled ||
      isCaliperMode ||
      isSimilarPattern2ndLevel
    ) {
      return;
    }

    if (isSetRpeak) {
      chartContainerRef.current.removeEventListener(
        'mousedown',
        handleMousedown
      );
      chartContainerRef.current.removeEventListener('mouseup', handleMouseup);

      return;
    }

    let onsetWfi: number | null = null;
    function handleMousedown(event) {
      onsetWfi = onMousedownFindingPatternTargetRange({
        event,
        chartInst,
        tenSecStripStartGlobalWfi,
        editRange,
        similarPatternListAcrossRange,
        recordingStartMs,
      });
    }

    function handleMouseup(event) {
      onMouseupFindingPatternTargetRange({
        event,
        chartInst,
        tenSecStripStartGlobalWfi,
        editRange,
        similarPatternListAcrossRange,
        recordingStartMs,
        onsetWfi,
        handleSetFindingPatternTargetRange,
        chartEditUtil,
        handleSetEditState,
        handleShowDialog,
        handleSetInitPatternOfActionUpdate,
        isFirstPatternMatchingStep,
        currentLevel,
      });
      onsetWfi = null;
    }

    chartContainerRef.current.addEventListener('mousedown', handleMousedown);
    chartContainerRef.current.addEventListener('mouseup', handleMouseup);
    return () => {
      if (chartContainerRef.current) {
        chartContainerRef.current.removeEventListener(
          'mousedown',
          handleMousedown
        );
        chartContainerRef.current.removeEventListener('mouseup', handleMouseup);
      }
    };
  }, [
    chartInst,
    chartContainerRef,
    editRange,
    isPatternMatchingEnabled,
    isSetRpeak,
    isCaliperMode,
    similarPatternListAcrossRange,
    currentLevel,
  ]);

  // 10s strip - Rpeak 설정하기 기능 이벤트
  useEffect(() => {
    if (!chartInst || !isTenSecStrip || !isPatternMatchingEnabled) return;
    // 첫번째 구간 선택 후 r peak 찾기 클릭
    if (!isSetRpeak) {
      chartContainerRef.current.removeEventListener(
        'mouseup',
        onMouseupFindingRpeakTargetList
      );
      return;
    }

    let terminationWfi: number | null = null;
    function onMouseupFindingRpeakTargetList(event) {
      const {
        onsetWaveformIndex: originOnsetWaveformIndex,
        terminationWaveformIndex: originTerminationWaveformIndex,
      } = similarPatternDataByLevels[FIND_PATTERN_LEVEL.LEVEL1].targetRange;

      terminationWfi = transformXaxisToWfi({ chartInst, xAxis: event.layerX });
      const globalTerminationWfi = tenSecStripStartGlobalWfi + terminationWfi;
      // mouse up시 대표 유사 패턴 찾은 케이스 안에서만 drag 기능을 수행
      const isMouseUpWfiWithinSimilarPattern = isTargetWithinRange({
        target: globalTerminationWfi,
        range: {
          start: originOnsetWaveformIndex,
          end: originTerminationWaveformIndex,
        },
      });
      event.isMouseupAvailable = isMouseUpWfiWithinSimilarPattern;

      // 유사 패턴 찾은 케이스 안에서 유사 Rpeak 찾기 기능 수행
      if (
        isSetRpeak &&
        isMouseUpWfiWithinSimilarPattern &&
        findingRpeakTargetList.length <= 1
      ) {
        handleSetFindingRpeakTargetList({
          rpeakTargetWaveformIndex: globalTerminationWfi,
        });

        // 두번쨰 r peak 찾기 클릭
        if (findingRpeakTargetList.length === 1) {
          const mouseTrackerElement = chartContainerRef.current?.querySelector(
            `.${CHART_EDIT_CONST.MOUSE_TRACKER}`
          );
          const beatAddPlusButton = mouseTrackerElement.querySelector(
            `.${CHART_EDIT_CONST.BEAT_ADD_PLUS_BUTTON}`
          );
          beatAddPlusButton.style.visibility = 'hidden';
        }
        return;
      }
    }

    chartContainerRef.current.addEventListener(
      'mouseup',
      onMouseupFindingRpeakTargetList
    );
    return () => {
      if (chartContainerRef.current) {
        chartContainerRef.current.removeEventListener(
          'mouseup',
          onMouseupFindingRpeakTargetList
        );
      }
    };
  }, [
    chartInst,
    chartContainerRef,
    tenSecStripStartGlobalWfi,
    //
    isPatternMatchingEnabled,
    isSetRpeak,
    //
    findingRpeakTargetList,
  ]);

  return {};
}

export default usePatternMatchingMouseEvent;
