import { processBeatsByStrategyConst } from 'constant/PatternMatchingConst';

import {
  processExistingBeats,
  insertFindingRpeaksInOrder,
  setBeatType,
} from './processors';
import { isWaveformIndexInPatterns } from './patternRangeUtils';

const { WITH_FINDING_RPEAKS, WITHOUT_FINDING_RPEAKS, DELETE_RPEAK } =
  processBeatsByStrategyConst;

export const beatProcessingStrategies = {
  [WITH_FINDING_RPEAKS]: processWithFindingRpeaks,
  [WITHOUT_FINDING_RPEAKS]: processWithoutFindingRpeaks,
  [DELETE_RPEAK]: processDeleteRpeak,
};

// [WITH_FINDING_RPEAKS] 패턴 매칭에서 찾은 R-peak가 있을 때 10s strip에서 비트 변경
function processWithFindingRpeaks(currentBeats, options) {
  const { wfisOfNotInPatterns } = processExistingBeats(
    currentBeats,
    options.level1Patterns,
    options.level2Patterns
  );

  const newWaveformIndex = insertFindingRpeaksInOrder(
    wfisOfNotInPatterns,
    options.rpeakList
  );

  const newBeatType = setBeatType(
    newWaveformIndex,
    options.rpeakList,
    options.beatType
  );

  // wfi[유사 패턴 구간이 아닌 wfi + 패턴 매칭에서 찾은 R-peak(wfi)]을 비트 타입 변경(패턴 매칭에서 찾은 R-peak(wfi) 비트 타입만 변경)
  return { newWaveformIndex, newBeatType };
}

// [WITHOUT_FINDING_RPEAKS] 패턴 매칭에서 찾은 R-peak가 없을 때 10s strip에서 비트 변경
function processWithoutFindingRpeaks(currentBeats, options) {
  const newWaveformIndex = [...currentBeats.waveformIndex];
  const newBeatType = [];

  for (let currentWfi of currentBeats.waveformIndex) {
    let currentBeatType = 0;
    const { isInLevel1, isInLevel2 } = isWaveformIndexInPatterns(
      currentWfi,
      options.level1Patterns,
      options.level2Patterns
    );

    if ((isInLevel1 || isInLevel2) && options.beatType != null) {
      currentBeatType = options.beatType;
    }

    newBeatType.push(currentBeatType);
  }

  // 유사 패턴 잡은 구간만 변경하고자 하는 비트로 변경
  return { newWaveformIndex, newBeatType };
}

// [DELETE_RPEAK] 패턴 매칭에서 유사 패턴 찾은 이후 10s strip에서 비트 remove 했을 때
function processDeleteRpeak(currentBeats, options) {
  const newWaveformIndex = [];
  const newBeatType = [];

  for (let wfi of currentBeats.waveformIndex) {
    const { isInLevel1, isInLevel2 } = isWaveformIndexInPatterns(
      wfi,
      options.level1Patterns,
      options.level2Patterns
    );

    if (!isInLevel1 && !isInLevel2) {
      newWaveformIndex.push(wfi);
      newBeatType.push(0);
    }
  }

  // 유사 패턴 잡지 않은 구간만 남겨둠
  return { newWaveformIndex, newBeatType };
}
