import React from 'react';
import { useIntl } from 'react-intl';
import styled, { useTheme } from 'styled-components';

import Const from 'constant/Const';

import useDoctorsDropdown from 'component/hook/useDoctorsDropdown';

import {
  DialogWrapper,
  DialogTitleText,
  DialogMessageText,
  DialogButtonWrapper,
} from 'component/ui/dialog/Dialog';
import TextButton from 'component/ui/button/TextButton';
import Button from 'component/ui/button/Button';

import { ReactComponent as FileIcon } from 'static/icon/icon-file.svg';

const IS_CUSTOMER_WEB =
  process.env.REACT_APP_CLIENT_NAME === Const.CLIENT_NAME.WEB_APP;

function FinalConfirmReportDialog(props) {
  const intl = useIntl();
  const theme = useTheme();

  const {
    // Dialog Props
    open,
    params,
    onClose,
    // Store States
    submitPending,
  } = props;
  const {
    purposeText,
    reportFileName,
    reportOnClick,
    initConfirmedBy,
    onSubmit,
  } = params;

  const [confirmedBy, DoctorsDropdown] = useDoctorsDropdown(
    intl.formatMessage({
      id: '99-FinalConfirmReportDialog-Dropdown-placeholder-confirmedBy',
      description: '12 리포트 생성 팝업의 confirmedBy 필드 placeholder',
      defaultMessage: '확인의 선택',
    }),
    initConfirmedBy
  );

  return (
    <DialogWrapper open={open} maxWidth={480}>
      <DialogTitleText>
        {IS_CUSTOMER_WEB
          ? intl.formatMessage({
              id: '99-FinalConfirmReportDialog-DialogTitleText-customer',
              description: '검토 완료하기',
              defaultMessage: '검토 완료하기',
            })
          : intl.formatMessage(
              {
                id: '99-FinalConfirmReportDialog-DialogTitleText-partner',
                description: '검토를 최종 완료하시겠습니까?',
                defaultMessage: '{purposeText} 최종 완료하시겠습니까?',
              },
              { purposeText: purposeText[1] }
            )}
      </DialogTitleText>
      <DialogMessageText>
        {IS_CUSTOMER_WEB
          ? intl.formatMessage({
              id: '99-FinalConfirmReportDialog-DialogMessageText-customer',
              description: '현재 버전의 리포트로 검토를 완료합니다.',
              defaultMessage: '현재 버전의 리포트로 검토를 완료합니다.',
            })
          : intl.formatMessage(
              {
                id: '99-FinalConfirmReportDialog-DialogMessageText-partner',
                description:
                  '검토 완료 이후에는 더 이상 검토할 수 없으며 검토한 정보가 담당병원으로 전달됩니다.',
                defaultMessage:
                  '{purposeText} 완료 이후에는 더 이상 {purposeText}할 수 없으며 {purposeText}한 정보가 {goalText} 전달됩니다.',
              },
              { purposeText: purposeText[0], goalText: purposeText[2] }
            )}
      </DialogMessageText>

      <DialogContentContainer>
        <DialogContentLabel>
          {intl.formatMessage({
            id: '99-FinalConfirmReportDialog-DialogContentLabel-report',
            description: '',
            defaultMessage: '리포트',
          })}
        </DialogContentLabel>
        <DialogContentContentContainer
          style={{ cursor: 'pointer' }}
          // 리포트 영역 전체에 OnClick 적용
          onClick={reportOnClick}>
          <DialogContentContentLeft>
            <DialogContentContentIcon>
              <FileIcon />
            </DialogContentContentIcon>
            <DialogContentContentLabel>
              {reportFileName}
            </DialogContentContentLabel>
          </DialogContentContentLeft>
          <Button
            style={{
              boxSizing: 'border-box',
              cursor: 'default',
              width: 41,
              height: 24,
              fontSize: 11,
              fontWeight: '400',
            }}
            outline
            color="default"
            title={intl.formatMessage({
              id: '99-FinalConfirmReportDialog-Button-title-01',
              description: '보기',
              defaultMessage: '보기',
            })}
          />
        </DialogContentContentContainer>
      </DialogContentContainer>
      {IS_CUSTOMER_WEB && (
        <DialogContentContainer style={{ margin: '8px 0 16px' }}>
          <DialogContentLabel>
            {intl.formatMessage({
              id: '99-FinalConfirmReportDialog-DialogContentLabel-confirmedBy',
              description: '확인의',
              defaultMessage: '확인의',
            })}
          </DialogContentLabel>
          <DoctorsDropdown
            anchorContainerStyle={{
              minWidth: 370 - 24 - 2,
            }}
            itemContainerStyle={{
              top: 34,
            }}
          />
        </DialogContentContainer>
      )}

      <DialogButtonWrapper>
        <TextButton
          textColor={theme.color.MEDIUM_DARK}
          title={intl.formatMessage({
            id: '99-FinalConfirmReportDialog-TextButton-title-01',
            description: '취소',
            defaultMessage: '취소',
          })}
          onClick={onClose}
        />
        <TextButton
          disabled={IS_CUSTOMER_WEB && !confirmedBy}
          pending={submitPending}
          title={intl.formatMessage(
            {
              id: '99-FinalConfirmReportDialog-TextButton-title-02',
              description: '검토 완료',
              defaultMessage: '{purposeText} 완료',
            },
            { purposeText: purposeText[0] }
          )}
          onClick={() => {
            if (typeof onSubmit === 'function') {
              onSubmit(confirmedBy);
            }
          }}
        />
      </DialogButtonWrapper>
    </DialogWrapper>
  );
}

export const DialogContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
export const DialogContentContentContainer = styled.div`
  width: 352px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${(props) => props.theme.color.MEDIUM_LIGHT};
  padding: 10px 8px;
  border-radius: 6px;
`;
export const DialogContentLabel = styled.label`
  white-space: nowrap;
  padding: 0 20px 0 0;
  font-size: 12px;
  line-height: 130%;
  color: ${(props) => props.theme.color.BLACK};
`;
export const DialogContentContentLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
export const DialogContentContentIcon = styled.div``;
export const DialogContentContentLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 7px;
  font-size: 12px;
  color: ${(props) => props.theme.color.BLACK};
`;
export const DialogContentContentButton = styled.div``;

export default FinalConfirmReportDialog;
