import { FIND_PATTERN_LEVEL_TYPE } from 'constant/PatternMatchingConst';

import { isRange1InRange2 } from 'util/checkRange';

import { WaveformIndex } from '@type/ecgEventType/eventUnit';
import { BeatType } from '@type/ecgEventType/baseEventType';
import { PatternAction, SimilarPatternData } from '@type/patternMatching/type';

/**
 * onsetWaveformIdx와 terminationWaveformIdx 사이의 waveformIndex와 beatType 필터링
 */
interface FilteredLists {
  beatTypeList: BeatType[];
  beatWaveformIndexList: WaveformIndex[];
}
export function filterBeatLists({
  beatTypeList,
  beatWaveformIndexList,
  onsetWaveformIdx,
  terminationWaveformIdx,
}: {
  beatTypeList: BeatType[];
  beatWaveformIndexList: WaveformIndex[];
  onsetWaveformIdx: WaveformIndex;
  terminationWaveformIdx: WaveformIndex;
}): FilteredLists {
  const filteredLists: FilteredLists = {
    beatTypeList: [],
    beatWaveformIndexList: [],
  };

  for (let i = 0; i < beatWaveformIndexList?.length; i++) {
    if (
      beatWaveformIndexList[i] >= onsetWaveformIdx &&
      beatWaveformIndexList[i] < terminationWaveformIdx
    ) {
      filteredLists.beatWaveformIndexList.push(beatWaveformIndexList[i]);
      filteredLists.beatTypeList.push(beatTypeList[i]);
    }
  }

  return filteredLists;
}

/**
 * 대표 30초 구간에서 구간(onsetWaveformIdx, terminationWaveformIdx) 유사 패턴 범위 필터링
 */
export function filterPatternRangesInStrip({
  similarPatternData,
  onsetWaveformIdx,
  terminationWaveformIdx,
  //
  actionType,
}: {
  similarPatternData: SimilarPatternData;
  onsetWaveformIdx: WaveformIndex;
  terminationWaveformIdx: WaveformIndex;
  //
  actionType: PatternAction;
}): [WaveformIndex, WaveformIndex][] {
  const similarPatternDataByLevels = similarPatternData.data?.levels;
  if (!similarPatternDataByLevels) return [];

  const similarPatternLevel1ListAcrossRange =
    similarPatternDataByLevels[FIND_PATTERN_LEVEL_TYPE.LEVEL1]
      ?.similarPatternList ?? [];

  return similarPatternLevel1ListAcrossRange.filter((range) =>
    isRange1InRange2({
      range1: { start: range[0], end: range[1] },
      range2: { start: onsetWaveformIdx, end: terminationWaveformIdx },
    })
  );
}

/**
 * WFI가 패턴 범위 내에 있는지 확인
 */
export function isWfiInPatternRanges(
  wfi: number,
  patternRanges: Array<[number, number]>
): boolean {
  return patternRanges.some((range) => wfi >= range[0] && wfi <= range[1]);
}
