import { CHART_EDIT_CONST } from 'constant/ChartEditConst';

import ChartUtil from 'util/ChartUtil';

import { WaveformIndex } from '@type/ecgEventType/eventUnit';
import { BeatType } from '@type/ecgEventType/baseEventType';

const beatMarkerRenderer = {
  renderBeatMarker(
    chartInst,
    {
      rpeakList = [],
      beatTypeList = [],
      hrList = [],
      //
      onsetWaveformIndex,
      //
      className, // CHART_EDIT_CONST.PATTERN_MATCHING_FOUND_BEAT_MARKER
      svgGroupProperty,
      beatMarkerAddGroupTarget,
      //
      color,
      totalWaveformIndex, // 2500, 7500
    }: {
      rpeakList: WaveformIndex[];
      beatTypeList: BeatType[];
      hrList?: number[];
      //
      onsetWaveformIndex: WaveformIndex;
      //
      className: string;
      svgGroupProperty: string;
      beatMarkerAddGroupTarget: string;
      //
      color: string;
      totalWaveformIndex: number;
    }
  ) {
    const validation =
      chartInst &&
      totalWaveformIndex &&
      typeof onsetWaveformIndex === 'number' &&
      rpeakList.length !== 0;
    if (!validation) return;

    // this.initBeatMarkerGroup(chartInst, {
    //   svgGroupProperty,
    // });
    for (let i = 0; i < rpeakList.length; i++) {
      ChartUtil._renderBeatMarker(chartInst, {
        waveformIndex: rpeakList[i] - onsetWaveformIndex,
        beatType: beatTypeList[i],
        hr: hrList[i],
        //
        className,
        beatMarkerAddGroupTarget,
        //
        color,
        totalWaveformIndex,
      });
    }
  },
  _renderBeatMarker(
    chartInst,
    {
      waveformIndex,
      beatType = '',
      hr = '',
      //
      className = '',
      beatMarkerAddGroupTarget = CHART_EDIT_CONST.BEAT_MARKER_GROUP,
      //
      color,
      totalWaveformIndex,
    }: {
      waveformIndex: WaveformIndex;
      beatType: BeatType | '';
      hr: number | '';
      //
      className: string;
      beatMarkerAddGroupTarget: string;
      //
      color: string;
      totalWaveformIndex: number;
    }
  ) {
    const {
      x: plotX,
      y: plotY,
      width: plotWidth,
      // height: plotHeight,
    } = chartInst.plotBox;
    const beatMarkerSvg = 'M0 0V2L1 5L2 2V0H0Z';
    const widthPerPoint = plotWidth / totalWaveformIndex;
    const xPixel = plotX + widthPerPoint * waveformIndex - 1;
    const yPixel = plotY - 2;

    const newClassName = Array.isArray(className)
      ? className.join(' ')
      : className;
    const newClassNameBeatType = beatType ? `beat-type-${beatType}` : '';

    const addTargetGroup = chartInst[beatMarkerAddGroupTarget];
    const marker = chartInst.renderer
      .path()
      .attr({
        class: `${CHART_EDIT_CONST.BEAT_MARKER} ${newClassName} ${newClassNameBeatType}`,
        d: beatMarkerSvg,
        transform: `translate(${xPixel}, ${yPixel})`,
        fill: color,
      })
      .add(addTargetGroup);

    return marker;
  },
};

export default beatMarkerRenderer;
