import { useEffect } from 'react';

import {
  CHART_EDIT_CONST,
  HIGHCHART_UNIT,
  SELECTION_MARKER_OFFSET_CONST,
  SELECTION_MARKER_TYPE,
} from 'constant/ChartEditConst';
import {
  ACTION_TYPE,
  FIND_PATTERN_LEVEL_TYPE,
} from 'constant/PatternMatchingConst';

import {
  filterBeats,
  getBeatMarkerColor,
  handleDeleteRpeak,
  renderPatternMatchingBeatMarkers,
} from 'util/patternMatching/beatMarker';
import ChartUtil from 'util/ChartUtil';

import { Level, SimilarPatternLevels } from '@type/patternMatching/type';
import { WaveformIndex } from '@type/ecgEventType/eventUnit';
import { CustomTheme } from '@type/types';

interface UsePatternMatchingRpeakProps {
  //
  chartRef: any;
  chartInst: any;
  chartOnsetWfi: WaveformIndex;
  chartTerminationWfi: WaveformIndex;
  tenSecStripStartGlobalWfi: WaveformIndex;
  //
  _beatEventData: any;
  //
  isTenSecStrip: boolean;
  isPatternMatchingEnabled: boolean;
  isFindRpeak: boolean;
  isSetRpeak: boolean;
  //
  renderBeatButtonList: Function;
  //
  beatType: any;
  patternAction: string;
  actionType: string | null;
  findingRpeakTargetList: WaveformIndex[];
  findingRpeakListInRange: WaveformIndex[];
  similarPatternDataByLevels: Record<Level, SimilarPatternLevels>;
  similarPatternLevel2ListAcrossRange: [WaveformIndex, WaveformIndex][];
  similarPatternLevel1ListAcrossRange: [WaveformIndex, WaveformIndex][];
  //
  theme: CustomTheme;
  initTenSecStripDetail: Function;
}

function usePatternMatchingRpeak({
  // 차트 관련
  chartRef,
  chartInst,
  chartOnsetWfi,
  chartTerminationWfi,
  tenSecStripStartGlobalWfi,
  // 비트 데이터
  _beatEventData,
  // 상태 플래그
  isTenSecStrip,
  isPatternMatchingEnabled,
  isFindRpeak,
  isSetRpeak,
  // 콜백
  renderBeatButtonList,
  // 패턴 매칭 데이터
  beatType,
  actionType,
  patternAction,
  findingRpeakTargetList,
  findingRpeakListInRange,
  similarPatternDataByLevels,
  similarPatternLevel2ListAcrossRange,
  similarPatternLevel1ListAcrossRange,
  // 기타
  theme,
  initTenSecStripDetail,
}: UsePatternMatchingRpeakProps) {
  const chartEditUtil = ChartUtil.chartEdit(chartInst, { theme });

  // [render] Rpeak 설정하기시 10s strip mousemove시 tracker top icon 변경
  useEffect(() => {
    try {
      if (!isTenSecStrip) return;

      initTenSecStripDetail();
      chartEditUtil.removeSelectionMarkerAllInTenSecStrip();
      chartEditUtil.removeSelectionHighlightAll();
      chartEditUtil.removeBeatAddPlusButton({ chartRef, isSetRpeak });
      chartEditUtil.removeRpeakRangeElement({ chartRef });
    } catch (error) {
      console.error('set Rpeak', error);
    }
  }, [isSetRpeak]);

  // [render] pattern matching으로 찾을 rpeak target
  useEffect(() => {
    if (!isTenSecStrip) return;
    const {
      onsetWaveformIndex: targetOnsetWaveformIndex = null,
      terminationWaveformIndex: targetTerminationWaveformIndex = null,
    } =
      similarPatternDataByLevels[FIND_PATTERN_LEVEL_TYPE.LEVEL1]?.targetRange ||
      {};
    const [rpeakTargetOnsetWaveformIndex, rpeakTargetTerminationWaveformIndex] =
      findingRpeakTargetList;

    chartEditUtil.removeRpeakRangeElement({ chartRef });
    for (const target of findingRpeakTargetList) {
      chartEditUtil.renderTargetRpeak({
        isFindRpeak,
        selectionMarkerType: SELECTION_MARKER_TYPE.FINDING_RPEAK_TARGET,
        type: HIGHCHART_UNIT.LOCATION,
        offsetInfo: SELECTION_MARKER_OFFSET_CONST.TENSEC_STRIP,
        xAxis: target - tenSecStripStartGlobalWfi,
        withRepresentativeSelectMarker: false,
      });
    }

    const rpeakElementList =
      chartRef.current?.container.current.querySelectorAll(
        `.${CHART_EDIT_CONST.PATTERN_MATCHING_SELECTION_MARKER_TEN_SEC_STRIP}`
      );
    const mouseTrackerElement =
      chartRef.current?.container.current.querySelector(
        `.${CHART_EDIT_CONST.MOUSE_TRACKER}`
      );
    const beatAddPlusButton = mouseTrackerElement.querySelector(
      `.${CHART_EDIT_CONST.BEAT_ADD_PLUS_BUTTON}`
    );
    beatAddPlusButton.style.visibility =
      rpeakElementList.length !== 2 && isSetRpeak ? 'visible' : 'hidden';

    if (
      targetOnsetWaveformIndex !== null &&
      rpeakTargetOnsetWaveformIndex < targetOnsetWaveformIndex
    ) {
      rpeakElementList[0].style.visibility = 'hidden';
    }

    if (
      targetTerminationWaveformIndex !== null &&
      rpeakTargetTerminationWaveformIndex > targetTerminationWaveformIndex
    ) {
      rpeakElementList[1].style.visibility = 'hidden';
    }
  }, [
    chartInst,
    findingRpeakTargetList,
    isFindRpeak,
    isSetRpeak,
    tenSecStripStartGlobalWfi,
  ]);

  // [render] "beat button", "finding rpeak button" in 10s strip
  useEffect(() => {
    if (!chartInst || !isTenSecStrip || !isPatternMatchingEnabled) {
      return;
    }
    // remove 할때도 "renderBeatButtonList"함수가 다시 생성되서 다시 render
    // render: beat button('찾은 Rpeak를 포함하는 유사패턴 구간'에 포함되지 않는 비트)
    renderBeatButtonList({
      findingRpeakListInRange,
      similarPatternDataByLevels,
      chartOnsetWfi,
      chartTerminationWfi,
      //
      actionType,
      beatType,
    });

    // render: "finding rpeak button"
    const svgGroupProperty =
      CHART_EDIT_CONST.PATTERN_MATCHING_BEAT_MARKER_TEN_SEC_STRIP;
    ChartUtil.initSVGGroup(chartInst, {
      svgGroupProperty,
      zIndex: 4,
    });
    // todo: jyoon - [#patternMatching] 유사 패턴에서 설정된 actionType을 rpeak 찾기 세팅시 초기화 필요
    actionType === null &&
      ChartUtil.renderFindingRpeakButtonList({
        chartInst,
        tenSecStripStartGlobalWfi,
        //
        svgGroupProperty,
        findingRpeakListInRange,
        similarPatternDataByLevels,
        similarPatternLevel2ListAcrossRange,
        patternAction,
        beatType,
        theme,
      });
  }, [
    actionType,
    chartInst,
    renderBeatButtonList,
    chartOnsetWfi,
    findingRpeakListInRange,
    isPatternMatchingEnabled,
    similarPatternDataByLevels,
    similarPatternLevel2ListAcrossRange,
  ]);

  // [render] "beat marker" in 30s strip
  useEffect(() => {
    if (
      !chartInst ||
      !isPatternMatchingEnabled ||
      isTenSecStrip ||
      !_beatEventData
    ) {
      return;
    }

    ChartUtil.initSVGGroup(chartInst, {
      svgGroupProperty: CHART_EDIT_CONST.PATTERN_MATCHING_BEAT_MARKER_GROUP,
      zIndex: 4,
    });

    const beatMarkerColor = getBeatMarkerColor(
      findingRpeakListInRange,
      similarPatternLevel2ListAcrossRange
    );

    // render: 첫번재 유사 패턴 안에 있는 rpeak 버튼
    renderPatternMatchingBeatMarkers({
      chartInst,
      rpeakList: beatMarkerColor.lvl1Range,
      onsetWaveformIndex: chartOnsetWfi,
      className: CHART_EDIT_CONST.PATTERN_MATCHING_FOUND_BEAT_MARKER,
      color: theme.color.RED_70,
    });
    // render: 두번째 유사 패턴 안에 있는 rpeak 버튼
    renderPatternMatchingBeatMarkers({
      chartInst,
      rpeakList: beatMarkerColor.lvl2Range,
      onsetWaveformIndex: chartOnsetWfi,
      className: CHART_EDIT_CONST.PATTERN_MATCHING_FOUND_BEAT_MARKER,
      color: theme.color.PAUSE_70,
    });

    // render: 찾은 rpeak 제외한 나머지 rpeak
    const newBeats = filterBeats({
      beats: _beatEventData.beats,
      findingRpeakListInRange,
      similarPatternLevel1ListAcrossRange,
      similarPatternLevel2ListAcrossRange,
    });
    renderPatternMatchingBeatMarkers({
      chartInst,
      rpeakList: newBeats.waveformIndex,
      beatTypeList: newBeats.beatType,
      onsetWaveformIndex: chartOnsetWfi,
      className: CHART_EDIT_CONST.PATTERN_MATCHING_FOUND_BEAT_MARKER,
      color: theme.color.COOL_GRAY_80,
    });
  }, [
    chartInst,
    _beatEventData,
    isPatternMatchingEnabled,
    findingRpeakListInRange,
    similarPatternLevel1ListAcrossRange,
    similarPatternLevel2ListAcrossRange,
  ]);

  // [render] 유사 패턴 찾기 이후 beat remove 편집(30s strip에서 rpeak 삭제)
  useEffect(() => {
    if (
      !chartInst ||
      isTenSecStrip ||
      !_beatEventData ||
      similarPatternLevel1ListAcrossRange.length === 0
    ) {
      return;
    }

    if (actionType === ACTION_TYPE.DELETE_RPEAK) {
      handleDeleteRpeak(
        _beatEventData,
        findingRpeakListInRange,
        similarPatternLevel1ListAcrossRange,
        similarPatternLevel2ListAcrossRange,
        beatType,
        actionType,
        chartInst,
        chartOnsetWfi,
        theme
      );
    }
  }, [
    actionType,
    beatType,
    _beatEventData,
    findingRpeakListInRange,
    similarPatternLevel1ListAcrossRange,
    similarPatternLevel2ListAcrossRange,
    chartInst,
    chartOnsetWfi,
    isTenSecStrip,
    isPatternMatchingEnabled,
    theme,
  ]);
}

export default usePatternMatchingRpeak;
