import { useTheme } from 'styled-components';
import { ChangeEvent } from 'react';

import { HandleRevertToPartnerEditDoneParam } from 'redux/container/dialog/RevertToPartnerEditDoneDialogContainer';

import TextButton from 'component/ui/button/TextButton';
import {
  DialogButtonWrapper,
  DialogMessageText,
  DialogTitleText,
  DialogWrapper,
} from 'component/ui/dialog/Dialog';
import TextAreaInput from 'component/ui/input/TextAreaInput';

import { ITheme } from 'theme/StyledComponentsTheme';

import { DialogContentContainer } from './FinalConfirmReportDialog';

interface RevertToPartnerEditDoneDialogProps {
  ecgTestId: string;
  inputMessage: string;
  open: boolean;
  onClose?: () => void;
  callback: Function;
  onInputChange: (
    evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleRevertToPartnerEditDone: ({
    ecgTestId,
    statusUpdatedBy,
    callback,
  }: HandleRevertToPartnerEditDoneParam) => void;
}
function RevertToPartnerEditDoneDialog({
  ecgTestId,
  inputMessage,
  open,
  onClose,
  callback,
  onInputChange,
  handleRevertToPartnerEditDone,
}: RevertToPartnerEditDoneDialogProps) {
  const theme = useTheme() as ITheme;
  return (
    <DialogWrapper open={open} maxWidth={480}>
      <DialogTitleText>검사를 반려하시겠습니까?</DialogTitleText>
      <DialogMessageText>
        반려를 진행하시면 되돌릴 수 없습니다.
      </DialogMessageText>
      <DialogContentContainer>
        <TextAreaInput
          placeholder={'사유 입력'}
          style={{
            width: 440,
            height: 148,
          }}
          height={110}
          maxLength={200}
          resize={'none'}
          value={inputMessage}
          onChange={onInputChange}
          helperRightText={`${inputMessage.length}/200`}
        />
      </DialogContentContainer>
      <DialogButtonWrapper>
        <TextButton
          textColor={theme.color.MEDIUM_DARK}
          title={'취소'}
          onClick={onClose}
        />
        <TextButton
          title={'확인'}
          onClick={() => {
            if (typeof handleRevertToPartnerEditDone === 'function') {
              handleRevertToPartnerEditDone({
                ecgTestId,
                statusUpdatedBy: inputMessage,
                callback,
              });
            }
          }}
        />
      </DialogButtonWrapper>
    </DialogWrapper>
  );
}
export default RevertToPartnerEditDoneDialog;
