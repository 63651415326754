import Const from './Const';

export const sortTarget = {
  createdAt: 'createdAt', // 검사 생성
  startDatetime: 'startDatetime', // 시작일
  estimatedEndDatetime: 'estimatedEndDatetime', // 종료예정일
  uploadDatetime: 'uploadDatetime', // 업로드 시점
  patchecgStartTimestamp: 'startTimestamp', // 시작일
  patchecgEndTimestamp: 'endTimestamp', // 종료일
  internalConfirmDatetime: 'internalConfirmDatetime', // 분석 완료일
  customerConfirmDatetime: 'customerConfirmDatetime', // 검토 완료일
  recordingTime: 'recordingTime', // 실 측정기간
  estimatedInternalConfirmDatetime: 'estimatedInternalConfirmDatetime', // 전달기한
  eventCounts: 'eventCounts', // 총 이벤트 수
  estimatedReportDeliveryDatetime: 'estimatedReportDeliveryDatetime', // 변경된 전달기한을 포함한 전달기한

  // ecg-test v2에서 사용되는 ordering list
  patientName: 'patientName',
  patientNumber: 'patientNumber',
  deviceNumber: 'deviceNumber',
  testStatus: 'testStatus',
  endDatetime: 'endDatetime',
  prescriptionDuration: 'prescriptionDuration',
};

export const tableSortInfoByStep = {
  onGoingTest: {
    default: { [sortTarget.createdAt]: Const.ORDER_TYPE.DESCENDING },
    sortTarget: {
      [sortTarget.startDatetime]: sortTarget.startDatetime,
      [sortTarget.estimatedEndDatetime]: sortTarget.estimatedEndDatetime,
    },
  },
  testReviewing: {
    default: {
      [sortTarget.customerConfirmDatetime]: Const.ORDER_TYPE.DESCENDING,
    },
    sortTarget: {
      [sortTarget.patchecgStartTimestamp]: sortTarget.patchecgStartTimestamp,
      [sortTarget.patchecgEndTimestamp]: sortTarget.patchecgEndTimestamp,
      [sortTarget.internalConfirmDatetime]: sortTarget.internalConfirmDatetime,
    },
  },
  testConfirmed: {
    default: { [sortTarget.uploadDatetime]: Const.ORDER_TYPE.DESCENDING },
    sortTarget: {
      [sortTarget.patchecgStartTimestamp]: sortTarget.patchecgStartTimestamp,
      [sortTarget.patchecgEndTimestamp]: sortTarget.patchecgEndTimestamp,
    },
  },
};

export const tableSortInfo = {
  default: {
    [sortTarget.eventCounts]: Const.ORDER_TYPE.DESCENDING,
    [sortTarget.uploadDatetime]: Const.ORDER_TYPE.NONE,
    [sortTarget.estimatedReportDeliveryDatetime]: Const.ORDER_TYPE.DESCENDING,
  },
  sortTarget: {
    [sortTarget.eventCounts]: sortTarget.eventCounts,
    [sortTarget.uploadDatetime]: sortTarget.uploadDatetime,
    [sortTarget.estimatedReportDeliveryDatetime]:
      sortTarget.estimatedReportDeliveryDatetime,
  },
};

export const allDefaultSort = {
  [sortTarget.createdAt]: Const.ORDER_TYPE.DESCENDING,
};
