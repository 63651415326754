import { THIRTY_SEC_WAVEFORM_LENGTH, isGeneralSection } from './common';

/**
 *
 * @param {*} rootState
 * @param {WaveformIndex} onsetWaveformIndex
 * @param {WaveformIndex} terminationWaveformIndex
 * @returns
 */
export const selectSubBeatEventInfoMap = (
  rootState,
  onsetWaveformIndex,
  terminationWaveformIndex
) => {
  const beatsNEctopicData = rootState.testResultReducer.beatsNEctopicList.data;
  if (
    onsetWaveformIndex === undefined ||
    terminationWaveformIndex === undefined ||
    Number.isNaN(onsetWaveformIndex + terminationWaveformIndex) ||
    Object.keys(beatsNEctopicData).length === 0
  ) {
    return {};
  }

  const testArray = [];
  if (testArray.includes(onsetWaveformIndex)) {
    // debugger;
    // console.log(onsetWaveformIndex, 'select beat event');
  }
  const preOnsetWaveformIndex =
    onsetWaveformIndex - (onsetWaveformIndex % THIRTY_SEC_WAVEFORM_LENGTH);
  const postOnsetWaveformIndex = isGeneralSection(
    onsetWaveformIndex,
    terminationWaveformIndex
  )
    ? preOnsetWaveformIndex
    : terminationWaveformIndex -
      (terminationWaveformIndex % THIRTY_SEC_WAVEFORM_LENGTH);
  return {
    onsetWaveformIndex,
    [preOnsetWaveformIndex]:
      rootState.testResultReducer.beatsNEctopicList.data[preOnsetWaveformIndex],
    [postOnsetWaveformIndex]:
      rootState.testResultReducer.beatsNEctopicList.data[
        postOnsetWaveformIndex
      ],
  };
};

/**
 *
 * @param {*} rootState
 * @param {WaveformIndex} onsetWaveformIndex
 * @param {WaveformIndex} terminationWaveformIndex
 * @param {Timestamp} recordingStartMs
 * @param {Boolean} withoutTimeEvents
 * @returns
 */
export const selectSubTimeEventList = (
  rootState,
  onsetWaveformIndex,
  terminationWaveformIndex,
  recordingStartMs,
  withoutTimeEvents
) => {
  if (
    onsetWaveformIndex === undefined ||
    terminationWaveformIndex === undefined ||
    Number.isNaN(onsetWaveformIndex + terminationWaveformIndex)
  ) {
    return [];
  }

  const localOnsetMs = recordingStartMs + onsetWaveformIndex * 4;
  const localTerminationMs = recordingStartMs + terminationWaveformIndex * 4;

  const leadOffList = rootState.testResultReducer.timeEventsList.leadOff.filter(
    (value) =>
      // ECG Strip 구간과 중첩되는 시간의 Arrhythmia Event 검색
      !(
        localTerminationMs <= value.onsetMs ||
        value.terminationMs <= localOnsetMs
      )
  );
  const timeEventList = withoutTimeEvents
    ? []
    : rootState.testResultReducer.timeEventsList.data.filter(
        (value) =>
          // ECG Strip 구간과 중첩되는 시간의 Arrhythmia Event 검색
          !(
            localTerminationMs <= value.onsetMs ||
            value.terminationMs <= localOnsetMs
          )
      );
  return [...leadOffList, ...timeEventList];
};
