import { CHART_CONST, CLASS_NAME_CHART_BASE } from 'constant/ChartConst';
import {
  SELECTION_MARKER_TYPE,
  TEN_SEC_STRIP,
  ECG_CHART_UNIT,
  MOUSE_EVENT_TYPE,
} from 'constant/ChartEditConst';
import {} from 'constant/EventConst';

import AppColors, { getThemeColor } from 'theme/AppColors';

import { getStringFloat } from './NumberUtil';
import eventMarkerRenderer from './ChartRenderUtil/eventMarkerRenderer';
import beatButtonRenderer from './ChartRenderUtil/beatButtonRenderer';
import beatMarkerRenderer from './ChartRenderUtil/beatMarkerRenderer';
import findingRpeakButtonRenderer from './ChartRenderUtil/findingRpeakButtonRenderer';
import { CaliperUtil } from './CaliperUtil';
import chartEdit from './ChartEditUtil/ChartEditUtil';

const THIRTY_SEC_WAVEFORM_LENGTH = ECG_CHART_UNIT.THIRTY_SEC_WAVEFORM_IDX;

const {
  renderBeatButtonList,
  renderBeatButton,
  onClickBeatButton,
  onMouseLeaveBeatButton,
  initBeatButtonList,
} = beatButtonRenderer;

const { renderEventMarker, renderHighlighter } = eventMarkerRenderer;

const { renderBeatMarker, _renderBeatMarker } = beatMarkerRenderer;
const { renderFindingRpeakButtonList, renderFindingRpeakButton } =
  findingRpeakButtonRenderer;

const ChartUtil = {
  /**********************************/
  /*  render 10s strip beat button  */
  /**********************************/
  renderBeatButtonList,
  renderBeatButton,
  onClickBeatButton, // 10s strip beat button click callback 함수
  onMouseLeaveBeatButton, // 10s strip beat button mouseleave callback 함수
  initBeatButtonList, // 10s strip beat button 목록 초기화

  /************************/
  /*  render event marker */
  /************************/
  renderEventMarker,
  renderHighlighter, // render event marker에서 사용되는 highlight 부분

  /***********************************************/
  /*  render 30s strip에서 사용하는 beat marker  */
  /***********************************************/
  renderBeatMarker,
  _renderBeatMarker,

  /**********************************************************************/
  /*  render pattern matching mode, 30s strip에서 사용하는 beat marker  */
  /**********************************************************************/
  renderFindingRpeakButtonList,
  renderFindingRpeakButton,

  // 차트 편집 관련
  chartEdit,
  // 캘리퍼 관련
  CaliperUtil,

  renderBorderGrid: (
    //
    chart,
    xAxisTickLength,
    lineWidth,
    xIntervals,
    theme
  ) => {
    const { x, y, width, height } = chart.plotBox;
    const yIntervals = 6;

    const renderer = chart.renderer;
    const yInterval = (height - lineWidth) / yIntervals;
    const xInterval = (width - lineWidth) / xIntervals;

    // Grid
    if (chart.huinnoGrid) {
      chart.huinnoGrid.destroy();
    }
    chart.huinnoGrid = renderer
      .g()
      .attr({
        class: 'huinno-chart-grid-g',
        zIndex: 0,
      })
      .add();

    const minorYPath = [];
    for (let i = 1; i < yIntervals; i++) {
      minorYPath.push(
        'M',
        x + lineWidth / 2,
        y + lineWidth / 2 + i * yInterval,
        'H',
        x + width
      );
    }
    renderer
      .path([...minorYPath, 'Z'])
      .attr({
        className: 'huinno-chart-grid-h-path',
        stroke: theme.color.MEDIUM_LIGHT,
        'stroke-width': lineWidth,
      })
      .add(chart.huinnoGrid);

    const minorXPath = [];
    const majorXPath = [];
    for (let i = 1; i < xIntervals; i++) {
      if (i % 5 !== 0) {
        minorXPath.push(
          'M',
          x + lineWidth / 2 + i * xInterval,
          y + lineWidth / 2,
          'V',
          y + height
        );
      } else {
        majorXPath.push(
          'M',
          x + lineWidth / 2 + i * xInterval,
          y + lineWidth / 2,
          'V',
          y + height + xAxisTickLength
        );
      }
    }
    renderer
      .path([...minorXPath, 'Z'])
      .attr({
        className: 'huinno-chart-grid-v-minor-path',
        stroke: theme.color.MEDIUM_LIGHT,
        'stroke-width': lineWidth,
      })
      .add(chart.huinnoGrid);
    renderer
      .path([...majorXPath, 'Z'])
      .attr({
        className: 'huinno-chart-grid-v-major-path',
        stroke: theme.color.MEDIUM,
        'stroke-width': lineWidth,
      })
      .add(chart.huinnoGrid);

    // Border
    if (chart.huinnoBorder) {
      chart.huinnoBorder.destroy();
    }
    chart.huinnoBorder = renderer
      .g()
      .attr({ class: 'huinno-chart-border-g', zIndex: 0 })
      .add();

    renderer
      .rect(x, y, width - lineWidth / 2, height - lineWidth / 2)
      .attr({
        class: 'huinno-chart-border-rect',
        stroke: theme.color.MEDIUM,
        'stroke-width': lineWidth,
        zIndex: 7,
      })
      .add(chart.huinnoBorder);
    renderer
      .path([
        'M',
        x,
        y,
        'V',
        y + height + xAxisTickLength,
        'M',
        x + width - lineWidth / 2,
        y,
        'V',
        y + height + xAxisTickLength,
        'Z',
      ])
      .attr({
        className: 'huinno-chart-border-path',
        stroke: theme.color.MEDIUM,
        'stroke-width': lineWidth,
      })
      .add(chart.huinnoBorder);
  },
  renderBorderDecoration: (chart) => {
    const {
      x: plotX,
      y: plotY,
      width: plotWidth,
      height: plotHeight,
    } = chart.plotBox;
    const strokeWidth = 0.5;

    if (chart.borderDecoration) {
      chart.borderDecoration.destroy();
    }
    chart.borderDecoration = chart.renderer
      .path()
      .attr({
        fill: 'none',
        'stroke-width': strokeWidth,
        stroke: AppColors.COOL_GRAY_70,
        d: `M 0,0 v ${plotHeight} M ${plotWidth},0 v ${plotHeight}`,
        transform: `translate(${plotX - strokeWidth / 2}, ${
          plotY - strokeWidth / 2
        })`,
        class: `huinno-long-term-border-decoration`,
        zIndex: 2,
      })
      .add();
  },
  /**********************************/
  /*  render patient event button  */
  /**********************************/
  renderPatientEventButton: (
    chart,
    {
      //
      patientEventId,
      position,
      waveformIndex,
      buttonClickEventHandler,
    }
  ) => {
    const { x: plotX, y: plotY, width: plotWidth } = chart.plotBox;
    const widthPerPoint = plotWidth / THIRTY_SEC_WAVEFORM_LENGTH;

    /** width: 16, height: 17 */
    const patientEventMarkerPath =
      'M2 0C0.895431 0 0 0.895431 0 2V10.8915C0 11.5811 0.355238 12.222 0.940001 12.5875L6.94 16.3375C7.58854 16.7428 8.41146 16.7428 9.06 16.3375L15.06 12.5875C15.6448 12.222 16 11.5811 16 10.8915V2C16 0.895431 15.1046 0 14 0H2ZM6.5875 5.4125C6.97917 5.80417 7.45 6 8 6C8.55 6 9.02083 5.80417 9.4125 5.4125C9.80417 5.02083 10 4.55 10 4C10 3.45 9.80417 2.97917 9.4125 2.5875C9.02083 2.19583 8.55 2 8 2C7.45 2 6.97917 2.19583 6.5875 2.5875C6.19583 2.97917 6 3.45 6 4C6 4.55 6.19583 5.02083 6.5875 5.4125ZM4 8.6V10H12V8.6C12.0003 8.317 11.9275 8.05667 11.7815 7.819C11.6355 7.58133 11.4417 7.4 11.2 7.275C10.6833 7.017 10.1583 6.82333 9.625 6.694C9.09167 6.56467 8.55 6.5 8 6.5C7.45 6.49967 6.90833 6.56417 6.375 6.6935C5.84167 6.82283 5.31667 7.01667 4.8 7.275C4.55867 7.39967 4.365 7.58083 4.219 7.8185C4.073 8.05617 4 8.31667 4 8.6Z';
    const xPixel = plotX + widthPerPoint * waveformIndex - 8;
    const yPixel = plotY - 14;

    const pteCoverBaseAttr = {
      class: `patient-icon-back patientTrigger-back-${waveformIndex}`,
      d: patientEventMarkerPath,
      transform: `translate(${xPixel}, ${yPixel})`,
      cursor: 'pointer',
      'pointer-events': 'auto',
      fill: '#fff',
      'fill-opacity': '0.001',
      zIndex: 1,
    };
    const pteCover = chart.renderer
      .path()
      .attr(pteCoverBaseAttr)
      .add(chart.patientButtonGroup);
    pteCover.element.addEventListener('click', function (event) {
      if (!pteButton.isSelected && pteButton.isClickable) {
        buttonClickEventHandler(event);
      }
    });

    const pteButtonBaseAttr = {
      class: `patient-icon-button patientTrigger-button-${waveformIndex}`,
      d: patientEventMarkerPath,
      'fill-rule': 'evenodd',
      'clip-rule': 'evenodd',
      transform: `translate(${xPixel}, ${yPixel})`,
    };
    const pteButton = chart.renderer.path().add(chart.patientButtonGroup);
    pteButton.position = position;
    pteButton.patientEventId = patientEventId;
    pteButton.isReportIncluded = false;
    pteButton.isSelected = false;
    pteButton.isClickable = false;

    const patientButtonStyleLookUp = [
      [
        { fill: '#000000', 'fill-opacity': '0.28' },
        { fill: '#565A5E', 'fill-opacity': '1' },
      ],
      [
        { fill: '#426AFF', 'fill-opacity': '0.5' },
        { fill: '#426AFF', 'fill-opacity': '1' },
      ],
    ];
    pteButton.setPTEButtonState = function (
      isReportIncluded,
      isSelected,
      isClickable
    ) {
      pteButton.attr({
        ...pteButtonBaseAttr,
        ...patientButtonStyleLookUp[Number(isReportIncluded)][
          Number(isSelected)
        ],
      });
      pteCover.attr({
        ...pteCoverBaseAttr,
        'pointer-events': isClickable ? 'auto' : 'none',
      });

      pteButton.isReportIncluded = isReportIncluded;
      pteButton.isSelected = isSelected;
      pteButton.isClickable = isClickable;
    };

    pteButton.setPTEButtonState(false, false, false);
    return pteButton;
  },
  /**
   * 하이차트에 render하는 요소를 g element추가해 하위에 render할 수 있는 환경 세팅
   * 세팅하려고하는 그룹이 이미 존재하는 경우, 해당 그룹을 제거하고 다시 생성합니다.
   *
   * @param {Object} chartInst - 렌더러를 포함하는 차트 인스턴스입니다.
   * @param {Object} options - SVG 그룹에 대한 설정 객체입니다.
   * @param {string | string[]} options.svgGroupProperty - 초기화할 단일 그룹 이름 또는 그룹 이름의 배열입니다.
   * @param {number} [options.zIndex=4] - SVG 그룹의 z-index 값입니다.
   */
  initSVGGroup: (chartInst, { svgGroupProperty: svgGrpProp, zIndex = 4 }) => {
    if (!chartInst) return;

    let svgGrpProps = Array.isArray(svgGrpProp) ? svgGrpProp : [svgGrpProp];
    for (let svgGrpProp of svgGrpProps) {
      if (chartInst[svgGrpProp]) {
        chartInst[svgGrpProp].destroy();
        delete chartInst[svgGrpProp];
      }

      chartInst[svgGrpProp] = chartInst.renderer
        .g()
        .attr({
          class: svgGrpProp,
          zIndex,
        })
        .add();
    }
  },
  checkRenderedChart: (chart) => isNaN(chart.xAxis[0].toPixels(0)),
  chartEditStore: {
    [SELECTION_MARKER_TYPE.ONSET]: {
      representativeTimestamp: undefined,
      representativeWaveformIndex: undefined,
      clickedWaveformIndex: undefined,
      clickedTimestamp: undefined,
    },
    [SELECTION_MARKER_TYPE.TERMINATION]: {
      representativeTimestamp: undefined,
      representativeWaveformIndex: undefined,
      clickedWaveformIndex: undefined,
      clickedTimestamp: undefined,
    },
  },
};

export function getTenSecStripParam(
  event,
  representativeCenterTimeStamp,
  representativeCenterWaveformIndex
) {
  const maxXaxis = CHART_CONST.XAXIS_MAX;
  const { clickedWaveformIndex } = event;
  const MAX_SECOND = 30;

  const xAxisLocationScale = maxXaxis / MAX_SECOND;
  const fiveSecWaveformIndexScale = xAxisLocationScale * 5;

  const startTenSecStripWaveformIndex =
    clickedWaveformIndex - fiveSecWaveformIndexScale;
  const endTenSecStripWaveformIndex =
    clickedWaveformIndex + fiveSecWaveformIndexScale;

  let mainTenSecStrip = {
    type: TEN_SEC_STRIP.TYPE.MAIN,
    position: TEN_SEC_STRIP.POSITION.NONE,
    representativeTimestamp: representativeCenterTimeStamp,
    onsetWaveformIndex: startTenSecStripWaveformIndex,
    terminationWaveformIndex: endTenSecStripWaveformIndex,
  };

  let extraTenSecStrip = {
    type: TEN_SEC_STRIP.TYPE.EXTRA,
    position: TEN_SEC_STRIP.POSITION.NONE,
    representativeTimestamp: undefined,
    onsetWaveformIndex: undefined,
    terminationWaveformIndex: undefined,
  };

  // setting - mainTenSecStrip
  if (startTenSecStripWaveformIndex < 0) {
    mainTenSecStrip.onsetWaveformIndex = 0;
    mainTenSecStrip.terminationWaveformIndex = endTenSecStripWaveformIndex;
  } else if (endTenSecStripWaveformIndex > maxXaxis) {
    mainTenSecStrip.onsetWaveformIndex = startTenSecStripWaveformIndex;
    mainTenSecStrip.terminationWaveformIndex = maxXaxis;
  }

  // setting - extraTenSecStrip
  if (startTenSecStripWaveformIndex < 0) {
    Object.assign(extraTenSecStrip, {
      position: TEN_SEC_STRIP.POSITION.PREV,
      representativeTimestamp: representativeCenterTimeStamp - 1000 * 30,
      onsetWaveformIndex: maxXaxis + startTenSecStripWaveformIndex,
      terminationWaveformIndex: maxXaxis,
    });
  } else if (endTenSecStripWaveformIndex > maxXaxis) {
    Object.assign(extraTenSecStrip, {
      position: TEN_SEC_STRIP.POSITION.NEXT,
      representativeTimestamp: representativeCenterTimeStamp + 1000 * 30,
      onsetWaveformIndex: 0,
      terminationWaveformIndex: endTenSecStripWaveformIndex - maxXaxis,
    });
  }

  const tenSecStripParam = {
    representativeCenterTimeStamp: representativeCenterTimeStamp,
    representativeCenterWaveformIndex: representativeCenterWaveformIndex,
    centerWaveformIndex: clickedWaveformIndex,
    [TEN_SEC_STRIP.TYPE.MAIN]: mainTenSecStrip,
    [TEN_SEC_STRIP.TYPE.EXTRA]: extraTenSecStrip,
  };
  return tenSecStripParam;
}

/**
 * ECG 데이터를 시각화한 Highcharts 의 chartX 값을 **localWaveformIndex 로 변환**
 *
 * @param {number} chartX 렌더된 Highcharts Element 의 X 축 좌표값
 * @param {{x:number, y:number, width:number, height:number}} plotBox 렌더된 Highcharts Inst. 의 property 중 plotBox
 * @param {number} waveformLength 렌더된 Highcharts Element 에 사용된 ECG Waveform 길이
 * @return {number} chartX 에 해당하는 **localWaveformIndex**, 오류 시 -1
 */
export function transformSystemChartXToWaveformIndex(
  chartX,
  plotBox,
  waveformLength
) {
  try {
    const aWaveformWidth = plotBox.width / waveformLength;
    const plotX = chartX - plotBox.x;
    return Math.round(plotX / aWaveformWidth);
  } catch (error) {
    console.error('transformSystemChartXToWaveformIndex', error);
  }
  return -1;
}

/**
 * localWaveformIndex 값을 ECG 데이터를 시각화한 Highcharts 의 **chartX 로 변환**
 *
 * @param {number} localWaveformIndex 렌더된 Highcharts Element 에 사용된 ECG Waveform 중 특정 index
 * @param {{x:number, y:number, width:number, height:number}} plotBox 렌더된 Highcharts Inst. 의 property 중 plotBox
 * @param {number} waveformLength 렌더된 Highcharts Element 에 사용된 ECG Waveform 길이
 * @return {number} localWaveformIndex 에 해당하는 **chartX**, 오류 시 -1
 */
export function transformSystemWaveformIndexToChartX(
  localWaveformIndex,
  plotBox,
  waveformLength
) {
  try {
    const aWaveformWidth = plotBox.width / waveformLength;
    return Math.round(localWaveformIndex * aWaveformWidth + plotBox.x);
  } catch (error) {
    console.error('transformSystemWaveformIndexToChartX', error);
  }
  return -1;
}

export const ECGChartCommonOption = {
  tooltip: {
    enabled: false,
    formatter: function () {
      return `${this.x / 250}sec ${getStringFloat(this.y, 2)} mV`;
    },
  },
  boost: {
    // Need to consider whether use
    // enabled: true,
    useGPUTranslations: true,
    // usePreallocated: true,
  },
  title: null,
  legend: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
};

const xAxisFrom = -100;
const xAxisTo = THIRTY_SEC_WAVEFORM_LENGTH + 100;
const xAxisStep = 50;
const yAxisFrom = -3;
const yAxisTo = 4;
const yAxisStep = 0.5;
const isHalfEven = (value) => Math.floor(value / 2) % 2 === 0;
export const ecgChartGridSeries30 = [
  {
    data: (() => [
      ...Array.from({ length: 2 * 15 }, (v, k) => [
        isHalfEven(k + 1) ? xAxisFrom : xAxisTo,
        yAxisFrom + yAxisStep * Math.floor(k / 2),
      ]),
      ...Array.from({ length: 2 * 3 * 10 * 4 }, (v, k) => [
        xAxisStep * (Math.floor(k / 2) + Math.floor(k / 2 / 4) + 1),
        isHalfEven(k + 1) ? yAxisTo : yAxisFrom,
      ]),
    ])(),
    className: `huinno-minor-grid ${CLASS_NAME_CHART_BASE}`,
    name: 'huinno-grid',
    lineWidth: 0.5,
    animation: false,
    connectEnds: false,
    color: getThemeColor('COOL_GRAY_40'),
    zoneAxis: 'x',
    enableMouseTracking: false,
    zIndex: -1,
    isGridSeries: true,
  },
  {
    data: (() => [
      ...Array.from({ length: 2 * 3 * 9 }, (v, k) => [
        xAxisStep * 5 * (Math.floor(k / 2) + Math.floor(k / 2 / 9) + 1),
        isHalfEven(k + 1) ? yAxisTo : yAxisFrom,
      ]),
    ])(),
    className: `huinno-normal-grid ${CLASS_NAME_CHART_BASE}`,
    name: 'huinno-grid',
    lineWidth: 0.5,
    animation: false,
    connectEnds: false,
    color: AppColors.COOL_GRAY_50,
    zoneAxis: 'x',
    enableMouseTracking: false,
    zIndex: -1,
    isGridSeries: true,
  },
  {
    data: (() => [
      ...Array.from({ length: 2 * 4 }, (v, k) => [
        xAxisStep * 5 * 10 * Math.floor(k / 2),
        isHalfEven(k + 1) ? yAxisTo : yAxisFrom,
      ]),
    ])(),
    className: `huinno-major-grid ${CLASS_NAME_CHART_BASE}`,
    name: 'huinno-grid',
    lineWidth: 0.5,
    animation: false,
    connectEnds: false,
    color: AppColors.COOL_GRAY_70,
    zoneAxis: 'x',
    enableMouseTracking: false,
    zIndex: -1,
    isGridSeries: true,
  },
];
export const ecgChartGridSeries10 = [
  {
    data: (() => [
      ...Array.from({ length: 2 * 15 }, (v, k) => [
        isHalfEven(k + 1) ? xAxisFrom : xAxisTo,
        yAxisFrom + yAxisStep * Math.floor(k / 2),
      ]),
      ...Array.from({ length: 2 * 10 * 4 }, (v, k) => [
        xAxisStep * (Math.floor(k / 2) + Math.floor(k / 2 / 4) + 1),
        isHalfEven(k + 1) ? yAxisTo : yAxisFrom,
      ]),
    ])(),
    className: `huinno-minor-grid ${CLASS_NAME_CHART_BASE}`,
    name: 'huinno-grid',
    lineWidth: 0.5,
    animation: false,
    connectEnds: false,
    color: getThemeColor('COOL_GRAY_40'),
    zoneAxis: 'x',
    enableMouseTracking: false,
    zIndex: -1,
  },
  {
    data: (() => [
      ...Array.from({ length: 2 * 11 }, (v, k) => [
        xAxisStep * 5 * Math.floor(k / 2),
        isHalfEven(k + 1) ? yAxisTo : yAxisFrom,
      ]),
    ])(),
    className: `huinno-normal-grid ${CLASS_NAME_CHART_BASE}`,
    name: 'huinno-grid',
    lineWidth: 0.5,
    animation: false,
    connectEnds: false,
    color: AppColors.COOL_GRAY_50,
    zoneAxis: 'x',
    enableMouseTracking: false,
    zIndex: -1,
  },
];

/**
 * ms 단위인 interval로 bpm 구하기
 *
 * @param {*} interval
 * @returns
 */
export function convertMsToBpm(interval) {
  if (interval === 0) return 0;

  // 공식 : 60 * 1000 / RR-Interval
  const BASE_SIXTY = 60;
  const ONE_SEC_TO_MILLISECONDS = 1000;

  // MEMO 서비스는 HR계산시 소수점 첫번째 자리 반올림 (sync with BackEnd)
  return Math.round((BASE_SIXTY * ONE_SEC_TO_MILLISECONDS) / interval);
}

export function convertMsToSec(ms) {
  return ms / 1000;
}

export function isRightClick(event) {
  return (
    event.button === MOUSE_EVENT_TYPE.RIGHT_CLICK_BUTTON ||
    event.buttons === MOUSE_EVENT_TYPE.RIGHT_CLICK_BUTTON
  );
}

export function isLeftClick(event) {
  return event.buttons === MOUSE_EVENT_TYPE.LEFT_CLICK_BUTTON;
}

export default ChartUtil;
