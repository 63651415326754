import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';

import Const from 'constant/Const';
import {
  CHART_EDIT_CONST,
  TEN_SEC_STRIP_DETAIL,
  ECG_CHART_UNIT,
  AMPLITUDE_OPTION,
} from 'constant/ChartEditConst';
import { ComponentId } from 'constant/ComponentId';
import { CLASS_CONST } from 'constant/ClassConst';
import { REPORT_EVENT_EDITOR_STEP_MAP } from 'constant/ReportConst';
import LocalStorageKey from 'constant/LocalStorageKey';
import { ShapeReviewOrderFilterClickedElements } from 'constant/ShapeReviewConst';
import { CHART_CONST } from 'constant/ChartConst';
import { PATTERN_MATCHING_CONST } from 'constant/PatternMatchingConst';

import { getParentComponent } from 'util/Utility';
import ChartUtil from 'util/ChartUtil';
import {
  getCaliperActionCreatorDuckMap,
  selectCaliperDuckMap,
  selectTenSecStripDuckMap,
} from 'util/selectTenSecDuckMap';
import {
  areBeatLabelButtonDataListEqual,
  generateReviewState,
} from 'util/TenSecStripDetailUtil';

import useShallowEqualSelector from 'component/hook/useShallowEqualSelector';
import usePrevious from 'component/hook/usePrevious';
import usePatternMatchingAction from 'component/hook/usePatternMatching/usePatternMatchingAction';

import TenSecStripDetail from 'component/fragment/test-result/share/tenSecStripDetail';

import {
  selectReportEventEditorState,
  setBeatContextmenuRequest,
} from 'redux/duck/testResultDuck';
import LocalStorageManager from 'manager/LocalStorageManager';
import { setOrderingContextmenuRequest } from 'redux/duck/shapeReviewDuck';

const CLASS_CONST_UTIL_CALIPER_GROUP = CLASS_CONST.CALIPER.GROUP;

function TenSecStripDetailContainer({
  tenSecStripTabValue,
  isFocusPatternSimilarity = false,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    editState: { isPatternMatchingEnabled },
    findingPatternTargetRange,
    similarPatternData: { pending: findPatternPending },
    rpeakList: { pending: findRpeakPending },
    editedWaveformIndexes: { pending: editedWaveformIndexPending },
  } = usePatternMatchingAction();
  const patternMatchingPending = findPatternPending || findRpeakPending;

  // todo: jyoon - TEN_SEC_STRIP_DETAIL.TAB으로 시작하는 변수명 아래 isTabName으로 변경
  const reviewCurrentTabState = generateReviewState(tenSecStripTabValue);
  const { isHrReview, isEventReview, isBeatReview, isShapeReview } =
    reviewCurrentTabState;

  /* redux state */
  const tenSecStripDetail = useShallowEqualSelector(
    (state) => selectTenSecStripDuckMap(state)[tenSecStripTabValue],
    (prev, next) => {
      if (isHrReview) return false;

      const resultCompareArrays = areBeatLabelButtonDataListEqual(
        prev.beatLabelButtonDataList,
        next.beatLabelButtonDataList
      );

      return resultCompareArrays;
    }
  );
  const beatReviewSidePanelState = useShallowEqualSelector(
    (state) =>
      tenSecStripTabValue === TEN_SEC_STRIP_DETAIL.TAB.BEAT_REVIEW &&
      state.beatReviewReducer.sidePanelState
  );
  const orderingContextMenu = useShallowEqualSelector(
    (state) =>
      state.shapeReviewReducer.shapeReviewState.isOrderingBeatContextmenu
  );

  const caliperPlotLines = useShallowEqualSelector(
    (state) => selectCaliperDuckMap(state)[tenSecStripTabValue].caliperPlotLines
  );
  const isCaliperMode = useShallowEqualSelector(
    (state) => selectCaliperDuckMap(state)[tenSecStripTabValue].isCaliperMode
  );
  const isTickMarksMode = useShallowEqualSelector(
    (state) => selectCaliperDuckMap(state)[tenSecStripTabValue].isTickMarksMode
  );
  /** @type {import('constant/ReportConst').ReportEventEditorState} ReportEventEditor 모듈의 Global State */
  const reportEventEditorState = useShallowEqualSelector(
    selectReportEventEditorState
  );
  const { editorStep: reportEventEditorStep, selectedAmplitudeRate } =
    reportEventEditorState;

  /* local state */
  const [chartOption, setChartOption] = useState(() => {
    const storedLastViewedTidState = LocalStorageManager.getItem(
      LocalStorageKey.LAST_VIEWED_TID_STATE
    );

    return {
      tabType: tenSecStripTabValue,
      chart: {
        plotBorderColor: theme.color.PRIMARY_BLUE,
        plotBorderWidth: 2,
        amplitude:
          storedLastViewedTidState?.tenSecStripAmplitudeRate ??
          AMPLITUDE_OPTION.TWENTY_MV.RATE,
      },
      editModeType: TEN_SEC_STRIP_DETAIL.EDIT_MODE.INIT,
      isCaliperMode: false,
      isTickMarksMode: false,
      isReportRepresentativeStripChangeMode: false,
      component: {
        hasMoveBtn: true,
        hasTickMarks: false,
        hasCaliper: true,
        hasBeatOptions: true,
      },
    };
  });
  const [selectedBeatBtnInfoList, setSelectedBeatBtnInfoList] = useState([]); // { waveformIndex: undefined, beatType: undefined }
  const [selectedBeatOption, setSelectedBeatOption] = useState(null); // CONTEXT_MENU.BEAT_TYPE[NORMAL|APC|VPC|QUESTIONABLE].index
  const tenSecStripChartRef = useRef(null);
  const chartInst = tenSecStripChartRef.current?.chart;

  /* redux action creator */
  const setBeatContextmenu = (payload) =>
    dispatch(setBeatContextmenuRequest(payload));
  const handleOrderingContextmenu = (isOpenOrderingContextmenu) =>
    dispatch(setOrderingContextmenuRequest(isOpenOrderingContextmenu));

  const _setCaliperPlotLines = (caliperPlotLines) =>
    dispatch(
      getCaliperActionCreatorDuckMap('setCaliperPlotLines', caliperPlotLines)[
        tenSecStripTabValue
      ]()
    );
  const _setIsCaliperMode = (boolean) =>
    dispatch(
      getCaliperActionCreatorDuckMap('setIsCaliperMode', boolean)[
        tenSecStripTabValue
      ]()
    );
  const _setIsTickMarksMode = (boolean) =>
    dispatch(
      getCaliperActionCreatorDuckMap('setIsTickMarksMode', boolean)[
        tenSecStripTabValue
      ]()
    );

  /* useEffect */
  const prevTenSecStripDetail = usePrevious(tenSecStripDetail);

  /******************************************************************************************************/
  /* event Listener                                                                                     */
  /*  ㄴ click(validation - 비트 선택 취소 interaction, shape-review에서는 컨텍스트 메뉴 hide)          */
  /*  ㄴ keydown (ArrowDown, ArrowUp, ArrowLeft, ArrowRight) - AddBeat Mode                             */
  /******************************************************************************************************/
  // [event Listener] click(validation - 비트 선택 취소 interaction, shape-review에서는 컨텍스트 메뉴 hide)
  useEffect(() => {
    function onClick(e) {
      e.stopPropagation();
      if (!e.target.parentElement) return;

      const cancelSelectedChartExclusionList = [
        CHART_CONST.HIGHCHARTS_BUTTON_HOVER,
        CHART_CONST.HIGHCHARTS_BUTTON_PRESSED,
      ];
      const cancelSelectedPatternMatchingExclusionList = [
        PATTERN_MATCHING_CONST.PATTERN_MATCHING_SIMILARITY_INPUT,
        PATTERN_MATCHING_CONST.PATTERN_MATCHING_NAVIGATION_BUTTON,
        PATTERN_MATCHING_CONST.PATTERN_MATCHING_NAVIGATION_INPUT,
        CLASS_CONST.DIALOG,
      ];
      const chartValidation = cancelSelectedChartExclusionList.some((v) =>
        [...e.target.parentElement?.classList].includes(v)
      );
      const patternMatchingValidation =
        cancelSelectedPatternMatchingExclusionList.some((v) =>
          [...e.target.parentElement?.classList].includes(v)
        );
      const validation = chartValidation || patternMatchingValidation;

      if (validation) return;
      const result = getParentComponent(
        e.target,
        ComponentId.TEN_SEC_STRIP_DETAIL_CHART
      );

      // shape review에만 필요한 로직
      // shape review 정렬 관련하여 10s에 클릭 이벤트가 발생 시 컨텍스트 메뉴를 hide 시키는 로직
      if (isShapeReview) {
        const isClickedOrderingLabel =
          e.target.dataset.testid ===
            ShapeReviewOrderFilterClickedElements.SORT_LABEL_WRAPPER ||
          e.target.dataset.testid ===
            ShapeReviewOrderFilterClickedElements.SORT_LABEL;

        if (!isClickedOrderingLabel) {
          handleOrderingContextmenu(false);
        }
      }

      if (!result && tenSecStripChartRef.current) {
        // 비트 선택 취소 interaction
        initTenSecStripDetail();

        // caliper 선택 취소
        if (isCaliperMode && caliperPlotLines.length < 2) {
          ChartUtil.CaliperUtil.resetCaliper(
            _setIsCaliperMode,
            _setIsTickMarksMode,
            _setCaliperPlotLines
          );
        }
      }
    }

    document.addEventListener('click', onClick);
    return () => {
      document.removeEventListener('click', onClick);
    };
  }, [
    chartInst,
    isCaliperMode,
    caliperPlotLines,
    orderingContextMenu,
    findingPatternTargetRange,
  ]);
  // [event Listener] keydown (ArrowDown, ArrowUp, ArrowLeft, ArrowRight) - AddBeat Mode
  useEffect(() => {
    if (chartOption.editModeType !== TEN_SEC_STRIP_DETAIL.EDIT_MODE.ADD_BEAT) {
      return;
    }

    function onKeyDownEventListener(event) {
      const isArrowKeys = [
        Const.KEY_MAP.ARROW_DOWN,
        Const.KEY_MAP.ARROW_UP,
        Const.KEY_MAP.ARROW_LEFT,
        Const.KEY_MAP.ARROW_RIGHT,
      ].includes(event.key);

      isArrowKeys && event.stopImmediatePropagation();
    }

    window.addEventListener('keyup', onKeyDownEventListener, {
      capture: true,
    });
    return () => {
      window.removeEventListener('keyup', onKeyDownEventListener, {
        capture: true,
      });
    };
  }, [chartOption.editModeType]);

  /******************************************************************************************************/
  /* setting                                                                                            */
  /*  ㄴ setChartOption - tabType에 따른 component 설정                                                 */
  /*  ㄴ setChartOption - editModeType을 ChangeBeat 로 초기화(BeatReview, HrReview에서 포지션 조회 시)  */
  /*  ㄴ setChartOption - Sync TickMarksMode With Redux state                                           */
  /*  ㄴ amplitude설정  - chartOption.chart.amplitude 와 LocalStorage 동기화                            */
  /******************************************************************************************************/
  // [setting] setChartOption - tabType에 따른 component 설정
  useEffect(() => {
    setChartOption((prev) => {
      return {
        ...prev,
        tabType: tenSecStripTabValue,
        component: {
          hasMoveBtn:
            tenSecStripTabValue === TEN_SEC_STRIP_DETAIL.TAB.EVENT_REVIEW,
          hasTickMarks: true,
          hasCaliper: true,
          hasBeatOptions: true,
        },
      };
    });
  }, [tenSecStripTabValue]);
  // [setting] 포지션 이동시 가운데 비트를 선택으로 기본 설정하는 기능
  //          - 가운데 이벤트 의미: 현재 포지션의 비트를 10s strip의 가운데로 설정
  useEffect(() => {
    // case1. 가운데 이벤트 선택으로 기본 설정하지 않는 탭
    //  - event-review, shape-review
    // case2-1. 가운데 이벤트 선택으로 기본 설정 탭 & 포지션 이동, 편집 이후에도 가운데 비트 기본 설정
    //  - hr-review, beat-review
    // case2-2. 가운데 이벤트 선택 & 포지션 이동시에만(= 편집 이후에는 가운데 비트 기본 설정하지 않음)
    //  - beat-review
    const hasTenSecStripDetailButtonList =
      tenSecStripDetail !== undefined &&
      Array.isArray(tenSecStripDetail.beatLabelButtonDataList);
    const enableInitSelectCenterEventTab = isHrReview || isBeatReview;
    const centerButtonData = tenSecStripDetail.beatLabelButtonDataList?.find(
      (buttonInfo) => buttonInfo.isSelected
    );
    if (
      !enableInitSelectCenterEventTab ||
      !hasTenSecStripDetailButtonList ||
      !centerButtonData
    ) {
      return;
    }

    // execute setting centerButtonData
    setChartOption((prev) => {
      return {
        ...prev,
        editModeType: centerButtonData
          ? TEN_SEC_STRIP_DETAIL.EDIT_MODE.CHANGE_BEAT
          : TEN_SEC_STRIP_DETAIL.EDIT_MODE.INIT,
      };
    });
    setSelectedBeatOption(centerButtonData?.beatType);
    setSelectedBeatBtnInfoList([
      {
        waveformIndex: centerButtonData.xAxisPoint,
        beatType: centerButtonData.beatType,
      },
    ]);
  }, [tenSecStripDetail, tenSecStripTabValue, beatReviewSidePanelState]);

  // [setting] setChartOption - Sync TickMarksMode With Redux state
  useEffect(() => {
    return setChartOption((prev) => {
      return {
        ...prev,
        isTickMarksMode,
      };
    });
  }, [isTickMarksMode]);
  // [setting] amplitude설정 - chartOption.chart.amplitude 와 LocalStorage 동기화
  useEffect(() => {
    const storedLastViewedTidState = LocalStorageManager.getItem(
      LocalStorageKey.LAST_VIEWED_TID_STATE
    );

    if (!storedLastViewedTidState) return;

    LocalStorageManager.setItem(LocalStorageKey.LAST_VIEWED_TID_STATE, {
      ...storedLastViewedTidState,
      tenSecStripAmplitudeRate: chartOption.chart.amplitude,
    });
  }, [chartOption.chart.amplitude]);

  /******************************************************************************************************/
  /* Report                                                                                             */
  /*  ㄴ Report 담기 - 대표 Strip 변경 모드일 때,                                                       */
  /*      chartOption.chart.amplitude, chartOption.isReportRepresentativeStripChangeMode 변경           */
  /******************************************************************************************************/
  // [Report] Report 담기 - 대표 Strip 변경 모드일 때, chartOption.chart.amplitude 변경
  useEffect(() => {
    setChartOption((prev) => {
      // Report Event 단계 중 strip title 입력 단계에서 리포트 담는 이벤트의 대표 Strip을 설정하는 단계입니다.
      // 해당 단계에서 amplitude, isReportRepresentativeStripChangeMode를 변경합니다.
      const isReportRepresentativeStripChangeMode =
        reportEventEditorStep === REPORT_EVENT_EDITOR_STEP_MAP.TITLE;

      if (isReportRepresentativeStripChangeMode) {
        return {
          ...prev,
          chart: { ...prev.chart, amplitude: selectedAmplitudeRate },
          isReportRepresentativeStripChangeMode: true,
        };
      } else {
        return {
          ...prev,
          isReportRepresentativeStripChangeMode: false,
        };
      }
    });
  }, [reportEventEditorStep, selectedAmplitudeRate]);

  /***********************/
  /*     caliperMode     */
  /***********************/

  // Sync CaliperMode With Redux
  useEffect(() => {
    if (!isCaliperMode) {
      const caliperGroup = document.querySelectorAll(
        `.${CLASS_CONST_UTIL_CALIPER_GROUP}`
      );
      caliperGroup.forEach((node) => node.remove());

      ChartUtil.CaliperUtil.resetCaliper(
        _setIsCaliperMode,
        _setIsTickMarksMode,
        _setCaliperPlotLines
      );
    }

    initTenSecStripDetail();
    setChartOption((prev) => {
      return {
        ...prev,
        isCaliperMode: isCaliperMode,
        component: {
          ...prev.component,
          hasTickMarks: isCaliperMode,
        },
      };
    });
  }, [chartInst, isCaliperMode]);
  // TenSecStrip.onsetMs가 변경되면(포지션 이동) caliper OFF
  useEffect(() => {
    if (prevTenSecStripDetail === undefined) return;

    if (prevTenSecStripDetail.onsetMs !== tenSecStripDetail?.onsetMs) {
      ChartUtil.CaliperUtil.resetCaliper(
        _setIsCaliperMode,
        _setIsTickMarksMode,
        _setCaliperPlotLines
      );
    }
  }, [tenSecStripDetail]);
  // EventReview에서 TenSecStripDetail이 unMount되면 caliper정보 초기화
  useEffect(() => {
    return () => {
      if (tenSecStripTabValue === TEN_SEC_STRIP_DETAIL.TAB.EVENT_REVIEW) {
        ChartUtil.CaliperUtil.resetCaliper(
          _setIsCaliperMode,
          _setIsTickMarksMode,
          _setCaliperPlotLines
        );
      }
    };
  }, [tenSecStripTabValue]);

  /* function */
  function initTenSecStripDetail() {
    if (!chartInst) return;

    const centerButtonData = tenSecStripDetail.beatLabelButtonDataList?.find(
      (buttonInfo) =>
        buttonInfo.xAxisPoint === ECG_CHART_UNIT.HALF_TEN_SEC_WAVEFORM_IDX
    );
    // remove vertical beat plot line
    if (tenSecStripChartRef.current) {
      chartInst.xAxis[0].removePlotLine(
        CHART_EDIT_CONST.VERTICAL_BEAT_PLOT_LINE
      );

      const chartEditUtil = ChartUtil.chartEdit(chartInst, { theme });
      // init selection strip(selection marker + selection highlight)
      chartEditUtil.removeSelectionHighlightAll();
      chartEditUtil.removeSelectionMarkerAllInTenSecStrip();
    }

    setBeatContextmenu(false); // close context menu open status
    setSelectedBeatBtnInfoList([]); // init clicked beat label waveIndex
    setSelectedBeatOption(centerButtonData?.beatType); // init clicked beat label class
    // init edit mode
    setChartOption((prev) => {
      return {
        ...prev,
        chart: {
          ...prev.chart,
          plotBorderColor: theme.color.PRIMARY_BLUE,
          plotBorderWidth: 2,
        },
        editModeType: TEN_SEC_STRIP_DETAIL.EDIT_MODE.INIT,
      };
    });
  }

  return (
    <TenSecStripDetail
      tenSecStripChartRef={tenSecStripChartRef}
      isFocusPatternSimilarity={isFocusPatternSimilarity}
      chartOption={chartOption}
      setChartOption={setChartOption}
      selectedBeatBtnInfoList={selectedBeatBtnInfoList}
      setSelectedBeatBtnInfoList={setSelectedBeatBtnInfoList}
      selectedBeatOption={selectedBeatOption}
      setSelectedBeatOption={setSelectedBeatOption}
      initTenSecStripDetail={initTenSecStripDetail}
      tenSecStripTabValue={tenSecStripTabValue}
      tenSecStripDetail={tenSecStripDetail}
      isPatternMatchingEnabled={isPatternMatchingEnabled}
      patternMatchingPending={patternMatchingPending}
      editedWaveformIndexPending={editedWaveformIndexPending}
    />
  );
}

export default TenSecStripDetailContainer;
