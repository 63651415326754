import { useRef } from 'react';
import styled from 'styled-components';

import { ReactComponent as IndeterminateIcon } from 'static/icon/ic-Indeterminate-check-box.svg';
import { ReactComponent as CheckIcon } from 'static/icon/ic-check-on.svg';

import Tooltip from '../tooltip/Tooltip';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: fit-content;
  // height: fit-content;
  max-height: 100%;

  cursor: pointer;
  * {
    box-sizing: border-box;
  }
`;

const HiddenCheckBox = styled.input.attrs({ type: 'checkbox' })`
  width: 18px;
  height: 18px;
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  opacity: 0;
  cursor: inherit;
`;

const SvgIconCheck = styled(CheckIcon)`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  position: absolute;
  left: 0;
  top: 0;
  margin: -1.5px;
  z-index: 2;
`;

const SvgIndeterminateIcon = styled(IndeterminateIcon)`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  position: absolute;
  left: 0;
  top: 0;
  margin: -1.5px;
  z-index: 2;
`;

const StyledCheckboxWrapper = styled.div`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  position: relative;

  :hover {
    .HoverBackground {
      background-color: ${(props) => props.theme.color.DIM};
    }
  }
`;

const StyledCheckbox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 100%;

  width: ${(props) => props.size};
  height: ${(props) => props.size};
  background-color: ${(props) => props.theme.color.WHITE};
  border: 1.5px solid ${(props) => props.theme.color.MEDIUM};
  border-radius: 4px;
  cursor: inherit;
  position: relative;
  min-width: 15px;
  min-height: 15px;
  z-index: 2;

  ${HiddenCheckBox}:focus {
    box-shadow: 0 0 0 3px pink;
  }
  ${SvgIconCheck} {
    display: ${(props) => (props.checked ? 'inline-block' : 'none')};
  }
`;

const Label = styled.label`
  margin-left: 4px;
  font-size: 11px;
  color: ${(props) => props.theme.color.DARK};
  cursor: inherit;
  white-space: nowrap;
`;

const CheckBoxLabelContainer = styled.label`
  font-weight: 700;
  font-size: 11px;
  line-height: 100%;
  color: ${(props) => props.theme.color.COOL_GRAY_70};
  pointer-events: none;
`;

function CheckBox(props) {
  const {
    checked,
    indeterminate = false,
    onChange,
    backgroundLabel,
    label,
    labelStyle,
    size = '18px',
    hover = false,
    hoverStyle = { padding: '2px' },
    tooltipTitle,
  } = props;

  const checkBoxRef = useRef(null);

  const handleClick = (event) => {
    event.stopPropagation();
    checkBoxRef.current.click();
  };

  let $checkbox;
  const makeBackgroundLabelEl = (backgroundLabel) => {
    return (
      <CheckBoxLabelContainer className="CheckBoxLabelContainer">
        {backgroundLabel}
      </CheckBoxLabelContainer>
    );
  };

  const makeCheckbox = ($el) => {
    let $result;
    if (checked) {
      if (indeterminate) {
        $result = <SvgIndeterminateIcon size={size} />;
      } else {
        $result = <SvgIconCheck size={size} />;
      }
    }

    $checkbox = (
      <Tooltip title={tooltipTitle} placement="top" offset={[0, 0]}>
        <StyledCheckboxWrapper className="StyledCheckboxWrapper">
          <StyledCheckbox
            className="StyledCheckbox"
            indeterminate={indeterminate}
            checked={checked}
            size={size}
            onClick={handleClick}>
            {$result}
            {$el}
          </StyledCheckbox>
          {hover && (
            <BackgroundHover
              handleClick={handleClick}
              size={size}
              hoverStyle={hoverStyle}
            />
          )}
        </StyledCheckboxWrapper>
      </Tooltip>
    );

    return $checkbox;
  };

  const $backgroundLabel =
    !checked && backgroundLabel && makeBackgroundLabelEl(backgroundLabel);

  makeCheckbox($backgroundLabel);

  return (
    <Wrapper data-component-type="CheckBox" data-cid="CheckBox">
      <HiddenCheckBox
        ref={checkBoxRef}
        checked={checked}
        onChange={onChange}
        readOnly={typeof onChange !== 'function'}
        size={size}
      />
      {$checkbox}
      {label && (
        <Label style={labelStyle} onClick={handleClick}>
          {label}
        </Label>
      )}
    </Wrapper>
  );
}

export default CheckBox;

const HoverBackground = styled.div`
  border-radius: 6px;
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  position: absolute;
  z-index: 1;
  box-sizing: content-box !important;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${(props) => {
    return props.hoverStyle;
  }}
`;

function BackgroundHover(props) {
  const { size, hoverStyle, handleClick } = props;

  return (
    <HoverBackground
      className="HoverBackground"
      onClick={handleClick}
      size={size}
      hoverStyle={hoverStyle}></HoverBackground>
  );
}
