import { CHART_EDIT_CONST, HIGHCHART_UNIT } from 'constant/ChartEditConst';
import {
  ACTION_TYPE,
  FIND_PATTERN_LEVEL_TYPE,
  PATTERN_MATCHING_CONST,
} from 'constant/PatternMatchingConst';

const findingRpeakButtonRenderer = {
  renderFindingRpeakButtonList({
    chartInst,
    tenSecStripStartGlobalWfi,
    //
    svgGroupProperty,
    findingRpeakListInRange,
    similarPatternDataByLevels,
    similarPatternLevel2ListAcrossRange,
    patternAction,
    beatType,
    theme,
  }) {
    for (const rpeak of findingRpeakListInRange) {
      const iconColor = getIconColor({
        rpeak,
        similarPatternDataByLevels,
        similarPatternLevel2ListAcrossRange,
        theme,
      });

      this.renderFindingRpeakButton(chartInst, {
        type: HIGHCHART_UNIT.LOCATION,
        offsetInfo:
          PATTERN_MATCHING_CONST.TEN_SEC_STRIP_PATTERN_MATCHING_RPEAK.OFFSET,
        xAxis: rpeak - tenSecStripStartGlobalWfi,
        withRepresentativeSelectMarker: false,
        theme,
        iconColor,
        beatType,
        className: CHART_EDIT_CONST.PATTERN_MATCHING_FOUND_BEAT_MARKER,
        patternAction,
        svgGroupProperty,
      });
    }
  },
  renderFindingRpeakButton(
    chartInst,
    {
      type,
      offsetInfo,
      xAxis,
      withRepresentativeSelectMarker,
      theme,
      iconColor: { backgroundColor, star },
      beatType,
      className,
      patternAction,
      svgGroupProperty,
    }
  ) {
    if (patternAction === ACTION_TYPE.DELETE_RPEAK) return;

    const zIndex = 10;
    const xAxisPixel =
      type === HIGHCHART_UNIT.PIXEL
        ? xAxis
        : chartInst.xAxis[0].toPixels(xAxis);

    // icon의 절반을 x축 왼쪽으로 이동시키기 위한 값
    const translateX = xAxisPixel + offsetInfo.TRANSLATE_X_OFFSET;
    const translateY = offsetInfo.TRANSLATE_Y_OFFSET;

    const patternMatchingRpeakIconGroup = chartInst.renderer
      .g()
      .attr({
        class: `${PATTERN_MATCHING_CONST.TEN_SEC_STRIP_PATTERN_MATCHING_RPEAK.CLASS_TITLE.ICON_GROUP}, ${className}`,
        zIndex: 4,
      })
      .add(chartInst[svgGroupProperty]);
    chartInst.renderer
      .rect({
        x: xAxisPixel - 12,
        y: 0.5,
        width: 24,
        height: 24,
        r: 2,
        zIndex: 8,
      })
      .attr({
        id: CHART_EDIT_CONST.PATTERN_MATCHING_RPEAK_RECT,
        zIndex: 9,
        'stroke-width': 1,
        stroke: backgroundColor.stroke,
        fill: backgroundColor.fill,
        fillOpacity: 0.5,
      })
      .add(patternMatchingRpeakIconGroup);

    chartInst.renderer
      .path()
      .attr({
        id: CHART_EDIT_CONST.PATTERN_MATCHING_RPEAK,
        d: PATTERN_MATCHING_CONST.TEN_SEC_STRIP_PATTERN_MATCHING_RPEAK.ICON_SVG
          .STAR,
        transform: `translate(${translateX}, ${translateY}) scale(1.4)`,
        fill: star.fill,
        zIndex,
      })
      .css({
        'pointer-events': 'none',
      })
      .add(patternMatchingRpeakIconGroup);
  },
};

function getIconColor({
  rpeak,
  similarPatternDataByLevels,
  similarPatternLevel2ListAcrossRange,
  theme,
}) {
  const defaultIconColor = {
    backgroundColor: {
      stroke: theme.color.RED_30,
      fill: theme.color.RED_30,
    },
    star: {
      fill: theme.color.RED_70,
    },
  };

  let iconColor = { ...defaultIconColor };

  // todo: jyoon - [#patternMatching #refactoring] 매직 넘버 수정 필요
  iconColor.backgroundColor.stroke = getTargetRangeColor({
    level: FIND_PATTERN_LEVEL_TYPE.LEVEL1,
    defaultColor: 'RED_70',
    similarPatternDataByLevels,
    rpeak,
    defaultIconColor,
    theme,
  });

  if (similarPatternLevel2ListAcrossRange.length > 0) {
    const isRpeakInLevel2 = similarPatternLevel2ListAcrossRange.some(
      ([start, end]) => start <= rpeak && rpeak <= end
    );
    if (isRpeakInLevel2) {
      iconColor = {
        backgroundColor: {
          stroke: theme.color.PAUSE_30,
          fill: theme.color.PAUSE_30,
        },
        star: {
          fill: theme.color.PAUSE_70,
        },
      };

      // todo: jyoon - [#patternMatching #refactoring] 매직 넘버 수정 필요
      iconColor.backgroundColor.stroke = getTargetRangeColor({
        level: FIND_PATTERN_LEVEL_TYPE.LEVEL2,
        defaultColor: 'PAUSE_70',
        similarPatternDataByLevels,
        rpeak,
        defaultIconColor,
        theme,
      });
    }
  }

  return iconColor;
}
function getTargetRangeColor({
  level,
  defaultColor,
  similarPatternDataByLevels,
  rpeak,
  defaultIconColor,
  theme,
}: {
  level: string;
  defaultColor: string;
  similarPatternDataByLevels: any;
  rpeak: number;
  defaultIconColor: any;
  theme: any;
}) {
  const {
    onsetWaveformIndex: onsetWfi,
    terminationWaveformIndex: terminationWfi,
  } = similarPatternDataByLevels[level]?.targetRange || {};
  const isRpeakInTargetRange = onsetWfi <= rpeak && rpeak <= terminationWfi;

  return isRpeakInTargetRange
    ? theme.color[defaultColor]
    : defaultIconColor.backgroundColor.stroke;
}

export default findingRpeakButtonRenderer;
