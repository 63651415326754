import { useEffect, useState } from 'react';

import { ACTION_TYPE } from 'constant/PatternMatchingConst';

import usePatternMatchingAction from './usePatternMatchingAction';

function usePatternMatchingValidation() {
  const {
    editRange,
    editState,
    findingPatternTargetRange,
    similarPatternData,
    findingRpeakTargetList,
    patternAction,
    handleSetEditState,
  } = usePatternMatchingAction();
  const { isFindSimilarPattern, isFindRpeak, isSetRpeak } = editState;
  const { searchCondition, validationStatus } = editRange;
  const {
    currentLevel: similarPatternCurrentLevel,
    limitLevel: similarPatternLimitLevel,
  } = similarPatternData.data;
  const { isTotalRangeSelected } = searchCondition;
  const { onsetWaveformIndex, terminationWaveformIndex } =
    findingPatternTargetRange;

  // Local states for button validations
  const [findPatternDisabledValidation, setFindPatternDisabledValidation] =
    useState(true);
  const [
    exclusionPatternDisabledValidation,
    setExclusionPatternDisabledValidation,
  ] = useState(true);
  const [findRpeakDisabledValidation, setFindRpeakDisabledValidation] =
    useState(true);
  const [getRpeakDisabledValidation, setGetRpeakDisabledValidation] =
    useState(true);
  const [editButtonDisabledValidation, setEditButtonDisabledValidation] =
    useState(true);
  const [removeButtonDisabledValidation, setRemoveButtonDisabledValidation] =
    useState(true);

  /**
   * findPattern 버튼 활성화 조건
   * * 패턴 영역 인풋의 validation이 true 이거나 전체를 선택
   * * 10s 스트립에서 드래그 없이 클릭만 발생해 onset 과 termination이 같지 않음
   * * onset과 termination 값이 있을때(드래그 한 시작과 끝값이 없을때는 찾을 수 없음)
   * * similarPatternCurrentLevel 와 similarPatternLimitLevel 같지 않을때(레벨 2에서는 찾을 수 없음)
   * * 패턴을 찾은 이후에는 global state findingTargetRange를 비우기 때문에 disabled가 된다.
   */
  useEffect(() => {
    const isFindPatternEnabled =
      (validationStatus.editSectionIntervals || isTotalRangeSelected) &&
      onsetWaveformIndex !== terminationWaveformIndex &&
      onsetWaveformIndex !== undefined &&
      terminationWaveformIndex !== undefined &&
      similarPatternCurrentLevel !== similarPatternLimitLevel;
    setFindPatternDisabledValidation(!isFindPatternEnabled);
  }, [
    validationStatus.editSectionIntervals,
    isTotalRangeSelected,
    similarPatternCurrentLevel,
    similarPatternLimitLevel,
    findingPatternTargetRange,
  ]);

  /**
   * 패턴 제외하기 disabled 조건
   * * similarPatternCurrentLevel 와 similarPatternLimitLevel 같지 않을때(레벨 2가 아닌 상태에서는 패턴 제외하기 버튼은 사용 불가)
   
   * r peak 설정하기 disabled 조건
   * * isFindRpeak 상태(이미 r peak를 찾은 상태)
   * * similarPatternCurrentLevel 이 레벨 1이 아닌 상태(레벨 2일 때는 disabled 되어야한다.)
   */
  useEffect(() => {
    setExclusionPatternDisabledValidation(
      similarPatternCurrentLevel !== similarPatternLimitLevel
    );
    setFindRpeakDisabledValidation(
      isFindRpeak ||
        similarPatternCurrentLevel !== 1 ||
        (findingPatternTargetRange.onsetWaveformIndex !== undefined &&
          findingPatternTargetRange.terminationWaveformIndex !== undefined)
    );
  }, [
    similarPatternCurrentLevel,
    similarPatternLimitLevel,
    isFindRpeak,
    findingPatternTargetRange,
  ]);

  /**
   * r peak 찾기 (R peak list 받기)
   * * r peak 찾은 후 찾은 r peak list가 0개일때는 비활성화
   */
  useEffect(() => {
    setGetRpeakDisabledValidation(
      findingRpeakTargetList.length === 0 || isFindRpeak
    );
  }, [findingRpeakTargetList, isFindRpeak]);

  /**
   * 비트 타입 & 삭제 버튼 validation
   * * similarPatternCurrentLevel가 1이 아닌 경우
   * isSetRpeak가 true일때(r peak 위치를 세팅할때)
   */
  useEffect(() => {
    setEditButtonDisabledValidation(
      isSetRpeak ||
        similarPatternCurrentLevel !== 1 ||
        patternAction === ACTION_TYPE.DELETE_RPEAK
    );
    setRemoveButtonDisabledValidation(
      patternAction === ACTION_TYPE.GET_RPEAK ||
        isSetRpeak ||
        similarPatternCurrentLevel !== 1
    );
  }, [patternAction, isSetRpeak, similarPatternCurrentLevel]);

  return {
    findPatternDisabledValidation,
    exclusionPatternDisabledValidation,
    findRpeakDisabledValidation,
    getRpeakDisabledValidation,
    editButtonDisabledValidation,
    removeButtonDisabledValidation,
    handleSetEditState,
  };
}

export default usePatternMatchingValidation;
