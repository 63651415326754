import { useEffect } from 'react';
import { useTheme } from 'styled-components';

import { CHART_EDIT_CONST } from 'constant/ChartEditConst';

import { isPositiveWaveformIndexPair } from 'util/validation/ValidationUtil';
import ChartUtil from 'util/ChartUtil';

import usePatternMatchingAction from './usePatternMatching/usePatternMatchingAction';

function useManagedBeatMarker(
  onsetWaveformIndex,
  chartInst,
  beats,
  totalWaveformIndex = 7500
) {
  const theme = useTheme();
  const {
    editState: { isPatternMatchingEnabled },
  } = usePatternMatchingAction();

  // [render] beat marker(pattern matching 상태라면 pattern matching 구간 제외한 rpeak render)
  useEffect(() => {
    if (
      isPatternMatchingEnabled ||
      !beats ||
      !isPositiveWaveformIndexPair(onsetWaveformIndex, onsetWaveformIndex) ||
      !chartInst?.series[0]?.data.length
    ) {
      ChartUtil.initSVGGroup(chartInst, {
        svgGroupProperty: CHART_EDIT_CONST.BEAT_MARKER_GROUP,
        zIndex: 4,
      });
      return;
    }

    ChartUtil.initSVGGroup(chartInst, {
      svgGroupProperty: CHART_EDIT_CONST.BEAT_MARKER_GROUP,
      zIndex: 4,
    });
    const { waveformIndex, beatType, hr } = beats;
    ChartUtil.renderBeatMarker(chartInst, {
      onsetWaveformIndex,
      svgGroupProperty: CHART_EDIT_CONST.BEAT_MARKER_GROUP,
      //
      rpeakList: waveformIndex,
      beatTypeList: beatType,
      hrList: hr,
      //
      className: CHART_EDIT_CONST.BEAT_MARKER,
      totalWaveformIndex,
      //
      color: theme.color.COOL_GRAY_80,
      beatMarkerAddGroupTarget: CHART_EDIT_CONST.BEAT_MARKER_GROUP,
    });
  }, [chartInst, onsetWaveformIndex, beats, isPatternMatchingEnabled]);
}

export default useManagedBeatMarker;
