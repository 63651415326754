import { defineMessages } from 'react-intl';

const Const = {
  CLIENT_NAME: {
    SYSTEM: 'system',
    WEB_APP: 'memo-web',
    PARTNER_WEB_APP: 'memo-partner-web',
  },

  SESSION_DURATION_TIME: 8,

  PATIENT_SEX_LABEL_DICT: defineMessages({
    patientSex_M: {
      id: 'code_patientSex_M',
      description: '남자(성별)',
      defaultMessage: '남',
    },
    patientSex_F: {
      id: 'code_patientSex_F',
      description: '여자(성별)',
      defaultMessage: '여',
    },
  }),

  PATIENT_PACEMAKER_LABEL_DICT: defineMessages({
    pacemaker_Null: {
      id: 'code_pacemaker_Null',
      description: '페이스메이커 선택안함 / Unknown',
      defaultMessage: '선택안함',
    },
    pacemaker_True: {
      id: 'code_pacemaker_True',
      description: '페이스메이커 있음 / Present',
      defaultMessage: '있음',
    },
    pacemaker_False: {
      id: 'code_pacemaker_False',
      description: '페이스메이커 없음 / Absent',
      defaultMessage: '없음',
    },
  }),

  // ecgTestStatus(hospital) status
  ECG_TEST_STATUS: {
    BEFORE_TEST: 10, // Before Test  # 시작 전
    PAIRING_DEVICE: 20, // Paring Device
    ON_GOING_TEST: 30, // On Going Test
    WAITING_RETURN: 40, // Wait for Return
    WAITING_UPLOAD: 50, // Wait for Uploading
    ECG_UPLOADED: 60, // ECG Data Uploaded
    UNDER_CLOUD: 70, // Under Cloud Analysis
    CONFIRMED_CLOUD: 80, // Confirmed cloud
    CUSTOMER_CONFIRMED: 200, // Customer Confirmed
    ERROR: 990, // Error'
    // DEPRECATED
    CALCULATING_TEST: 90,
    UNCALCULATED_TEST: 91,
    CALCULATED_TEST: 100,
    REPORT_RENDERING: 110,
    REPORT_RENDERED: 120,
    getLang: function () {
      return {
        KOR: {
          [this.BEFORE_TEST]: '시작 전',
          [this.PAIRING_DEVICE]: '시작 전',
          [this.ON_GOING_TEST]: '측정 중',
          [this.WAITING_RETURN]: '반납 대기',
          [this.WAITING_UPLOAD]: '업로드 대기',
        },
      };
    },
  },

  // status notion: https://www.notion.so/huinno/FE-BE-Enum-Status-Env-2b5c123e691648aebf122285928d85fd
  // partner status
  CLOUD_ANALYSIS_STATUS: {
    INIT: 10, // 'Init'  # '클라우드 분석 대기' 상태
    UNDER_AI: 20, // 'Under AI Analysis'  # '클라우드 분석 중' 상태
    AI_ANALYSIS_DELAYED: 22, // AI 분석 지연
    AI_RE_ANALYSIS_DONE: 24, // AI 재분석 완료
    ANALYZED_DATA_IN_CHECK: 26, // 데이터 확인 중
    EDIT_REQUESTED: 28, // 편집 요청
    UNDER_PARTNER: 30, // 'Under Partner Analysis'  # 편집 중' 상태
    PARTNER_EDIT_DONE: 60, //  # '편집 완료' 상태
    UNDER_INTERNAL: 70, // 'Under Internal Analysis'  # 휴이노 내부 검토 중
    INTERNAL_EDIT_DONE: 200, // 'Confirmed Internal'  # 휴이노 내부 편집 완료
    DISCARDED: -1, // 폐기
    ERROR: 990, // 'Error'
    // DEPRECATED
    REPORT_RENDERING: 40, //
    REPORT_RENDERED: 50, //
    isDisableStatusOnDetail: function (status, loginUserRelationType) {
      if (loginUserRelationType === 20) {
        return [
          this.UNDER_AI,
          this.PARTNER_EDIT_DONE,
          this.INTERNAL_EDIT_DONE,
        ].includes(status);
      }
      if (loginUserRelationType === 10) {
        return [this.UNDER_AI, this.INTERNAL_EDIT_DONE].includes(status);
      }
    },
    getDisableStatusOnDetailForPartner: function () {
      return [this.UNDER_AI, this.PARTNER_EDIT_DONE, this.INTERNAL_EDIT_DONE];
    },
    getDisableStatusOnDetailForInternal: function () {
      return [this.UNDER_AI, this.INTERNAL_EDIT_DONE];
    },
    getDisableStatusOnList: function () {
      return [this.UNDER_AI];
    },
    getLang: function () {
      return {
        KOR: {
          [this.UNDER_AI]: 'AI 분석 중',
          [this.UNDER_PARTNER]: '편집 중',
          [this.REPORT_RENDERING]: '생성 중',
          [this.REPORT_RENDERED]: '생성 완료',
          [this.PARTNER_EDIT_DONE]: '파트너 편집 완료',
          [this.UNDER_INTERNAL]: '휴이노 내부 판독 중',
          [this.INTERNAL_EDIT_DONE]: '휴이노 내부 판독 완료',
        },
      };
    },
  },

  // 리포트 생성 status
  REPORT_STATUS: {
    NO_REPORT: 10, // '생성 전'
    COMPUTING: 20, // '계산 중'
    COMPUTING_FAILED: 21, // '계산 실패'
    COMPUTED: 30, //
    REPORT_RENDERING: 40, // 'Rendering'
    REPORT_RENDERING_FAILED: 41, // 'Rendering Failed'
    REPORT_RENDERED: 50, // 'Rendered'
    REPORT_ERROR: 990, //, 'Error'
    isRenderingReport: function (status) {
      return [this.REPORT_RENDERING].includes(status);
    },
    isRenderedReport: function (status) {
      return [this.REPORT_RENDERED].includes(status);
    },
    isFailGeneratingReport: function (status) {
      return [
        this.COMPUTING_FAILED,
        this.REPORT_RENDERING_FAILED,
        this.REPORT_ERROR,
      ].includes(status);
    },
    getLang: function () {
      return {
        KOR: {
          [this.COMPUTED]: '생성 중',
          [this.REPORT_RENDERING]: '생성 중',
          [this.REPORT_RENDERED]: '생성 완료',
          [this.COMPUTING_FAILED]: '생성 실패',
          [this.REPORT_RENDERING_FAILED]: '생성 실패',
          [this.REPORT_ERROR]: '생성 실패',
        },
      };
    },
  },

  ECG_TEST_STATUS_LABEL_DICT: defineMessages({
    // status 10, 20 둘 다 테이블에는 동일하게 [시작 전]으로 표기
    ecgTestStatus_10: {
      id: 'code_ecgTestStatus_10',
      description: '시작 전',
      defaultMessage: '시작 전',
    },
    ecgTestStatus_20: {
      id: 'code_ecgTestStatus_20',
      description: '시작 전',
      defaultMessage: '시작 전',
    },
    ecgTestStatus_30: {
      id: 'code_ecgTestStatus_30',
      description: '측정 중',
      defaultMessage: '측정 중',
    },
    ecgTestStatus_40: {
      id: 'code_ecgTestStatus_40',
      description: '반납 대기',
      defaultMessage: '반납 대기',
    },
    ecgTestStatus_50: {
      id: 'code_ecgTestStatus_50',
      description: '업로드 대기',
      defaultMessage: '업로드 대기',
    },
    ecgTestStatus_60: {
      id: 'code_ecgTestStatus_60',
      description: '업로드 완료',
      defaultMessage: '업로드 완료',
    },
    ecgTestStatus_70: {
      id: 'code_ecgTestStatus_70',
      description: '클라우드 분석 중',
      defaultMessage: '클라우드 분석 중',
    },
    ecgTestStatus_80: {
      id: 'code_ecgTestStatus_80',
      description: '클라우드 분석 완료',
      defaultMessage: '클라우드 분석 완료',
    },
    ecgTestStatus_90: {
      id: 'code_ecgTestStatus_90',
      description: '검사 계산 중',
      defaultMessage: '검사 계산 중',
    },
    ecgTestStatus_100: {
      id: 'code_ecgTestStatus_100',
      description: '검사 계산 완료',
      defaultMessage: '검사 계산 완료',
    },
    ecgTestStatus_110: {
      id: 'code_ecgTestStatus_110',
      description: '리포트 생성 중',
      defaultMessage: '리포트 생성 중',
    },
    ecgTestStatus_120: {
      id: 'code_ecgTestStatus_120',
      description: '리포트 생성 완료',
      defaultMessage: '리포트 생성 완료',
    },
    ecgTestStatus_200: {
      id: 'code_ecgTestStatus_200',
      description: '고객 확인 완료',
      defaultMessage: '고객 확인 완료',
    },
    ecgTestStatus_990: {
      id: 'code_ecgTestStatus_990',
      description: '오류',
      defaultMessage: '오류',
    },
  }),

  RELATION_TYPE: {
    INTERNAL: 10,
    PARTNER: 20,
    CUSTOMER: 30,
    getUserRelationType: function (relationType) {
      return this.PARTNER === relationType
        ? 'PARTNER'
        : this.INTERNAL === relationType
        ? 'INTERNAL'
        : '';
    },
    getReviewingLabel: function (relationType) {
      return this.PARTNER === relationType
        ? '편집'
        : this.INTERNAL === relationType
        ? '검토'
        : '편집';
    },
  },

  CLASSIFICATION: {
    NORMAL: { value: 0, label: 'Normal', shortcut: 'Q' },
    APC: { value: 1, label: 'APC', shortcut: 'W' },
    VPC: { value: 2, label: 'VPC', shortcut: 'E' },
    NOISE: { value: 5, label: 'Noise', shortcut: 'R' },
    AV_BLOCK: { value: 8, label: 'AV Block', shortcut: 'T' },
    AF: { value: 3, label: 'AF', shortcut: 'A' },
    PAUSE: { value: 9, label: 'Pause', shortcut: 'S' },
    OTHERS: { value: 4, label: 'Others', shortcut: 'D' },
    SVT: { value: 6, label: 'SVT', shortcut: 'F' },
    VT: { value: 7, label: 'VT', shortcut: 'G' },
    ALL: { value: -1, label: 'All', shortcut: '' },
    LEAD_OFF: { value: -2, label: 'Lead Off', shortcut: '' },
  },

  TABLE: {
    ROWHEIGHT: 40,
    PAGINATIONBUTTON: {
      FIRST: 'FIRST',
      PREVIOUS: 'PREVIOUS',
      NEXT: 'NEXT',
      LAST: 'LAST',
    },
  },
  // Table header order type
  ORDER_TYPE: {
    NONE: 0,
    ASCENDING: 1,
    DESCENDING: 2,
    getNextOrderType: function (currentOrder, isReverse = false) {
      if (isReverse) {
        switch (currentOrder) {
          case this.DESCENDING:
            return this.ASCENDING;
          case this.NONE:
            return this.DESCENDING;
          case this.ASCENDING:
          default:
            return this.NONE;
        }
      } else {
        switch (currentOrder) {
          case this.ASCENDING:
            return this.DESCENDING;
          case this.NONE:
            return this.ASCENDING;
          case this.DESCENDING:
          default:
            return this.NONE;
        }
      }
    },
  },

  PASSWORD_CHANGE_TYPE: {
    DEFAULT: 'default',
    FORGOT: 'forgot',
    DORMANT: 'release-dormant',
    NEW: 'new',
    RECOMMENDED: 'recommended',
  },

  ACCESS_LEVEL: defineMessages({
    READ_ONLY: {
      id: 'code_accessLevel_readOnly',
      description: '열람 전용',
      defaultMessage: '열람 전용',
      value: 1,
    },
    READ_WRITE: {
      id: 'code_accessLevel_readWrite',
      description: '수정 가능',
      defaultMessage: '수정 가능',
      value: 2,
    },
  }),

  STAFF_TYPE: defineMessages({
    DOCTOR: {
      id: 'code_staffType_doctor',
      description: '의사',
      defaultMessage: '의사',
      value: 1,
    },
    NURSE: {
      id: 'code_staffType_nurse',
      description: '간호사',
      defaultMessage: '간호사',
      value: 2,
    },
    MEDICAL_TECHNOLOGIST: {
      id: 'code_staffType_medicalTechnologist',
      description: '임상병리사',
      defaultMessage: '임상병리사',
      value: 3,
    },
    MEDICAL_STAFF: {
      id: 'code_staffType_medicalStaff',
      description: '직원',
      defaultMessage: '직원',
      value: 4,
    },
    OPERATOR: {
      id: 'code_staffType_operator',
      description: 'OPERATOR(QA,BM)',
      defaultMessage: '운영팀',
      value: 5,
    },
  }),

  /**
   * 병원 구분
   */
  USER_RELATION_TYPE: {
    INTERNAL: 10,
    PARTNER: 20,
    CUSTOMER: 30,
  },

  TABLE_FILTER_TEST_TYPE_OPTION: [
    {
      key: 1,
      type: 'check',
      label: '일반',
      status: true,
    },
    {
      key: 2,
      type: 'check',
      label: '임상',
      status: true,
    },
    {
      key: 3,
      type: 'check',
      label: '검진',
      status: true,
    },
  ],

  PATIENT_EVENT_TYPE: {
    PALPITATION: 1, // '두근거림'
    NAUSEA: 2, // '울렁거림'
    HEADACHE: 3, // '두통'
    PLEURODYNIA: 4, // '흉통'
    DYSPNEA: 5, // '호흡 곤란'
    SLEEP: 6, // '취침'
    WAKEUP: 7, // '기상'
    PRESS_BUTTON: 1, //, '패치 버튼'
    ETC: -1, //, 'etc.'
  },
  PATIENT_EVENT_BY: {
    BUTTON: 1, // 'button'
    CHATBOT: 2, // 'chatbot'
  },
  PATIENT_EVENT_ENTRY_TYPE: {
    NOW: 1, // 'now'
    PAST: 2, // 'past'
  },

  /* Key Map */
  KEY_MAP: {
    ARROW_DOWN: 'ArrowDown',
    ARROW_LEFT: 'ArrowLeft',
    ARROW_RIGHT: 'ArrowRight',
    ARROW_UP: 'ArrowUp',
    BACKSPACE: 'Backspace',
    BRACKET_LEFT: '[',
    BRACKET_RIGHT: ']',
    ENTER: 'Enter',
    ESCAPE: 'Escape',
    SLASH: '/',
    QUESTION_MARK: '?',
    F1: 'F1',
    COMMA: ',',
    PERIOD: '.',
    //
    KEY_A: 'KeyA',
    KEY_B: 'KeyB',
    KEY_C: 'KeyC',
    KEY_D: 'KeyD',
    KEY_E: 'KeyE',
    KEY_F: 'KeyF',
    KEY_G: 'KeyG',
    KEY_M: 'KeyM',
    KEY_N: 'KeyN',
    KEY_Q: 'KeyQ',
    KEY_R: 'KeyR',
    KEY_S: 'KeyS',
    KEY_T: 'KeyT',
    KEY_V: 'KeyV',
    KEY_W: 'KeyW',
    KEY_X: 'KeyX',
    KEY_Z: 'KeyZ',
  },

  EVENT_TYPE: {
    KEYUP: 'keyup',
    KEYDOWN: 'keydown',
  },

  SIDE_PANEL: {
    TYPE: {
      SIDE_PANEL_SELECTED_TYPE: 'SIDE_PANEL_SELECTED_TYPE', // SidePanel 이벤트그룹에서 이벤트를 선택했을 경우
      CHART_SELECTED_TYPE: 'CHART_SELECTED_TYPE', // ECG차트에서 클릭했을 경우
      CHART_SECTION_TYPE: 'CHART_SECTION_TYPE', // ECG차트에서 구간을 선택했을경우
    },
  },

  LANGUAGE: {
    EN: 'en',
    KO: 'ko',
  },

  CHARACTERS_MAX_LENGTH: {
    NOTE: 2000,
    FINDINGS: 700,
  },
};

export default Const;

export const TAB_VALUE_OBJECT = {
  GENERAL: 'general',
  HR_REVIEW: 'hr-review',
  EVENT_REVIEW: 'event-review',
  ECG_PREVIEW: 'ecg-preview',
  BEAT_REVIEW: 'beat-review',
  SHAPE_REVIEW: 'shape-review',
};

export const TAB_VALUES = [
  TAB_VALUE_OBJECT.GENERAL,
  TAB_VALUE_OBJECT.HR_REVIEW,
  TAB_VALUE_OBJECT.EVENT_REVIEW,
  TAB_VALUE_OBJECT.ECG_PREVIEW,
  TAB_VALUE_OBJECT.BEAT_REVIEW,
  TAB_VALUE_OBJECT.SHAPE_REVIEW,
];

export const TEST_VERSION = {
  UNKNOWN: null,
  V1: 'v1',
  V2: 'v2',
  V3: 'v3',
};

export const NOTHING_REDUX_ACTION = 'memo-web/nothing/NOTHING';

export const HR_REVIEW_SUB_TAB = {
  HR: 'hr',
  RR: 'rr',
};

export const TIME_ZONE = {
  SEOUL: 'Asia/Seoul',
  HO_CHI_MINH: 'Asia/Ho_Chi_Minh',
};

export const TEST_STATUS_ARRAY = [
  {
    key: 'INIT',
    label: '시작전',
    value: Const.CLOUD_ANALYSIS_STATUS.INIT,
    color: 'COOL_GRAY_80',
    background: 'COOL_GRAY_40',
  },
  {
    key: 'UNDER_AI',
    label: 'AI 분석 중',
    value: Const.CLOUD_ANALYSIS_STATUS.UNDER_AI,
    color: 'AVB_90',
    background: 'AVB_40',
  },
  {
    key: 'AI_ANALYSIS_DELAYED',
    label: 'AI 분석 지연',
    value: Const.CLOUD_ANALYSIS_STATUS.AI_ANALYSIS_DELAYED,
    color: 'RED_90',
    background: 'RED_50',
  },
  {
    key: 'AI_RE_ANALYSIS_DONE',
    label: 'AI 재분석 완료',
    value: Const.CLOUD_ANALYSIS_STATUS.AI_RE_ANALYSIS_DONE,
    color: 'PAUSE_70',
    background: 'PAUSE_40',
  },
  {
    key: 'ANALYZED_DATA_IN_CHECK',
    label: '데이터 확인 중',
    value: Const.CLOUD_ANALYSIS_STATUS.ANALYZED_DATA_IN_CHECK,
    color: 'AVB_90',
    background: 'AVB_40',
  },
  {
    key: 'EDIT_REQUESTED',
    label: '편집 요청',
    value: Const.CLOUD_ANALYSIS_STATUS.EDIT_REQUESTED,
    color: 'PAUSE_70',
    background: 'PAUSE_40',
  },
  {
    key: 'UNDER_PARTNER',
    label: '편집 중',
    value: Const.CLOUD_ANALYSIS_STATUS.UNDER_PARTNER,
    color: 'BLUE_80',
    background: 'BLUE_40',
  },
  {
    key: 'PARTNER_EDIT_DONE',
    label: '편집 완료',
    value: Const.CLOUD_ANALYSIS_STATUS.PARTNER_EDIT_DONE,
    color: 'BLUE_80',
    background: 'BLUE_40',
  },
  {
    key: 'UNDER_INTERNAL',
    label: '내부 검토 중',
    value: Const.CLOUD_ANALYSIS_STATUS.UNDER_INTERNAL,
    color: 'SVE_80',
    background: 'SVE_40',
  },
  {
    key: 'INTERNAL_EDIT_DONE',
    label: '검토 완료',
    value: Const.CLOUD_ANALYSIS_STATUS.INTERNAL_EDIT_DONE,
    color: 'GREEN_90',
    background: 'GREEN_40',
  },
  {
    key: 'DISCARDED',
    label: '폐기',
    value: Const.CLOUD_ANALYSIS_STATUS.DISCARDED,
    color: 'COOL_GRAY_80',
    background: 'COOL_GRAY_40',
  },
];

export const INPUT_TAGNAME_LIST = ['TEXTAREA', 'INPUT'];
