import { CHART_CONST } from 'constant/ChartConst';
import {
  CHART_EDIT_CONST,
  HIGHCHART_UNIT,
  REPORT_REPRESENTATIVE_STRIP,
  SELECTION_MARKER_OFFSET_CONST,
  SELECTION_MARKER_TYPE,
  STRIP_TYPE,
  TEN_SEC_STRIP,
} from 'constant/ChartEditConst';
import {
  CLASS_NAME_HUINNO_CONTEXT_MENU_AREA,
  EVENT_CONST_TYPES,
} from 'constant/EventConst';

import { getTenSecStripParam } from 'util/ChartUtil';
import { getEventInfo } from 'util/EventConstUtil';
import { events } from 'util/PubSubUtil';

import { WaveformIndex } from '@type/ecgEventType/eventUnit';

interface ChartEditParams {
  setSelectionStrip?: (arg: any) => void;
  setTenSecStrip?: (arg: any) => void;
  timestamp?: number;
  representativeWaveformIndex?: number;
  recordingStartMs?: number;
  theme?: Record<string, any>; // theme이 객체이므로 적절한 타입 지정
}

const defaultChartEditParams: ChartEditParams = {
  setSelectionStrip: () => {},
  setTenSecStrip: () => {},
  timestamp: 0,
  representativeWaveformIndex: 0,
  recordingStartMs: 0,
  theme: {},
};

const CONST_CLASS_NAME_HUINNO_CONTEXT_MENU_AREA =
  CLASS_NAME_HUINNO_CONTEXT_MENU_AREA;

export default function chartEdit(
  chartInstParam?: any,
  param2Param?: ChartEditParams
) {
  let chartInst: any = chartInstParam;
  let param2: ChartEditParams = { ...defaultChartEditParams, ...param2Param };

  let setSelectionStrip: Function = param2.setSelectionStrip || (() => {});
  let setTenSecStrip: Function = param2.setTenSecStrip || (() => {});
  let timestamp: number = param2.timestamp || 0;
  let representativeWaveformIndex: WaveformIndex =
    param2.representativeWaveformIndex || 0;
  let recordingStartMs: number = param2.recordingStartMs || 0;
  let theme: Record<string, any> = param2.theme || {};

  // 인자값 최종 결정 및 초기화
  if (arguments.length === 1) {
    param2 = arguments[0];
  } else if (arguments.length === 2) {
    chartInst = arguments[0];
    param2 = arguments[1];
  }

  if (!chartInst) {
    chartInst = (
      document.querySelector(
        '.chartContainer .highcharts-plot-background'
      ) as any
    )?.highchartInst;
  }

  let _selectionStrip = this.chartEditStore;
  return {
    getSelectionStrip() {
      return _selectionStrip;
    },
    hasOnsetSelectionMarker() {
      return (
        _selectionStrip[SELECTION_MARKER_TYPE.ONSET].representativeTimestamp !==
        undefined
      );
    },
    hasTerminationSelectionMarker() {
      return (
        _selectionStrip[SELECTION_MARKER_TYPE.TERMINATION]
          .representativeTimestamp !== undefined
      );
    },
    /**
     * selection strip 생성 로직
     *
     * @param {event} 하이차트 클릭 이벤트 객체
     * @param {[Timestamp, Timestamp]} onset, termination selection marker timestamp
     * @returns
     */
    renderSelectionStrip(
      event,
      [onsetRepresentativeTimestamp, terminationRepresentativeTimestamp]
    ) {
      const constOnset = SELECTION_MARKER_TYPE.ONSET;
      const constTermination = SELECTION_MARKER_TYPE.TERMINATION;
      onsetRepresentativeTimestamp =
        _selectionStrip && _selectionStrip[constOnset].representativeTimestamp;
      terminationRepresentativeTimestamp =
        _selectionStrip &&
        _selectionStrip[constTermination].representativeTimestamp;

      if (
        !this._validationSelectionMarker(event, [
          onsetRepresentativeTimestamp,
          terminationRepresentativeTimestamp,
        ])
      ) {
        return;
      }

      const { type, xAxis, clickedWaveformIndex } = event;
      const shiftKey = event.shiftKey || event.isDragging;
      const noiseEventMetaInfo = getEventInfo({
        type: EVENT_CONST_TYPES.NOISE,
      }).findOne();
      let clickedXAxisPixel = event.clickedWaveformIndexPixels;
      let selectionMarkerType;

      if (_isClick()) {
        selectionMarkerType = SELECTION_MARKER_TYPE.ONSET;
        this.removeSelectionMarkerAll();
      } else if (_isShiftClick()) {
        selectionMarkerType = SELECTION_MARKER_TYPE.TERMINATION;

        if (
          _selectionStrip.TERMINATION.clickedWaveformIndex !== undefined &&
          representativeWaveformIndex + clickedWaveformIndex <
            _selectionStrip.ONSET.representativeWaveformIndex +
              _selectionStrip.ONSET.clickedWaveformIndex
        ) {
          this.removeOnsetSelectionMarker({
            chartType: CHART_EDIT_CONST.CHART_TYPE.THIRTY_SEC_STRIP,
          });
        } else {
          this.removeTerminationSelectionMarker({
            chartType: CHART_EDIT_CONST.CHART_TYPE.THIRTY_SEC_STRIP,
          });
        }
      }

      if (selectionMarkerType === SELECTION_MARKER_TYPE.ONSET) {
        _selectionStrip[SELECTION_MARKER_TYPE.ONSET] = {
          selectionMarkerType,
          representativeTimestamp: timestamp,
          representativeWaveformIndex,
          clickedWaveformIndex: Math.round(clickedWaveformIndex),
          extraParam: {
            isNoise: event.target.classList.contains(
              noiseEventMetaInfo?.renderAttrs?.className
            ),
          },
        };
        _selectionStrip[SELECTION_MARKER_TYPE.TERMINATION] = {
          selectionMarkerType: SELECTION_MARKER_TYPE.TERMINATION,
          representativeTimestamp: undefined,
          representativeWaveformIndex: undefined,
          clickedWaveformIndex: undefined,
          extraParam: {
            isNoise: event.target.classList.contains(
              noiseEventMetaInfo?.renderAttrs?.className
            ),
          },
        };
      }

      if (selectionMarkerType === SELECTION_MARKER_TYPE.TERMINATION) {
        if (
          _selectionStrip.ONSET.clickedWaveformIndex !== undefined &&
          _selectionStrip.TERMINATION.clickedWaveformIndex !== undefined &&
          representativeWaveformIndex + clickedWaveformIndex <
            _selectionStrip.ONSET.representativeWaveformIndex +
              _selectionStrip.ONSET.clickedWaveformIndex
        ) {
          _selectionStrip[SELECTION_MARKER_TYPE.ONSET] = {
            selectionMarkerType: SELECTION_MARKER_TYPE.ONSET,
            representativeTimestamp: timestamp,
            representativeWaveformIndex,
            clickedWaveformIndex: Math.round(clickedWaveformIndex),
            extraParam: {
              isNoise: event.target.classList.contains(
                noiseEventMetaInfo?.renderAttrs?.className
              ),
            },
          };
        } else {
          _selectionStrip[SELECTION_MARKER_TYPE.TERMINATION] = {
            selectionMarkerType,
            representativeTimestamp: timestamp,
            representativeWaveformIndex,
            clickedWaveformIndex: Math.round(clickedWaveformIndex),
            extraParam: {
              isNoise: event.target.classList.contains(
                noiseEventMetaInfo?.renderAttrs?.className
              ),
            },
          };
        }
      }

      if (
        _selectionStrip.ONSET.clickedWaveformIndex +
          _selectionStrip.ONSET.representativeWaveformIndex >
        _selectionStrip.TERMINATION.clickedWaveformIndex +
          _selectionStrip.TERMINATION.representativeWaveformIndex
      ) {
        const swap = _selectionStrip.ONSET;
        _selectionStrip.ONSET = _selectionStrip.TERMINATION;
        _selectionStrip.ONSET.selectionMarkerType = SELECTION_MARKER_TYPE.ONSET;

        _selectionStrip.TERMINATION = swap;
        _selectionStrip.TERMINATION.selectionMarkerType = selectionMarkerType =
          SELECTION_MARKER_TYPE.TERMINATION;
      }

      // selection strip highchart에서 render
      this.renderSelectionMarker({
        xAxis: clickedXAxisPixel,
        type: HIGHCHART_UNIT.PIXEL,
        offsetInfo: SELECTION_MARKER_OFFSET_CONST.THIRTY_SEC_STRIP,
        selectionMarkerType,
      });

      // setSelectionStrip action을 통해서 selection strip 정보 redux store에 저장
      events.subscribe(
        setSelectionStrip.bind(this, {
          selectionMarkerType,
          representativeTimestamp: timestamp,
          representativeWaveformIndex,
          clickedWaveformIndex: Math.round(clickedWaveformIndex),
          extraParam: {
            isNoise: event.target.classList.contains(
              noiseEventMetaInfo?.renderAttrs?.className
            ),
          },
        })
      );

      function _isShiftClick() {
        return (
          (shiftKey &&
            onsetRepresentativeTimestamp !== undefined &&
            terminationRepresentativeTimestamp === undefined) ||
          (shiftKey &&
            onsetRepresentativeTimestamp !== undefined &&
            terminationRepresentativeTimestamp !== undefined)
        );
      }

      function _isClick() {
        return (
          (!shiftKey &&
            onsetRepresentativeTimestamp !== undefined &&
            terminationRepresentativeTimestamp !== undefined) ||
          (!shiftKey && terminationRepresentativeTimestamp === undefined)
        );
      }
    },
    /**
     * render selection marker
     *
     * @param {Object} params - The parameters for rendering the selection marker.
     * @param {string} params.type - HIGHCHART_UNIT.PIXEL or HIGHCHART_UNIT.LOCATION
     * @param {number} params.xAxis - 하이차트 x축 위치(pixel)
     * @param {string} params.selectionMarkerType - SELECTION_MARKER_TYPE.ONSET or SELECTION_MARKER_TYPE.TERMINATION or SELECTION_MARKER_TYPE.RESET
     * @param {any} params.offsetInfo - selection marker를 차트에 render할 요소의 offset 정보(SELECTION_MARKER_OFFSET_CONST 참고)
     * @param {boolean} params.withRepresentativeSelectMarker - 대표 Strip 선택시 보여지는 Marker 의 render 여부
     *
     */
    renderSelectionMarker({
      selectionMarkerType,
      type,
      offsetInfo,
      xAxis,
      withRepresentativeSelectMarker,
    }) {
      const zIndex = 10;
      const strokeWidth = 1;
      const renderAt = offsetInfo.TYPE;
      const plotHeight = chartInst.plotHeight;
      const chartPlotHeight = chartInst.plotHeight;
      const xAxisPixel =
        type === HIGHCHART_UNIT.PIXEL
          ? xAxis
          : chartInst.xAxis[0].toPixels(xAxis);

      // vertical instance
      const yAxisPixel =
        SELECTION_MARKER_OFFSET_CONST.CHART.OFFSET_YAXIS +
        offsetInfo.VERTICAL.YAXIS;
      const vPointPixel = chartPlotHeight + offsetInfo.VERTICAL.HEIGHT;

      // top instance
      const top_translateX = xAxisPixel - offsetInfo.SELECTION_MARKER_TOP.XAXIS;
      const top_translateY = offsetInfo.SELECTION_MARKER_TOP.YAXIS;

      // bottom instance
      const bottom_translateX =
        xAxisPixel - offsetInfo.SELECTION_MARKER_BOTTOM.XAXIS;
      const bottom_translateY =
        plotHeight - offsetInfo.SELECTION_MARKER_BOTTOM.YAXIS;

      // Selection Marker Group 생성 - start
      const selectionMarkerGroup = chartInst.renderer
        .g()
        .attr({
          class: `huinno-selectionMarker-${renderAt}`,
          zIndex,
        })
        .add();
      chartInst.renderer
        .path()
        .attr({
          id: 'huinno-selectionMarker-vertical-line',
          d: `M${xAxisPixel} ${yAxisPixel} V${vPointPixel}`,
          clickedChartX: xAxisPixel,
          stroke: theme.color.PRIMARY_BLUE,
          'stroke-width': strokeWidth,
          zIndex,
        })
        .css({
          'pointer-events': 'none',
        })
        .add(selectionMarkerGroup);
      chartInst.renderer
        .path()
        .attr({
          class: 'huinno-selectionMarker-top',
          d: 'M8 2L4 6L-2.62268e-07 2L-3.93402e-07 9.3251e-07L8 9.53674e-07L8 2Z',
          transform: `translate(${top_translateX}, ${top_translateY})`,
          fill: theme.color.PRIMARY_BLUE,
          stroke: theme.color.PRIMARY_BLUE,
          'stroke-width': strokeWidth,
          zIndex,
        })
        .css({
          'pointer-events': 'none',
        })
        .add(selectionMarkerGroup);
      chartInst.renderer
        .path()
        .attr({
          class: 'huinno-selectionMarker-bottom',
          d: 'M2.00115e-07 52L4 48L8 52L8 54L-3.45442e-07 54L2.00115e-07 52Z',
          transform: `translate(${bottom_translateX}, ${bottom_translateY})`,
          fill: theme.color.PRIMARY_BLUE,
          stroke: theme.color.PRIMARY_BLUE,
          'stroke-width': strokeWidth,
          zIndex,
        })
        .css({
          'pointer-events': 'none',
        })
        .add(selectionMarkerGroup);
      chartInst._renderAt = renderAt;
      chartInst._selectionMarkerGroup = selectionMarkerGroup;
      // Selection Marker Group 생성 - end

      const _selectionMarkerUuid =
        CHART_EDIT_CONST.SELECTION_MARKER + selectionMarkerType + renderAt;
      if (!window[_selectionMarkerUuid]) {
        window[_selectionMarkerUuid] = [];
      }
      window[_selectionMarkerUuid].push(...[selectionMarkerGroup]);

      if (withRepresentativeSelectMarker) {
        const selectMarker = this.renderRepresentativeSelectMarker(xAxisPixel);
        window[_selectionMarkerUuid].push(selectMarker);
      }
    },
    renderTargetRpeak({
      isFindRpeak,
      selectionMarkerType,
      type,
      offsetInfo,
      xAxis,
      withRepresentativeSelectMarker,
    }) {
      const zIndex = 10;
      const strokeWidth = 1;
      const renderAt = offsetInfo.TYPE;
      const rectSize = 24;
      const imageSize = 14;
      // const plotHeight = chartInst.plotHeight;
      const chartPlotHeight = chartInst.plotHeight;
      const xAxisPixel =
        type === HIGHCHART_UNIT.PIXEL
          ? xAxis
          : chartInst.xAxis[0].toPixels(xAxis);

      // vertical instance
      const yAxisPixel =
        SELECTION_MARKER_OFFSET_CONST.CHART.OFFSET_YAXIS +
        offsetInfo.VERTICAL.YAXIS;
      const vPointPixel = chartPlotHeight + offsetInfo.VERTICAL.HEIGHT;

      const selectionMarkerUuid =
        CHART_EDIT_CONST.SELECTION_MARKER + selectionMarkerType + renderAt;

      if (isFindRpeak) {
        // Remove selection marker
        if (window[selectionMarkerUuid]) {
          window[selectionMarkerUuid].forEach((markerGroup) =>
            markerGroup.destroy()
          );
          delete window[selectionMarkerUuid];
        }
        return;
      }

      // Selection Marker Group 생성 - start
      const selectionMarkerGroup = chartInst.renderer
        .g()
        .attr({
          class: `huinno-selectionMarker-${renderAt}`,
          zIndex,
        })
        .add();
      chartInst.renderer
        .path()
        .attr({
          id: 'huinno-selectionMarker-vertical-line',
          d: `M${xAxisPixel} ${yAxisPixel} V${vPointPixel}`,
          clickedChartX: xAxisPixel,
          stroke: theme.color.PRIMARY_BLUE,
          'stroke-width': strokeWidth,
          zIndex,
        })
        .css({
          'pointer-events': 'none',
        })
        .add(selectionMarkerGroup);

      chartInst.renderer
        .rect({
          x: 0.5, // x
          y: 0.5, // y
          width: rectSize, // width
          height: rectSize, // height
          r: 2, // r
        })
        .attr({
          zIndex: -1,
          fill: theme.color.PRIMARY_BLUE,
          'stroke-width': 1,
          transform: `translate(${xAxisPixel - 12})`,
          stroke: theme.color.PRIMARY_BLUE,
        })
        .add(selectionMarkerGroup);

      chartInst.renderer
        // 이미지를 사용하려면 Public 위치에 필요
        .image(
          '/Icon-Check.svg',
          0.5 + (rectSize - imageSize) / 2,
          0.5 + (rectSize - imageSize) / 2,
          14,
          14
        )
        .attr({
          zIndex: 10,
          fill: theme.color.BLACK,
          'stroke-width': 1,
          transform: `translate(${xAxisPixel - 12})`,
          stroke: theme.color.BLACK,
        })
        .add(selectionMarkerGroup);
      chartInst._renderAt = renderAt;
      chartInst._selectionMarkerGroup = selectionMarkerGroup;
      // Selection Marker Group 생성 - end

      const _selectionMarkerUuid =
        CHART_EDIT_CONST.SELECTION_MARKER + selectionMarkerType + renderAt;
      if (!window[_selectionMarkerUuid]) {
        window[_selectionMarkerUuid] = [];
      }
      window[_selectionMarkerUuid].push(...[selectionMarkerGroup]);

      if (withRepresentativeSelectMarker) {
        const selectMarker = this.renderRepresentativeSelectMarker(xAxisPixel);
        window[_selectionMarkerUuid].push(selectMarker);
      }
    },
    /**
     * 대표 Strip 클릭 시 제공되는 Marker
     * @param {number} xAxisPixel 하이차트 x축 위치(pixel)
     */
    renderRepresentativeSelectMarker: (xAxisPixel) => {
      const title = 'Selected Strip';

      const defaultAttrs = {
        // svg 설정 option
        'stroke-width': 0,
        stroke: theme.color.PRIMARY_BLUE,
        r: 2,
        fill: theme.color.PRIMARY_BLUE,
        zIndex: 8,
        padding: 0,
        paddingRight: 3,
        paddingLeft: 3,
        // ⭐️svg 안의 style 설정 option
        style: {
          fontFamily: 'Spoqa Han Sans Neo',
          color: theme.color.WHITE,
          fontSize: '10px',
          lineHeight: '130%',
          fontWeight: 500,
          cursor: 'default',
        },
      };

      const selectMarker = chartInst.renderer
        .button(
          title,
          0, // x
          0, // y
          () => {},
          defaultAttrs,
          defaultAttrs,
          defaultAttrs,
          defaultAttrs
        )
        .attr({
          class: `representative-select-strip-marker-button-${xAxisPixel}`,
        })
        .add();

      const halfOfPatientTriggerWidth = selectMarker.width / 2;

      // ⭐️ selectMarker x축 위치는 아래 align의 x,y property에 의해서 결정됩니다.
      selectMarker.align(
        {
          align: 'left',
          x: xAxisPixel - halfOfPatientTriggerWidth, // ⭐️ patient box 가로 중심이 patient point(ECG point)로 오게 하는 과정.
          y: 1, // ⭐️ 10 char와 patient trigger button의 간격
        },
        false,
        null
      );

      return selectMarker;
    },
    /**
     * render selection highlight
     *
     * @param {string} STRIP_TYPE
     * @param {{ number, number }} selection strip의 selection highlight 영역의 하이차트 waveformIndex
     */
    renderSelectionHighlight(
      stripType,
      { onsetWaveformIndex, terminationWaveformIndex }
    ) {
      const onsetXPoint = chartInst.xAxis[0].toPixels(onsetWaveformIndex);
      const terminationXPoint = chartInst.xAxis[0].toPixels(
        terminationWaveformIndex
      );

      const xAxis = chartInst.xAxis[0];
      const yAxis = chartInst.yAxis[0];

      let leftPosition;
      let topPosition = yAxis.top;
      let rectWidth = xAxis.width;
      let rectHeight = yAxis.height;

      // eslint-disable-next-line default-case
      switch (stripType) {
        case STRIP_TYPE.SINGLE_LINE:
          leftPosition = onsetXPoint;
          rectWidth = terminationXPoint - onsetXPoint;
          break;
        case STRIP_TYPE.MULTI_LINE.ONSET:
          leftPosition = onsetXPoint;
          rectWidth = rectWidth - onsetXPoint;
          break;
        case STRIP_TYPE.MULTI_LINE.MIDDLE:
          leftPosition = 0;
          //   rectWidth = rectWidth;
          break;
        case STRIP_TYPE.MULTI_LINE.TERMINATION:
          leftPosition = 0;
          rectWidth = terminationXPoint;
          break;
      }

      const _selectionHighLightUuid =
        CHART_EDIT_CONST.SELECTION_HIGHLIGHT + timestamp;
      window[_selectionHighLightUuid] &&
        window[_selectionHighLightUuid]?.destroy();

      // react 에 필요한 값들중 NaN인 경우 에러가 발생함
      if (
        isNaN(leftPosition) ||
        isNaN(topPosition) ||
        isNaN(rectWidth) ||
        isNaN(rectHeight)
      ) {
        return;
      }

      chartInst._selectionHighlight = chartInst.renderer
        .rect(
          leftPosition, // x
          topPosition, // y
          rectWidth, // width
          rectHeight // height
        )
        .attr({
          id: `${CHART_CONST.SELECTION_STRIP}-` + timestamp,
          'stroke-width': 0,
          fill: theme.color.PRIMARY_BLUE_FOR_SELECTION,
          'stroke-dashoffset': -300,
          zIndex: 9,
          class: `${CONST_CLASS_NAME_HUINNO_CONTEXT_MENU_AREA}`,
        })
        .add();

      if (!window[CHART_EDIT_CONST.SELECTION_HIGHLIGHT]) {
        window[CHART_EDIT_CONST.SELECTION_HIGHLIGHT] = [];
      }
      window[CHART_EDIT_CONST.SELECTION_HIGHLIGHT].push(
        chartInst._selectionHighlight
      );
    },
    /**
     * 10s Strip Render하기 위한 사전 작업
     *
     * @param {event} 하이차트 클릭 이벤트 객체
     * @param {number} representativeCenterTimeStamp
     * @param {number} representativeCenterWaveformIndex
     */
    renderTenSecStrip(
      event,
      representativeCenterTimeStamp,
      representativeCenterWaveformIndex
    ) {
      try {
        _init.call(this);
        const tenSecStripParam = getTenSecStripParam(
          event,
          representativeCenterTimeStamp,
          representativeCenterWaveformIndex
        );
        events.subscribe(setTenSecStrip.bind(this, tenSecStripParam));

        return window[CHART_EDIT_CONST.MAIN_TEN_SEC_STRIP];

        function _init() {
          this.removeTenSecStrip();
        }
      } catch (error) {
        console.error(error);
      }
    },
    /**
     * render 10s Strip
     *
     * @param {TENSEC_STRIP.TYPE} type
     * @param {TENSEC_STRIP.POSITION} position
     * @param {number} timestamp
     * @param {number} timestamp
     * @param {number} timestamp
     */
    _renderTenSecStrip(
      type,
      position,
      tenSecStripRepresentativeTimestamp,
      tenSecStripOnsetWaveformIndex,
      tenSecStripTerminationWaveformIndex
    ) {
      try {
        const { y: plotBoxY, height: plotBoxHeight } = chartInst.plotBox;
        let startXLocation, topYaxis, width, heightYaxis;
        startXLocation = tenSecStripOnsetWaveformIndex;
        width = chartInst.xAxis[0].toPixels(
          tenSecStripTerminationWaveformIndex - tenSecStripOnsetWaveformIndex
        );
        topYaxis = plotBoxY;
        heightYaxis = plotBoxHeight;

        const xPixel = chartInst.xAxis[0].toPixels(startXLocation);
        const tenSecStripInst = chartInst.renderer
          .rect(xPixel, topYaxis, width, heightYaxis)
          .attr({
            id: `${CHART_CONST.TEN_SEC_STRIP}`,
            stroke: theme.color.PRIMARY_BLUE,
            'stroke-width': 2,
            zIndex: 11,
          })
          .add();
        tenSecStripInst.timestamp = timestamp;

        if (type === TEN_SEC_STRIP.TYPE.MAIN) {
          const mainTenSecSTripInst =
            window[CHART_EDIT_CONST.MAIN_TEN_SEC_STRIP];
          mainTenSecSTripInst?.element && mainTenSecSTripInst.destroy();
          delete window[CHART_EDIT_CONST.MAIN_TEN_SEC_STRIP];

          window[CHART_EDIT_CONST.MAIN_TEN_SEC_STRIP] = tenSecStripInst;
        } else if (type === TEN_SEC_STRIP.TYPE.EXTRA) {
          const extraTenSecStripInst =
            window[CHART_EDIT_CONST.EXTRA_TEN_SEC_STRIP];
          extraTenSecStripInst?.element && extraTenSecStripInst.destroy();
          delete window[CHART_EDIT_CONST.EXTRA_TEN_SEC_STRIP];

          window[CHART_EDIT_CONST.EXTRA_TEN_SEC_STRIP] = tenSecStripInst;
        }
      } catch (error) {
        console.error(error);
      }
    },
    /**
     * render report representative strip
     *
     * @param {number} onsetMs
     * @param {number} terminationMs
     * @param {number} onset
     * @param {number} termination
     * @param {REPORT_REPRESENTATIVE_STRIP.TYPE} type
     */
    renderRepresentativeReportStrip(
      onsetMs,
      terminationMs,
      onset,
      termination,
      type
    ) {
      const { max: maxXaxis } = chartInst.xAxis[0];
      const { top: topYaxis, height: heightYaxis } = chartInst.yAxis[0];
      const wholeLineSecXAxisLocationScale = maxXaxis;

      let startPoint, width;
      if (type === REPORT_REPRESENTATIVE_STRIP.TYPE.WHOLELINE) {
        startPoint = 0;
        width = chartInst.xAxis[0].toPixels(wholeLineSecXAxisLocationScale);
      } else if (type === REPORT_REPRESENTATIVE_STRIP.TYPE.MULTILINE.BEGIN) {
        startPoint = chartInst.xAxis[0].toPixels(onset - onsetMs);
        width = chartInst.xAxis[0].toPixels(
          wholeLineSecXAxisLocationScale - (onset - onsetMs)
        );
      } else if (type === REPORT_REPRESENTATIVE_STRIP.TYPE.MULTILINE.END) {
        startPoint = 0;
        width = chartInst.xAxis[0].toPixels(termination - onsetMs);
      } else if (type === REPORT_REPRESENTATIVE_STRIP.TYPE.MULTILINE.MID) {
        startPoint = 0;
        width = chartInst.xAxis[0].toPixels(wholeLineSecXAxisLocationScale);
      } else if (type === REPORT_REPRESENTATIVE_STRIP.TYPE.ONELINE) {
        startPoint = chartInst.xAxis[0].toPixels(onset - onsetMs);
        width = chartInst.xAxis[0].toPixels(
          wholeLineSecXAxisLocationScale -
            (terminationMs - termination) -
            (onset - onsetMs)
        );
      }

      const representativeReportStripInst = chartInst.renderer
        .rect(startPoint + 1, topYaxis, width - 2, heightYaxis)
        .attr({
          id: 'huinno-representative-report-strip',
          stroke: theme.color.PRIMARY_BLUE,
          'stroke-width': 2,
          zIndex: 11,
        })
        .add();

      if (Array.isArray(window[CHART_EDIT_CONST.REPORT_REPRESENTATIVE_STRIP])) {
        window[CHART_EDIT_CONST.REPORT_REPRESENTATIVE_STRIP].push(
          representativeReportStripInst
        );
      } else {
        window[CHART_EDIT_CONST.REPORT_REPRESENTATIVE_STRIP] = [
          representativeReportStripInst,
        ];
      }
    },
    /**
     *
     * move 10s strip
     * @param {TENSEC_STRIP.MOVE_TYPE} type
     * @param {timestamp} onsetRepresentativeTimestamp
     * @param {number} representativeCenterWaveformIndex
     * @param {number} centerWaveformIndex
     * @param {number} sec
     */
    moveTenSecStrip(
      type,
      onsetRepresentativeTimestamp,
      representativeCenterWaveformIndex = undefined,
      centerWaveformIndex,
      sec
    ) {
      let movedRepresentativeCenterTimeStamp, movedOnsetWaveformIndex;

      if (type === TEN_SEC_STRIP.MOVE_TYPE.PREV) {
        const nextWaveformIndex = centerWaveformIndex - 250 * sec; // 250: 1초를 그릴때 필요한 waveformIndex 개수, 10: 10초

        if (nextWaveformIndex < 0) {
          movedRepresentativeCenterTimeStamp =
            onsetRepresentativeTimestamp - 1000 * 30; // 1000 * 30: 1초 * 30초
          movedOnsetWaveformIndex = CHART_CONST.XAXIS_MAX + nextWaveformIndex;
        } else {
          movedRepresentativeCenterTimeStamp = onsetRepresentativeTimestamp;
          movedOnsetWaveformIndex = nextWaveformIndex;
        }
      } else if (type === TEN_SEC_STRIP.MOVE_TYPE.NEXT) {
        const nextWaveformIndex = centerWaveformIndex + 250 * sec;

        if (nextWaveformIndex > CHART_CONST.XAXIS_MAX) {
          movedRepresentativeCenterTimeStamp =
            onsetRepresentativeTimestamp + 1000 * 30;
          movedOnsetWaveformIndex = Math.abs(
            CHART_CONST.XAXIS_MAX - nextWaveformIndex
          );
        } else {
          movedRepresentativeCenterTimeStamp = onsetRepresentativeTimestamp;
          movedOnsetWaveformIndex = nextWaveformIndex;
        }
      }

      this.renderTenSecStrip(
        { clickedWaveformIndex: movedOnsetWaveformIndex },
        movedRepresentativeCenterTimeStamp,
        (movedRepresentativeCenterTimeStamp - recordingStartMs) / 4
      );

      // events.subscribe(
      //   setSelectionStrip.bind(this, {
      //     selectionMarkerType: SELECTION_MARKER_TYPE.ONSET,
      //     representativeTimestamp: moveOnsetTimeStamp,
      //     waveformIndex: moveOnsetWaveformIndex,
      //   })
      // );
    },
    /**
     * 차트에 그려진 selection marker중 제거할 객체를 받아 제거
     *
     * @param {Object} selection Marker 객체
     */
    removeSelectionMarker(selectionMarkerInst) {
      try {
        if (!selectionMarkerInst || selectionMarkerInst.length === 0) return;

        while (selectionMarkerInst.length > 0) {
          const selectionMarkerInstOne = selectionMarkerInst.shift();
          selectionMarkerInstOne.element && selectionMarkerInstOne.destroy();
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 30s strip에 그려진 selection marker 모두 제거
    removeSelectionMarkerAll() {
      this.removeOnsetSelectionMarker({
        chartType: CHART_EDIT_CONST.CHART_TYPE.THIRTY_SEC_STRIP,
      });
      this.removeTerminationSelectionMarker({
        chartType: CHART_EDIT_CONST.CHART_TYPE.THIRTY_SEC_STRIP,
      });
    },
    removeSelectionMarkerAllInTenSecStrip() {
      this.removeOnsetSelectionMarker({
        chartType: CHART_EDIT_CONST.CHART_TYPE.TEN_SEC_STRIP,
      });
      this.removeTerminationSelectionMarker({
        chartType: CHART_EDIT_CONST.CHART_TYPE.TEN_SEC_STRIP,
      });
    },
    // 10, 30s strip에 그려진 onset selection marker 제거
    /**
     *
     * @param {param}
     * @prop {string} chartType CHART_EDIT_CONST.CHART_TYPE.THIRTY_SEC_STRIP, CHART_EDIT_CONST.CHART_TYPE.TEN_SEC_STRIP
     */
    removeOnsetSelectionMarker({ chartType }) {
      this.removeSelectionMarker(
        window[
          CHART_EDIT_CONST.SELECTION_MARKER +
            SELECTION_MARKER_TYPE.ONSET +
            chartType
        ]
      );
    },
    // 10, 30s strip에 그려진 termination selection marker 제거
    /**
     *
     * @param {param}
     * @prop {string} chartType CHART_EDIT_CONST.CHART_TYPE.THIRTY_SEC_STRIP, CHART_EDIT_CONST.CHART_TYPE.TEN_SEC_STRIP
     */
    removeTerminationSelectionMarker({ chartType }) {
      this.removeSelectionMarker(
        window[
          CHART_EDIT_CONST.SELECTION_MARKER +
            SELECTION_MARKER_TYPE.TERMINATION +
            chartType
        ]
      );
    },
    /**
     * selection highlight 제거
     */
    removeSelectionHighlight() {
      try {
        const _selectionStripUuid = CHART_EDIT_CONST.SELECTION_HIGHLIGHT;
        window[_selectionStripUuid] && window[_selectionStripUuid]?.destroy();
      } catch (error) {
        // 이미 삭제된 요소의 삭제 처리임
      }
    },
    /**
     * multi line으로 렌더된 selection highlight 제거
     */
    removeSelectionHighlightAll() {
      try {
        const _selectionStripArr = window[CHART_EDIT_CONST.SELECTION_HIGHLIGHT];

        if (!Array.isArray(_selectionStripArr)) return;

        while (_selectionStripArr.length > 0) {
          const one = _selectionStripArr.shift();
          one.element && one.destroy();
        }
      } catch (error) {
        // 이미 삭제된 요소의 삭제 처리임
      }
    },
    /**
     * 10s strip 제거
     */
    removeTenSecStrip() {
      try {
        const tenSecStripInst = window[CHART_EDIT_CONST.MAIN_TEN_SEC_STRIP];
        const extraTenSecStripInst =
          window[CHART_EDIT_CONST.EXTRA_TEN_SEC_STRIP];

        tenSecStripInst?.element && tenSecStripInst.destroy();
        extraTenSecStripInst?.element && extraTenSecStripInst.destroy();

        delete window[CHART_EDIT_CONST.MAIN_TEN_SEC_STRIP];
        delete window[CHART_EDIT_CONST.EXTRA_TEN_SEC_STRIP];
      } catch (error) {
        // 이미 삭제된 요소의 삭제 처리임
      }
    },
    // todo: jyoon[#todo: jyoon [#patternMatching #refactoring] 삭제 변경 방법 필요(inst에 담고 제거, 이렇게 하기 위해서 render시 inst에 설정 필요)
    removeBeatAddPlusButton({ chartRef, isSetRpeak }) {
      const mouseTrackerElement =
        chartRef.current?.container.current.querySelector(
          `.${CHART_EDIT_CONST.MOUSE_TRACKER}`
        );
      const beatAddPlusButton = mouseTrackerElement.querySelector(
        `.${CHART_EDIT_CONST.BEAT_ADD_PLUS_BUTTON}`
      );
      beatAddPlusButton.style.visibility = isSetRpeak ? 'visible' : 'hidden';
    },
    // todo: jyoon[#todo: jyoon [#patternMatching #refactoring] 삭제 변경 방법 필요(inst에 담고 제거, 이렇게 하기 위해서 render시 inst에 설정 필요)
    removeRpeakRangeElement({ chartRef }) {
      const setRpeakRangeElement =
        chartRef.current?.container.current.querySelectorAll(
          `.${CHART_EDIT_CONST.PATTERN_MATCHING_SELECTION_MARKER}-${CHART_EDIT_CONST.CHART_TYPE.TEN_SEC_STRIP}`
        );
      setRpeakRangeElement.forEach((node) => node.remove());
    },
    /**
     * 30s, 10s에서 하이라이트 된 패턴 제거
     */
    removePatternHighlightElement() {
      const patternHighlightArr = document.querySelectorAll(
        `.${CHART_CONST['event-marker']}`
      );
      patternHighlightArr.forEach((node) => {
        if (
          !node.classList.value
            .split(' ')
            .includes(`huinno-${EVENT_CONST_TYPES.LEAD_OFF}`)
        ) {
          node.remove();
        }
      });
    },
    /**
     * report representative strip 제거
     */
    removeRepresentativeReportStrip() {
      try {
        if (
          !Array.isArray(window[CHART_EDIT_CONST.REPORT_REPRESENTATIVE_STRIP])
        )
          return;

        while (
          window[CHART_EDIT_CONST.REPORT_REPRESENTATIVE_STRIP].length !== 0
        ) {
          window[CHART_EDIT_CONST.REPORT_REPRESENTATIVE_STRIP].pop().destroy();
        }
      } catch (error) {
        // 이미 삭제된 요소의 삭제 처리임
      }
    },
    /**
     * chartEdit에서 render한 모든 것들 제거
     */
    removeAll() {
      this.removeSelectionMarkerAll();
      this.removeSelectionHighlightAll();
      this.removeTenSecStrip();
      this.removeRepresentativeReportStrip();
    },
    /**
     *
     * @param {event} 하이차트 클릭 이벤트 객체
     * @param {[Timestamp, Timestamp]} onset, termination selection marker timestamp
     * @returns boolean
     */
    _validationSelectionMarker(event, [onsetInst, terminationInst]) {
      const { shiftKey } = event;
      let result = true;

      if (!onsetInst) {
        if (shiftKey) {
          return (result = false), result;
        } else {
          return result;
        }
      }

      return result;
    },
    /**
     * renderSelectionMarker fn에서 window객체에서 생성하는 hight chart selection strip 객체 반환
     *
     * @param {Object} params
     * @param {CHART_EDIT_CONST.CHART_TYPE.TEN_SEC_STRIP | CHART_EDIT_CONST.CHART_TYPE.THIRTY_SEC_STRIP} params.chartType - chart 타입(10s, 30s)
     * @param {SELECTION_MARKER_TYPE.ONSET | SELECTION_MARKER_TYPE.TERMINATION} params.selectionMarkerType - selection marker 타입(onset, termination)
     * @returns {Object}
     */
    getSelectionMarker({ chartType, selectionMarkerType }) {
      return window[
        CHART_EDIT_CONST.SELECTION_MARKER + selectionMarkerType + chartType
      ];
    },
  };
}
