import { useState, useEffect, useMemo, useCallback } from 'react';
import { useStore } from 'react-redux';
import rfdc from 'rfdc';

import { EVENT_CONST_TYPES } from 'constant/EventConst';
import { FIND_PATTERN_LEVEL_TYPE } from 'constant/PatternMatchingConst';

import {
  getBeatsNBeatEventsList,
  selectFilteredEpisodeOrLeadOffList,
  getOverlapRangeFilter,
} from 'util/BeatUtil';
import { isNumberWaveformIndexPair } from 'util/validation/ValidationUtil';
import { getRegular30sWaveformIndexRange } from 'util/rawUtil';
import { processBeatsByStrategy } from 'util/patternMatching/beatProcessing';
import { isRangeOverlap, isTargetWithinRange } from 'util/checkRange';
import { getNewWithoutTimeEvents } from 'util/patternMatching/patternMatchingUtil';

import { selectRecordingTime } from 'redux/duck/testResultDuck';

import {
  isEqualBeatEventInfo,
  isEqualBeatEventInfoMap,
  isEqualTimeEventList,
} from './equalityFunctions';
import {
  selectSubBeatEventInfoMap,
  selectSubTimeEventList,
} from './selectFunctions';
import {
  filterLocalTimeEventList,
  extractNTransformLocalBeatEventInfo,
} from './etlFunctions';

import usePrevious from '../usePrevious';
import useShallowEqualSelector from '../useShallowEqualSelector';
import usePatternMatchingAction from '../usePatternMatching/usePatternMatchingAction';

/**
 * ECG Strip 구간과 관련된 부정맥 구간과 Patient Triggered Event 를 반환합니다.
 *
 * @param {WaveformIndex} onsetWaveformIndex 구간 시작 지점의 waveform index
 * @param {WaveformIndex} terminationWaveformIndex 구간 종료 지점의 waveform index
 * @param {Object} beatsOrigin
 * @param {Boolean} isNotChangeState testResultReducer.eventDetail 의 State 를 참조하여 조회중인 Event 의 Marker 를 제어하지 않으려면 True
 * @param {Boolean} withoutTimeEvents Beat Review 탭 에서 Ectopic 정보만 보여주기 위해 사용
 * @param {Boolean} isContextMenu Context Menu 에 Time Event 목록 제공하기 위해 사용
 * @returns {ReturnType}
 */
const rfdcClone = rfdc();

function useGetArrhythmiaEvents(
  onsetWaveformIndex,
  terminationWaveformIndex,
  beatsOrigin,
  //
  isNotChangeState,
  withoutTimeEventsProps,
  isContextMenu
) {
  const {
    onsetWaveformIndex: standardOnsetWaveformIndex,
    terminationWaveformIndex: standardTerminationWaveformIndex,
  } = getRegular30sWaveformIndexRange(
    onsetWaveformIndex,
    terminationWaveformIndex
  );

  const beatsInitialState = {
    hr: [],
    beatType: [],
    waveformIndex: [],
  };
  const [beats, setBeats] = useState(beatsInitialState);
  const [noiseList, setNoiseList] = useState([]);
  const [ectopicList, setEctopicList] = useState([]);
  const [timeEventsList, setTimeEventsList] = useState([]);
  const [selectedEventLocalIndex, setSelectedEventLocalIndex] = useState(null); // null: 초기화, -1: 매칭되는 항목 없음

  // 구간과 관련된 모든 부정맥 정보 배열
  const arrhythmiaEventList = useMemo(() => {
    return [...timeEventsList, ...ectopicList].sort((a, b) => {
      return a.onsetMs - b.onsetMs;
    });
  }, [timeEventsList, ectopicList]);
  const {
    editState: { isPatternMatchingEnabled },
    rpeakList: { data: findingRpeakList },
    applyPatternMatchingAction: { beatType, actionType },
    similarPatternData: {
      data: { levels: similarPatternDataByLevels },
    },
    patternAction,
  } = usePatternMatchingAction();

  const withoutTimeEvents = getNewWithoutTimeEvents(
    isPatternMatchingEnabled,
    withoutTimeEventsProps
  );

  // Select Global States
  const store = useStore();
  const { recordingStartMs } = useShallowEqualSelector(selectRecordingTime);
  const _timeEventList = useShallowEqualSelector(
    (state) =>
      selectSubTimeEventList(
        state,
        onsetWaveformIndex,
        terminationWaveformIndex,
        recordingStartMs,
        withoutTimeEvents
      ),
    isEqualTimeEventList
  );
  const _beatEventInfoMap = useShallowEqualSelector(
    (state) =>
      selectSubBeatEventInfoMap(
        state,
        onsetWaveformIndex,
        terminationWaveformIndex
      ),
    isEqualBeatEventInfoMap
  );
  const _sidePanelState = useShallowEqualSelector(
    (state) => state.testResultReducer.eventReview.sidePanelState
  );
  const _eventDetail = useShallowEqualSelector(
    (state) => state.testResultReducer.eventDetail
  );
  const _reportDetail = useShallowEqualSelector(
    (state) => state.testResultReducer.reportDetail
  );
  const _leadOffList = useShallowEqualSelector((state) =>
    selectFilteredEpisodeOrLeadOffList(
      state,
      onsetWaveformIndex,
      terminationWaveformIndex,
      EVENT_CONST_TYPES.LEAD_OFF
    )
  );
  const _afList = useShallowEqualSelector(
    (state) =>
      selectFilteredEpisodeOrLeadOffList(
        state,
        onsetWaveformIndex,
        terminationWaveformIndex,
        EVENT_CONST_TYPES.AF,
        withoutTimeEvents
      ),
    isEqualTimeEventList
  );
  const getOverLappedEventList = useCallback(
    (typeChangedRange, isBeatTypeS) =>
      getOverlapRangeFilter({
        leadOffList: _leadOffList,
        afList: _afList,
      })(typeChangedRange, isBeatTypeS),
    [_leadOffList, _afList]
  );

  // todo: jyoon [#patternMatching #refactoring] - 구간내 유사패턴 로직이니 usePatternMatching.tsx에 반복됨으로 정리 필요
  // todo: jyoon [#patternMatching #refactoring] - 아래 코드 반복되는 케이스 4곳 발생
  const similarPatternLevel1ListAcrossRange = useMemo(() => {
    const lvl1SPData =
      similarPatternDataByLevels[FIND_PATTERN_LEVEL_TYPE.LEVEL1];
    const rst =
      (lvl1SPData &&
        lvl1SPData?.similarPatternList.filter((wfiPair) => {
          return isRangeOverlap({
            range1: {
              start: onsetWaveformIndex,
              end: terminationWaveformIndex,
            },
            range2: {
              start: wfiPair[0],
              end: wfiPair[1],
            },
          });
        })) ??
      [];
    return rst;
  }, [
    similarPatternDataByLevels,
    onsetWaveformIndex,
    terminationWaveformIndex,
  ]);

  const similarPatternLevel2ListAcrossRange = useMemo(() => {
    const lvl2SPData =
      similarPatternDataByLevels[FIND_PATTERN_LEVEL_TYPE.LEVEL2];
    const rst =
      (lvl2SPData &&
        lvl2SPData?.similarPatternList.filter((wfiPair) => {
          return isRangeOverlap({
            range1: {
              start: onsetWaveformIndex,
              end: terminationWaveformIndex,
            },
            range2: {
              start: wfiPair[0],
              end: wfiPair[1],
            },
          });
        })) ??
      [];
    return rst;
  }, [
    similarPatternDataByLevels,
    onsetWaveformIndex,
    terminationWaveformIndex,
  ]);

  const findingRpeakListInRange = useMemo(() => {
    if (!isPatternMatchingEnabled) return [];

    return findingRpeakList.filter((rpeak) => {
      const isRpeakInChartRange = isTargetWithinRange({
        target: rpeak,
        range: {
          start: onsetWaveformIndex,
          end: terminationWaveformIndex,
        },
      });
      return isRpeakInChartRange;
    });
  }, [
    isPatternMatchingEnabled,
    findingRpeakList,
    onsetWaveformIndex,
    terminationWaveformIndex,
  ]);

  // [패턴 매칭] 패턴 매칭이 false -> true일때
  const prevIsPatternMatchingEnabled = usePrevious(isPatternMatchingEnabled);
  useEffect(() => {
    if (!prevIsPatternMatchingEnabled && isPatternMatchingEnabled) {
      let beatEventInfoMap = _beatEventInfoMap;

      let currentChartBeatEventInfoMap = rfdcClone(
        beatEventInfoMap[standardOnsetWaveformIndex]
      );
      if (!currentChartBeatEventInfoMap) {
        // todo: jyoon - [#patternM]
        console.warn('No beat event info map found for current chart');
        return;
      }
      beatEventInfoMap[standardOnsetWaveformIndex] = {
        ...beatEventInfoMap[standardOnsetWaveformIndex],
        beats: {
          // todo: jyoon - [#patternMatching #refactoring] - 유사패턴 찾은 이후 또는 rpeak 찾은 이후 ".beats"값이 없다는 오류 발생
          ...currentChartBeatEventInfoMap.beats,
          beatType: beatEventInfoMap[
            standardOnsetWaveformIndex
          ].beats.beatType.map((v, i) => 0),
        },
      };
      beatEventInfoMap = getBeatsNBeatEventsList(
        standardOnsetWaveformIndex,
        standardTerminationWaveformIndex,
        beatEventInfoMap[standardOnsetWaveformIndex].beats,
        undefined,
        getOverLappedEventList
      );

      const newBeatEventInfo = extractNTransformLocalBeatEventInfo(
        beatEventInfoMap,
        onsetWaveformIndex,
        terminationWaveformIndex
      );
      const prevBeatEventInfo = {
        beats,
        noises: noiseList,
        ectopics: ectopicList,
      };
      if (
        !isEqualBeatEventInfo(
          prevBeatEventInfo,
          newBeatEventInfo,
          onsetWaveformIndex
        )
      ) {
        setBeats(newBeatEventInfo.beats);
        setNoiseList(newBeatEventInfo.noises);
        setEctopicList(newBeatEventInfo.ectopics);
      }
    }
    // Time Event 구간정보 업데이트
    const timeEventList = selectSubTimeEventList(
      store.getState(),
      onsetWaveformIndex,
      terminationWaveformIndex,
      recordingStartMs,
      withoutTimeEvents
    );
    const newTimeEventList = filterLocalTimeEventList(
      timeEventList,
      recordingStartMs,
      onsetWaveformIndex,
      terminationWaveformIndex
    );
    if (!isEqualTimeEventList(timeEventsList, newTimeEventList)) {
      setTimeEventsList(newTimeEventList);
    }
  }, [isPatternMatchingEnabled, _beatEventInfoMap]);

  // [패턴 매칭] 패턴 매칭 모드에서 비트 업데이트시 '유사패턴', '찾은 rpeak' 데이터를 가지고 차트에 보여질 데이터 업데이트
  useEffect(() => {
    if (!isPatternMatchingEnabled) return;

    const beatEventInfoMap = rfdcClone(_beatEventInfoMap);
    const currentChartBeatEventInfoMap =
      beatEventInfoMap[standardOnsetWaveformIndex];
    const currentChartBeatsInfoList = [];
    Object.entries(beatEventInfoMap).forEach(([key, value]) => {
      if (typeof value === 'object') {
        currentChartBeatsInfoList.push(value.beats);
      }
    });

    if (!currentChartBeatEventInfoMap) {
      // todo: jyoon - [#patternMatching #refactoring] - 해당 로직 파악 필요      console.warn('No beat event info map found for current chart');
      return;
    }

    const mergedCurrentChartBeatsInfoList = {
      waveformIndex: [],
      beatType: [],
      hr: [],
    };
    currentChartBeatsInfoList.forEach((item) => {
      mergedCurrentChartBeatsInfoList.waveformIndex = [
        ...mergedCurrentChartBeatsInfoList.waveformIndex,
        ...item.waveformIndex,
      ];
      mergedCurrentChartBeatsInfoList.beatType = [
        ...mergedCurrentChartBeatsInfoList.beatType,
        ...item.beatType,
      ];
      mergedCurrentChartBeatsInfoList.hr = [
        ...mergedCurrentChartBeatsInfoList.hr,
        ...item.hr,
      ];
    });
    const { newWaveformIndex, newBeatType } = processBeatsByStrategy(
      mergedCurrentChartBeatsInfoList,
      {
        rpeakList: findingRpeakListInRange,
        level1Patterns: similarPatternLevel1ListAcrossRange,
        level2Patterns: similarPatternLevel2ListAcrossRange,
        beatType,
        actionType,
      }
    );

    // 업데이트된 비트 정보로 새로운 이벤트 맵 생성
    const updatedBeatEventInfoMap = {
      ...currentChartBeatEventInfoMap,
      beats: {
        ...mergedCurrentChartBeatsInfoList,
        waveformIndex: newWaveformIndex,
        beatType: newBeatType,
      },
    };

    // 새로운 비트 이벤트 목록 생성 및 상태 업데이트
    const newBeatEventMap = getBeatsNBeatEventsList(
      standardOnsetWaveformIndex,
      standardTerminationWaveformIndex,
      updatedBeatEventInfoMap.beats,
      undefined,
      getOverLappedEventList
    );

    const newBeatEventInfo = extractNTransformLocalBeatEventInfo(
      newBeatEventMap,
      onsetWaveformIndex,
      terminationWaveformIndex
    );

    setBeats(newBeatEventInfo.beats);
    setNoiseList(newBeatEventInfo.noises);
    setEctopicList(newBeatEventInfo.ectopics);
  }, [
    isPatternMatchingEnabled,
    _beatEventInfoMap,
    beatType,
    actionType,
    patternAction,
    onsetWaveformIndex,
  ]);

  // 구간 정보가 변경되면 전부 변경
  const prevOnsetWaveformIndex = usePrevious(onsetWaveformIndex);
  const prevTerminationWaveformIndex = usePrevious(terminationWaveformIndex);
  useEffect(() => {
    if (
      !isNumberWaveformIndexPair(onsetWaveformIndex, terminationWaveformIndex)
    ) {
      return;
    }

    if (
      !(
        prevOnsetWaveformIndex === onsetWaveformIndex &&
        prevTerminationWaveformIndex === terminationWaveformIndex
      )
    ) {
      if (!isContextMenu && !isPatternMatchingEnabled) {
        // Beat 기반 이벤트 구간정보 업데이트
        const beatEventInfoMap = beatsOrigin?.beatType
          ? getBeatsNBeatEventsList(
              standardOnsetWaveformIndex,
              standardTerminationWaveformIndex,
              beatsOrigin,
              undefined,
              getOverLappedEventList
            )
          : selectSubBeatEventInfoMap(
              store.getState(),
              onsetWaveformIndex,
              terminationWaveformIndex
            );
        const newBeatEventInfo = extractNTransformLocalBeatEventInfo(
          beatEventInfoMap,
          onsetWaveformIndex,
          terminationWaveformIndex
        );
        const prevBeatEventInfo = {
          beats,
          noises: noiseList,
          ectopics: ectopicList,
        };
        if (
          !isEqualBeatEventInfo(
            prevBeatEventInfo,
            newBeatEventInfo,
            onsetWaveformIndex
          )
        ) {
          setBeats(newBeatEventInfo.beats);
          setNoiseList(newBeatEventInfo.noises);
          setEctopicList(newBeatEventInfo.ectopics);
        }
      }
      // Time Event 구간정보 업데이트
      const timeEventList = selectSubTimeEventList(
        store.getState(),
        onsetWaveformIndex,
        terminationWaveformIndex,
        recordingStartMs,
        withoutTimeEvents
      );
      const newTimeEventList = filterLocalTimeEventList(
        timeEventList,
        recordingStartMs,
        onsetWaveformIndex,
        terminationWaveformIndex
      );
      if (!isEqualTimeEventList(timeEventsList, newTimeEventList)) {
        setTimeEventsList(newTimeEventList);
      }
    }
  }, [
    onsetWaveformIndex,
    terminationWaveformIndex,
    isContextMenu,
    isPatternMatchingEnabled,
  ]);

  // 기존 이벤트 목록과 신규 이벤트 목록이 동일한지 확인하는 로직, 하나의 이벤트라도 다르면 목록 업데이트
  // Beat 기반 이벤트 구간정보 업데이트
  useEffect(() => {
    if (
      !isNumberWaveformIndexPair(
        prevOnsetWaveformIndex,
        prevTerminationWaveformIndex
      ) ||
      !isNumberWaveformIndexPair(
        onsetWaveformIndex,
        terminationWaveformIndex
      ) ||
      !(
        prevOnsetWaveformIndex === onsetWaveformIndex &&
        prevTerminationWaveformIndex === terminationWaveformIndex
      ) ||
      isContextMenu ||
      isPatternMatchingEnabled
    ) {
      return;
    }

    const beatEventInfoMap = beatsOrigin?.beatType
      ? getBeatsNBeatEventsList(
          standardOnsetWaveformIndex,
          standardTerminationWaveformIndex,
          beatsOrigin,
          undefined,
          getOverLappedEventList
        )
      : _beatEventInfoMap;

    const newBeatEventInfo = extractNTransformLocalBeatEventInfo(
      beatEventInfoMap,
      onsetWaveformIndex,
      terminationWaveformIndex
    );
    const prevBeatEventInfo = {
      beats,
      noises: noiseList,
      ectopics: ectopicList,
    };
    if (
      !isEqualBeatEventInfo(
        prevBeatEventInfo,
        newBeatEventInfo,
        onsetWaveformIndex
      )
    ) {
      setBeats(newBeatEventInfo.beats);
      setNoiseList(newBeatEventInfo.noises);
      setEctopicList(newBeatEventInfo.ectopics);
    }
  }, [
    _beatEventInfoMap,
    beatsOrigin,
    onsetWaveformIndex,
    terminationWaveformIndex,
    isPatternMatchingEnabled,
  ]);

  // Time Event 구간정보 업데이트
  useEffect(() => {
    if (
      !isNumberWaveformIndexPair(
        prevOnsetWaveformIndex,
        prevTerminationWaveformIndex
      ) ||
      !isNumberWaveformIndexPair(
        onsetWaveformIndex,
        terminationWaveformIndex
      ) ||
      !(
        prevOnsetWaveformIndex === onsetWaveformIndex &&
        prevTerminationWaveformIndex === terminationWaveformIndex
      )
    ) {
      return;
    }

    const newTimeEventList = filterLocalTimeEventList(
      _timeEventList,
      recordingStartMs,
      onsetWaveformIndex,
      terminationWaveformIndex
    );
    if (!isEqualTimeEventList(timeEventsList, newTimeEventList)) {
      setTimeEventsList(newTimeEventList);
    }
  }, [_timeEventList, onsetWaveformIndex, terminationWaveformIndex]);

  // Event의 Local Array Index 검색
  //  - 목적: highLight 상태 여부 확인하기 위한 데이터 설정
  //  - 케이스: Event Review Side Panel에서 조회, event 선택 케이스 포함
  useEffect(() => {
    if (
      isNotChangeState ||
      !validateEventDetail(_eventDetail) ||
      !validateSidePanelState(_sidePanelState) ||
      !isNumberWaveformIndexPair(onsetWaveformIndex, terminationWaveformIndex)
    ) {
      setSelectedEventLocalIndex(null);
      return;
    }

    const timeEventId = _eventDetail.data.id ?? undefined;
    const waveformIndexList = _eventDetail.data.waveformIndex ?? undefined;
    if (!timeEventId && !waveformIndexList) {
      console.error(
        'uerGetArrhythmiaEvents, selectedEventLocalIndex error: testResultReducer.eventDetail.data 값 이상',
        _eventDetail
      );
      return;
    }

    const matchingIndexList = getMatchingIndexList({
      arrhythmiaEventList,
      waveformIndexList,
      timeEventId,
    });

    setSelectedEventLocalIndex(matchingIndexList);

    function getMatchingIndexList({
      arrhythmiaEventList,
      waveformIndexList,
      timeEventId,
    }) {
      const results = [];
      const waveformIndexSet = new Set(waveformIndexList);
      const arrhythmiaEventListLength = arrhythmiaEventList.length;

      for (let index = 0; index < arrhythmiaEventListLength; index++) {
        const value = arrhythmiaEventList[index];

        const hasMatchingWaveformIndex =
          waveformIndexSet.size > 0 &&
          value.waveformIndex &&
          waveformIndexSet.has(value.waveformIndex[0]);

        const hasMatchingTimeEventId =
          timeEventId && value.timeEventId && value.timeEventId === timeEventId;

        if (hasMatchingWaveformIndex || hasMatchingTimeEventId) {
          results.push(index);
        }
      }

      return results;
    }
  }, [arrhythmiaEventList, _sidePanelState, _reportDetail, _eventDetail]);

  return {
    beats,
    noiseList,
    ectopicList,
    arrhythmiaEventList,
    selectedEventLocalIndex,
  };
}

/**
 * Side Panel State에서 선택된 값에 대한 검증
 * validate 실패시 false 반환 (return 이 true 일 경우, 하이라이트할 event가 있는 것으로 판단)
 *
 * @param {*} selectedEventList
 * @returns
 */
function validateSidePanelState(_sidePanelState) {
  const selectedEventList = _sidePanelState.selectedEventList;
  const isSelectedOnlyOne = selectedEventList.length === 1;
  const nonDisplayedEventTypesSidePanel = [
    undefined,
    EVENT_CONST_TYPES.NORMAL,
    EVENT_CONST_TYPES.NOISE,
  ];

  const validation1 = isSelectedOnlyOne;
  const validation2 =
    isSelectedOnlyOne &&
    (typeof selectedEventList[0].position === 'number' ||
      typeof selectedEventList[0].timeEventId === 'number' ||
      Array.isArray(selectedEventList[0].waveformIndex));
  const validation3 =
    isSelectedOnlyOne &&
    !nonDisplayedEventTypesSidePanel.includes(selectedEventList[0].type);

  return validation1 && validation2 && validation3;
}

/**
 * Event Detail 정보에 대한 검증
 * validate 실패시 false 반환 (return 이 true 일 경우, 하이라이트할 event가 있는 것으로 판단)
 *
 * @param {*} _eventDetail
 * @returns
 */
function validateEventDetail(_eventDetail) {
  if (!_eventDetail.data) return false;

  const hasTimeEventId = _eventDetail.data.id;
  const hasWaveformIndexList = _eventDetail.data.waveformIndex;
  const hasEventDetailData = !_eventDetail.pending && _eventDetail.data;

  const validation1 = hasTimeEventId || hasWaveformIndexList;
  const validation2 = hasEventDetailData;
  const validation3 = !_eventDetail.isEventEdited; // jyoon(240828) - 비트 이벤트 선택 편집시 하이라이트 안돼서 validation에서 제거

  return validation1 && validation2;
}

export default useGetArrhythmiaEvents;
