import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { useEffect } from 'react';

import { CONTEXT_MENU } from 'constant/EventConst';
import {
  AMPLITUDE_OPTION,
  CHART_EDIT_CONST,
  TEN_SEC_STRIP_DETAIL,
} from 'constant/ChartEditConst';
import { INTL_MAP } from 'constant/TenSecStripDetailConst';
import { ComponentId } from 'constant/ComponentId';
import Const from 'constant/Const';
import { CHART_CONST } from 'constant/ChartConst';
import {
  ACTION_TYPE,
  EDIT_STATE_FIELD,
  PATTERN_MATCHING_REDUX_STATE_FIELD,
} from 'constant/PatternMatchingConst';

import DateUtil from 'util/DateUtil';
import { getStringFloat } from 'util/NumberUtil';

import useTimeZone from 'component/hook/useTimeZone';
import usePatternMatchingValidation from 'component/hook/usePatternMatching/usePatternMatchingValidation';
import useKeyEventListener, {
  keyboardEventInterfaceMap,
} from 'component/hook/useKeyEventListener';
import usePatternMatchingAction from 'component/hook/usePatternMatching/usePatternMatchingAction';

import Button from 'component/ui/button/Button';
import Tooltip from 'component/ui/tooltip/Tooltip';
import ButtonGroup from 'component/ui/buttonGroup/ButtonGroup';
import ShortcutText from 'component/ui/highlight/ShortcutText';
import {
  OpenInNewIcon,
  KeyboardArrowLeftIcon,
  KeyboardArrowRightIcon,
  AmplitudeIcon,
  HeartIcon,
} from 'component/ui/icons';

import { hrValue } from '@type/optimisticUpdate/type';
import { initFindingPatternTargetRange } from 'redux/duck/patternMatchingDuck';

import { ReactComponent as CheckIcon } from 'static/icon/icon-button-check.svg';
import { ReactComponent as CloseIcon } from 'static/icon/icon-close-x.svg';

import PatternMatchingEdit from '../../event-review/PatternMatching/PatternMatchingEdit';

function TenSecStripDetailHeader({
  // parent props
  // hrAvg,
  isFocusPatternSimilarity,
  tenSecStripChartRef,
  chartOption,
  // local state
  isRRHist,
  beatRRIValue,
  isReadOnly,
  patternSimilarity,
  setPatternSimilarity,
  selectedBeatOption,
  onClickTickMarks,
  onClickCaliper,
  onClickAddBeat,
  onClickRemoveBeat,
  onClickBeatOptions,
  onClickEsc,
  onClickMove,
  onClickAmplitude,
  onClickOpenInNew,
  onClickPatternOfUpdateOption,
  // redux state
  isPatternMatchingEnabled,
  isSetRpeak,
  isRawDataOnly,
  tenSecStripDetail,
  caliperPlotLines,
  //
  chartHeaderRef,
  editBtnRef,
}) {
  const {
    findPatternDisabledValidation,
    exclusionPatternDisabledValidation,
    findRpeakDisabledValidation,
    getRpeakDisabledValidation,
    editButtonDisabledValidation,
    removeButtonDisabledValidation,
    handleSetEditState,
  } = usePatternMatchingValidation();

  const {
    handleSetInitPatternOfActionUpdate,
    handleSetFindingPatternTargetRange,
  } = usePatternMatchingAction();

  const KEYBOARD_EVENT_LISTEN_LIST = [
    Const.KEY_MAP.KEY_Q,
    Const.KEY_MAP.KEY_W,
    Const.KEY_MAP.KEY_E,
    Const.KEY_MAP.KEY_R,
    Const.KEY_MAP.KEY_Z,
  ];

  const { btnNormal, btnAPC, btnVPC, btnNoise } = editBtnRef;

  const intl = useIntl();
  const { newDate } = useTimeZone();

  const {
    chart: { amplitude },
    editModeType,
    isCaliperMode,
    isTickMarksMode,
    tabType,
    component: { hasMoveBtn, hasTickMarks, hasCaliper, hasBeatOptions },
  } = chartOption;
  const isEventReview = TEN_SEC_STRIP_DETAIL.TAB.EVENT_REVIEW === tabType;
  const isShapeReview = TEN_SEC_STRIP_DETAIL.TAB.SHAPE_REVIEW === tabType;
  //
  const { BEAT_TYPE } = CONTEXT_MENU;
  const { onsetMs, terminationMs, hrAvg, pending, episodeBeatInfo } =
    tenSecStripDetail;

  const isNullTenSecStripDetail = onsetMs === null && terminationMs === null;

  const isDisabledByCaliper = isCaliperMode && caliperPlotLines.length !== 2;
  const editButtonValidation =
    isDisabledByCaliper ||
    (editButtonDisabledValidation && isPatternMatchingEnabled);
  const removeButtonValidation =
    isDisabledByCaliper ||
    (removeButtonDisabledValidation && isPatternMatchingEnabled);

  let centerMs;
  if (!pending && episodeBeatInfo) {
    centerMs = episodeBeatInfo[2];
  } else if (!pending && onsetMs !== undefined && terminationMs !== undefined) {
    centerMs = onsetMs + (terminationMs - onsetMs) / 2;
  }
  const formatCenterMs = centerMs
    ? DateUtil.format(newDate(centerMs), 'yyyy-MM-dd, HH:mm:ss')
    : '0000-00-00, --:--:--';
  const avgHrValue =
    hrAvg === hrValue.optimisticEventDataUpdated
      ? '- bpm'
      : `${getStringFloat(!pending ? hrAvg : null)} bpm`;
  const amplitudeOptions = Object.values(AMPLITUDE_OPTION).map((option) => ({
    label: option.LABEL,
    rate: option.RATE,
  }));

  useEffect(() => {
    if (!isPatternMatchingEnabled) return;
    const handleMouseDown = (event) => {
      if (!event.target.classList.contains(CHART_CONST.HIGHCHARTS_BACKGROUND))
        return;
      handleSetFindingPatternTargetRange(initFindingPatternTargetRange);
    };

    if (chartHeaderRef.current) {
      chartHeaderRef.current.addEventListener('mousedown', handleMouseDown);
    }

    return () => {
      if (chartHeaderRef.current) {
        chartHeaderRef.current.removeEventListener(
          'mousedown',
          handleMouseDown
        );
      }
    };
  }, [isPatternMatchingEnabled]);

  // 패턴 매칭 편집 단축키
  useKeyEventListener(
    Const.EVENT_TYPE.KEYUP,
    [...KEYBOARD_EVENT_LISTEN_LIST],
    (event) => {
      onKeyPress({
        event,
        isPatternMatchingEnabled,
        BEAT_TYPE,
        ACTION_TYPE,
        editButtonValidation,
        removeButtonValidation,
        onClickPatternOfUpdateOption,
      });
    },
    keyboardEventInterfaceMap.code
  );

  // todo: jyoon [#refactoring] 10s strip header component 쪼개기
  return (
    <Wrapper ref={chartHeaderRef} className={CHART_CONST.HIGHCHARTS_BACKGROUND}>
      <NavigationChildLeftWrapper>
        <BeatDetailInformationText>{formatCenterMs}</BeatDetailInformationText>
        <>
          {/* 10s Avg HR 정보 */}
          <VerticalBar />
          <BeatDetailSubInformationWrapper>
            <Tooltip
              title={<TooltipTitleWrapper>{'10s Avg HR'}</TooltipTitleWrapper>}
              placement={'bottom'}
              option={{ maxWidth: 'initial' }}>
              <Avg10sButton>
                <StyledHeartIconIcon />
                <BeatDetailSubInformationValue>
                  {avgHrValue}
                </BeatDetailSubInformationValue>
              </Avg10sButton>
            </Tooltip>
          </BeatDetailSubInformationWrapper>
          {/* hr review > R-R Interval histogram에서(isRRHist에서 true) 사용*/}
          {isRRHist && (
            <>
              <VerticalBar />
              <BeatDetailSubInformationWrapper>
                <BeatDetailSubInformationLabel>
                  {'R-R'}
                </BeatDetailSubInformationLabel>
                <BeatDetailSubInformationValue>
                  {beatRRIValue}
                </BeatDetailSubInformationValue>
              </BeatDetailSubInformationWrapper>
            </>
          )}
        </>
        {/* prev, next button */}
        {hasMoveBtn &&
          !isPatternMatchingEnabled &&
          editModeType !== TEN_SEC_STRIP_DETAIL.EDIT_MODE.CHANGE_BEAT && (
            <>
              <VerticalBar />
              <Button
                style={{
                  width: 'fit-content',
                  padding: '4px 7px',
                  height: '24px',
                  borderRadius: 6,
                }}
                disabled={
                  editModeType !== TEN_SEC_STRIP_DETAIL.EDIT_MODE.INIT ||
                  isDisabledByCaliper
                }
                title={<StyledKeyboardArrowLeftIcon />}
                color={'secondary'}
                outline
                onClick={(e) => {
                  e.stopPropagation();
                  onClickMove(e, CHART_EDIT_CONST.TEN_SEC_STRIP_MOVE_DIR.PREV);
                }}
              />
              <Button
                style={{
                  width: 'fit-content',
                  padding: '4px 7px',
                  height: '24px',
                  borderRadius: 6,
                }}
                disabled={
                  editModeType !== TEN_SEC_STRIP_DETAIL.EDIT_MODE.INIT ||
                  isDisabledByCaliper
                }
                title={<StyledKeyboardArrowRightIcon />}
                color={'secondary'}
                outline
                onClick={(e) => {
                  e.stopPropagation();
                  onClickMove(e, CHART_EDIT_CONST.TEN_SEC_STRIP_MOVE_DIR.NEXT);
                }}
              />
            </>
          )}
      </NavigationChildLeftWrapper>
      {/* right button section */}
      <NavigationChildRightWrapper
        id={ComponentId.TEN_SEC_BEAT_CHART_EVENT_BTNS}
        data-cid={ComponentId.TEN_SEC_BEAT_CHART_EVENT_BTNS}>
        <PatternMatchingEdit
          isFocusPatternSimilarity={isFocusPatternSimilarity}
          isDisabledByCaliper={isDisabledByCaliper}
          tenSecStripChartRef={tenSecStripChartRef}
          patternSimilarity={patternSimilarity}
          setPatternSimilarity={setPatternSimilarity}
          findPatternDisabledValidation={findPatternDisabledValidation}
          exclusionPatternDisabledValidation={
            exclusionPatternDisabledValidation
          }
          findRpeakDisabledValidation={findRpeakDisabledValidation}
          getRpeakDisabledValidation={getRpeakDisabledValidation}
          handleSetEditState={handleSetEditState}
        />
        {!isReadOnly &&
          !isRawDataOnly &&
          ((hasBeatOptions &&
            editModeType === TEN_SEC_STRIP_DETAIL.EDIT_MODE.CHANGE_BEAT) ||
            isPatternMatchingEnabled) && (
            <>
              {/* Type Edit Group Button */}
              <ButtonGroup>
                <Button
                  ref={btnNormal}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (isPatternMatchingEnabled) {
                      onClickPatternOfUpdateOption({
                        beatType: BEAT_TYPE.NORMAL.value,
                        actionType: ACTION_TYPE.SELECT_BEAT_TYPE,
                      });
                    } else {
                      onClickBeatOptions(BEAT_TYPE.NORMAL.value);
                    }
                  }}
                  id={BEAT_TYPE.NORMAL.label}
                  data-value={BEAT_TYPE.NORMAL.value}
                  data-clicked={selectedBeatOption === BEAT_TYPE.NORMAL.value}
                  disabled={editButtonValidation}
                  style={{
                    width: 'fit-content',
                    height: '24px',
                    padding: '5px 9px',
                    borderRadius: '6px 0px 0px 6px',
                    fontSize: '12px',
                  }}
                  color={
                    selectedBeatOption === BEAT_TYPE.NORMAL.value
                      ? 'pressed'
                      : 'secondary'
                  }
                  title={
                    <EditTitle
                      label={BEAT_TYPE.NORMAL.shortLabel}
                      shortCut={BEAT_TYPE.NORMAL.shortcut}
                      index={BEAT_TYPE.NORMAL.index}
                    />
                  }
                  outline
                />
                <Button
                  ref={btnAPC}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (isPatternMatchingEnabled) {
                      onClickPatternOfUpdateOption({
                        beatType: BEAT_TYPE.APC.value,
                        actionType: ACTION_TYPE.SELECT_BEAT_TYPE,
                      });
                    } else {
                      onClickBeatOptions(BEAT_TYPE.APC.value);
                    }
                  }}
                  id={BEAT_TYPE.APC.label}
                  data-value={BEAT_TYPE.APC.value}
                  data-clicked={selectedBeatOption === BEAT_TYPE.APC.value}
                  disabled={editButtonValidation}
                  style={{
                    width: 'fit-content',
                    height: '24px',
                    padding: '5px 9px',
                    borderRadius: 0,
                    fontSize: '12px',
                  }}
                  color={
                    selectedBeatOption === BEAT_TYPE.APC.value
                      ? 'pressed'
                      : 'secondary'
                  }
                  title={
                    <EditTitle
                      label={BEAT_TYPE.APC.shortLabel}
                      shortCut={BEAT_TYPE.APC.shortcut}
                      index={BEAT_TYPE.APC.index}
                    />
                  }
                  outline
                />
                <Button
                  ref={btnVPC}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (isPatternMatchingEnabled) {
                      onClickPatternOfUpdateOption({
                        beatType: BEAT_TYPE.VPC.value,
                        actionType: ACTION_TYPE.SELECT_BEAT_TYPE,
                      });
                    } else {
                      onClickBeatOptions(BEAT_TYPE.VPC.value);
                    }
                  }}
                  id={BEAT_TYPE.VPC.label}
                  data-value={BEAT_TYPE.VPC.value}
                  data-clicked={selectedBeatOption === BEAT_TYPE.VPC.value}
                  disabled={editButtonValidation}
                  style={{
                    width: 'fit-content',
                    height: '24px',
                    padding: '5px 9px',
                    borderRadius: 0,
                    fontSize: '12px',
                  }}
                  color={
                    selectedBeatOption === BEAT_TYPE.VPC.value
                      ? 'pressed'
                      : 'secondary'
                  }
                  title={
                    <EditTitle
                      label={BEAT_TYPE.VPC.shortLabel}
                      shortCut={BEAT_TYPE.VPC.shortcut}
                      index={BEAT_TYPE.VPC.index}
                    />
                  }
                  outline
                />
                <Button
                  ref={btnNoise}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (isPatternMatchingEnabled) {
                      onClickPatternOfUpdateOption({
                        beatType: BEAT_TYPE.QUESTIONABLE.value,
                        actionType: ACTION_TYPE.SELECT_BEAT_TYPE,
                      });
                    } else {
                      onClickBeatOptions(BEAT_TYPE.QUESTIONABLE.value);
                    }
                  }}
                  id={BEAT_TYPE.QUESTIONABLE.label}
                  data-value={BEAT_TYPE.QUESTIONABLE.value}
                  data-clicked={
                    selectedBeatOption === BEAT_TYPE.QUESTIONABLE.value
                  }
                  disabled={editButtonValidation}
                  style={{
                    width: 'fit-content',
                    height: '24px',
                    padding: '5px 9px',
                    borderRadius: '0px 6px 6px 0px',
                    fontSize: '12px',
                  }}
                  color={
                    selectedBeatOption === BEAT_TYPE.QUESTIONABLE.value
                      ? 'pressed'
                      : 'secondary'
                  }
                  title={
                    <EditTitle
                      label={BEAT_TYPE.QUESTIONABLE.shortLabel}
                      shortCut={BEAT_TYPE.QUESTIONABLE.shortcut}
                      index={BEAT_TYPE.QUESTIONABLE.index}
                    />
                  }
                  outline
                />
              </ButtonGroup>
              {/* Remove button */}
              <TopTooltip
                title={
                  <TooltipTitleWrapper>
                    {intl.formatMessage(INTL_MAP.REMOVE_TOOLTIP)}
                    <ShortcutText text="Z" />
                  </TooltipTitleWrapper>
                }>
                <Button
                  style={{
                    width: 'fit-content',
                    padding: '4px 8px',
                    height: '24px',
                  }}
                  disabled={removeButtonValidation}
                  color={'secondary'}
                  outline
                  text={'Remove'}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (isPatternMatchingEnabled) {
                      onClickPatternOfUpdateOption({
                        beatType: CONTEXT_MENU.REMOVE.beatType,
                        actionType: ACTION_TYPE.DELETE_RPEAK,
                      });
                      handleSetInitPatternOfActionUpdate([
                        PATTERN_MATCHING_REDUX_STATE_FIELD.FINDING_RPEAK_TARGET_LIST,
                        PATTERN_MATCHING_REDUX_STATE_FIELD.RPEAK_LIST,
                      ]);
                      handleSetEditState({
                        editStateField: {
                          [EDIT_STATE_FIELD.IS_PATTERN_MATCHING_ENABLED]: true,
                        },
                      });
                    } else {
                      onClickRemoveBeat();
                    }
                  }}
                />
              </TopTooltip>
            </>
          )}
        {/* Tick Marks */}
        {hasTickMarks && (
          <TopTooltip
            title={
              <TooltipTitleWrapper>
                {intl.formatMessage(
                  isTickMarksMode
                    ? INTL_MAP.TICK_MARKS_CLICKED_TOOLTIP
                    : INTL_MAP.TICK_MARKS_DEFAULT_TOOLTIP
                )}
                <ShortcutText text="V" />
              </TooltipTitleWrapper>
            }>
            <>
              <Button
                style={{
                  width: 'fit-content',
                  padding: isTickMarksMode ? '3px 8px 3px 6px' : '4px 8px',
                  height: '24px',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  onClickTickMarks();
                }}
                disabled={
                  isDisabledByCaliper ||
                  editModeType === TEN_SEC_STRIP_DETAIL.EDIT_MODE.ADD_BEAT
                }
                startIcon={isTickMarksMode && <CheckIcon />}
                color={isTickMarksMode ? 'pressed' : 'secondary'}
                text={'Tick Marks'}
                outline
              />
            </>
          </TopTooltip>
        )}
        {isPatternMatchingEnabled && <DividerOfPatternMatching />}
        {/* Caliper */}
        {hasCaliper && (
          <TopTooltip
            title={
              <TooltipTitleWrapper>
                {intl.formatMessage(
                  isCaliperMode
                    ? INTL_MAP.CALIPER_CLICKED_TOOLTIP
                    : INTL_MAP.CALIPER_DEFAULT_TOOLTIP
                )}
                <ShortcutText text="C" />
              </TooltipTitleWrapper>
            }>
            <Button
              style={{
                width: 'fit-content',
                padding: isCaliperMode ? '3px 8px 3px 6px' : '4px 8px',
                height: '24px',
              }}
              onClick={(e) => {
                e.stopPropagation();
                onClickCaliper();
              }}
              disabled={
                editModeType === TEN_SEC_STRIP_DETAIL.EDIT_MODE.ADD_BEAT ||
                isNullTenSecStripDetail ||
                isSetRpeak
              }
              startIcon={isCaliperMode && <CheckIcon />}
              color={isCaliperMode ? 'pressed' : 'secondary'}
              text={'Caliper'}
              outline
            />
          </TopTooltip>
        )}
        {/* Add button */}
        {!isReadOnly && !isRawDataOnly && !isPatternMatchingEnabled && (
          <TopTooltip
            title={
              <TooltipTitleWrapper>
                {intl.formatMessage(
                  editModeType === TEN_SEC_STRIP_DETAIL.EDIT_MODE.ADD_BEAT
                    ? INTL_MAP.ADD_BEAT_CLICKED_TOOLTIP
                    : INTL_MAP.ADD_BEAT_DEFAULT_TOOLTIP
                )}
                <ShortcutText text="T" />
              </TooltipTitleWrapper>
            }>
            <Button
              style={{
                width: 'fit-content',
                padding:
                  editModeType === TEN_SEC_STRIP_DETAIL.EDIT_MODE.ADD_BEAT
                    ? '3px 8px 3px 6px'
                    : '4px 8px',
                height: '24px',
                cursor: 'pointer',
              }}
              color={
                editModeType === TEN_SEC_STRIP_DETAIL.EDIT_MODE.ADD_BEAT
                  ? 'pressed'
                  : 'secondary'
              }
              onClick={(e) => {
                e.stopPropagation();
                onClickAddBeat();
              }}
              disabled={
                !(
                  editModeType === TEN_SEC_STRIP_DETAIL.EDIT_MODE.ADD_BEAT ||
                  editModeType === TEN_SEC_STRIP_DETAIL.EDIT_MODE.INIT ||
                  editModeType === TEN_SEC_STRIP_DETAIL.EDIT_MODE.CHANGE_BEAT
                ) ||
                isDisabledByCaliper ||
                isNullTenSecStripDetail
              }
              startIcon={
                editModeType === TEN_SEC_STRIP_DETAIL.EDIT_MODE.ADD_BEAT && (
                  <CheckIcon />
                )
              }
              text={'Add Beat'}
              outline
            />
          </TopTooltip>
        )}
        <Divider />
        {/* mm/Mv group button */}
        <ButtonGroup>
          <Icon as={AmplitudeIcon} />
          {amplitudeOptions.map(({ label, rate }) => {
            return (
              <TopTooltip title={`${label}mm/mV`} key={label}>
                <Button
                  style={{
                    width: 32,
                    padding: '3px 8px',
                    height: '24px',
                    fontSize: '12px',
                    fontWeight: 500,
                  }}
                  data-clicked={amplitude === rate}
                  color={amplitude === rate ? 'pressed' : 'secondary'}
                  text={label}
                  outline
                  onClick={(e) => {
                    e.stopPropagation();
                    onClickAmplitude(rate);
                  }}
                />
              </TopTooltip>
            );
          })}
        </ButtonGroup>
        {isEventReview ? (
          <EscWrapper
            onClick={(e) => {
              e.stopPropagation();
              onClickEsc();
            }}>
            <CloseIconWrapper>
              <CloseIcon />
            </CloseIconWrapper>
          </EscWrapper>
        ) : (
          <TopTooltip title={intl.formatMessage(INTL_MAP.OPEN_IN_NEW_TOOLTIP)}>
            <Button
              outline
              color={'secondary'}
              disabled={pending}
              style={{
                width: 'initial',
                height: '24px',
                padding: '4px',
                borderRadius: '6px',
              }}
              text={<OpenInNewIcon />}
              onClick={(e) => {
                e.stopPropagation();
                onClickOpenInNew();
              }}
            />
          </TopTooltip>
        )}
      </NavigationChildRightWrapper>
    </Wrapper>
  );
}

function onKeyPress({
  event,
  BEAT_TYPE,
  ACTION_TYPE,
  editButtonValidation,
  removeButtonValidation,
  onClickPatternOfUpdateOption,
}) {
  const { code } = event;

  const keyMapToBeatType = {
    [Const.KEY_MAP.KEY_Q]: BEAT_TYPE.NORMAL.value,
    [Const.KEY_MAP.KEY_W]: BEAT_TYPE.APC.value,
    [Const.KEY_MAP.KEY_E]: BEAT_TYPE.VPC.value,
    [Const.KEY_MAP.KEY_R]: BEAT_TYPE.QUESTIONABLE.value,
  };

  const handleAction = (beatType) => {
    if (!editButtonValidation) {
      onClickPatternOfUpdateOption({
        beatType,
        actionType: ACTION_TYPE.SELECT_BEAT_TYPE,
      });
    }
  };

  if (code in keyMapToBeatType) {
    handleAction(keyMapToBeatType[code]);
    return;
  }

  if (code === Const.KEY_MAP.KEY_Z && !removeButtonValidation) {
    onClickPatternOfUpdateOption({
      beatType: CONTEXT_MENU.REMOVE.beatType,
      actionType: ACTION_TYPE.DELETE_RPEAK,
    });
  }
}

function TopTooltip(props) {
  const { children, ...restProps } = props;
  return (
    <Tooltip placement={'top'} option={{ maxWidth: 'initial' }} {...restProps}>
      {children}
    </Tooltip>
  );
}

function EditTitle(props) {
  const { label, shortCut, index } = props;
  return (
    <div
      style={{
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      id={label}
      data-index={index}>
      {label}
      <div
        style={{
          fontSize: '10px',
          fontWeight: 500,
          lineHeight: '100%',
        }}
        id={label}
        data-index={index}>
        (
        <u id={label} data-index={index}>
          {shortCut}
        </u>
        )
      </div>
    </div>
  );
}

const Wrapper = styled.div`
  padding: 11px 24px 10px;
  box-sizing: border-box;
  width: 100%;
  min-height: 46px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const NavigationChildLeftWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  & > * {
    :not(:last-child) {
      margin-right: ${(props) => props.childrenGap ?? 8}px;
    }
  }
`;

const NavigationChildRightWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0px;
  gap: 6px;
  right: 24px;
`;

const VerticalBar = styled.div`
  height: 20px;
  border-right: 1px solid ${(props) => props.theme.color.COOL_GRAY_40};
`;

const BeatDetailInformationText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: ${(props) => props.theme.color.COOL_GRAY_90};
`;

const BeatDetailSubInformationWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  font-weight: 400;
  font-size: 12px;
`;
const BeatDetailSubInformationLabel = styled.div`
  color: ${(props) => props.theme.color.COOL_GRAY_80};
`;
const BeatDetailSubInformationValue = styled.div`
  color: ${(props) => props.theme.color.COOL_GRAY_80};
  margin-left: 2px;
`;

const EscWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border: none;
  cursor: pointer;
  align-items: center;
`;

const CloseIconWrapper = styled.div`
  display: flex;
  align-items: center;
  & #icon-close-x {
    fill: ${(props) => props.theme.color.COOL_GRAY_90};
  }
`;

const TooltipTitleWrapper = styled.div`
  display: flex;
  gap: 4px;
`;

const Divider = styled.div`
  height: 20px;
  width: 1px;
  background: ${({ theme }) => theme.color.COOL_GRAY_40};
`;
const DividerOfPatternMatching = styled.div`
  height: 20px;
  width: 1px;
  background: ${({ theme }) => theme.color.COOL_GRAY_60};
`;

const Icon = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 2px;
`;

const StyledKeyboardArrowLeftIcon = styled(KeyboardArrowLeftIcon)`
  path {
    fill: ${({ theme }) => theme.color.COOL_GRAY_80};
  }
`;

const StyledKeyboardArrowRightIcon = styled(KeyboardArrowRightIcon)`
  path {
    fill: ${({ theme }) => theme.color.COOL_GRAY_80};
  }
`;
const StyledHeartIconIcon = styled(HeartIcon)`
  width: 12px;
  height: 10.45px;
  top: 2.88px;
  left: 2px;
`;

const Avg10sButton = styled.div`
  display: flex;
  align-items: center;
  padding: 2px;
  :hover {
    cursor: pointer;
    border-radius: 6px;
    padding: 2px;
    background-color: ${({ theme }) => theme.color.COOL_GRAY_30};
  }
`;

export default TenSecStripDetailHeader;
