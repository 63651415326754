import { combineReducers } from 'redux';

// Reducers
import dialogReducer from './duck/dialogDuck';
import authReducer from './duck/authDuck';
import medicalStaffsReducer from './duck/medicalStaffsDuck';
import ecgTestsReducer from './duck/ecgTestsDuck';
import ecgTotalReducer from './duck/ecgTotalDuck';
import eventReducer from './duck/eventDuck';
import reportReducer from './duck/reportDuck';
import patchEcgsReducer from './duck/patchEcgsDuck';
import testResultReducer from './duck/testResultDuck';
import beatReviewReducer from './duck/beatReviewDuck';
import shapeReviewReducer from './duck/shapeReviewDuck';
import hrReviewReducer from './duck/hrReviewDuck';
import patternMatchingDuckReducer from './duck/patternMatchingDuck';
import taskQueueReducer from './duck/taskQueueDuck';
import testNotificationReducer from './duck/testNotificationDuck';
import opsReducer from './duck/ops/opsDuck';
import ecgTestListReducer from './duck/ecgTestList/ecgTestListDuck';
import passwordReducer from './duck/passwordDuck';

const rootReducer = combineReducers({
  dialogReducer,
  authReducer,
  medicalStaffsReducer,
  ecgTestsReducer,
  ecgTestListReducer,
  ecgTotalReducer,
  eventReducer,
  reportReducer,
  patchEcgsReducer,
  testResultReducer,
  beatReviewReducer,
  shapeReviewReducer,
  hrReviewReducer,
  patternMatchingDuckReducer,
  taskQueueReducer,
  testNotificationReducer,
  opsReducer,
  passwordReducer,
});

export default rootReducer;
