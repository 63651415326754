import rfdc from 'rfdc';

import { CHART_EDIT_CONST } from 'constant/ChartEditConst';

import ChartUtil from 'util/ChartUtil';

import { WaveformIndex } from '@type/ecgEventType/eventUnit';
import { BeatType } from '@type/ecgEventType/baseEventType';
import { CustomTheme } from '@type/types';

import { processBeatsByStrategy } from '../beatProcessing';

interface Beat {
  waveformIndex: WaveformIndex[];
  beatType: BeatType[];
  hr: number[];
}

interface BeatMarkerColor {
  lvl1Range: WaveformIndex[];
  lvl2Range: WaveformIndex[];
}

// 첫번째, 두번째 패턴 매칭에 렌더할 비트 마커 색상 반환
export function getBeatMarkerColor(
  rpeakList: WaveformIndex[],
  similarPatternLevel2List: [WaveformIndex, WaveformIndex][]
): BeatMarkerColor {
  const beatMarkerColor: BeatMarkerColor = {
    lvl1Range: [],
    lvl2Range: [],
  };

  if (similarPatternLevel2List.length > 0) {
    for (const rpeak of rpeakList) {
      const isRpeakInLvl2Range = similarPatternLevel2List.some(
        ([start, end]) => start <= rpeak && rpeak <= end
      );
      if (isRpeakInLvl2Range) {
        beatMarkerColor.lvl2Range.push(rpeak);
      } else {
        beatMarkerColor.lvl1Range.push(rpeak);
      }
    }
  } else {
    beatMarkerColor.lvl1Range = rpeakList;
  }

  return beatMarkerColor;
}

/**
 *
 * '찾은 rpeak'를 제외한 나머지 rpeak 반환
 * '찾은 rpeak'가 없는 경우 beats데이터 rpeak를 그대로 반환
 *
 * * 상세 설명
 *  - 30s strip에 있는 기존 rpeak 중(beats에 있는 rpeak)
 *    유사 패턴에 '찾은 rpeak'가 있는 유사 패턴 범위(filterSimilarPatternListHavingFindingRpeak)를 제외한 rpeak 반환
 *
 */
export function filterBeats({
  beats,
  findingRpeakListInRange,
  similarPatternLevel1ListAcrossRange,
  similarPatternLevel2ListAcrossRange,
}: {
  beats: Beat;
  findingRpeakListInRange: WaveformIndex[];
  similarPatternLevel1ListAcrossRange: [WaveformIndex, WaveformIndex][];
  similarPatternLevel2ListAcrossRange: [WaveformIndex, WaveformIndex][];
}): Beat {
  const newBeats: Beat = {
    waveformIndex: [],
    beatType: [],
    hr: [],
  };

  // '찾은 rpeak'가 있는 유사 패턴 범위 필터
  // '찾은 rpeak'가 없는 케이스에서는 빈 배열 반환
  const filterSimilarPatternListHavingFindingRpeak: [
    WaveformIndex,
    WaveformIndex
  ][] = [];
  for (const [start, end] of similarPatternLevel1ListAcrossRange) {
    const isRpeakInSimilarPatternList = findingRpeakListInRange.some(
      (rpeak) => start <= rpeak && rpeak <= end
    );
    if (isRpeakInSimilarPatternList) {
      filterSimilarPatternListHavingFindingRpeak.push([start, end]);
    }
  }
  for (const [start, end] of similarPatternLevel2ListAcrossRange) {
    const isRpeakInSimilarPatternList = findingRpeakListInRange.some(
      (rpeak) => start <= rpeak && rpeak <= end
    );
    if (isRpeakInSimilarPatternList) {
      filterSimilarPatternListHavingFindingRpeak.push([start, end]);
    }
  }

  for (let i = 0; i < beats.waveformIndex.length; i++) {
    const waveformIndex = beats.waveformIndex[i];
    const isBeatInSimilarPatternList =
      filterSimilarPatternListHavingFindingRpeak.some(
        ([start, end]) => start <= waveformIndex && waveformIndex <= end
      );

    // 30s strip에 있는 기존 rpeak 중 유사 패턴에 '찾은 rpeak'가 있는 유사 패턴 범위를 제외한 rpeak 반환
    if (!isBeatInSimilarPatternList) {
      newBeats.waveformIndex.push(waveformIndex);
      newBeats.beatType.push(beats.beatType[i]);
      newBeats.hr.push(beats.hr[i]);
    }
  }
  return newBeats;
}

// 패턴 매칭 비트 마커 렌더(30s strip)
export function renderPatternMatchingBeatMarkers({
  chartInst,
  rpeakList, // render할 rpeak
  beatTypeList = [],
  onsetWaveformIndex,
  className,
  color,
}: {
  chartInst: any;
  rpeakList: WaveformIndex[];
  beatTypeList?: BeatType[];
  onsetWaveformIndex: WaveformIndex;
  className: string;
  color: string;
}) {
  const { PATTERN_MATCHING_BEAT_MARKER_GROUP } = CHART_EDIT_CONST;

  ChartUtil.renderBeatMarker(chartInst, {
    rpeakList,
    beatTypeList,
    onsetWaveformIndex,
    className,
    svgGroupProperty: PATTERN_MATCHING_BEAT_MARKER_GROUP,
    beatMarkerAddGroupTarget: PATTERN_MATCHING_BEAT_MARKER_GROUP,
    totalWaveformIndex: 7500,
    color,
  });
}
export function handleDeleteRpeak(
  _beatEventData: any,
  findingRpeakListInRange: number[],
  similarPatternLevel1ListAcrossRange: [number, number][],
  similarPatternLevel2ListAcrossRange: [number, number][],
  beatType: any,
  actionType: string | null,
  chartInst: any,
  chartOnsetWfi: number,
  theme: CustomTheme
) {
  const rfdcClone = rfdc();
  const beats = rfdcClone(_beatEventData.beats);
  const { newWaveformIndex, newBeatType } = processBeatsByStrategy(beats, {
    rpeakList: findingRpeakListInRange,
    level1Patterns: similarPatternLevel1ListAcrossRange,
    level2Patterns: similarPatternLevel2ListAcrossRange,
    beatType,
    actionType,
  });

  ChartUtil.initSVGGroup(chartInst, {
    svgGroupProperty: CHART_EDIT_CONST.PATTERN_MATCHING_BEAT_MARKER_GROUP,
    zIndex: 4,
  });
  ChartUtil.initSVGGroup(chartInst, {
    svgGroupProperty: CHART_EDIT_CONST.BEAT_MARKER_GROUP,
    zIndex: 4,
  });
  renderPatternMatchingBeatMarkers({
    chartInst,
    rpeakList: findingRpeakListInRange,
    beatTypeList: [],
    onsetWaveformIndex: chartOnsetWfi,
    className: CHART_EDIT_CONST.PATTERN_MATCHING_FOUND_BEAT_MARKER,
    color: theme.color.RED_70,
  });
  renderPatternMatchingBeatMarkers({
    chartInst,
    rpeakList: newWaveformIndex,
    beatTypeList: newBeatType,
    onsetWaveformIndex: chartOnsetWfi,
    className: CHART_EDIT_CONST.PATTERN_MATCHING_ORIGIN_BEAT_MARKER,
    color: theme.color.COOL_GRAY_80,
  });
}
