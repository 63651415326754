import { getEventInfo } from 'util/EventConstUtil';
import {
  getRRIBeatsArray,
  getBeatInfosFromSelectionMarker,
} from 'util/optimisticEventDataUpdate/eventReview/timeEvent/PreProcessTimeEventEdit';

import { TimeEvent } from '@type/ecgEventType/eventType';
import { SelectedEventList } from '@type/optimisticUpdate/process';
import {
  optimisticEventDataUpdateForTimeEventConstTypes,
  optimisticEventDataUpdateForTimeEventParam,
} from '@type/optimisticUpdate/type';

import {
  EventHandlingParams,
  EventReviewOptimisticEventDataUpdateForTimeEvent,
} from './optimisticEventDataUpdateForTimeEvent';

export class OptimisticEventDataUpdateForPause extends EventReviewOptimisticEventDataUpdateForTimeEvent {
  validation() {}

  *optimisticEventData({
    updateReqOption,
  }: optimisticEventDataUpdateForTimeEventParam) {
    const isAddEvent = !updateReqOption.isRemove;
    const {
      leadOff: staleLeadOffList,
      data: staleTimeEventList,
    }: { leadOff: any; data: TimeEvent[] } = yield this.getTimeEventList();
    const {
      onset: onsetSelectionStrip,
      termination: terminationSelectionStrip,
    } = yield this.getSelectionStrip();

    if (terminationSelectionStrip.clickedTimestamp === undefined) {
      console.warn('terminationSelectionStrip is empty');
      return;
    }

    if (isAddEvent) {
      yield* this.handleAddEvent({
        updateReqOption,
        //
        staleLeadOffList,
        staleTimeEventList,
        //
        onsetSelectionStrip,
        terminationSelectionStrip,
      });
    } else {
      yield* this.handleDeleteEvent({
        updateReqOption,
        //
        staleLeadOffList,
        staleTimeEventList,
        //
        onsetSelectionStrip,
        terminationSelectionStrip,
      });
    }
  }
  private *handleAddEvent({
    updateReqOption,
    //
    staleLeadOffList,
    staleTimeEventList,
    //
    onsetSelectionStrip,
    terminationSelectionStrip,
  }: EventHandlingParams) {
    const recordingStartMs = yield this.getRecordingStartMs();
    const beatsNEctopicList: unknown = yield this.getBeatsNEctopicList();

    const eventType = getEventInfo({
      timeEventType: updateReqOption.timeEventType,
    }).findOne()?.type as optimisticEventDataUpdateForTimeEventConstTypes;
    const selectedBeatsInfo = getBeatInfosFromSelectionMarker({
      onsetWaveformIndex: updateReqOption.onsetWaveformIndex,
      terminationWaveformIndex: updateReqOption.terminationWaveformIndex,
      beatsNEctopicList,
    });
    const rriBeatsArray = getRRIBeatsArray(selectedBeatsInfo);
    const formatRriBeatsArrayForAsyncEventMarkerUpdate =
      rriBeatsArray.onsetWaveformIndexes.map((onsetWaveformIndex, i) => {
        return {
          onsetWaveformIndex,
          terminationWaveformIndex: rriBeatsArray.terminationWaveformIndexes[i],
        };
      });
    const freshLeadOffList = [...staleLeadOffList];

    const responseList: any[] = [];
    for (let item of formatRriBeatsArrayForAsyncEventMarkerUpdate) {
      const onsetWaveformIndexTimeStamp =
        recordingStartMs + item.onsetWaveformIndex * 4;
      const terminationWaveformIndexTimeStamp =
        recordingStartMs + item.terminationWaveformIndex * 4;
      responseList.push({
        createAt: new Date().getTime(),
        durationMs:
          terminationWaveformIndexTimeStamp - onsetWaveformIndexTimeStamp,
        onsetMs: onsetWaveformIndexTimeStamp,
        terminationMs: terminationWaveformIndexTimeStamp,
        type: eventType,
        timeEventId: null,
        onsetRPeakIndex: null,
        position: null,
        isOptimisticEventDataUpdate: true, // useGetArrhythmiaEvents.jsx > useEffect(Time Event 구간정보 업데이트)에서 사용하는 isEqualTimeEventList(equalityFunctions.js>getObjectFromArray)에서 사용
      });
    }

    const fullyExclusiveRange = staleTimeEventList.filter((staleTimeEvent) => {
      const isSameType = staleTimeEvent.type === eventType;
      const conditionOfExclusiveRangeOfSelectionStrip =
        this.isFullyExclusiveRangeOfSelectionStrip({
          onsetSelectionStrip: onsetSelectionStrip.clickedTimestamp,
          terminationSelectionStrip: terminationSelectionStrip.clickedTimestamp,
          onsetRange: staleTimeEvent.onsetMs,
          terminationRange: staleTimeEvent.terminationMs,
        });

      const conditionOfIsPartiallyInclusiveRangeOfSelectionStrip =
        this.isPartiallyInclusiveRangeOfSelectionStrip(
          {
            onsetSelectionStrip: onsetSelectionStrip.clickedTimestamp,
            terminationSelectionStrip:
              terminationSelectionStrip.clickedTimestamp,
            onsetRange: staleTimeEvent.onsetMs,
            terminationRange: staleTimeEvent.terminationMs,
          },
          { isIncludeBorder: true }
        );

      // pause구간에 있는 이벤트는 제거에서 제외
      const validationPauseRangeInQBeat = this.validatePauseInQ({
        selectedBeatsInfo,
        staleTimeEvent,
        recordingStartMs,
      });

      return (
        !isSameType ||
        conditionOfExclusiveRangeOfSelectionStrip ||
        conditionOfIsPartiallyInclusiveRangeOfSelectionStrip ||
        validationPauseRangeInQBeat
      );
    });

    let freshTimeEventList = [
      ...fullyExclusiveRange,
      ...responseList,
    ] as TimeEvent[];
    freshTimeEventList = this.sortTimeEventList(freshTimeEventList);

    yield this.setTimeEventsList({
      freshLeadOffList,
      freshTimeEventList,
    });
  }
  private *handleDeleteEvent({
    updateReqOption,
    //
    staleLeadOffList,
    staleTimeEventList,
    //
    onsetSelectionStrip,
    terminationSelectionStrip,
  }: EventHandlingParams) {
    /*****************************************************/
    /* # 제거 되는 케이스 2가지                          */
    /*   CASE1. 이벤트 선택 제거 (isWholeUnMark = true)  */
    /*   CASE2. 구간 선택 제거 (isWholeUnMark = false)   */
    /*****************************************************/
    const freshLeadOffList = [...staleLeadOffList];
    const isWholeUnMark: unknown = yield this.getIsWholeUnMark();
    if (isWholeUnMark) {
      // CASE1. 이벤트 선택 제거 (isWholeUnMark = true)
      const selectedEventList: SelectedEventList[] =
        yield this.getSelectedEventList();
      const freshTimeEventList = staleTimeEventList.filter(
        (staleTimeEvent) =>
          staleTimeEvent.timeEventId !== selectedEventList[0].timeEventId
      );

      yield this.setTimeEventsList({
        freshLeadOffList,
        freshTimeEventList,
      });
    } else {
      // CASE2. 구간 선택 제거 (isWholeUnMark = false)
      const eventType = getEventInfo({
        timeEventType: updateReqOption.timeEventType,
      }).findOne()?.type as optimisticEventDataUpdateForTimeEventConstTypes;
      const freshTimeEventList = staleTimeEventList.filter((staleTimeEvent) => {
        return !(
          staleTimeEvent.type === eventType &&
          onsetSelectionStrip.clickedTimestamp < staleTimeEvent.onsetMs &&
          staleTimeEvent.terminationMs <
            terminationSelectionStrip.clickedTimestamp
        );
      });

      yield this.setTimeEventsList({
        freshLeadOffList,
        freshTimeEventList,
      });
    }
  }
}
