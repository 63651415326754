/**
 * ❗️❗️❗️
 * *주의*
 * ❗️❗️❗️
 *
 * 하기 코드는 파트너 웹과 병원 웹 간 차이가 있습니다.
 */
import Const from 'constant/Const';

import useShallowEqualSelector from './useShallowEqualSelector';

const IS_CUSTOMER_WEB =
  process.env.REACT_APP_CLIENT_NAME === Const.CLIENT_NAME.WEB_APP;

function useAuthority(args) {
  const {
    USER_RELATION_TYPE,
    ACCESS_LEVEL,
    ECG_TEST_STATUS,
    CLOUD_ANALYSIS_STATUS,
    RELATION_TYPE,
  } = Const;

  const {
    isHospitalAdmin,
    relationType,
    accessLevel,
    ecgTestStatus,
    cloudStatus,
  } = useShallowEqualSelector((state) => ({
    isHospitalAdmin: state.authReducer.me?.isHospitalAdmin ?? false,
    relationType:
      state.authReducer.me?.relationType ?? USER_RELATION_TYPE.INTERNAL,
    accessLevel:
      state.authReducer.me?.accessLevel ?? ACCESS_LEVEL.READ_ONLY.value,
    ecgTestStatus: state.testResultReducer.ecgTest.data?.ecgTestStatus,
    cloudStatus: state.testResultReducer.ecgTest.data?.cloudStatus,
  }));

  const isDone = () => {
    switch (relationType) {
      case RELATION_TYPE.INTERNAL:
        return IS_CUSTOMER_WEB
          ? ecgTestStatus === ECG_TEST_STATUS.CUSTOMER_CONFIRMED
          : cloudStatus === CLOUD_ANALYSIS_STATUS.INTERNAL_EDIT_DONE;
      case RELATION_TYPE.PARTNER:
        return cloudStatus >= CLOUD_ANALYSIS_STATUS.UNDER_INTERNAL;
      case RELATION_TYPE.CUSTOMER:
        return ecgTestStatus === Const.ECG_TEST_STATUS.CUSTOMER_CONFIRMED;

      default:
        return;
    }
  };

  return {
    // XXX: 병원 어드민에 인터널 병원 사용자가 로그인한 경우, read only?
    isReadOnly:
      (isHospitalAdmin
        ? false
        : accessLevel === Const.ACCESS_LEVEL.READ_ONLY.value) || isDone(),
    isEditable: accessLevel === Const.ACCESS_LEVEL.READ_WRITE.value,
    isHospitalAdmin,
    relationType,
  };
}

export default useAuthority;
