import { useDispatch } from 'react-redux';
import { ChangeEvent, useState } from 'react';

import RevertToPartnerEditDoneDialog from 'component/dialog/RevertToPartnerEditDoneDialog';
import { revertToPartnerEditDoneRequested } from 'redux/duck/ecgTestsDuck';

interface RevertToPartnerEditDoneDialogContainerProps {
  params: {
    ecgTestId: string;
  };
  open: boolean;
  callback: Function;
  onClose: () => void;
}
export interface HandleRevertToPartnerEditDoneParam {
  ecgTestId: string;
  statusUpdatedBy: string;
  callback?: Function;
}
function RevertToPartnerEditDoneDialogContainer({
  params,
  open,
  callback,
  onClose,
}: RevertToPartnerEditDoneDialogContainerProps) {
  const { ecgTestId } = params;
  const dispatch = useDispatch();
  const [inputMessage, setInputMessage] = useState('');
  const handleRevertToPartnerEditDone = ({
    ecgTestId,
    statusUpdatedBy,
    callback,
  }: HandleRevertToPartnerEditDoneParam) => {
    dispatch(
      revertToPartnerEditDoneRequested({
        ecgTestId,
        statusUpdatedBy,
        callback,
      })
    );
    onClose();
  };
  const onInputChange = (
    evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = evt.target.value;
    if (value.length > 200) return;
    setInputMessage(value);
  };
  return (
    <RevertToPartnerEditDoneDialog
      ecgTestId={ecgTestId}
      inputMessage={inputMessage}
      open={open}
      onClose={onClose}
      callback={callback}
      onInputChange={onInputChange}
      handleRevertToPartnerEditDone={handleRevertToPartnerEditDone}
    />
  );
}
export default RevertToPartnerEditDoneDialogContainer;
