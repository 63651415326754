import { BeatType } from '@type/ecgEventType/baseEventType';

import { isWaveformIndexInPatterns } from './patternRangeUtils';
import { validateBeatEventData } from './validators';

/**
 * 유사 패턴 구간이 아닌 wfi 추출
 */
export const processExistingBeats = (
  currentBeats,
  level1Patterns,
  level2Patterns
) => {
  validateBeatEventData(currentBeats);

  const wfisOfNotInPatterns = [];
  const beatTypeOfNotInPatters = [];

  for (let currentWfi of currentBeats.waveformIndex) {
    const { isInLevel1, isInLevel2 } = isWaveformIndexInPatterns(
      currentWfi,
      level1Patterns,
      level2Patterns
    );

    if (!isInLevel1 && !isInLevel2) {
      wfisOfNotInPatterns.push(currentWfi);
      beatTypeOfNotInPatters.push(BeatType.NORMAL);
    }
  }

  return { wfisOfNotInPatterns, beatTypeOfNotInPatters };
};

/**
 * 패턴 매칭에서 찾은 R-peak(wfi) 삽입 처리
 */
export const insertFindingRpeaksInOrder = (
  wfisOfNotInPatterns,
  findingRpeakListInRange
) => {
  const baseWfis = [...wfisOfNotInPatterns];

  for (const rpeak of findingRpeakListInRange) {
    const insertIndex = baseWfis.findIndex((wfi) => wfi > rpeak);
    if (insertIndex === -1) {
      baseWfis.push(rpeak);
    } else {
      baseWfis.splice(insertIndex, 0, rpeak);
    }
  }

  return baseWfis;
};

/**
 * 비트 타입 설정
 */
export const setBeatType = (waveformIndexList, findingRpeakList, beatType) => {
  const result = waveformIndexList.map((wfi) => {
    const isFindingRpeak = findingRpeakList.includes(wfi);
    return isFindingRpeak ? beatType : BeatType.NORMAL;
  });

  return result;
};
