import { isRangeListInRange } from 'util/checkRange';

import { WaveformIndex } from '@type/ecgEventType/eventUnit';

export function isSimilarPatternListInRange(
  similarPatternListAcrossRange: [WaveformIndex, WaveformIndex][],
  {
    onsetWaveformIndex,
    terminationWaveformIndex,
  }: {
    onsetWaveformIndex?: WaveformIndex;
    terminationWaveformIndex?: WaveformIndex;
  }
) {
  const adjustOnsetWfi = onsetWaveformIndex ?? terminationWaveformIndex;
  const adjustTerminationWfi = terminationWaveformIndex ?? onsetWaveformIndex;

  if (adjustOnsetWfi === undefined || adjustTerminationWfi === undefined) {
    return false;
  }

  const rst = isRangeListInRange(similarPatternListAcrossRange, {
    start: adjustOnsetWfi,
    end: adjustTerminationWfi,
  });

  return rst;
}
